import React from 'react';
import { Trans } from 'react-i18next';
import { UsefulInformation } from '../../Universe/UsefulInformation';

const plusUsefulInfos = [
  {
    question: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.first.question" />
    ),
    answer: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.first.answer" />
    ),
    value: 'first',
  },
  {
    question: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.second.question" />
    ),
    answer: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.second.answer" />
    ),
    value: 'second',
  },
  {
    question: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.third.question" />
    ),
    answer: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.third.answer" />
    ),
    value: 'third',
  },
  {
    question: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.fourth.question" />
    ),
    answer: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.fourth.answer" />
    ),
    value: 'fourth',
  },
  {
    question: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.fifth.question" />
    ),
    answer: (
      <Trans i18nKey="core.ecommerce.plus.useful_information.fifth.answer" />
    ),
    value: 'fifth',
  },
  // {
  //   question: (
  //     <Trans i18nKey="core.ecommerce.plus.useful_information.sixth.question" />
  //   ),
  //   answer: (
  //     <Trans i18nKey="core.ecommerce.plus.useful_information.sixth.answer" />
  //   ),
  //   value: 'sixth',
  // },
  // {
  //   question: (
  //     <Trans i18nKey="core.ecommerce.plus.useful_information.seventh.question" />
  //   ),
  //   answer: (
  //     <Trans i18nKey="core.ecommerce.plus.useful_information.seventh.answer" />
  //   ),
  //   value: 'seventh',
  // },
  // {
  //   question: (
  //     <Trans i18nKey="core.ecommerce.plus.useful_information.eighth.question" />
  //   ),
  //   answer: (
  //     <Trans i18nKey="core.ecommerce.plus.useful_information.eighth.answer" />
  //   ),
  //   value: 'eighth',
  // },
];

const PlusUsefulInformation = () => (
  <UsefulInformation informationsData={plusUsefulInfos} />
);

export default PlusUsefulInformation;
