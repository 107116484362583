/* istanbul ignore file */
// No need to be tested, it doesnt have any specific logic
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import coreApi from '../api/coreApi';
import { selectors } from '../coreSlice';

const useCountries = () => {
  const locale = useSelector(selectors.getLocale);
  return useQuery({
    queryKey: ['countries', locale],
    queryFn: () => coreApi.getCountries(locale),
    retry: false,
  });
};

export default useCountries;
