import { useCallback, useLayoutEffect } from 'react';

const useGetElementPosition = (
  ref: React.RefObject<HTMLElement>,
  shouldReportValue: boolean | undefined,
  reportValue: (clientRect: DOMRect) => void
) => {
  const handleChangeLocation = useCallback(() => {
    const clientRect = ref.current?.getBoundingClientRect();
    if (clientRect && shouldReportValue) {
      reportValue(clientRect);
    }
  }, [ref, reportValue, shouldReportValue]);
  useLayoutEffect(() => {
    handleChangeLocation();
    window.addEventListener('resize', handleChangeLocation);
    window.addEventListener('scroll', handleChangeLocation);
    return () => {
      window.removeEventListener('resize', handleChangeLocation);
      window.removeEventListener('scroll', handleChangeLocation);
    };
  }, [handleChangeLocation]);
};

export default useGetElementPosition;
