import { breakpoints, styled } from '@swibeco/shared';
import { InputProps, InputSProps, InputCProps, LabelProps } from '../types';

export const InputStyle = styled.input<InputProps>`
  font-size: ${({ size }) => (size ? `${size}pt` : '17px')};
  font-weight: 400;
  padding: ${({ customPaddingTopAndBottom, canHideInputValue }) => {
    const paddingTopAndBottom = customPaddingTopAndBottom
      ? `${customPaddingTopAndBottom}rem`
      : '0.25rem';

    return `${paddingTopAndBottom} ${
      canHideInputValue ? '2.6rem' : '0.5rem'
    } ${paddingTopAndBottom} 0.5rem`;
  }};

  border-radius: 3px;
  width: 100%;
  outline: none;
  color: ${({ theme, color }) => color || theme.colors?.default.black};
  text-align: ${({ align }) => align || 'left'};

  ${(p) =>
    p.border
      ? `border: 1px solid ${
          p.error
            ? p.theme.colors.default.middle
            : p.theme.colors.default.middle
        };`
      : `background-color: ${p.bg};
        border: 1px solid transparent;`}
  ${({ bg, theme }) =>
    bg && `background-color: ${bg || theme.colors?.default.black}`}

  ${(p) =>
    p.state && `border-bottom: 2px solid ${p.theme.colors.default.black};`};

  &:focus {
    ${(p) => !p.state && `border: 1px solid ${p.theme.colors.default.black};`};
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors?.default.black};
    opacity: 0.5;
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.colors?.default.black};
    opacity: 0.5;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors?.default.black};
    opacity: 0.5;
  }
`;

export const Label = styled.label<LabelProps>`
  display: block;
  margin: 0 0 4px 0;
  line-height: 18px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? '1.272px'};
  color: ${({ theme }) => `${theme.colors.default.black}`};
  display: flex;
  gap: 0.5rem;
  vertical-align: center;
  ${({ theme, isRequired }) =>
    isRequired &&
    `::after {
        content: ' *';
        color: ${theme.colors.complementary.red} `};
  ${breakpoints.sm_max} {
    ${({ displayOptional }) => displayOptional && 'line-height:24px;'}
  }
  ${breakpoints.lg_max} {
    font-size: 16px;
  }
`;

export const InputContainer = styled.div<InputCProps>`
  ${({ noMargin, selectOptions }) =>
    // eslint-disable-next-line no-nested-ternary
    !noMargin
      ? selectOptions
        ? 'margin-bottom: 1.3rem;'
        : 'margin-bottom: 1.4rem;'
      : ''};
  position: relative;
  p {
    color: ${({ theme, errorString }) =>
      errorString !== ''
        ? theme.colors.complementary.red
        : theme.colors.default.black};
    font-size: 15px;
    max-width: 100%;
    position: absolute;
  }
  ${breakpoints.sm_max} {
    ${({ noMargin, selectOptions }) =>
      // eslint-disable-next-line no-nested-ternary
      !noMargin
        ? selectOptions
          ? 'margin-bottom: 0.55rem;'
          : 'margin-bottom: 0.6rem;'
        : ''};
  }
`;

export const ErrorLabel = styled.p<InputSProps>`
  width: 100%;
  text-align: ${({ errorTextAlign }) => errorTextAlign || 'left'};
  pointer-events: none;
`;

export const PassIcon = styled.svg`
  position: absolute;
  right: 0.75rem;
  bottom: 45%;
`;

export const Icon = styled.svg`
  position: absolute;
  right: 0.75rem;
  bottom: 26%;
`;

export const Optional = styled.span`
  margin-left: 19px;
  color: ${(p) => p.theme.colors.default.strong};
  font-size: 17px;
`;

export const LabelBottom = styled.div<{ errorString?: boolean }>`
  text-align: right;
  ${({ errorString }) => errorString && 'margin-top: 1rem;'}
  color: ${(p) => p.theme.colors?.primary.main};
`;

export const LabelBottomContainer = styled.div`
  padding: 0.5rem 0;
`;

export const LinkControl = styled.a<{ letterSpace?: boolean }>`
  text-align: right;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  ${({ letterSpace }) => letterSpace && 'letter-spacing: 0.1rem;'}
`;
