import { styled } from '@swibeco/shared';
import { Input as RSInput } from 'reactstrap';
import { sliderThumbIcon } from './sliderThumbIcon';

export const InputDesign = styled(RSInput, { shouldForwardProp: () => true })`
  width: 100%;
  margin: 10px 0px;
  background-color: transparent;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    background: #d3d3d3;
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 3px;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  &::-webkit-slider-thumb {
    margin-top: -17.5px;
    width: 35px;
    height: 35px;
    ${({ theme }) => sliderThumbIcon(theme?.colors?.primary?.main)};
    background-position: center;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 17.5px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &:focus::-webkit-slider-runnable-track {
    background: #e0e0e0;
  }

  &::-moz-range-track {
    background: ${({ theme }) => theme.colors.default.white};
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: inset -2px 2px 4px rgba(123, 123, 123, 0.2),
      inset 2px -2px 4px rgba(123, 123, 123, 0.2),
      inset -2px -2px 4px rgba(255, 255, 255, 0.9),
      inset 2px 2px 5px rgba(123, 123, 123, 0.9);
    border-radius: 32px;
  }
  &::-moz-range-thumb {
    width: 35px;
    height: 35px;
    ${({ theme }) => sliderThumbIcon(theme?.colors?.primary?.main)};
    background-position: center;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 17.5px;
    cursor: pointer;
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 5.5px 0;
    color: transparent;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  &::-ms-fill-lower {
    background: #c6c6c6;
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 6px;
  }
  &::-ms-fill-upper {
    background: #d3d3d3;
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 6px;
  }
  &::-ms-thumb {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.primary.main};
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  &:focus::-ms-fill-lower {
    background: #d3d3d3;
  }
  &:focus::-ms-fill-upper {
    background: #e0e0e0;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    & {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }

  @-moz-document url-prefix() {
    margin: -8px 0;
  }
`;

export const MinMax = styled('span', {
  shouldForwardProp: (prop) => prop !== 'max',
})<{
  max?: boolean;
}>`
  position: absolute;
  left: ${({ max }) => (max ? '100%' : '0')};
  width: 100%;
  text-align: ${({ max }) => (max ? 'right' : 'left')};
  transform: translateX(${({ max }) => (max ? '-100%' : '0')});
  top: 25px;
  font-weight: 600;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.default.black};
`;

export const BubbleWrapper = styled.div<{ value?: number }>`
  ${({ theme, value }) =>
    typeof value !== 'undefined' &&
    `
  position: relative;
  margin-top: 2.375rem;
  background-color: ${theme.colors.primary.main};

  &:before {
    content: '${value}';
    left: calc(${value}% + ${8 - value * 0.15}px);
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    height: 1.8125rem;
    width: 1.8125rem;
    bottom: 0.9375rem;
    font-size: 13px;
    font-weight: 500;
    background: ${theme.colors.primary.main};
    border-radius: 0%;
    color: ${theme.colors.default.light};
    position: absolute;
    transform: translateX(-50%);
  }`}
`;
