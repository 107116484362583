import { Portal } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { MotionStyle, Transition, motion } from 'framer-motion';

type Props = {
  children: ReactNode;
  style: {
    left: number;
    top: number;
  } & MotionStyle;
  initialVariantStyle: {
    left: number;
    top: number;
  } & {
    [key: string]: any;
  };
  finalVariantStyle: {
    left: number;
    top: number;
  } & {
    [key: string]: any;
  };
  onAnimationComplete?: () => void;
  transition?: Transition;
};

export default function AnimatedMovingDiv({
  children,
  style,
  initialVariantStyle,
  finalVariantStyle,
  onAnimationComplete,
  transition,
}: Props) {
  return (
    <Portal>
      <motion.div
        style={{
          position: 'absolute',
          zIndex: 9999,
          height: 'fit-content',
          scale: 5,
          ...style,
        }}
        animate={['middle', 'end']}
        variants={{
          middle: {
            position: 'absolute',
            opacity: 1,
            scale: 4,
            rotate: 0,
            ...initialVariantStyle,
          },
          end: {
            position: 'absolute',
            opacity: 1,
            scale: 1,
            ...finalVariantStyle,
          },
        }}
        onAnimationComplete={onAnimationComplete}
        transition={{ delay: 0.5, duration: 1.5, ...transition }}
      >
        {children}
      </motion.div>
    </Portal>
  );
}
