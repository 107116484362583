import React from 'react';
import { styled } from '@swibeco/shared';
import { StyledColorProps } from '@swibeco/types';

export enum ChevronDirection {
  Down = 'translate(449.73 10855.414)',
  Up = 'translate(-433.73 -10847.414) rotate(180)',
  Left = 'translate(-10847.414 449.73) rotate(90)',
  Right = 'translate(10855.414 -433.73) rotate(-90)',
}

export type ChevronProps = {
  direction: ChevronDirection;
  color?: StyledColorProps;
  className?: string;
};

const ChevronWrapper = styled.span<StyledColorProps>`
  width: 16px;
  display: flex;

  svg {
    path {
      stroke: ${({ theme, variant, variantColor }) =>
        theme.colors[variant][variantColor]};
    }
  }
`;

const Chevron = ({ direction, color, className }: ChevronProps) => (
  <ChevronWrapper
    className={className}
    variant={color?.variant || 'primary'}
    variantColor={color?.variantColor || 'main'}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 20.242 12.242"
    >
      <g transform="translate(2.121 2.121)">
        <path
          className="a"
          d="M-449.73-10855.414l8,8,8-8"
          transform={direction}
          fill="none"
          stroke="#006cff;"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  </ChevronWrapper>
);

export default Chevron;
