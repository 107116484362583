import {
  selectors as coreSelectors,
  useElementOnScreen,
  useIsTablet,
  usePlatform,
} from '@swibeco/core';
import { homeStoreApi } from '@swibeco/ecommerce';
import { AnalyticsEvents, Brand } from '@swibeco/types';
import { Button } from '@swibeco/ui';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SwiperClass } from 'swiper/react';
import TagManager from 'react-gtm-module';
import * as Styles from './Slider.styles';
import { SliderBackground } from './SliderBackground';
import { SliderBrandImage } from './SliderBrandImage';
import { SliderSkeletonLoading } from './SliderSkeletonLoading';

const sendDataToAnalytics = (
  event: string,
  environment: string,
  brand: Brand
) => {
  const { id, name, title } = brand;
  TagManager.dataLayer({
    dataLayer: {
      event,
      environment,
      ecommerce: {
        items: [
          {
            promotion_id: id,
            promotion_name: `${id} - ${name} - ${title}`,
            creative_name: title,
          },
        ],
      },
    },
  });
};

const GoToDealUrl = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  const isExternalLink = /^(https?|http):\/\//i.test(to);

  if (isExternalLink) {
    return (
      <Styles.GoToDeal href={to} target="_blank" rel="noopener noreferrer">
        {children}
      </Styles.GoToDeal>
    );
  }

  return <Styles.GoToDeal to={to}>{children}</Styles.GoToDeal>;
};

const Slider = () => {
  const [brandSwiper, setBrandSwiper] = useState<SwiperClass | null>(null);
  const [backgroundSlider, setBackgroundSlider] = useState<SwiperClass | null>(
    null
  );
  const locale = useSelector(coreSelectors.getLocale);
  const [selectedBrandIndex, setSelectedBrand] = useState(0);
  const isTablet = useIsTablet();
  const [brands, setBrands] = useState<Brand[]>();
  const environment = usePlatform(window);
  // eslint-disable-next-line prefer-const
  let { data, isLoading, isSuccess } = useQuery<Brand[]>({
    queryKey: ['slider-deals', locale],
    queryFn: () => homeStoreApi.getSliderDeals(locale),
    placeholderData: keepPreviousData,
  });
  useEffect(() => {
    if (isSuccess && data) {
      const sortedData = [...data].sort(
        (a: Brand, b: Brand) => a.position - b.position
      );
      sendDataToAnalytics(
        AnalyticsEvents.VIEW_PROMOTION,
        environment,
        sortedData[0]
      );
      setBrands(sortedData);
    }
  }, [data, isSuccess, environment]);
  const slidesPerView = 'auto';
  const sliderProps = useMemo(() => {
    let dynamicProps = {};
    if (!isTablet) {
      dynamicProps = {
        cssMode: true,
        rewind: true,
      };
    }
    return dynamicProps;
  }, [isTablet]);

  const handleNextBrand = useCallback(() => {
    if (backgroundSlider) {
      backgroundSlider.slideNext();
    }
  }, [backgroundSlider]);

  const handlePrevBrand = useCallback(() => {
    if (backgroundSlider) {
      backgroundSlider.slidePrev();
    }
  }, [backgroundSlider]);

  const handleBrandSelection = useCallback(
    (brand: Brand) => {
      const index = brands?.findIndex((b: Brand) => b.id === brand.id);
      setSelectedBrand(index || 0);
      // eslint-disable-next-line dot-notation
      if (backgroundSlider && backgroundSlider['initialized']) {
        backgroundSlider.slideTo(index || 0);
      }
    },
    [backgroundSlider, brands]
  );

  const handleSlideToIndex = useCallback(
    (index: number) => {
      // eslint-disable-next-line dot-notation
      if (brandSwiper && brandSwiper['initialized']) {
        brandSwiper.slideTo(index || 0);
        setSelectedBrand(index || 0);
        // toggle selected class for span with class custom-bullet
        const bullets = document.querySelectorAll('.custom-bullet');
        if (bullets.length > 0) {
          const activeClasses = ['active', 'swiper-pagination-bullet-active'];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < bullets.length; i++) {
            bullets[i].classList.remove(...activeClasses);
          }
          bullets[index || 0].classList.add(...activeClasses);
        }
      }
    },
    [brandSwiper]
  );

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.8,
  });

  if (isLoading) return <SliderSkeletonLoading />;

  return brands ? (
    <Styles.SliderWrapper data-testid="slider" ref={containerRef}>
      <SliderBackground
        brandSwiper={brandSwiper}
        brands={brands}
        onSwiper={setBackgroundSlider}
        onSlideChange={({ activeIndex }) => {
          if (isVisible) {
            sendDataToAnalytics(
              AnalyticsEvents.VIEW_PROMOTION,
              environment,
              brands[activeIndex]
            );
          }
          handleSlideToIndex(activeIndex);
        }}
      >
        <Styles.SliderContent>
          <Styles.BrandDetails>
            <SliderBrandImage src={brands[selectedBrandIndex].logo} alt="" />
            <Styles.TextDetails>
              <Styles.SliderTitle level="h2">
                {brands[selectedBrandIndex].title}
              </Styles.SliderTitle>
              <Styles.CaptionSlider>
                {brands[selectedBrandIndex].description}
              </Styles.CaptionSlider>
            </Styles.TextDetails>
            <GoToDealUrl to={brands[selectedBrandIndex].link}>
              <Button
                color="primary"
                onClick={() =>
                  brands &&
                  sendDataToAnalytics(
                    AnalyticsEvents.SELECT_PROMOTION,
                    environment,
                    brands[selectedBrandIndex]
                  )
                }
              >
                {brands[selectedBrandIndex].textButton || 'go to deal'}
              </Button>
            </GoToDealUrl>
          </Styles.BrandDetails>
          <Styles.SliderItemsContainer>
            <Styles.SliderItems
              slidesPerView={slidesPerView}
              onSwiper={(swiper: any) => {
                handleSlideToIndex(0);
                setBrandSwiper(swiper);
              }}
              pagination={{
                dynamicBullets: false,
                clickable: false,
              }}
              {...sliderProps}
            >
              {brands.map((brand: Brand) => (
                <Styles.SliderItem
                  selectedBrand={
                    brands ? brand.id === brands[selectedBrandIndex].id : false
                  }
                  onClick={() => handleBrandSelection(brand)}
                  key={brand.id}
                  data-testid="slider-item"
                >
                  <Styles.BrandName component="span">
                    {brands && brand.id === brands[selectedBrandIndex].id && (
                      <Styles.BrandSelected />
                    )}
                    {brand.name}
                  </Styles.BrandName>
                </Styles.SliderItem>
              ))}
            </Styles.SliderItems>
            <Styles.SliderItemsActions>
              <Styles.GoToPrevBrand
                onClick={handlePrevBrand}
                data-testid="slider-next-item"
              />
              <Styles.GoToNextBrand
                onClick={handleNextBrand}
                data-testid="slider-prev-item"
              />
            </Styles.SliderItemsActions>
          </Styles.SliderItemsContainer>
        </Styles.SliderContent>
      </SliderBackground>
    </Styles.SliderWrapper>
  ) : null;
};
export default Slider;
