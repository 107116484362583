import { breakpoints, styled } from '@swibeco/shared';

export const MenuIcon = styled.div`
  cursor: pointer;
  ${breakpoints.lg} {
    display: none !important;
  }
`;

/* eslint-disable indent */
export const Span = styled.span<{ toggled?: boolean }>`
  display: block;
  width: 15px;
  height: 2px;
  margin-bottom: 4.4px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.default.black};
  border-radius: 3px;
  transition: all 0.4s ease;

  &:first-of-type {
    transform-origin: 0% 0%;
  }

  &:nth-of-type(2) {
    width: 9.5px;
  }

  &:last-child {
    transform-origin: 0% 0%;
    margin-bottom: 0;
  }

  ${({ toggled }) => toggled && 'height: 1.8px; '};

  &:first-of-type {
    ${({ toggled }) =>
      toggled && 'transform: rotate(45deg) translate(3px, -1px);'}
  }

  &:nth-of-type(2) {
    ${({ toggled }) => toggled && 'opacity: 0;'}
  }

  &:last-child {
    ${({ toggled }) =>
      toggled && 'transform: rotate(-45deg) translate(1px, 1px);'}
  }
`;
/* eslint-enable indent */

export const HamburgerMenuIcon = styled.div``;
