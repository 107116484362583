import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type * as rtk from '@reduxjs/toolkit';

export interface SecurityState {
  authenticated: boolean | null;
  onboardingModal: {
    visible: boolean;
  };
}

const initialState: SecurityState = {
  authenticated: null, // at the beginning, we don't know if we are authenticated or not
  onboardingModal: {
    visible: false,
  },
};

const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    userLoggedIn: (state) => {
      state.authenticated = true;
    },
    userLoggedOut: (state) => {
      state.authenticated = false;
    },
    reset: () => initialState,
    setOnboardingModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.onboardingModal.visible = action.payload;
    },
  },
});

const isAuthenticated = (state: { security: SecurityState }) =>
  state.security.authenticated;
const isOnboardingModalVisible = (state: {
  security: SecurityState;
}): boolean => state.security.onboardingModal.visible;

export const actions = { ...securitySlice.actions };
export const selectors = { isAuthenticated, isOnboardingModalVisible };

// eslint-disable-next-line prefer-destructuring
export const reducer = securitySlice.reducer;
