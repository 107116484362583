import { syliusApi } from '@swibeco/ecommerce';
import { SubTitle, HoverBehavior } from '@swibeco/ui';
import { ECOMMERCE_CATEGORY_ROOT, useColorVariant } from '@swibeco/shared';
import {
  EnumLinkProps,
  AnalyticsContext,
  TaxonWithProducts,
  UserTypeEnum,
} from '@swibeco/types';
import { MainContentWrapper } from '@swibeco/shared-web';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { selectors as coreSelectors } from '@swibeco/core';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { useGetCurrentUser } from '@swibeco/security';
import { addWeeks } from 'date-fns';
import { ExploreCallouts } from '../ExploreCallouts';
import { PlusBanner } from '../PlusBanner';
import * as Styles from './Sliders.style';
import { ProductsSwiper } from './ProductsSwiper';
import { ProductsSkeletonLoading } from './ProductsSkeletonLoading';
import { BrandsSwiper } from './BrandsSwiper';
import { HighlightedBrandItem } from '../../components/Brands/HighlightedBrandItem';
import { BrandItem } from '../../components/Universe/BrandsList';
import highlightedBrandIcon from '../../assets/svgs/star-in-circle.svg';
import stopwatchInCircleIcon from '../../assets/svgs/stopwatch-in-circle.svg';
import plusInCircleIcon from '../../assets/svgs/plus-in-circle.svg';

const Sliders = () => {
  const { t } = useTranslation();
  const locale = useSelector(coreSelectors.getLocale);
  const { data, isLoading } = useQuery<TaxonWithProducts[]>({
    queryKey: ['explore-discounts', locale],
    queryFn: () => syliusApi.getExploreDiscounts(locale),
  });
  const { data: fomoProducts } = useQuery({
    queryKey: ['products-sylius-fomo', locale],
    queryFn: () =>
      syliusApi.getProductsListing(locale, {
        itemsPerPage: 16,
        endDate: {
          before: addWeeks(new Date(), 1),
        },
        order: { endDate: 'asc' },
        exists: { endDate: true },
        isFlash: true,
      }),
    // enabled: user?.type === UserTypeEnum.FREE,
  });
  const { data: user } = useGetCurrentUser();
  const { data: brands, isLoading: brandsLoading } = useQuery({
    queryKey: ['brands-sylius', locale],
    queryFn: () =>
      syliusApi.getBrands(undefined, locale, {
        itemsPerPage: 16,
        'channelAvailability[]': UserTypeEnum.PRO.toLowerCase(),
        withActiveProducts: true,
      }),
    // enabled: user?.type === UserTypeEnum.FREE,
  });
  const { data: highlightedBrands } = useQuery({
    queryKey: ['highlighted-brands-sylius', locale],
    queryFn: () =>
      syliusApi.getBrands(undefined, locale, {
        itemsPerPage: 16,
        'order[position]': 'asc',
        withActiveProducts: true,
        exists: {
          position: true,
        },
      }),
    // enabled: user?.type === UserTypeEnum.FREE,
  });
  const mainColorVariant = useColorVariant('primary', 'main');

  const products = useMemo(
    () =>
      data?.filter((productsList) => productsList.optimizedProducts.length > 0),
    [data]
  );

  const canSplit = products && products.length > 1;

  if (isLoading || brandsLoading) return <ProductsSkeletonLoading />;

  /* analytics */
  const analyticsContext: AnalyticsContext = {
    listId: '',
    listName: '',
    quantity: 1,
    category: '',
  };
  /* end analytics */

  return (
    <Styles.ProductsSegment data-testid="deals">
      <MainContentWrapper>
        <Styles.Container>
          <SubTitle>{t('core.ecommerce.homepage.deals.title')}</SubTitle>
          <HoverBehavior className="ml-4" color={mainColorVariant}>
            <Styles.Link
              type={EnumLinkProps.NewPage}
              color={mainColorVariant}
              to={`${ECOMMERCE_CATEGORY_ROOT}/all-results`}
            >
              {t('core.ecommerce.homepage.deals.link')}
            </Styles.Link>
          </HoverBehavior>
        </Styles.Container>
        <ExploreCallouts />
        <Box h="32px" />
      </MainContentWrapper>
      {highlightedBrands && highlightedBrands.length > 0 && (
        <Box pb="24px">
          <BrandsSwiper
            forceShowAll
            position={1}
            analytics={{
              ...analyticsContext,
              listId: 'homepage_highlighted_brands',
              listName: 'Homepage - Highlighted Brands',
            }}
            mainContent
            title={t('core.ecommerce.homepage.highlighted_brands.title')}
            brands={highlightedBrands}
            component={HighlightedBrandItem}
            componentProps={{
              maxW: '181px',
              maxH: '80px',
            }}
            icon={highlightedBrandIcon}
            link="/core/universe/brands"
          />
        </Box>
      )}
      {fomoProducts?.length > 0 && (
        <Box pb="24px">
          <ProductsSwiper
            forceShowAll
            position={2}
            analytics={{
              ...analyticsContext,
              listId: 'homepage_flash_deals',
              listName: 'Homepage - Flash deals',
            }}
            mainContent
            title={t('core.ecommerce.homepage.fomo.title')}
            icon={stopwatchInCircleIcon}
            products={fomoProducts}
            link={`${ECOMMERCE_CATEGORY_ROOT}/flash-deals`}
          />
        </Box>
      )}
      {user?.type === UserTypeEnum.FREE && brands && brands.length > 0 && (
        <Box pb="120px">
          <BrandsSwiper
            forceShowAll
            position={3}
            analytics={{
              ...analyticsContext,
              listId: 'homepage_plus_brands',
              listName: 'Homepage - Plus Brands',
            }}
            mainContent
            title={t('core.ecommerce.homepage.brands_for_plus.title')}
            brands={brands}
            component={BrandItem}
            icon={plusInCircleIcon}
            link="/core/universe/plus-brands?channelAvailability[]=pro"
          />
        </Box>
      )}
      {products?.slice(0, canSplit ? 1 : products.length).map(
        (productsList) =>
          productsList.optimizedProducts.length > 0 && (
            <Box pb="24px" key={productsList.slug}>
              <ProductsSwiper
                position={4}
                mainContent
                title={productsList.name}
                icon={productsList.images?.[0]?.path}
                products={productsList.optimizedProducts}
                analytics={analyticsContext}
              />
            </Box>
          )
      )}
      <PlusBanner />
      {canSplit &&
        products?.slice(1, products.length).map(
          (productsList, index) =>
            productsList.optimizedProducts.length > 0 && (
              <Box pb="24px" key={productsList.slug}>
                <ProductsSwiper
                  position={index + 5}
                  mainContent
                  title={productsList.name}
                  icon={productsList.images?.[0]?.path}
                  products={productsList.optimizedProducts}
                  analytics={{
                    listId: 'homepage_deals',
                    listName: `Homepage - ${productsList.name}`,
                    category: productsList.name,
                    quantity: 1,
                  }}
                />
              </Box>
            )
        )}
    </Styles.ProductsSegment>
  );
};
export default Sliders;
