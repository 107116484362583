import React from 'react';

export const NewTabIcon = ({ fill }: { fill: string }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2857 5C13.6536 5 13.1429 5.47879 13.1429 6.07143C13.1429 6.66406 13.6536 7.14286 14.2857 7.14286H16.0964L10.05 12.8147C9.60357 13.2333 9.60357 13.9129 10.05 14.3315C10.4964 14.75 11.2214 14.75 11.6679 14.3315L17.7143 8.6596V10.3571C17.7143 10.9498 18.225 11.4286 18.8571 11.4286C19.4893 11.4286 20 10.9498 20 10.3571V6.07143C20 5.47879 19.4893 5 18.8571 5H14.2857ZM6.85714 6.07143C5.27857 6.07143 4 7.27009 4 8.75V17.3214C4 18.8013 5.27857 20 6.85714 20H16C17.5786 20 18.8571 18.8013 18.8571 17.3214V14.6429C18.8571 14.0502 18.3464 13.5714 17.7143 13.5714C17.0821 13.5714 16.5714 14.0502 16.5714 14.6429V17.3214C16.5714 17.6161 16.3143 17.8571 16 17.8571H6.85714C6.54286 17.8571 6.28571 17.6161 6.28571 17.3214V8.75C6.28571 8.45536 6.54286 8.21429 6.85714 8.21429H9.71429C10.3464 8.21429 10.8571 7.73549 10.8571 7.14286C10.8571 6.55022 10.3464 6.07143 9.71429 6.07143H6.85714Z"
      fill={fill}
    />
  </svg>
);
