import React, { ReactNode } from 'react';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { Navigate } from 'react-router-dom';

interface ErrorBoundaryProps {
  children?: ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
}

const customJSON = /* istanbul ignore next */ (logInfo: {
  message: string;
  level: { label: string };
  stacktrace: string;
}) => ({
  message: logInfo.message,
  level: logInfo.level.label,
  stacktrace: logInfo.stacktrace,
  timestamp: () => new Date().toISOString(),
  url: window.location.href,
});

remote.apply(log, {
  url: 'api/v2/logger',
  method: 'POST',
  format: customJSON,
  capacity: 50,
});
log.enableAll();

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * Update state so the next render will show the fallback UI.
   */
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /**
   * Log error messages to an error reporting service here
   * @param error
   */
  componentDidCatch(error: unknown) {
    log.error(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    // Error message
    if (hasError) {
      return <Navigate to="/error" />;
    }

    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
