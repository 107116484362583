import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors as coreSelectors, usePlatform } from '@swibeco/core';
import TagManager from 'react-gtm-module';
import { RootState } from '@swibeco/store';
import { useLocation } from 'react-router-dom';
import { AnalyticsEvents } from '@swibeco/types';
import { GlobalSelectors, generatePageName } from '@swibeco/shared';
import { useCurrentCompany } from '@swibeco/security';

type DataLayer = {
  event: AnalyticsEvents.PAGE_VIEW;
  environment: string;
  language: string;
  user_role: string;
  user_id: number | null;
  content_group: string;
  subscription_type?: string;
  user_type?: string;
};

const PageViewDataLayerHOC = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation();
  const locale = useSelector(coreSelectors.getLocale);
  const pageNameRef = useRef('');
  const isUserFetched = useSelector(GlobalSelectors.user.isUserFetched);
  const isPrevLocationLoginRef = useRef(false);
  const { company } = useCurrentCompany();
  const contract_type = company?.contract_type;
  const environment = usePlatform(window);

  const { id, type, roles } = useSelector(
    (state: RootState) => state.global.user
  );

  const trackPageView = useCallback(
    (pathname: string) => {
      const pageName = generatePageName(pathname);
      if (
        pageName !== null &&
        pageName !== pageNameRef.current &&
        isUserFetched
      ) {
        if (isPrevLocationLoginRef.current) {
          TagManager.dataLayer({
            dataLayer: {
              event: AnalyticsEvents.LOGIN,
              environment,
              user_id: id,
            },
          });
          isPrevLocationLoginRef.current = false;
        }
        const dataLayer: DataLayer = {
          event: AnalyticsEvents.PAGE_VIEW,
          environment,
          language: locale as string,
          user_id: id,
          content_group: pageName,
          user_role: roles.join(),
        };

        if (type) {
          dataLayer.user_type = type;
        }

        if (contract_type) {
          dataLayer.subscription_type = contract_type;
        }

        TagManager.dataLayer({ dataLayer });
        pageNameRef.current = pageName;
      }
    },
    [locale, environment, type, roles, contract_type, id, isUserFetched]
  );

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname, trackPageView]);

  return children;
};

export default PageViewDataLayerHOC;
