import TagManager from 'react-gtm-module';
import { AnalyticsEvents } from '@swibeco/types';

type PromotionEventDiscriminatingUnionItemType =
  | {
      item_id?: string;
      item_name: string;
    }
  | {
      item_id: string;
      item_name?: string;
    };

type PromotionEventDetails = PromotionEventDiscriminatingUnionItemType & {
  creative_name?: string;
  creative_slot?: string;
  promotion_name?: string;
  promotion_id?: string;
};

export const trackViewPromotionEvent = (
  eventDetails: PromotionEventDetails,
  environment: string,
  extendedDetails: Record<any, any> = {}
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: AnalyticsEvents.VIEW_PROMOTION,
      environment,
      ecommerce: {
        items: [
          {
            ...eventDetails,
            ...extendedDetails,
          },
        ],
      },
    },
  });
};

export const trackSelectPromotionEvent = (
  eventDetails: PromotionEventDetails,
  environment: string,
  extendedDetails: Record<any, any> = {}
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: AnalyticsEvents.SELECT_PROMOTION,
      environment,
      ecommerce: {
        items: [
          {
            ...eventDetails,
            ...extendedDetails,
          },
        ],
      },
    },
  });
};
