import { produce } from 'immer';
import { universeActions } from './actions';
import { UniverseStore, universeTypes } from './types';

export const universeReducer = produce((state: UniverseStore, action) => {
  switch (action.type) {
    case universeTypes.SET_CONTEXT:
      state.context = action.payload;
      break;
    default:
      break;
  }
});

export const universeStoreActions = {
  universe: universeActions,
};
