import { RefObject, useEffect } from 'react';
import useIntersectionObserver from './useIntersectionObserver';

function useExecuteOnView(
  callback: () => void,
  elementRef: RefObject<Element>,
  onlyOnce = true
) {
  const entry = useIntersectionObserver(elementRef, { onlyOnce });

  useEffect(() => {
    if (entry) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry]);
}

export default useExecuteOnView;
