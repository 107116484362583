import React from 'react';
import { Heading, type HeadingProps } from '@chakra-ui/react';

export type TertiaryTitleProps = HeadingProps & {
  children: React.ReactNode;
};

const TertiaryTitle = ({
  as = 'h3',
  children,
  color,
  ...rest
}: TertiaryTitleProps) => (
  <Heading as={as} variant="tertiary-title" color={color} {...rest}>
    {children}
  </Heading>
);
export default TertiaryTitle;
