import { breakpoints, styled } from '@swibeco/shared';
import { Button } from '@swibeco/ui';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 34px;
`;

export const UseTheCode = styled(Button)`
  ${breakpoints.lg_max} {
    margin-top: 24px;
  }
  width: fit-content;
  margin-top: 24px;
`;

export const InsideButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;

export const CodeField = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.primary.main};
  background-color: ${({ theme }) => theme.colors.primary.decorationLight};
  letter-spacing: 8px;
  border-radius: 5px;
  word-break: break-word;
`;

export const CopyButton = styled(Button)`
  width: 100%;

  ${breakpoints.lg} {
    width: auto;
  }
  display: flex;
  justify-content: center;
`;
