import { selectors as coreSelectors } from '@swibeco/core';
import {
  GlobalSelectors,
  getTranslationsForLocale,
  isPremiumUser,
} from '@swibeco/shared';
import {
  ChannelPricing,
  MappedProductVariant,
  ProductVariant,
} from '@swibeco/types';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import syliusApi from '../api/syliusApi';

export type UseProductParams = {
  productVariant?: MappedProductVariant & {
    channelPricing: ChannelPricing;
  };
  isLoading: boolean;
};

export type IUseProductParams = {
  product: string;
};

export const useProduct = ({
  slug,
  options,
}: {
  slug?: string;
  options?: any;
} = {}): UseProductParams => {
  const locale = useSelector(coreSelectors.getLocale);
  const { product } = useParams<IUseProductParams>();
  const userType = useSelector(GlobalSelectors.user.getUserType);
  const premiumUser = isPremiumUser(userType);
  const productSlug = slug || product;
  const { data, isLoading } = useQuery<ProductVariant>({
    queryKey: ['sylius-product', productSlug],
    queryFn: () => syliusApi.getProductVariantBySlug(productSlug!, locale),
    enabled: !!productSlug,
    retry: (failureCount, error: any) => {
      if (error?.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    ...options,
  });
  if (!productSlug) {
    return {} as UseProductParams;
  }

  if (!data || !userType) {
    return {
      productVariant: undefined,
      isLoading,
    };
  }

  const mappedProductVariant: MappedProductVariant & { channelPricing: any } = {
    ...data,
    product: {
      ...data?.product,
      brand: data.product.brand && {
        ...data?.product.brand,
        translationsMap: getTranslationsForLocale(
          locale,
          data.product.brand.translations
        ),
      },
    },
    ...(data.variantChannelInfos?.[premiumUser ? 'PRO' : 'FREE'] || {}),
    variantChannelInfos:
      data.variantChannelInfos?.[premiumUser ? 'PRO' : 'FREE'] || {},
    channelPricing: data?.channelPricings?.[premiumUser ? 'PRO' : 'FREE'] || {},
    translationsMap: getTranslationsForLocale(locale, data.translations),
  };
  return {
    productVariant: mappedProductVariant,
    isLoading,
  };
};
