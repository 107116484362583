import { selectors as coreSelectors } from '@swibeco/core';
import {
  AxiosError,
  GlobalSelectors,
  getTranslationsForLocale,
  isPremiumUser,
} from '@swibeco/shared';
import { MappedProductVariant, ProductVariant } from '@swibeco/types';
import {
  UseQueryOptions,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import syliusApi from '../api/syliusApi';

export type UseProductsParams = {
  products: (MappedProductVariant & { channelPricing?: any })[];
  isLoading: boolean;
};

type IUseProductParams = {
  product: string;
};

export const useProductVariants = ({
  slug,
  options,
}: {
  slug?: string;
  options?: Omit<UseQueryOptions<ProductVariant[]>, 'queryKey'>;
} = {}): UseProductsParams => {
  const locale = useSelector(coreSelectors.getLocale);
  const { product } = useParams<IUseProductParams>();
  const userType = useSelector(GlobalSelectors.user.getUserType);
  const premiumUser = isPremiumUser(userType);
  const productSlug = slug || product;
  const { data, isLoading } = useQuery<ProductVariant[]>({
    queryKey: ['sylius-product-variants', productSlug],
    queryFn: () => syliusApi.getProductVariantsBySlug(productSlug!, locale),
    enabled: !!productSlug,
    retry: (failureCount, error: AxiosError) => {
      if (error?.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    placeholderData: keepPreviousData,
    ...options,
  });
  if (!productSlug) {
    return {} as UseProductsParams;
  }
  if (!data || !userType) {
    return {
      products: [],
      isLoading,
    };
  }
  const mappedProducts = data.map((product) => ({
    ...product,
    product: {
      ...product.product,
      brand: product.product.brand && {
        ...product.product.brand,
        translationsMap: getTranslationsForLocale(
          locale,
          product.product.brand?.translations
        ),
      },
    },
    ...(product.variantChannelInfos?.[premiumUser ? 'PRO' : 'FREE'] || {}),
    variantChannelInfos:
      product.variantChannelInfos?.[premiumUser ? 'PRO' : 'FREE'] || {},
    channelPricing:
      product.channelPricings?.[premiumUser ? 'PRO' : 'FREE'] || {},
    translationsMap: getTranslationsForLocale(locale, product?.translations),
  }));
  return {
    products: mappedProducts,
    isLoading,
  };
};
