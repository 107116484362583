import Colr from 'colr';
import { customTheme, SwibecoColors } from '../assets';

const buildThemes = (company?: any) => {
  const primary = company
    ? Colr.fromHex(company.theme.main_color)
    : Colr.fromHex(SwibecoColors.colors.primary.main);
  const mainLightHsl = primary.clone().toHslObject();
  const mainLight = Colr.fromHslObject({ ...mainLightHsl, l: 96 }).toHex();
  const light = primary.clone().lighten(19.3).toHex();
  const strong = primary.clone().darken(15).toHex();
  const dark = primary.clone().darken(34.8).toHex();
  const secondary = `${company?.theme.main_color}3D`;

  const theme = {
    colors: {
      ...SwibecoColors.colors,
      primary: {
        ...SwibecoColors.colors.primary,
        // override default swibeco colors
        main: company?.theme.main_color,
        mainLight,
        light,
        strong,
        dark,
      },
      shadow: {
        ...SwibecoColors.colors.shadow,
        // override default swibeco colors
        secondary,
      },
    },
    initial: {
      colors: {
        primary: SwibecoColors.colors.primary,
        shadow: SwibecoColors.colors.shadow,
      },
    },
  };

  const customChakraTheme = {
    ...customTheme,
    colors: {
      ...customTheme.colors,
      ...SwibecoColors.colors,
      primary: {
        ...customTheme.colors.primary,
        ...SwibecoColors.colors.primary,
        // override default swibeco colors
        main: company?.theme.main_color,
        mainLight,
        light,
        strong,
        dark,
      },
      shadow: {
        ...customTheme.colors.shadow,
        ...SwibecoColors.colors.shadow,
        // override default swibeco colors
        secondary,
      },
    },
    initial: {
      colors: {
        primary: SwibecoColors.colors.primary,
        shadow: SwibecoColors.colors.shadow,
      },
    },
  };

  return { theme, customChakraTheme };
};

export default buildThemes;
