import { breakpoints, styled } from '@swibeco/shared';
import { Col, Row } from 'reactstrap';

export const StyledCol = styled(Col)`
  ${breakpoints.md_max} {
    height: 100%;
  }
`;

export const StyledRow = styled(Row)`
  margin-right: 0;
  min-height: inherit;
  ${breakpoints.md} {
    height: 100%;
  }
  ${breakpoints.md_max} {
    padding-bottom: 32px;
  }
`;
