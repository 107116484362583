import { Text } from '@swibeco/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import swissFlag from '../../assets/images/swiss_flag.svg';
import * as Styles from './ReinsuranceElements.styles';

type ReinsuranceElementProps = {
  emoji: string | JSX.Element;
  title: string;
  description: string;
};

const ReinsuranceElement = ({
  emoji,
  title,
  description,
}: ReinsuranceElementProps) => (
  <Styles.ReinsuranceElementContainer>
    <Styles.ReinsuranceElementWrapper>
      <Styles.EmojiContainer>
        <span>{emoji}</span>
      </Styles.EmojiContainer>
      <Text bold align="center">
        {title}
      </Text>
      <Styles.StyledCaption>{description}</Styles.StyledCaption>
    </Styles.ReinsuranceElementWrapper>
  </Styles.ReinsuranceElementContainer>
);

const ReinsuranceElements = () => {
  const { t } = useTranslation();
  return (
    <Styles.ReinsuranceElementsContainer>
      <ReinsuranceElement
        emoji="🎉"
        title={t('core.ecommerce.universe.reassurance_elements.discount.title')}
        description={t(
          'core.ecommerce.universe.reassurance_elements.discount.description'
        )}
      />
      <ReinsuranceElement
        emoji="🌈"
        title={t(
          'core.ecommerce.universe.reassurance_elements.free_promo_code.title'
        )}
        description={t(
          'core.ecommerce.universe.reassurance_elements.free_promo_code.description'
        )}
      />
      <ReinsuranceElement
        emoji="💳"
        title={t(
          'core.ecommerce.universe.reassurance_elements.easy_payment.title'
        )}
        description={t(
          'core.ecommerce.universe.reassurance_elements.easy_payment.description'
        )}
      />
      <ReinsuranceElement
        emoji={<img src={swissFlag} alt="swiss-flag" />}
        // emoji="test"
        title={t(
          'core.ecommerce.universe.reassurance_elements.swiss_made.title'
        )}
        description={t(
          'core.ecommerce.universe.reassurance_elements.swiss_made.description'
        )}
      />
    </Styles.ReinsuranceElementsContainer>
  );
};

export default ReinsuranceElements;
