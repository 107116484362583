/* eslint-disable @typescript-eslint/no-explicit-any */
import { Ability, AbilityClass, AbilityBuilder } from '@casl/ability';
import { UserType } from '@swibeco/types';
import buildUserPermissions, { UserAbilities } from './user';

// later, UserAbilities | CompanyAbilities | DealAbilities etc
export type AppAbility = Ability<UserAbilities>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

const buildPermissionsForUser = (user: UserType) => {
  const builder = new AbilityBuilder(AppAbility);
  buildUserPermissions(builder as any, user);

  return builder.build({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    detectSubjectType: (object: any) => object!.kind,
  });
};

export default buildPermissionsForUser;
