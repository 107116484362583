import { useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import coreApi from '../api/coreApi';
import { actions, selectors } from '../coreSlice';

const useLanguageSwitcher = (): {
  locales: string[];
  setLocale: (newLocale: string) => void;
  currentLocale: string;
  isLoading: boolean;
} => {
  const { i18n } = useTranslation();
  const locales = useMemo(() => ['fr', 'en', 'de', 'it'], []);
  const dispatch = useDispatch();
  const currentLocale = useSelector(selectors.getLocale);
  const { mutate, isPending } = useMutation({
    mutationFn: coreApi.setLocale,
    onSuccess: (_, locale) => {
      dispatch(actions.setLocale(locale));
    },
  });
  const mutationRef = useRef(mutate);

  const setLocale = useCallback(
    (newLocale: any) => {
      if (!locales.includes(newLocale)) {
        return;
      }

      mutationRef.current(newLocale);
      i18n.changeLanguage(newLocale);
    },
    [locales, i18n]
  );

  return useMemo(
    () => ({
      locales,
      setLocale,
      currentLocale,
      isLoading: isPending,
    }),
    [locales, setLocale, currentLocale, isPending]
  );
};

export default useLanguageSwitcher;
