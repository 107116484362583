import { arrayFrom, useTheme } from '@swibeco/shared';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import * as BottomMenuStyles from '../BottomCategoriesMenu.styles';
import * as Styles from './BottomCategoriesMenuSkeletonLoading.styles';

const CategorySkeleton = () => {
  const theme = useTheme();
  return (
    <BottomMenuStyles.BottomCategoriesMenuItem>
      <Skeleton
        baseColor={theme.colors.default.white}
        circle
        width="60px"
        height="60px"
        containerClassName="mb-2"
        key="top"
      />
      <Skeleton
        baseColor={theme.colors.default.white}
        width="101px"
        height="21px"
        key="bottom-text"
      />
    </BottomMenuStyles.BottomCategoriesMenuItem>
  );
};

const BottomCategoriesMenuSkeletonLoading = () => (
  <BottomMenuStyles.BottomCategoriesMenuWrapper>
    <BottomMenuStyles.BottomCategoriesContentWrapper as={Styles.FlexContainer}>
      {arrayFrom(6).map((i) => (
        <CategorySkeleton key={i} />
      ))}
    </BottomMenuStyles.BottomCategoriesContentWrapper>
  </BottomMenuStyles.BottomCategoriesMenuWrapper>
);

export default BottomCategoriesMenuSkeletonLoading;
