import React from 'react';
import { breakpoints, styled } from '@swibeco/shared';
import { ShapeVariants } from '@swibeco/types';

export type IconWrapperProps = {
  sm?: boolean;
  shape?: ShapeVariants;
  children?: React.ReactNode;
};

const Icon = styled.span<IconWrapperProps>`
  width: 40px;
  height: 40px;
  font-size: 14px;
  border-radius: ${({ shape }) => {
    const variantMapping: { [key in ShapeVariants]: string } = {
      [ShapeVariants.Circle]: '50%',
    };
    if (shape) {
      return variantMapping[shape];
    }
    return 'unset';
  }};
  border: ${({ shape }) => shape && '1px solid #ccc'};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  ${breakpoints.sm} {
    width: ${({ sm }) => (sm ? '25px' : '32px')};
    height: ${({ sm }) => (sm ? '25px' : '32px')};
    font-size: ${({ sm }) => (sm ? '14px' : '17px')};
  }
  > img {
    width: 40px;
    height: 40px;
  }
`;

const IconWrapper = ({ sm, children, shape }: IconWrapperProps) => (
  <Icon sm={sm} shape={shape}>
    {children}
  </Icon>
);

export default IconWrapper;
