import { Text } from '@swibeco/ui';
import { breakpoints, styled } from '@swibeco/shared';

export const InspireMeWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
  padding-top: 37px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.decorationLight};
`;

export const InspireMeText = styled(Text)`
  margin-bottom: 16px;

  ${breakpoints.md_max} {
    padding: 0 38px;
    text-align: center;
  }
`;
