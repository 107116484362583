import 'proxy-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { styled, breakpoints } from '@swibeco/shared';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  padding: 50px;
  flex-direction: column;
`;

const Row = styled.div<{ centered?: boolean }>`
  width: 100%;
  display: flex;

  ${({ centered }) =>
    centered &&
    `
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;

  ${breakpoints.md} {
    width: 50%;
  }
`;

const Image = styled.img``;

const Text = styled.p`
  text-align: center;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.default.dark};
  line-height: 1.1;
  font-family: 'Segoe UI' !important;
`;

const IEPage = () => {
  const userLang = navigator.language;

  return (
    <Container>
      <Row>
        <LogoWrapper>
          <Image
            src="/static/img/placeholders/swibeco_logo.png"
            className="mx-auto d-block mb-4 img-fluid"
            alt="Swibeco logo"
          />
        </LogoWrapper>
      </Row>
      <Row centered>
        <Wrapper>
          <Image
            src="/static/img/placeholders/warning_icon.png"
            className="mx-auto d-block mb-4 img-fluid"
            alt="Warning logo"
            width="110"
          />
          <div>
            <Text>
              {userLang.startsWith('fr') ? (
                <>
                  Tu utilises actuellement Internet Explorer comme navigateur,
                  cette version n'est malheureusement pas supportée par
                  l'application Swibeco. Nous te recommandons d'utiliser{' '}
                  <a href="https://www.google.com/chrome/">Chrome</a> ou{' '}
                  <a href="https://www.mozilla.org/firefox/new/">Firefox</a>{' '}
                  pour une expérience optimale.
                </>
              ) : userLang.startsWith('de') ? (
                <>
                  Du verwendest den Internet Explorer als Web-Browser, leider
                  wird diese Version nicht mehr von Swibeco unterstützt. Für
                  eine optimale Nutzung, verwende am besten{' '}
                  <a href="https://www.google.com/chrome/">Chrome</a> oder{' '}
                  <a href="https://www.mozilla.org/firefox/new/">Firefox</a>.
                </>
              ) : (
                <>
                  You are using Internet Explorer as browser, unfortunately this
                  version is not supported on Swibeco application. We strongly
                  recommend you to use{' '}
                  <a href="https://www.google.com/chrome/">Chrome</a> or{' '}
                  <a href="https://www.mozilla.org/firefox/new/">Firefox</a> for
                  a better experience.
                </>
              )}
            </Text>
          </div>
        </Wrapper>
      </Row>
    </Container>
  );
};

export default IEPage;
