const countryCodes = {
  fr: 'FR',
  en: 'US',
  de: 'DE',
  it: 'IT',
};

export const getTranslationsForLocale = <T>(
  locale: string,
  translations: { [locale: string]: T }
): T => {
  const mappedLocale = `${locale}_${countryCodes[locale]}`;
  return translations[mappedLocale];
};

export const getCountryCodeByLocale = (locale: string): string =>
  `${locale}_${countryCodes[locale]}`;
