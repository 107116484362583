import { breakpoints, styled, withAttrs } from '@swibeco/shared';

import { ColorProps } from '@swibeco/types';
import { MainContentWrapper } from '@swibeco/shared-web';
import Modal from '@swibeco/ui/lib/components/Modal';
import testimonialBg from '../../assets/svgs/testimonial_bg.svg';
import { ReactComponent as TestimonialQuotesIcon } from '../../assets/svgs/testimonials_quotes.svg';
import plusBannerBg from '../../assets/svgs/plus_carousel_banner.svg';

export const PlusCarouselWrapper = styled.div<{
  fullWidth?: boolean;
  hasBeforeElement?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100vw' : '100%')};
  background-color: ${({ theme }) => theme.colors.default.main};
  position: relative;
  ${({ hasBeforeElement, theme }) =>
    hasBeforeElement &&
    `
  &:before {
    content: '';
    position: absolute;
    left: -100%;
    background-color: ${theme.colors.default.main};
    width: 100%;
    height: 100%;
  }
`})}
  
  .swiper-header {
    padding-top: 40px !important;
    margin-bottom: 16px !important;
  }
  .custom-swiper-wrapper,
  .swiper-header {
    margin: 0;
    ${breakpoints.xl} {
      width: 100%;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 29px;
    ${breakpoints.md} {
      font-size: 21px;
      line-height: 27px;
    }
  }
`;

export const ExploreDiscountsIcon = styled.img`
  height: 40px;
  width: 40px;
`;

export const CircularIconButtons = styled.button<ColorProps>`
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.default.white};
  margin-left: 4px;
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const BasketModal = styled(Modal)`
  .modal-footer {
    justify-content: center;
    ${breakpoints.sm} {
      justify-content: space-between;
    }
  }

  a {
    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
`;

export const DecorationShape = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.default.main};
  border-radius: 200px 0 0 200px;
  width: 400px;
  height: 248px;
  right: 0;

  ${breakpoints.sm_max} {
    width: 30%;
  }
`;

export const SwiperHeader = withAttrs(
  MainContentWrapper,
  ({ marginBottom, paddingTop }) => ({
    className: `d-flex justify-content-between mb-${marginBottom || 4} pt-${
      paddingTop || 4
    }`,
  })
);

export const Controls = styled.div<{ size: number; showControlsOn?: number }>`
  position: relative;
  ${breakpoints.md} {
    ${({ size }) => size < 3 && 'display:none'};
  }
  ${breakpoints.lg} {
    ${({ size, showControlsOn }) =>
      size < (showControlsOn || 5) && 'display:none'};
  }
`;
export const SwiperWrapper = styled(MainContentWrapper)`
  .swiper {
    overflow: visible;
  }
  .swiper-slide {
    min-width: 299px !important;

    ${breakpoints.lg} {
      margin-right: 20px !important;
    }
  }
`;

export const DiscountItemContainer = styled.div`
  width: 242px;
  height: 137px;
  background-color: ${({ theme }) => theme.colors.default.white};
  padding: 8px;
  border-radius: 3px;
`;

export const DiscountItemWrapper = styled.div`
  background: ${({ theme }) => theme.colors.primary.plusBlue};
  background-image: linear-gradient(
      262.34deg,
      rgba(0, 108, 255, 0) 22.52%,
      rgba(0, 108, 255, 0.6) 98.08%
    ),
    url(${testimonialBg});
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 60px;
  position: relative;
`;

export const DiscountItemLogoWrapper = styled.div`
  height: 42px;
  width: 105px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 52.5px);
  border-radius: 3px 3px 0px 0px;
  padding: 3px 12px 3px;
  background-color: ${({ theme }) => theme.colors.default.white};
  img {
    height: 100%;
    width: 100%;
  }
`;
export const DiscountItemDetails = styled.div`
  padding-top: 20px;
  padding-bottom: 15px;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;
export const DiscountItemDiscount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  height: 35px;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 32px;
`;

export const TestimonialItemContainer = styled.div`
  width: 242px;
  height: 137px;
  background-color: ${({ theme }) => theme.colors.primary.plusBlue};
  border-radius: 3px;
  padding: 7px 8px 6px;
  display: flex;
  flex-direction: column;
`;

export const TestimonialItemTextWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.default.white};
  border-radius: 3px;
  padding: 6px 13px 10px;
  flex: 1;
  position: relative;
  max-height: 80%;
`;

export const TestimonialMemberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 3px;
  max-height: 100%;
  overflow: hidden;
`;

export const TestimonialQuotes = styled(TestimonialQuotesIcon)`
  position: absolute;
  top: 2px;
  left: 12px;
`;

export const PlusBanner = styled.div`
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.primary.plusBlue};
  background-image: linear-gradient(
      72.89deg,
      rgba(0, 108, 255, 0) 64.24%,
      rgba(0, 108, 255, 0.6) 104.34%
    ),
    url(${plusBannerBg});
  background-size: cover;
  min-width: 330px;
  display: flex;
  flex-direction: column;
  padding: 34px 17px 32px 17px;

  ${breakpoints.lg} {
    margin: 0 33px;
  }
`;

export const PlusCarouselGrid = styled.div<{ hasBanner?: boolean }>`
  gap: 66px;
  grid-template-columns: ${({ hasBanner }) =>
    hasBanner ? '330px 1fr' : '1fr'};

  ${breakpoints.lg} {
    display: grid;
  }
`;
