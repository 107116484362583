import React from 'react';
import { Text as CText, type TextProps } from '@chakra-ui/react';

export type LegalsProps = TextProps & {
  children?: React.ReactNode;
};

const Legals = ({ as = 'p', color, children, ...rest }: LegalsProps) => (
  <CText
    data-typo="legal"
    variant="legal"
    as={as}
    color={color || 'default.black'}
    {...rest}
  >
    {children}
  </CText>
);

export default Legals;
