import { SectionTitle } from '@swibeco/ui';
import {
  FAQDictionaryRecords,
  GlobalSelectors,
  useFaqDictionary,
} from '@swibeco/shared';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Information, Informations } from '../../Shared/Informations';

enum InformationRecords {
  DiscountOffer = 'Discount Offer',
  SwipointsBenefits = 'Swipoints Benefits',
  PaymentMethods = 'Payment Methods',
  Swipoints = 'Swipoints',
  Swibeco = 'Swibeco',
}

type UsefulInfoType = {
  question: React.ReactNode;
  answer: React.ReactNode;
  value: InformationRecords | string;
};

type UsefulInformationProps = {
  informationsData?: UsefulInfoType[];
};

const UsefulInformation = ({ informationsData }: UsefulInformationProps) => {
  const { faqDictionary } = useFaqDictionary();
  const { available_swipoints } = useSelector(
    GlobalSelectors.company.getCurrentCompany
  );
  const swipointsBalance = useSelector(
    GlobalSelectors.user.getSwipointsBalance
  );

  const usefulInfos = useMemo(() => {
    const infos = faqDictionary.pick([
      FAQDictionaryRecords.Swibeco,
      FAQDictionaryRecords.DiscountOffer,
      FAQDictionaryRecords.PaymentMethods,
    ]);

    if (
      (available_swipoints && available_swipoints > 0) ||
      swipointsBalance > 0
    ) {
      infos.push(
        faqDictionary[FAQDictionaryRecords.Swipoints],
        faqDictionary[FAQDictionaryRecords.SwipointsBenefits]
      );
    }

    return infos;
  }, [faqDictionary, swipointsBalance, available_swipoints]);
  const info = informationsData || usefulInfos;
  return (
    <section>
      <SectionTitle margin="10px 0">
        <Trans i18nKey="core.ecommerce.universe.useful_information.title" />
      </SectionTitle>
      <Informations>
        <div>
          {info.map((info, index) => (
            <Information
              key={info.value}
              question={info.question}
              answer={info.answer}
              number={index}
            />
          ))}
        </div>
      </Informations>
    </section>
  );
};

export default UsefulInformation;
