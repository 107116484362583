import { Card, CardBody, Flex, useToken } from '@chakra-ui/react';
import { Link, Text } from '@swibeco/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconHistory } from '../../../../../assets/images/history.svg';

const OrderHistory = ({
  closeBottomSheet,
}: {
  closeBottomSheet: () => void;
}) => {
  const { t } = useTranslation();
  const shadowColor = useToken('colors', 'shadow.primary');
  const HandleBottomSheet = () => {
    closeBottomSheet();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Flex direction="column" gap="8px">
      <Text variant="default-important" bold>
        {t('core.header.userprofile.menu.myorders')}
      </Text>
      <Link
        to="/core/user/orders"
        onClick={HandleBottomSheet}
        style={{ textDecoration: 'none', display: 'block' }}
      >
        <Card
          boxShadow={`0px 3px 6px 0px ${shadowColor}`}
          _hover={{
            backgroundColor: 'default.main',
            cursor: 'pointer',
          }}
        >
          <CardBody p="16px">
            <Flex
              gap="8px"
              align="center"
              color="unset"
              fontWeight="normal"
              _hover={{
                color: 'default.black',
              }}
            >
              <IconHistory />
              <Text mb="0" mr="16px">
                {t('core.header.userprofile.menu.orderhistory')}
              </Text>
            </Flex>
          </CardBody>
        </Card>
      </Link>
    </Flex>
  );
};
export default OrderHistory;
