import React from 'react';
import { styled } from '@swibeco/shared';
import { ColorProps, StyledColorProps } from '@swibeco/types';

export type StyledHomeIconProps = StyledColorProps;

const HomeIconWrapper = styled.span`
  color: ${({ theme }) => theme.colors.default.black};
  width: 20px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
  display: flex;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export type HomeIconProps = ColorProps & {
  className?: string;
  onClick?: () => void;
};

const HomeIcon = ({ className, onClick }: HomeIconProps) => (
  <HomeIconWrapper className={className} onClick={onClick}>
    <svg
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M16.437 6.166c.284 0 .515.231.515.517v9.816c0 .285-.23.516-.515.516H3.401a.516.516 0 0 1-.514-.516V6.9a.516.516 0 1 1 1.029 0v9.082h12.007v-9.3c0-.285.23-.516.514-.516Z"
        clipRule="evenodd"
      />
      <path
        fillOpacity=".15"
        d="M7.003 13.384a2.922 2.922 0 0 1 2.916-2.928c1.61 0 2.916 1.31 2.916 2.928V17H7.003v-3.616Z"
      />
      <path
        fillRule="evenodd"
        d="M11.806 15.967v-2.583a1.89 1.89 0 0 0-1.887-1.895 1.89 1.89 0 0 0-1.886 1.895v2.583h3.773Zm-1.887-5.51a2.922 2.922 0 0 0-2.916 2.927V17h5.832v-3.616a2.922 2.922 0 0 0-2.916-2.928ZM9.682.61a.513.513 0 0 1 .674-.002l9.161 7.906c.216.186.24.512.055.729a.513.513 0 0 1-.726.055l-8.823-7.614-8.685 7.611a.513.513 0 0 1-.726-.05.518.518 0 0 1 .05-.728L9.682.61Z"
        clipRule="evenodd"
      />
    </svg>
  </HomeIconWrapper>
);

export default HomeIcon;
