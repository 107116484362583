import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectors as coreSelectors } from '@swibeco/core';
import { EnumDealOfferType } from '@swibeco/types';
import ecommerceApi from '../api/ecommerceApi';

export const useSubscriptionDeals = () => {
  const locale = useSelector(coreSelectors.getLocale);

  const { data: subscriptions, ...rest } = useQuery({
    queryKey: ['subscriptions', locale],
    queryFn: () =>
      ecommerceApi.searchDeals(
        '',
        locale,
        'search',
        EnumDealOfferType.Subscription
      ),
  });

  return { subscriptions, ...rest };
};
