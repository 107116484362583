import React from 'react';
import { Text as CText, type TextProps } from '@chakra-ui/react';

export type SectionTitleProps = TextProps & {
  children: React.ReactNode;
};

/**
 * @deprecated
 * use Chakra's <Text variant="section-title"
 */
const SectionTitle = ({
  as = 'p',
  color,
  children,
  ...rest
}: SectionTitleProps) => (
  <CText
    as={as}
    variant="section-title"
    color={color || 'default.black'}
    {...rest}
  >
    {children}
  </CText>
);

export default SectionTitle;
