import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { arrayFrom } from '@swibeco/shared';
import * as Styles from './DealsListSkeletonLoading.styles';
import DealCardSkeletonLoading from './DealCardSkeletonLoading';

const DealsListSkeletonLoading = ({
  dealCount = 3,
  showTitle = false,
  showSubTitle = false,
}) => (
  <>
    {showTitle && (
      <Skeleton height="41px" width="260px" className="mb-4" key="top" />
    )}
    {showSubTitle && (
      <Skeleton height="26px" width="195px" className="mb-3" key="bottom" />
    )}
    <Styles.FlexContainer>
      {arrayFrom(dealCount).map((i) => (
        <DealCardSkeletonLoading key={i} />
      ))}
    </Styles.FlexContainer>
  </>
);

export default DealsListSkeletonLoading;
