import { isAndroidWebView, isIosWebView } from '@swibeco/shared';
import useIsTablet from './useIsTablet';
import useIsMobile from './useIsMobile';

enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  PWA = 'PWA',
  Desktop = 'desktop',
}

export const usePlatform = (window: Record<string, any>) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const isIosWebViewFlag = isIosWebView(window);
  const isAndroidWebViewFlag = isAndroidWebView(window);

  if (isMobile && (!isIosWebViewFlag || !isAndroidWebViewFlag)) {
    return DeviceType.Mobile;
  }

  if (isTablet && (!isIosWebViewFlag || !isAndroidWebViewFlag)) {
    return DeviceType.Tablet;
  }

  if (isIosWebViewFlag || isAndroidWebViewFlag) {
    return DeviceType.PWA;
  }

  return DeviceType.Desktop;
};

export default usePlatform;
