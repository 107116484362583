import { breakpoints, styled } from '@swibeco/shared';
import dottedCircle from '../../../assets/images/dotted_circle.svg';

export const Col = styled.div<{
  grow?: boolean;
  mdHidden?: boolean;
  maxWidth?: number;
  minWidth?: number;
  centered?: boolean;
  widthFull?: boolean;
}>`
  display: flex;
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  width: ${({ widthFull }) => (widthFull ? '100%' : 'auto')};
  ${breakpoints.md} {
    flex-direction: column;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
    min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '')};
    align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
    text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  }
  ${breakpoints.md_max} {
    flex-direction: row;
    min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '')};
    display: ${({ mdHidden }) => (mdHidden ? 'none' : 'flex')};
    justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
    align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  }
  ${breakpoints.sm_max} {
    min-width: 0;
  }
`;

export const Circle = styled.div`
  font-size: 23px;
  min-height: 39px;
  min-width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${breakpoints.md} {
    margin-bottom: 1rem;
    margin-right: 0rem;
  }
  margin-right: 0.5rem;
  background-image: url(${dottedCircle});
  background-repeat: no-repeat;
  background-position: center;
`;

export const DiscoverCol = styled(Col)<{ isSwipointsBannerShown?: boolean }>`
  justify-content: ${({ isSwipointsBannerShown }) =>
    isSwipointsBannerShown ? 'flex-start' : 'center'};
`;

export const HeadingAndFlashDeals = styled.div`
  position: relative;
  padding-right: 70px;
  ${breakpoints.md} {
    margin-right: 16px;
  }
`;

export const FlashDealInBanner = styled.div<{
  top: number;
  right: number;
}>`
  z-index: 2;
  position: absolute;
  top: ${({ top }) => `${top}px`};
  right: ${({ right }) => `${right}px`};
`;
