import React, { Suspense } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { selectors as coreSelectors, usePlatform } from '@swibeco/core';
import { useSelector } from 'react-redux';
import { syliusApi } from '@swibeco/ecommerce';
import { Caption } from '@swibeco/ui';
import { Swiper, SwiperSlide } from '@swibeco/shared-web';
import { SyliusCategory } from '@swibeco/types';
import { ECOMMERCE_CATEGORY_ROOT } from '@swibeco/shared';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import * as Styles from './BottomCategoriesMenu.styles';
import { BottomCategoriesMenuSkeletonLoading } from './BottomCategoriesMenuSkeletonLoading';
import flashDeals from '../../assets/icons/flash-deals.png';

const BottomCategoriesMenuContent = () => {
  const locale = useSelector(coreSelectors.getLocale);
  const { t } = useTranslation();
  const { data: categories } = useQuery<SyliusCategory[]>({
    queryKey: ['sylius-taxons-with-product-variants', locale],
    queryFn: () => syliusApi.getTaxonsWithProductVariants(locale),
    placeholderData: keepPreviousData,
  });
  const environment = usePlatform(window);

  const goToCategory = (categoryName: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'click_universe',
        environment,
        universe_name: `${categoryName}`,
      },
    });
  };

  return categories?.length ? (
    <Styles.BottomCategoriesMenuWrapper data-testid="bottom-categories">
      <Styles.BottomCategoriesContentWrapper>
        <Swiper spaceBetween={30} style={{ overflow: 'visible' }}>
          <SwiperSlide>
            <Styles.BottomCategoriesMenuItem key="flash-deals">
              <Styles.BottomCategoriesImageWrapper
                to={`${ECOMMERCE_CATEGORY_ROOT}/flash-deals`}
                onClick={() => {
                  goToCategory('Flash Deals');
                }}
              >
                <img src={flashDeals} alt="flash deals" />
              </Styles.BottomCategoriesImageWrapper>
              <Caption data-testid="flash-deals">
                {t('core.homepage.categories.flash_deals')}
              </Caption>
            </Styles.BottomCategoriesMenuItem>
          </SwiperSlide>
          {categories.map((category) => (
            <SwiperSlide key={category.slug}>
              <Styles.BottomCategoriesMenuItem>
                <Styles.BottomCategoriesImageWrapper
                  to={`${ECOMMERCE_CATEGORY_ROOT}/${category.slug}`}
                  onClick={() => {
                    goToCategory(category.name);
                  }}
                >
                  <img
                    src={category.images?.[0]?.path || ''}
                    alt={category.slug}
                  />
                </Styles.BottomCategoriesImageWrapper>
                <Styles.BottomCategoriesCaption>
                  {category.name}
                </Styles.BottomCategoriesCaption>
              </Styles.BottomCategoriesMenuItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </Styles.BottomCategoriesContentWrapper>
    </Styles.BottomCategoriesMenuWrapper>
  ) : null;
};

const BottomCategoriesMenu = () => (
  <Suspense fallback={<BottomCategoriesMenuSkeletonLoading />}>
    <BottomCategoriesMenuContent />
  </Suspense>
);

export default BottomCategoriesMenu;
