import React, { useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chevron, ChevronDirection } from '@swibeco/ui';
import { Button, Flex } from '@chakra-ui/react';
import { useTheme } from '@swibeco/shared';
import HeaderItem from '../HeaderItem';
import { Badge } from '../Badge';
import * as Styles from './styles/MobileSearch.styles';
import SearchInput from './SearchInput';

const MobileSearch = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <HeaderItem>
        <Styles.IconToggleButton
          data-testid="header-search-toggle"
          onClick={toggle}
        >
          <Badge>
            <FontAwesomeIcon
              icon={faSearch}
              size="lg"
              color={theme.colors.default.black}
            />
            <span className="background" />
          </Badge>
        </Styles.IconToggleButton>
      </HeaderItem>
      {isOpen && (
        <Styles.SearchPanel data-testid="header-search-panel">
          <Flex w="100%">
            <Flex px="20px" w="100%">
              <Button
                minH="15px"
                px={0}
                flexShrink={0}
                onClick={toggle}
                variant="ghost"
                sx={{
                  span: {
                    h: '100%',
                  },
                }}
                data-testid="header-search-panel-close"
              >
                <Chevron direction={ChevronDirection.Left} />
              </Button>
              <SearchInput setIsOpen={setIsOpen} isOpen={isOpen} />
            </Flex>
          </Flex>
        </Styles.SearchPanel>
      )}
    </>
  );
};

export default MobileSearch;
