import { styled } from '@swibeco/shared';

export const DealCardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.default.white};
  padding: 4px 8px 8px 8px;
  border-radius: 2px;
  box-shadow: ${({ theme }) => `0 3px 6px ${theme.colors.shadow.primary}`};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  padding: 20px 12px 6px 12px;
  min-height: 55px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding-bottom: 72px;

  .deal-card-wrapper {
    clip-path: inset(-20px -100% -20px -32px);
  }
`;

export const IconsWrapper = styled.div`
  padding: 0px 12px;
`;

export const Separator = styled.hr`
  border-top: ${({ theme }) => `1px solid ${theme.colors.default.middle}`};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
