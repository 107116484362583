import React from 'react';
import * as Styles from './Brand.styles';

type BrandProps = {
  name: string;
  url: string;
};

const Brand = ({ name, url }: BrandProps) => (
  <Styles.BrandName to={url}>{name}</Styles.BrandName>
);

export default Brand;
