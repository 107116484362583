export enum AllResultsSlugs {
  en = 'all-results',
  fr = 'toutes-les-offres',
  de = 'alle-angebote-1',
  it = 'tutte-le-offerte-1',
}
export enum PlusDealSlugs {
  en = 'swibeco-plus-3-months-free-trial',
  fr = 'swibeco-plus-3-mois-dessai-gratuit',
  de = 'swibeco-plus-3-monate-kostenlos-testen',
  it = 'swibeco-plus-3-mesi-di-prova-gratuita',
}
