import {
  Box,
  Center,
  chakra,
  Circle,
  Flex,
  Image,
  Square,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  Arrow,
  ArrowDirection,
  Button,
  Caption,
  Icons,
  Link,
  Text,
} from '@swibeco/ui';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  hexToRgb,
  useColorVariant,
  useTheme,
  PlusDealSlugs,
  useExecuteOnView,
  trackViewPromotionEvent,
  trackSelectPromotionEvent,
  PLUS_LANDING_PAGE,
} from '@swibeco/shared';
import { useSelector } from 'react-redux';
import { selectors as coreSelectors, usePlatform } from '@swibeco/core';
import { Advertisement } from '@swibeco/types';
import { useCanAccessPlusLanding } from '../../hooks';
import { ReactComponent as PlusArrowsBase } from '../../assets/images/plus-arrows.svg';
import plusAdItemBg from '../../assets/images/plus-ad-item-bg.png';
import plusAdItemBannerBg from '../../assets/images/plus-ad-item-banner-bg.png';
import * as Styles from './PlusAdItem.styles';

const PlusArrows = chakra(PlusArrowsBase);
const LockIcon = chakra(Icons.IconLock);
const EyeIcon = chakra(Icons.IconEye);

export type PlusAdItemProps = {
  ad: Advertisement;
};

const MotionBox = chakra(motion.div);

const ANIMATION_DURATION = 0.1;

const BACK_SIDE_VARIANTS = {
  flip: {
    rotateY: 0,
    transition: {
      duration: ANIMATION_DURATION,
      delay: ANIMATION_DURATION,
    },
  },
  initial: {
    rotateY: 90,
    transition: {
      duration: ANIMATION_DURATION,
    },
  },
};

const FRONT_SIDE_VARIANTS = {
  flip: {
    rotateY: -90,
    transition: {
      duration: ANIMATION_DURATION,
    },
  },
  initial: {
    rotateY: 0,
    transition: {
      duration: ANIMATION_DURATION,
      delay: ANIMATION_DURATION,
    },
  },
};

const PlusAdItem: React.FC<PlusAdItemProps> = ({ ad }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const transparentWhite = String(hexToRgb(theme.colors.default.white, 0.15));
  const primaryDarkVariant = useColorVariant('primary', 'dark');
  const locale = useSelector(coreSelectors.getLocale);
  const canAccessPlusLanding = useCanAccessPlusLanding();
  const environment = usePlatform(window);

  const {
    id,
    branding: { logo, name },
    discount_low,
    discount_high,
  } = ad;

  const promoEvent = useMemo(
    () => ({
      item_id: String(id),
      item_name: name,
      promotion_id: 'plus_ad_item',
      promotion_name: 'Plus Ad Item',
      creative_slot: 'plus_ad_item',
      creative_name: `${name} from ${discount_low} to ${discount_high}`,
    }),
    [id, name, discount_low, discount_high]
  );

  const onMouseOver = useCallback(() => {
    setIsFlipped(true);
  }, [setIsFlipped]);

  const onMouseOut = useCallback(() => {
    setIsFlipped(false);
  }, [setIsFlipped]);

  const onClick = useCallback(() => {
    trackSelectPromotionEvent(promoEvent, environment);
  }, [promoEvent, environment]);

  useExecuteOnView(() => {
    trackViewPromotionEvent(promoEvent, environment);
  }, ref);

  return (
    <Styles.PlusAdCard
      id="plus-ad-item"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      ref={ref}
    >
      <MotionBox
        h="full"
        w="full"
        borderRadius="3px"
        boxShadow={`0 3px 6px ${theme.colors.shadow.primary}`}
        position="absolute"
        overflow="hidden"
        variants={FRONT_SIDE_VARIANTS}
        animate={isFlipped ? 'flip' : 'initial'}
      >
        <Flex
          h="full"
          w="full"
          bgImage={plusAdItemBg}
          bgPosition="center center"
          bgRepeat="no-repeat"
          bgSize="100% 100%"
          color="default.white"
          cursor="pointer"
          flexDir="column"
          align="center"
          justify="space-between"
          py={4}
        >
          <Flex
            bgColor={transparentWhite}
            align="center"
            gap="8px"
            px={2}
            py={1}
          >
            <LockIcon height="16px" />
            <Caption color="default.white">
              {t('core.plus_ad.only_for_plus')}
            </Caption>
          </Flex>
          <Flex align="center" flexDir="column" gap="8px">
            <Text important>{t('core.plus_ad.double_your_savings')}</Text>
            <Circle bgColor="default.white" size={85} p={1}>
              <Image src={logo} maxWidth={65} maxHeight={65} />
            </Circle>
            <Text important>{t('core.plus_ad.with_plus')}</Text>
          </Flex>
          <Flex align="center" gap="6px">
            <EyeIcon width="24px" />
            <Text bold>{t('core.plus_ad.discover')}</Text>
          </Flex>
        </Flex>
      </MotionBox>
      <MotionBox
        h="full"
        w="full"
        borderRadius="3px"
        boxShadow={`0 3px 6px ${theme.colors.shadow.primary}`}
        position="absolute"
        overflow="hidden"
        variants={BACK_SIDE_VARIANTS}
        animate={isFlipped ? 'flip' : 'initial'}
      >
        <Flex
          h="full"
          w="full"
          bgColor="default.white"
          cursor="pointer"
          flexDir="column"
          align="center"
          justify="space-between"
          p={2}
        >
          <Box
            bgImage={plusAdItemBannerBg}
            bgPosition="center center"
            bgSize="cover"
            borderTopRadius="0.25rem"
            pos="relative"
            height="70px"
            width="full"
          >
            <Square
              bgColor={transparentWhite}
              size={25}
              pos="absolute"
              top="5px"
              right="7px"
              borderRadius="3px"
            >
              <LockIcon color="default.white" height="16px" />
            </Square>
            <Center
              bgColor="default.white"
              width="105px"
              height="50px"
              pos="absolute"
              bottom="-10px"
              left="50%"
              transform="translate(-50%, 0)"
              py={2}
              px={3}
            >
              <Image src={logo} maxWidth="100px" maxHeight="45px" />
            </Center>
          </Box>
          <Box mx="40px">
            <Text textAlign="center" mb="8px" bold color="primary.dark">
              {t('core.plus_ad.unlock_full_discount')}
            </Text>
            <Flex align="center" justify="space-between">
              <Center
                border="1px"
                borderColor="primary.main"
                borderRadius="32px"
                px="16px"
                py="5px"
              >
                <Text important color="primary.main">
                  -{discount_low}%
                </Text>
              </Center>
              <Arrow
                color={primaryDarkVariant}
                direction={ArrowDirection.Right}
                width={25}
                height={25}
                thickness={1}
              />
              <Flex align="center">
                <Center
                  border="1px"
                  borderColor="primary.main"
                  borderRadius="32px"
                  px="16px"
                  py="5px"
                >
                  <Text important color="primary.main">
                    -{discount_high}%
                  </Text>
                </Center>
                <PlusArrows height="35px" />
              </Flex>
            </Flex>
          </Box>
          <Box mx="32px">
            <Link
              to={
                canAccessPlusLanding
                  ? PLUS_LANDING_PAGE
                  : `/core/product/${PlusDealSlugs[locale]}`
              }
            >
              <Button thin color="primary" lightColor={false} onClick={onClick}>
                {t('core.plus_ad.become_member')}
              </Button>
            </Link>
            <Caption textAlign="center" mt="8px" color="default.black">
              {t('core.plus_ad.for_free')}
            </Caption>
          </Box>
        </Flex>
      </MotionBox>
    </Styles.PlusAdCard>
  );
};

export default PlusAdItem;
