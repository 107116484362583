/* eslint-disable react/jsx-one-expression-per-line */
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  chakra,
  Flex,
  Image,
  Grid,
} from '@chakra-ui/react';
import { arrayFrom, useColorVariant } from '@swibeco/shared';
import { Swiper, SwiperSlide } from '@swibeco/shared-web';
import { Arrow, ArrowDirection, Text } from '@swibeco/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrows } from '../../../assets/svgs/arrows.svg';

const WhiteCircle = chakra(Flex, {
  baseStyle: {
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    backgroundColor: 'default.white',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface FakeAd {
  id: number;
  discount_low: number;
  discount_high: number;
}
const fakeData: Array<Array<FakeAd>> = [
  [
    {
      id: 0,
      discount_low: 3,
      discount_high: 5,
    },
    {
      id: 1,
      discount_low: 5,
      discount_high: 10,
    },
    {
      id: 2,
      discount_low: 4,
      discount_high: 8,
    },
    {
      id: 3,
      discount_low: 4,
      discount_high: 8,
    },
  ],
  [
    {
      id: 4,
      discount_low: 10,
      discount_high: 20,
    },
    {
      id: 5,
      discount_low: 4,
      discount_high: 7,
    },
    {
      id: 6,
      discount_low: 4,
      discount_high: 8,
    },
    {
      id: 7,
      discount_low: 9,
      discount_high: 18,
    },
  ],
  [
    {
      id: 8,
      discount_low: 5,
      discount_high: 10,
    },
    {
      id: 9,
      discount_low: 5,
      discount_high: 10,
    },
    {
      id: 10,
      discount_low: 4,
      discount_high: 8,
    },
    {
      id: 11,
      discount_low: 4,
      discount_high: 7,
    },
  ],
  [
    {
      id: 12,
      discount_low: 4,
      discount_high: 8,
    },
    {
      id: 13,
      discount_low: 10,
      discount_high: 15,
    },
    {
      id: 14,
      discount_low: 10,
      discount_high: 20,
    },
    {
      id: 15,
      discount_low: 10,
      discount_high: 20,
    },
  ],
  [
    {
      id: 16,
      discount_low: 57,
      discount_high: 67,
    },
    {
      id: 17,
      discount_low: 16,
      discount_high: 27,
    },
    {
      id: 18,
      discount_low: 27,
      discount_high: 50,
    },
    {
      id: 19,
      discount_low: 8,
      discount_high: 15,
    },
  ],
  // [
  //   {
  //     id: 20,
  //     discount_low: 3,
  //     discount_high: 13,
  //   },
  //   {
  //     id: 21,
  //     discount_low: 2,
  //     discount_high: 15,
  //   },
  //   {
  //     id: 22,
  //     discount_low: 5,
  //     discount_high: 17,
  //   },
  //   {
  //     id: 23,
  //     discount_low: 4,
  //     discount_high: 27,
  //   },
  // ],
];

interface OtherBrandsSectionProps {
  tabIndex: number;
}
const OtherBrandsSection = ({ tabIndex }: OtherBrandsSectionProps) => {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap="4px">
      <Text>{t(`ecommerce.plus.tab.${tabIndex}.other_brands`)}</Text>
      {/* commented for now, will be used in the future when backend returns actual data */}
      {/* <Flex justifyContent="center">
          <Box
            borderColor="default.main"
            borderWidth="1px"
            p="8px"
            borderRadius="100%"
            bgColor="white"
          >
            <Image
              w="16px"
              h="16px"
              src="/uploads/images/companies/brand-6.jpg"
            />
          </Box>
          <Box
            ml="-16px"
            borderColor="default.main"
            borderWidth="1px"
            p="8px"
            borderRadius="100%"
            bgColor="white"
          >
            <Image
              w="16px"
              h="16px"
              src="/uploads/images/companies/brand-6.jpg"
            />
          </Box>
          <Box
            ml="-16px"
            borderColor="default.main"
            borderWidth="1px"
            p="8px"
            borderRadius="100%"
            bgColor="white"
          >
            <Image
              w="16px"
              h="16px"
              src="/uploads/images/companies/brand-6.jpg"
            />
          </Box>
        </Flex> */}
    </Flex>
  );
};

interface DiscountDetailsProps {
  ad: FakeAd;
  tabIndex: number;
  discountIndex: number;
}
const DiscountDetails = ({
  ad,
  tabIndex,
  discountIndex,
}: DiscountDetailsProps) => {
  const strong = useColorVariant('primary', 'main');
  const { t } = useTranslation();
  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      gap="6px"
      flexDir="column"
      zIndex={2}
    >
      <Image
        maxHeight="70px"
        maxWidth="130px"
        src={t(`ecommerce.plus.tab.${tabIndex}.brand.${discountIndex}.logo`)}
      />
      <Flex>
        <Flex
          p="5px 16px"
          gap="4px"
          border="1px solid"
          borderColor="primary.main"
          borderRadius="32px"
          alignItems="center"
          justifyContent="center"
        >
          <Box as="span">
            <Text color="default.strong">{ad.discount_low}%</Text>
          </Box>
          <Arrow color={strong} direction={ArrowDirection.Right} />
          <Text color="primary.main" important>
            {ad.discount_high}%
          </Text>
        </Flex>
        <Box key={ad.id}>
          <Arrows />
        </Box>
      </Flex>
    </Flex>
  );
};

interface PlusLandingTabPanelProps {
  index: number;
}
const PlusLandingTabPanel = ({ index: tabIndex }: PlusLandingTabPanelProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      gap={{ base: '27px', lg: '44px' }}
      flexWrap={{ base: 'wrap', lg: 'unset' }}
    >
      <Flex
        maxW={{ base: 'full', lg: '200px' }}
        w={{ base: 'full', lg: 'unset' }}
        p={{ base: '8px 32px', lg: '52px 11px' }}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        bgColor="#E8EAF0"
        gap="4px"
        borderRadius={{ base: '3px', lg: 'unset' }}
      >
        <Text color="primary.plusBlue" important>
          {t(`ecommerce.plus.tab.${tabIndex}.savings`)}
        </Text>
        <Text>{t('ecommerce.plus.savings.description')}</Text>
      </Flex>
      <Flex
        p={{ base: 'unset', lg: '56px 0px 25px' }}
        w="100%"
        justifyContent="center"
        alignItems="center"
        gap={{ base: '40px', lg: '33px' }}
        flexDir="column"
      >
        <Grid
          templateRows={{ base: 'repeat(2, 1fr)', lg: 'unset' }}
          templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
          gap={{ base: '32px 16px', lg: '24px' }}
        >
          {fakeData[tabIndex].map((ad, discountIndex) => (
            <DiscountDetails
              tabIndex={tabIndex}
              discountIndex={discountIndex}
              ad={ad}
              key={ad.id}
            />
          ))}
        </Grid>
        <OtherBrandsSection tabIndex={tabIndex} />
      </Flex>
    </Flex>
  );
};

export default function PlusLandingTabs() {
  // const { data, isLoading } = useQuery(['advertisements'], () =>
  //   ecommerceApi.getAds()
  // );
  const { t } = useTranslation();

  return (
    <Box maxW="100%">
      <Box>
        <Tabs variant="rounded" isLazy>
          <TabList>
            <Swiper spaceBetween={16}>
              <SwiperSlide>
                <Tab id="ecommerce.plus.tab.0">
                  <WhiteCircle>🥕</WhiteCircle>{' '}
                  <Text bold>{t('ecommerce.plus.tab.0.name')}</Text>
                </Tab>
              </SwiperSlide>
              <SwiperSlide>
                <Tab id="ecommerce.plus.tab.1">
                  <WhiteCircle>⚽</WhiteCircle>{' '}
                  <Text bold>{t('ecommerce.plus.tab.1.name')}</Text>
                </Tab>
              </SwiperSlide>
              <SwiperSlide>
                <Tab id="ecommerce.plus.tab.2">
                  <WhiteCircle>👕</WhiteCircle>{' '}
                  <Text bold>{t('ecommerce.plus.tab.2.name')}</Text>
                </Tab>
              </SwiperSlide>
              <SwiperSlide>
                <Tab id="ecommerce.plus.tab.3">
                  <WhiteCircle>🌴</WhiteCircle>{' '}
                  <Text bold>{t('ecommerce.plus.tab.3.name')}</Text>
                </Tab>
              </SwiperSlide>
              <SwiperSlide>
                <Tab id="ecommerce.plus.tab.4">
                  <WhiteCircle>🛒</WhiteCircle>{' '}
                  <Text bold>{t('ecommerce.plus.tab.4.name')}</Text>
                </Tab>
              </SwiperSlide>
              {/* <SwiperSlide>
              <Tab>
                <WhiteCircle>🛒</WhiteCircle>
                <Text bold>{t('ecommerce.plus.tab.5.name')}</Text>
              </Tab>
            </SwiperSlide> */}
            </Swiper>
          </TabList>
          <TabPanels px={{ base: '0', xl: '109px' }} mx="auto">
            {arrayFrom(5).map((val) => (
              <TabPanel key={val}>
                <PlusLandingTabPanel index={val} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
