import { styled, hexToRgb } from '@swibeco/shared';
import { ColorProps } from '@swibeco/types';

export type HoverBehaviorProps = ColorProps & {
  disabled?: boolean;
  opacity?: number;
};

const HoverBehavior = styled.div<HoverBehaviorProps>`
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
  &:hover {
    background-color: ${({ color, theme, disabled, opacity }) =>
      disabled
        ? 'inherit'
        : hexToRgb(
            theme?.colors[color?.variant || 'primary']?.[
              color?.variantColor || 'main'
            ],
            opacity || 0.1
          )};

    > * {
      color: ${({ color, theme }) =>
        theme?.colors[color?.variant || 'primary'][
          color?.variantColor || 'main'
        ]};
      text-decoration: none;
    }
  }
`;

export default HoverBehavior;
