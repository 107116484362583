import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Loader } from '@swibeco/ui';
import { AuthenticatedRoute } from '@swibeco/shared-web';
import { EcommerceRouter } from '@swibeco/ecommerce-web';
import { selectors } from '@swibeco/core';
import { useSelector } from 'react-redux';

const CoreRouter = () => {
  const isDefaultLocale = useSelector(selectors.getIsDefaultLocale);
  // we don't render the ecommerce routes until we have a locale (from the user response, not the default one from redux)
  return (
    !isDefaultLocale && (
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="*"
            element={
              <AuthenticatedRoute>
                <EcommerceRouter />
              </AuthenticatedRoute>
            }
          />
        </Routes>
      </Suspense>
    )
  );
};

export default CoreRouter;
