import { useNumberFormatter } from '@swibeco/shared';
import { UserType } from '@swibeco/types';
// import { AppAbility } from '@swibeco/security';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@swibeco/core';
import useGetElementPosition from '../../hooks/useGetElementPosition';
import * as Styles from './styles/SwipointsBalance.style';
import { ReactComponent as SwipointsHeader } from '../../assets/images/swipoints_header.svg';
// import swipointsHeader from '../../assets/images/swipoints_header.png';

type ProfileSwipointsBalanceProps = {
  userData: UserType | undefined;
  style?: any;
  hasRef?: boolean;
};

const ProfileSwipointsBalance = ({
  userData,
  style,
  hasRef,
}: ProfileSwipointsBalanceProps) => {
  const dispatch = useDispatch();
  const formatter = useNumberFormatter('decimal');
  const swipointsIconRef = React.useRef<HTMLDivElement>(null);
  const { x, y } = useSelector(selectors.getSwipointsLocation);
  const reportValue = useCallback(
    (clientRect: DOMRect) => {
      if (x !== clientRect.left || y !== clientRect.top) {
        dispatch(
          actions.setSwipointsIconLocation({
            x: clientRect.left,
            y: clientRect.top,
          })
        );
      }
    },
    [dispatch, x, y]
  );
  useGetElementPosition(swipointsIconRef, hasRef, reportValue);
  return userData!.swipointsBalance > 0 ? (
    <Styles.SwipointsBox style={style}>
      <SwipointsHeader
        ref={swipointsIconRef}
        alt="swipoints icon"
        id="header-swipoints-icon"
        width={25}
      />
      <Styles.SwipointsValue>
        {formatter(userData!.swipointsBalance)}
      </Styles.SwipointsValue>
    </Styles.SwipointsBox>
  ) : null;
};

export default ProfileSwipointsBalance;
