export const mapSortToOrderParams = (value: string) => {
  const parts = value.split('_');
  let orderParams = {};
  if (parts[2]) {
    orderParams = {
      order_by: parts[0],
      order_with: parts[1],
      order_direction: parts[2] === 'desc' ? 'desc' : 'asc',
    };
  } else {
    orderParams = {
      order_by: parts[0],
      order_direction: parts[1] === 'desc' ? 'desc' : 'asc',
    };
  }

  return orderParams;
};
