import React from 'react';
import { useIsTablet } from '@swibeco/core';
import SearchInput from './SearchInput';
import MobileSearch from './MobileSearch';

const Search = () => {
  const isTablet = useIsTablet();

  return isTablet ? <MobileSearch /> : <SearchInput />;
};

export default Search;
