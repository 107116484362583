import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useIsTablet } from '@swibeco/core';
import { useLocation, matchPath } from 'react-router-dom';
import { UNIVERSE } from '@swibeco/shared';
import { filterDraftModeReducer } from './store';
import { DraftModeActions, DraftModeStore, draftModeTypes } from './types';
import { filterDraftModeInitialState } from './initialState';

const DraftModeContext = createContext<DraftModeStore>(
  filterDraftModeInitialState
);
export const DraftModeDispatchContext = createContext<
  Dispatch<DraftModeActions>
>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  /* istanbul ignore next */ () => {}
);

export const useDraftModeContext = (): [
  DraftModeStore,
  Dispatch<DraftModeActions>
] => [useContext(DraftModeContext), useContext(DraftModeDispatchContext)];

type DraftModeProviderProps = {
  children: React.ReactNode;
};

const DraftModeProvider = ({ children }: DraftModeProviderProps) => {
  const [state, dispatch] = useReducer(filterDraftModeReducer, {
    ...filterDraftModeInitialState,
  });
  const location = useLocation();
  const isTablet = useIsTablet();
  const isUniversePage = useMemo(
    () => !!matchPath(UNIVERSE, location.pathname),
    [location.pathname]
  );

  useEffect(() => {
    if (isTablet && isUniversePage) {
      dispatch({
        type: draftModeTypes.SAVE_GENERATED_PARAMS,
        payload: location.search,
      });
    } else {
      dispatch({ type: draftModeTypes.DISABLE_DRAFTMODE });
    }
  }, [isTablet, isUniversePage]);

  return (
    <DraftModeContext.Provider value={state}>
      <DraftModeDispatchContext.Provider value={dispatch}>
        {children}
      </DraftModeDispatchContext.Provider>
    </DraftModeContext.Provider>
  );
};

export default DraftModeProvider;
