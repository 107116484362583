import { styled } from '@swibeco/shared';
import { Button } from '@swibeco/ui';

export default styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 0px 0px 5px 5px;
  backdrop-filter: blur(25px);
  width: 100%;
  position: relative;
  z-index: 4;
`;
