import { SwiperCore } from '@swibeco/shared-web';
import { Brand } from '@swibeco/types';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Styles from './SliderBackground.styles';

type SliderBackgroundProps = {
  children: React.ReactNode;
  brands: Brand[];
  onSwiper: Dispatch<SetStateAction<unknown | null>>;
  onSlideChange?: (swiper: any) => void;
  brandSwiper: SwiperCore | null;
};
const SliderBackground = ({
  children,
  brands,
  onSwiper,
  onSlideChange,
  brandSwiper,
}: SliderBackgroundProps) => {
  const [backgroundSlider, setBackgroundSlider] = useState<SwiperCore | null>();

  useEffect(() => {
    let startTimeout: ReturnType<typeof setTimeout>;
    if (brandSwiper && backgroundSlider) {
      startTimeout = setTimeout(() => {
        backgroundSlider?.autoplay?.start();
      }, 2500);
    }
    return () => {
      clearTimeout(startTimeout);
    };
  }, [brandSwiper, backgroundSlider]);
  return (
    <>
      <Styles.ShapeContainer data-testid="slider-background">
        <Styles.FirstShape />
        <Styles.SecondShape />
        <Styles.ThirdShape />
        <Styles.ImageBackgroundContainer>
          <Styles.BackgroundSwiper
            onSwiper={(swiper: any) => {
              onSwiper(swiper);
              setBackgroundSlider(swiper);
            }}
            onSlideChange={onSlideChange}
            slidesPerView={1}
            rewind
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              stopOnLastSlide: false,
            }}
            initialSlide={0}
          >
            {brands.map((brand: Brand) => (
              <Styles.ImageBackground
                imageBackground={brand.background}
                key={brand.id}
              >
                <Styles.Gradient />
              </Styles.ImageBackground>
            ))}
          </Styles.BackgroundSwiper>
        </Styles.ImageBackgroundContainer>
      </Styles.ShapeContainer>
      {children}
    </>
  );
};

export default SliderBackground;
