import { styled } from '@swibeco/shared';
import { ReactComponent as PageBubbleBottomLeft } from '../../assets/images/page_bubble_bottom_left.svg';
import { ReactComponent as PageBubbleBottomLeftHover } from '../../assets/images/page_bubble_bottom_left_hover.svg';
import { ReactComponent as PageBubbleShared } from '../../assets/images/page_bubble_shared.svg';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.default.backgroundColor};
  min-height: 70vh;
  position: relative;
  overflow: hidden;
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
`;
export const TopLeftBubble = styled(PageBubbleShared)`
  position: absolute;
  left: -350px;
  top: -300px;
  width: 783px;
`;

export const BottomLeftBubble = styled(PageBubbleBottomLeft)<{
  withHoverBubble?: boolean;
}>`
  position: absolute;
  bottom: ${({ withHoverBubble }) => (withHoverBubble ? '-250px' : '-450px')};
  left: -700px;
  width: 1414px;
`;

export const BottomLeftBubbleHover = styled(PageBubbleBottomLeftHover)`
  position: absolute;
  bottom: -200px;
  left: -130px;
  width: 321px;
`;

export const RightBubble = styled(PageBubbleShared)`
  position: absolute;
  right: -480px;
  top: 50%;
  width: 783px;
  transform: translate(0, -50%);
`;
