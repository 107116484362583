import { useEffect, useRef, useState } from 'react';

const useElementOnScreen = (
  opts: IntersectionObserverInit
): [React.RefObject<HTMLDivElement>, boolean] => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const cbFunc = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };
  useEffect(() => {
    const obs = new IntersectionObserver(cbFunc, opts);
    const containerRefCurrent = containerRef.current;
    if (containerRefCurrent) obs.observe(containerRefCurrent);
    return () => {
      if (containerRefCurrent) obs.unobserve(containerRefCurrent);
    };
  }, [containerRef, opts]);
  return [containerRef, isVisible];
};

export default useElementOnScreen;
