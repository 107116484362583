import { CupertinoPane } from 'cupertino-pane';
import React, { useEffect, useRef } from 'react';

export interface BottomSheetProps {
  panelKey: string;
  canScrollBackdrop?: boolean;
  show?: boolean;
  buttonDestroy?: boolean;
  onDidDismiss?: () => Promise<void> | void;
  children: any;
}

const BottomSheet = ({
  panelKey,
  show,
  canScrollBackdrop,
  onDidDismiss,
  buttonDestroy = true,
  children,
}: BottomSheetProps) => {
  const panelRef = useRef<CupertinoPane | null>(null);
  const isShowing = useRef<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const pendingCallbackRef = useRef<(() => void) | undefined>();

  const destroy = () => {
    if (isShowing.current) {
      isShowing.current = false;
      panelRef.current?.destroy({ animate: true });
    }
  };

  const onBackdropTap = () => {
    destroy();
  };

  const onHide = async () => {
    if (onDidDismiss) {
      await onDidDismiss();
    }
    if (pendingCallbackRef.current) {
      pendingCallbackRef.current();
      pendingCallbackRef.current = undefined;
    }
  };

  useEffect(() => {
    const screenHeight = window.innerHeight;
    const breakHeight = (screenHeight * 90) / 100;
    panelRef.current = new CupertinoPane(`.${panelKey}`, {
      breaks: {
        bottom: { enabled: true, height: 0 },
        top: { enabled: true, height: screenHeight },
        middle: { enabled: true, height: breakHeight },
      },
      touchAngle: 60,
      initialBreak: 'bottom',
      animationDuration: 100,
      parentElement: '.app',
      backdrop: true,
      bottomClose: true,
      backdropOpacity: 0.4,
      buttonDestroy,
      events: {
        onWillDismiss: () => {
          isShowing.current = false;
          onHide();
        },
        onBackdropTap,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const showPanel = async () => {
      isShowing.current = true;
      await panelRef.current?.present();
      panelRef.current?.moveToBreak('middle');
    };
    if (show) {
      showPanel();
    } else {
      destroy();
    }
  }, [show, canScrollBackdrop]);

  return (
    <div className={panelKey}>
      <div ref={containerRef} className="panel-container">
        {children}
      </div>
    </div>
  );
};

export default BottomSheet;
