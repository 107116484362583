import { isPast } from 'date-fns';
import { BasketPageDeal } from '@swibeco/types';

export const isDealOutOfStock = (deal: BasketPageDeal) =>
  deal.inStock === false &&
  (deal.warning?.code === 'deal_quantity_reach' ||
    deal.warning?.code === 'deal_quantity_max_per_user_reach');

export const isDealPartialOutOfStock = (deal: BasketPageDeal) =>
  deal.inStock && !deal.canAddMoreQuantity;

export const isDealExpired = (deal: BasketPageDeal) =>
  deal?.isFlash && deal.endDatetime && isPast(new Date(deal.endDatetime));

export const isDealReachedMaxPerUser = (deal: BasketPageDeal) =>
  deal.inStock === true &&
  deal.warning?.code === 'deal_quantity_max_per_user_reach';
