import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  formatDistanceStrict,
  differenceInMonths,
} from 'date-fns';
import { enUS, fr, it, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors as coreSelectors } from '@swibeco/core';

const mappedLocale = {
  fr,
  en: enUS,
  de,
  it,
};

const useTimeLeftLabel = (endDatetime: string | null | undefined): string => {
  const { t } = useTranslation();
  const locale = useSelector(coreSelectors.getLocale);

  if (!endDatetime) return '';

  const now = new Date();
  const end = new Date(endDatetime);
  const minutesLeft = differenceInMinutes(end, now);
  const hoursLeft = differenceInHours(end, now);
  const daysLeft = differenceInDays(end, now);
  const monthsLeft = differenceInMonths(end, now);
  const shouldBeSingle =
    (hoursLeft === 0 && minutesLeft === 1) ||
    (daysLeft === 0 && hoursLeft === 1) ||
    daysLeft === 1 ||
    monthsLeft === 1;

  return `${formatDistanceStrict(end, now, {
    locale: mappedLocale[locale],
  })} ${
    shouldBeSingle
      ? t('core.deal.label.left.single')
      : t('core.deal.label.left')
  }`;
};

export default useTimeLeftLabel;
