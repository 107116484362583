import { useCallback } from 'react';

const useNumberFormatter = (
  style?: string | 'currency',
  currency?: string,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
) => {
  const formatStyle = style || 'currency';

  return useCallback(
    (valueToFormat: number, dashPosition: 'left' | 'right' = 'left') => {
      const floatPart = valueToFormat.toString().split('.');
      const formatter = new Intl.NumberFormat('de-CH', {
        style: formatStyle,
        currency: currency || 'CHF',
        minimumFractionDigits:
          minimumFractionDigits || floatPart.length > 1 ? 2 : 0,
        maximumFractionDigits: maximumFractionDigits || 2,
      });
      const value = valueToFormat >= 0 ? valueToFormat : valueToFormat * -1;
      const formattedValue = `${formatter.format(value)}${
        formatStyle === 'currency' && Number.isInteger(value) ? '.-' : ''
      }`;
      if (valueToFormat >= 0) {
        return formattedValue;
      }
      if (dashPosition === 'right') {
        return `${formattedValue} -`;
      }
      return `- ${formattedValue} `;
    },
    [formatStyle, currency, minimumFractionDigits, maximumFractionDigits]
  );
};

export default useNumberFormatter;
