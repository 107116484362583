import React from 'react';
import { styled, useColorVariant } from '@swibeco/shared';
import Chevron, { ChevronDirection } from './iconography/Chevron';
import Text from './typography/Text';

const Tab = styled.div<{ isOpen?: boolean }>`
  cursor: pointer;
  transition: all 0.3s;

  ${({ isOpen, theme }) =>
    isOpen &&
    `background-color: ${theme.colors.default.white};
    box-shadow: 0 3px 6px ${theme.colors.shadow.primary};    
    `};
`;

const Icon = styled.img`
  width: auto;
  height: 20px;
  margin-right: 10px;
`;

type TabulationType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  icon?: string;
  value: string;
  isOpen?: boolean;
  children: string;
  as?: React.ElementType;
};

const Tabulation = ({
  icon,
  value,
  isOpen,
  onClick,
  children,
  ...rest
}: TabulationType) => {
  const colorBase = useColorVariant('default', 'black');
  const colorActive = useColorVariant('primary', 'main');
  const color = isOpen ? colorActive : colorBase;

  return (
    <Tab
      className="px-2 py-3 my-2 d-flex justify-content-between"
      isOpen={isOpen}
      key={value}
      data-testid={`tab-${value}`}
      onClick={onClick}
      {...rest}
    >
      <div>
        {icon && <Icon src={icon} alt={children} />}
        <Text component="span" color={color}>
          {children}
        </Text>
      </div>
      <Chevron
        direction={ChevronDirection.Right}
        className="d-inline-flex"
        color={color}
      />
    </Tab>
  );
};

export default Tabulation;
