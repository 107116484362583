import React from 'react';
import { Col, Container } from 'reactstrap';
import { styled, breakpoints, ECOMMERCE_HOME } from '@swibeco/shared';
import { Button, PageTitle, Text } from '@swibeco/ui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { StyledCol, StyledRow } from './ErrorPage.styles';
import errorPNG from './images/error-desktop.png';

const ImageCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  padding: 0;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
  ${breakpoints.xxl} {
    img {
      max-height: 800px;
      width: auto;
      height: auto;
    }
  }
`;

const ErrorTitle = styled.div`
  margin-bottom: 10px;
  color: #fc136e;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #fc136e;
  border-radius: 30px;
  text-align: center;
`;

const StyledContainer = styled(Container)`
  padding: 0;
  height: 100%;
  min-height: inherit;
`;

const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer fluid>
      <StyledRow>
        <StyledCol
          xs={12}
          md={6}
          className="d-flex flex-column my-5 px-5 align-self-md-center"
        >
          <ErrorTitle className="col-4 col-md-3">404 Error</ErrorTitle>
          <PageTitle textAlign="left" mb="6">
            {t('core.error.title')}
          </PageTitle>
          <Text important>{t('core.error.text')}</Text>
          <div style={{ paddingTop: '32px' }}>
            <Link to={ECOMMERCE_HOME}>
              <Button color="primary">Back to home page</Button>
            </Link>
          </div>
        </StyledCol>
        <ImageCol xs={12} md={6}>
          <img src={errorPNG} className="desktop" alt="error" />
        </ImageCol>
      </StyledRow>
    </StyledContainer>
  );
};

export default ErrorPage;
