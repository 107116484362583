import React from 'react';
import {
  Text as ChakraText,
  ResponsiveValue,
  TextProps as ChakraTextProps,
  chakra,
  forwardRef,
} from '@chakra-ui/react';
import * as CSS from 'csstype';
import { useTheme } from '@chakra-ui/react';

type ColorObject = { variant: string; variantColor: string };
type TextProps = {
  important?: boolean;
  component?: any;
  className?: string;
  hoverable?: boolean;
  href?: string;
  target?: string;
  variant?: string;
  color?: ColorObject | string;
  bold?: boolean;
  align?: ResponsiveValue<CSS.Property.TextAlign>;
  size?: ResponsiveValue<CSS.Property.FontSize<string>>;
  children?: React.ReactNode;
} & ChakraTextProps;

function hasVariantProperty(
  obj: any
): obj is { variant: string; variantColor: string } {
  return obj && 'variant' in obj;
}

const getColorProp = (theme: any, color?: ColorObject | string) => {
  let colorProp = {};
  if (color === undefined) return colorProp;
  if (typeof color !== 'string' && hasVariantProperty(color)) {
    colorProp = {
      color:
        theme.colors[color?.variant || 'default'][
          color?.variantColor || 'black'
        ],
    };
  } else if (typeof color === 'string') {
    colorProp = {
      color,
    };
  }
  return colorProp;
};

const CText = chakra(ChakraText, {
  shouldForwardProp: (prop) =>
    !['important', 'bold', 'hoverable', 'active', 'secondary'].includes(prop),
});

// https://chakra-ui.com/community/recipes/as-prop
const Text = forwardRef<TextProps, 'p'>(
  (
    {
      component,
      className,
      children,
      important = undefined,
      variant = 'default',
      color,
      bold = undefined,
      align,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    let localVariant = variant;
    if (important) {
      localVariant += '-important';
    }
    return (
      <CText
        ref={ref}
        as={component}
        variant={localVariant}
        textAlign={align}
        className={className}
        important={important}
        fontWeight={bold || important ? 700 : 500}
        data-typo={variant}
        {...rest}
        {...getColorProp(theme, color)}
      >
        {children}
      </CText>
    );
  }
);

export default Text;
