import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useTranslateFormErrors = (
  errors: { [key: string]: unknown },
  triggerValidation: (fieldName: any) => void
): void => {
  const { i18n } = useTranslation();
  useEffect(() => {
    const onChange = () => {
      Object.keys(errors).forEach((fieldName) => {
        triggerValidation(fieldName);
      });
    };
    i18n.on('languageChanged', onChange);
    return () => {
      i18n.off('languageChanged', onChange);
    };
  }, [errors, i18n, triggerValidation]);
};

export default useTranslateFormErrors;
