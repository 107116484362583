import React from 'react';
import { breakpoints, styled } from '@swibeco/shared';
import { useTheme } from '@emotion/react';

type BillingAddressIconProps = {
  id?: string;
  width?: number;
  height?: number;
  color?: string;
};

const BillingAddressIconWrapper = styled.span<BillingAddressIconProps>`
  display: inline-block;
  width: 32.48px;
  height: 22px;
  ${breakpoints.sm_max} {
    width: 30px;
    height: 30px;
  }
`;

const BillingAddressIcon = ({
  id = 'billing-icon',
  width,
  height,
  color,
}: BillingAddressIconProps) => {
  const theme = useTheme();
  return (
    <BillingAddressIconWrapper
      id={id}
      color={color}
      width={width}
      height={height}
    >
      <svg
        width="21"
        height="26"
        viewBox="0 0 21 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4624 16.8223H0.808594V25.234L4.30859 23.1948L7.27013 25.234L10.2317 23.1948L14.2701 25.234L17.2317 23.1948L20.4624 25.234V16.8223Z"
          fill={color || theme.colors.primary.main}
          fillOpacity={0.1}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.901216 0.157603C1.45301 -0.0998966 2.11139 -0.0383093 2.5993 0.316448L4.69665 1.84145L6.58535 0.35372C7.18406 -0.117882 8.05294 -0.117882 8.65165 0.35372L10.5404 1.84145L12.6377 0.316448C13.2366 -0.119021 14.0743 -0.103543 14.6548 0.35372L16.5031 1.80966L15.47 2.98537L13.6216 1.52943L11.5243 3.05443C10.9254 3.4899 10.0877 3.47442 9.50721 3.01715L7.6185 1.52943L5.7298 3.01715C5.1493 3.47442 4.31165 3.4899 3.71274 3.05443L1.61538 1.52943V24.4706L3.64861 22.8359C4.2702 22.3362 5.18771 22.3534 5.78786 22.8761L7.6185 24.4706L9.44915 22.8761C10.0493 22.3534 10.9668 22.3362 11.5884 22.8359L13.6216 24.4706L15.4095 22.9134C16.0277 22.375 16.9786 22.375 17.5968 22.9134L19.3846 24.4706V1.52943L17.5363 2.98537C16.9376 3.45697 16.0687 3.45697 15.47 2.98537L16.5031 1.80966L18.3515 0.35372C18.8331 -0.0256833 19.5031 -0.10716 20.0704 0.14467C20.6377 0.396501 21 0.936211 21 1.52943V24.4706C21 25.0776 20.6208 25.6271 20.0338 25.871C19.4467 26.115 18.7628 26.0071 18.291 25.5961L16.5031 24.039L14.7153 25.5961C14.1151 26.1188 13.1976 26.1361 12.576 25.6364L10.5428 24.0017L8.71216 25.5961C8.09395 26.1346 7.14306 26.1346 6.52485 25.5961L4.6942 24.0017L2.66098 25.6364C2.18114 26.0221 1.5083 26.1089 0.936994 25.8586C0.365691 25.6083 0 25.0666 0 24.4706V1.52943C0 0.946986 0.349419 0.415103 0.901216 0.157603Z"
          fill={color || theme.colors.primary.main}
        />
        <path
          d="M16.6075 12.39H4.39452C4.30645 12.3845 4.21812 12.3961 4.13499 12.4241C4.05186 12.4522 3.9757 12.496 3.91125 12.553C3.84679 12.61 3.79542 12.6789 3.76029 12.7554C3.72516 12.832 3.70703 12.9145 3.70703 12.9979C3.70703 13.0813 3.72516 13.1639 3.76029 13.2404C3.79542 13.3169 3.84679 13.3858 3.91125 13.4428C3.9757 13.4998 4.05186 13.5437 4.13499 13.5717C4.21812 13.5997 4.30645 13.6114 4.39452 13.6058H16.6075C16.7706 13.5955 16.9236 13.527 17.0353 13.4141C17.147 13.3013 17.2092 13.1525 17.2092 12.9979C17.2092 12.8434 17.147 12.6946 17.0353 12.5817C16.9236 12.4688 16.7706 12.4003 16.6075 12.39V12.39Z"
          fill={color || theme.colors.primary.main}
        />
        <path
          d="M10.8092 8.73828H4.39466C4.23149 8.74857 4.07857 8.81709 3.96685 8.92996C3.85513 9.04283 3.79297 9.19162 3.79297 9.34618C3.79297 9.50074 3.85513 9.64952 3.96685 9.76239C4.07857 9.87526 4.23149 9.94378 4.39466 9.95406H10.8092C10.9723 9.94378 11.1252 9.87526 11.237 9.76239C11.3487 9.64952 11.4108 9.50074 11.4108 9.34618C11.4108 9.19162 11.3487 9.04283 11.237 8.92996C11.1252 8.81709 10.9723 8.74857 10.8092 8.73828V8.73828Z"
          fill={color || theme.colors.primary.main}
        />
        <path
          d="M16.6075 16.0424H4.39452C4.30645 16.0368 4.21812 16.0484 4.13499 16.0765C4.05186 16.1045 3.9757 16.1483 3.91125 16.2053C3.84679 16.2623 3.79542 16.3312 3.76029 16.4077C3.72516 16.4843 3.70703 16.5668 3.70703 16.6502C3.70703 16.7336 3.72516 16.8162 3.76029 16.8927C3.79542 16.9692 3.84679 17.0381 3.91125 17.0951C3.9757 17.1521 4.05186 17.196 4.13499 17.224C4.21812 17.2521 4.30645 17.2637 4.39452 17.2581H16.6075C16.6956 17.2637 16.7839 17.2521 16.867 17.224C16.9501 17.196 17.0263 17.1521 17.0908 17.0951C17.1552 17.0381 17.2066 16.9692 17.2417 16.8927C17.2768 16.8162 17.295 16.7336 17.295 16.6502C17.295 16.5668 17.2768 16.4843 17.2417 16.4077C17.2066 16.3312 17.1552 16.2623 17.0908 16.2053C17.0263 16.1483 16.9501 16.1045 16.867 16.0765C16.7839 16.0484 16.6956 16.0368 16.6075 16.0424V16.0424Z"
          fill={color || theme.colors.primary.main}
        />
      </svg>
    </BillingAddressIconWrapper>
  );
};

export default BillingAddressIcon;
