import { ProductVariant } from "ProductVariant";
import { Brand, BrandProductPage } from "../Brand";

export interface BaseDeal extends BaseDealCommon {
  thumbnailUrl: string;
  stock?: number; // deprecated, used only in category page
  offerType: EnumDealOfferType;
  promoLink?: string;
  brand?: Brand;
  recentUntilDate?: string | null;
  endDatetime: string | null;
}

interface BaseDealCommon {
  id: number;
  branding: Brand;
  shortTitle: string;
  url: string;
  finalPrice: number;
  listPrice: number;
  discount: string;
  dealType: EnumDealTypeProps;
  isFlash: boolean;
  isPermanent: boolean;
  isInternal: boolean;
  offerType: EnumDealOfferType;
  promoLink?: string;
  description?: string;
  slug: string;
  thumbnailUrl?: string;
  subscriptionDuration: number;
  cashbackAmount: number;
  cashbackPercentage: number;
}

export interface ProductPageDeal extends BaseDealCommon {
  recentUntilDate?: string | null;
  endDatetime?: string | null;
  title: string;
  gallery: DealMedia[];
  condition: string;
  inStock: boolean;
  isFavorite: boolean;
  hasReassurance: boolean;
  brand: BrandProductPage;
}

export interface BasketPageDeal extends BaseDealCommon {
  recentUntilDate?: string | null;
  endDatetime?: string | null;
  thumbnailUrl: string;
  quantity: number;
  isFavorite: boolean;
  inStock: boolean;
  deliveryPrice: number | null;
  warning: WarningType | null;
  canAddMoreQuantity: boolean;
  type: string;
}

export type DealMedia = {
  id: number;
  imageUrl: string;
  youtubeLink: string;
  branding: Brand;
};

export interface DealResponse {
  id: number;
  short_title: string;
  thumbnail_url: string;
  gallery_image?: string;
  url: string;
  final_price: number;
  list_price: number;
  discount: string;
  deal_type: string;
  offer_type: EnumDealOfferType;
  is_flash: boolean;
  is_permanent: boolean;
  stock?: number; // deprecated, used only in category page
  in_stock?: boolean;
}

export type DealSharedCodeInfo = {
  code?: string;
  externalLink?: string;
  deliveryDescription?: string;
};

export enum EnumDealVoucherType {
  Unique = "Unique voucher code",
  Generic = "Generic discount code",
}

export enum EnumDealTypeProps {
  Flash = "flash",
  Regular = "regular",
  Light = "light",
  Private = "private",
}

export enum DealProductType {
  address = "address",
  noShipping = "no_shipping",
  voucher = "voucher",
}

export type DealQuantityFormType = {
  deal: BaseDeal | ProductPageDeal | ProductVariant;
  quantity: number;
};
export type DealQuantityFormErrorType = {
  code: string;
  message: string;
  deal_id: number;
};

export type DealItemProps = {
  deal: BaseDeal | ProductPageDeal;
  onAddItem?: (deal: BaseDeal | ProductPageDeal, stock?: number) => void;
};

export type RandomDealResponse = {
  deal: RandomDeal;
};

export type RandomDeal = {
  id: string;
  url: string;
};

export enum EnumDealOfferType {
  Physical = "physical", // address
  PromoCode = "promo_code", // generic voucher
  PromoLink = "promo_link", // no shipping
  Voucher = "voucher", // unique voucher
  Subscription = "subscription", // subscription
  DropShipping = "dropshipping", // drop shipping
}

export interface TopDeal {
  id: number;
  invalid: boolean;
  thumbnail_url: string;
  slug: string;
}
export enum DealProductNatureType {
  PHYSICAL = "physical",
  DIGITAL = "digital",
  SUBSCRIPTION = "subscription",
}

export interface TranslatedName {
  lang: string;
  translation: string;
}

export interface Translation {
  lang: string;
  name: string;
  description: string;
  mouse_hover_text: string;
  mouse_hover_button_text: string;
}

export type ExploreDeals = {
  title: string;
  icon?: string;
  deals: BaseDeal[];
};

export type WarningType = {
  code: string;
  message: string;
};
