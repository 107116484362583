import React from 'react';
import { styled, useTheme } from '@swibeco/shared';
import { ColorProps, StyledColorProps } from '@swibeco/types';

export type StyledCloseIconProps = StyledColorProps;

const CloseIconWrapper = styled.span`
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
`;

export type CloseIconProps = ColorProps & {
  className?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
};

const CloseIcon = ({
  onClick,
  color,
  className,
  width = '15',
  height = '15',
}: CloseIconProps) => {
  const theme = useTheme();

  return (
    <CloseIconWrapper className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 19.647 19.647"
      >
        <g
          transform="translate(2.121 2.121)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="3px"
          stroke={
            theme.colors[color?.variant || 'default']?.[
              color?.variantColor || 'black'
            ]
          }
        >
          <line y1="15.404" x2="15.404" />
          <line x1="15.404" y1="15.404" />
        </g>
      </svg>
    </CloseIconWrapper>
  );
};

export default CloseIcon;
