const generatePageName = (routeParam: string): string | null => {
  let pageName: string | null = null;
  const routeSegments = routeParam.split('/');
  const lastSegment = routeSegments[routeSegments.length - 1];

  switch (true) {
    case routeParam.includes('/product'):
      pageName = 'product';
      break;
    case routeParam.includes('/basket'):
      pageName = 'basket';
      break;
    case routeParam.includes('/category'):
      pageName = 'category';
      break;
    case routeParam.includes('/brand'):
      pageName = 'brand';
      break;
    case routeParam.includes('/event'):
      pageName = 'event';
      break;
    case routeParam.includes('/dashboard'):
      pageName = 'dashboard';
      break;
    case routeParam.includes('/checkout'):
      if (lastSegment === 'checkout') {
        pageName = 'checkout';
      } else if (lastSegment === 'checkout-confirmation') {
        pageName = 'confirmation';
      }
      break;
    case routeParam.includes('/orders'):
      pageName = 'orders';
      break;
    case routeParam.includes('/register'):
      pageName = 'register';
      break;
    case routeParam.includes('/mycompanybenefits'):
      pageName = 'mycompanybenefits';
      break;
    case routeParam.includes('/login'):
      pageName = 'login';
      break;
    case routeParam.includes('/home'):
      pageName = 'home';
      break;
    default:
      pageName = null;
      break;
  }
  return pageName !== null ? `${pageName} page` : null;
};

export { generatePageName };
