import { Ability, AbilityClass, AbilityBuilder } from '@casl/ability';
import { CurrentCompanyInformationType } from '@swibeco/types';
import buildCompanyFeatures, {
  CompanyAbilities,
  CurrentCompanyAbilities,
} from './company';

export type AppFeatureAbility = CompanyAbilities;
export const AppFeatureAbility = Ability as AbilityClass<AppFeatureAbility>;

const buildFeaturesForCompany = (company: CurrentCompanyInformationType) => {
  const builder = new AbilityBuilder(AppFeatureAbility);
  buildCompanyFeatures(builder, company);

  return builder.build();
};

export { CurrentCompanyAbilities };

export default buildFeaturesForCompany;
