import { styled, breakpoints } from '@swibeco/shared';
import bubble1 from '../../assets/images/bubble_1.svg';
import bubble2 from '../../assets/images/bubble_2.svg';
import bubble3 from '../../assets/images/bubble_3.svg';

export const ShapeContainer = styled.ul`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
  padding: 0;
  margin: 0;
  overflow: hidden;
`;

const Shape = styled.li`
  position: absolute;
  display: block;
  list-style: none;
`;

export const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.default.light};
`;

export const FirstShape = styled(Shape)`
  width: 780px;
  height: 620px;
  background-image: url('${bubble1}');
  left: -200px;

  ${breakpoints.sm_max} {
    left: -400px;
  }
`;

export const SecondShape = styled(Shape)`
  left: 100%;
  top: 900px;
  transform: translate(-50%, -50%);
  width: 1400px;
  height: 990px;
  background-image: url('${bubble2}');

  ${breakpoints.sm_max} {
    top: 1200px;
    left: 140%;
  }
`;

export const ThirdShape = styled(Shape)`
  top: 1600px;
  left: -800px;
  width: 1700px;
  height: 1600px;
  background-image: url('${bubble3}');

  ${breakpoints.sm_max} {
    left: -1000px;
  }
`;
