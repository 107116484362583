import {
  ActionMap,
  AddressesType,
  AddressType,
  BasketType,
  SwibecoContactInfo,
  CheckoutErrorsType,
  Card,
} from '@swibeco/types';

export type CheckoutStore = {
  user: {
    addresses: AddressesType;
    allocatedSwipoints: number;
    useDeliveryAddressForBilling: boolean;
    payementMethod: string | null;
    card: Card | null;
  };
  basket: BasketType;
  swibeco: SwibecoContactInfo;
  errors: CheckoutErrorsType;
};

export enum CheckoutTypes {
  SET_ADDRESSES = 'SET_ADDRESSES',
  SET_BASKET = 'SET_BASKET',
  SET_ALLOCATED_SWIPOINTS = 'SET_ALLOCATED_SWIPOINTS',
  SET_PAYEMENT_METHOD = 'SET_PAYEMENT_METHOD',
  SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS',
  SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS',
  SET_USE_DELIVERY_ADDRESS_FOR_BILLING = 'SET_USE_DELIVERY_ADDRESS_FOR_BILLING',
  SET_ERRORS = 'SET_ERRORS',
}

type Payload = {
  [CheckoutTypes.SET_ADDRESSES]: AddressesType;
  [CheckoutTypes.SET_BASKET]: BasketType;
  [CheckoutTypes.SET_ALLOCATED_SWIPOINTS]: number;
  [CheckoutTypes.SET_ERRORS]: CheckoutErrorsType;
  [CheckoutTypes.SET_BILLING_ADDRESS]: AddressType;
  [CheckoutTypes.SET_DELIVERY_ADDRESS]: AddressType;
  [CheckoutTypes.SET_USE_DELIVERY_ADDRESS_FOR_BILLING]: boolean;
  [CheckoutTypes.SET_PAYEMENT_METHOD]: string;
};

export type CheckoutActions = ActionMap<Payload>[keyof ActionMap<Payload>];
