import { Link, Text, Toast } from '@swibeco/ui';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { hexToRgb, useTheme } from '@swibeco/shared';
import { Flex, useToast } from '@chakra-ui/react';
import { ColorVariants } from '@swibeco/types';
import * as Styles from './VoucherModalCodeContent.styles';
import { NewTabIcon } from './NewTabIcon';

export type VoucherModalCodeContentProps = {
  code?: string;
  url?: string;
};

const VoucherModalCodeContent = ({
  code,
  url,
}: VoucherModalCodeContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const toast = useToast();

  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(code ?? '');
    setIsCopied(true);
  };

  const externalLinkUrl = useMemo(() => {
    try {
      if (url) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return new URL(`https://${url}`);
        }
        return new URL(url);
      }
    } catch (e) {
      toast({
        id: 'url-error',
        position: 'top-right',
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            variant={ColorVariants.Danger}
            onCloseButtonClick={onClose}
            legacy={false}
          >
            The url is malformed
          </Toast>
        ),
      });
    }
    return null;
  }, [url, toast]);

  return (
    <Styles.Wrapper>
      <Text className="mb-2">{t('core.dashboard.voucher.your_code')}</Text>
      <div className="d-block d-lg-flex justify-content-center align-items-center">
        <Styles.CodeField
          className="d-flex align-items-center justify-content-center px-4 py-2 mr-lg-1 mb-4 mb-lg-0"
          data-testid="discount-code"
        >
          {code}
        </Styles.CodeField>
        <Styles.CopyButton
          icon={<FontAwesomeIcon icon={faCopy} />}
          color="primary"
          thin
          onClick={copyToClipboard}
          className="d-flex"
        >
          {t('core.voucher.copy')}
        </Styles.CopyButton>
      </div>
      <Text color="complementary.light" display={isCopied ? 'block' : 'none'}>
        {t('core.voucher.copied_to_clipboard')}
      </Text>
      {externalLinkUrl && (
        <Flex justifyContent="center" alignItems="center">
          <Styles.UseTheCode
            as={Link}
            href={externalLinkUrl}
            target="_blank"
            rel="noreferrer"
            data-testid="discount-link"
            thin
            hoverBackgroundColor={hexToRgb(theme.colors.primary.main, 0.1)}
          >
            <>
              {t('core.voucher.continue_on')} {externalLinkUrl?.hostname}
              <NewTabIcon fill={theme.colors.primary.main} />
            </>
          </Styles.UseTheCode>
        </Flex>
      )}
    </Styles.Wrapper>
  );
};

export default VoucherModalCodeContent;
