import { produce } from 'immer';
import {
  AddressType,
  EmployeeContributionType,
  MealTaxDeductionType,
  SalaryMethod,
} from '@swibeco/types';
import { SimulatorActions, Types } from './actions';

export type SimulatorSettingsState = {
  months: number;
};

export type SimulatorEmployerConfigurationState = {
  numberOfEmployees: number;
  employerContribution: number;
  employerSocialContributionPercentage: number;
  employerSocialContributionRedistributedPercentage: number;
  employeeContributionType: EmployeeContributionType;
  averageGrossSalary: number;
  grossSalaryConversion: boolean;
  netSalaryConversion: boolean;
  averageEmployeesSocialContributionPercentage: number;
  averageMarginalTaxRate: number;
  mealTaxDeductionType: MealTaxDeductionType;
  netSalaryConverted: number;
  grossSalaryConverted: number;
  amountLoadedOnTheCard: number;
  netContract?: string;
  grossContract?: string;
};

export type SimulatorEmployerState = {
  firstName: string;
  lastName: string;
  function: string;
  profilePicture: string;
  testimony: string;
  testimonyYoutubeId?: string;
  companyName: string;
  logo: string;
  address: AddressType;
};

export type SimulatorEmployeeState = {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  birthDate: string;
  nationality: string;
  language: string;
  address: AddressType;
  deliveryAddress: AddressType;
  salaryMethod: SalaryMethod;
  contractAccepted: boolean;
};

export type SimulatorState = {
  settings: SimulatorSettingsState;
  employerConfiguration: SimulatorEmployerConfigurationState;
  employer: SimulatorEmployerState;
  employee: SimulatorEmployeeState;
};

// eslint-disable-next-line arrow-body-style
const simulatorReducer = produce(
  (state: SimulatorState, action: SimulatorActions) => {
    switch (action.type) {
      case Types.SET_NUMBER_OF_EMPLOYEES:
        state.employerConfiguration.numberOfEmployees =
          action.payload.numberOfEmployees;
        break;
      case Types.SET_EMPLOYER_CONTRIBUTION: {
        let { grossSalaryConversion, netSalaryConversion } =
          state.employerConfiguration;
        const { contribution } = action.payload;

        if ([0, 180].includes(contribution)) {
          grossSalaryConversion = !(contribution === 180);
          netSalaryConversion = !(contribution === 0);
        }

        if (
          state.employerConfiguration.employeeContributionType ===
            EmployeeContributionType.Equivalent &&
          contribution > 90
        ) {
          grossSalaryConversion = false;
          netSalaryConversion = true;
        }

        if (contribution === 0) {
          state.employerConfiguration.employeeContributionType =
            EmployeeContributionType.Complete;
        } else if (
          contribution === 180 &&
          state.employerConfiguration.employeeContributionType ===
            EmployeeContributionType.Complete
        ) {
          state.employerConfiguration.employeeContributionType =
            EmployeeContributionType.Equivalent;
        }

        state.employerConfiguration.employerContribution = contribution;

        if (
          state.employerConfiguration.grossSalaryConversion !==
          grossSalaryConversion
        ) {
          state.employerConfiguration.grossSalaryConversion =
            grossSalaryConversion;
        }

        if (
          state.employerConfiguration.netSalaryConversion !==
          netSalaryConversion
        ) {
          state.employerConfiguration.netSalaryConversion = netSalaryConversion;
        }
        break;
      }
      case Types.SET_AVERAGE_GROSS_SALARY:
        state.employerConfiguration.averageGrossSalary = action.payload.salary;
        break;
      case Types.SET_EMPLOYER_SOCIAL_CONTRIBUTION_PERCENTAGE:
        state.employerConfiguration.employerSocialContributionPercentage =
          action.payload.percentage;
        break;
      case Types.SET_EMPLOYEE_CONTRIBUTION_TYPE: {
        const { contributionType } = action.payload;

        state.employerConfiguration.employeeContributionType = contributionType;

        if (
          contributionType === EmployeeContributionType.Equivalent &&
          state.employerConfiguration.employerContribution > 90
        ) {
          state.employerConfiguration.grossSalaryConversion = false;
          state.employerConfiguration.netSalaryConversion = true;
        }
        break;
      }

      case Types.SET_SALARY_CONVERSION: {
        const { netValue, grossValue } = action.payload;

        if (netValue !== state.employerConfiguration.netSalaryConversion) {
          state.employerConfiguration.netSalaryConversion = netValue;
        }
        if (grossValue !== state.employerConfiguration.grossSalaryConversion) {
          state.employerConfiguration.grossSalaryConversion = grossValue;
        }
        break;
      }

      case Types.SET_EMPLOYER_SOCIAL_CONTRIBUTION_REDISTRIBUTED_PERCENTAGE:
        state.employerConfiguration.employerSocialContributionRedistributedPercentage =
          action.payload.percentage;
        break;
      case Types.SET_AVERAGE_EMPLOYEES_SOCIAL_CONTRIBUTION_PERCENTAGE:
        state.employerConfiguration.averageEmployeesSocialContributionPercentage =
          action.payload.contribution;
        break;

      case Types.SET_AVERAGE_MARGINAL_TAX_RATE:
        state.employerConfiguration.averageMarginalTaxRate =
          action.payload.rate;
        break;
      case Types.SET_MEAL_DEDUCTION_TYPE:
        state.employerConfiguration.mealTaxDeductionType =
          action.payload.deductionType;
        break;
      case Types.TOGGLE_MONTHS:
        state.settings.months = state.settings.months === 12 ? 1 : 12;
        break;
      case Types.INITIALIZE:
        return action.payload;
      case Types.INITIALIZE_EMPLOYER_CONFIGURATION: {
        state.employerConfiguration = action.payload;
        break;
      }
      case Types.INITIALIZE_EMPLOYER:
        state.employer = action.payload;
        break;
      case Types.INITIALIZE_EMPLOYEE:
        state.employee = action.payload;
        break;
      case Types.SET_PERSONAL_INFOS: {
        const {
          gender,
          firstName,
          lastName,
          birthDate,
          nationality,
          country,
          language,
          email,
          streetAddress,
          complement,
          postalCode,
          city,
        } = action.payload;

        if (gender !== state.employee.gender) {
          state.employee.gender = gender;
        }
        if (firstName !== state.employee.firstName) {
          state.employee.firstName = firstName;
        }
        if (lastName !== state.employee.lastName) {
          state.employee.lastName = lastName;
        }
        if (birthDate !== state.employee.birthDate) {
          state.employee.birthDate = birthDate;
        }
        if (nationality !== state.employee.nationality) {
          state.employee.nationality = nationality;
        }
        if (country !== state.employee.address.country) {
          state.employee.address.country = country;
        }
        if (language !== state.employee.language) {
          state.employee.language = language;
        }
        if (email !== state.employee.email) {
          state.employee.email = email;
        }
        if (streetAddress !== state.employee.address.street) {
          state.employee.address.street = streetAddress;
        }
        if (complement !== state.employee.address.complement) {
          state.employee.address.complement = complement;
        }
        if (postalCode !== state.employee.address.postalCode) {
          state.employee.address.postalCode = postalCode;
        }
        if (city !== state.employee.address.city) {
          state.employee.address.city = city;
        }

        break;
      }
      case Types.SET_DELIVERY_INFOS: {
        const { addressName, streetAddress, complement, postalCode, city } =
          action.payload;

        if (addressName !== state.employee.deliveryAddress.name) {
          state.employee.deliveryAddress.name = addressName;
        }

        if (streetAddress !== state.employee.deliveryAddress.street) {
          state.employee.deliveryAddress.street = streetAddress;
        }
        if (complement !== state.employee.deliveryAddress.complement) {
          state.employee.deliveryAddress.complement = complement;
        }
        if (postalCode !== state.employee.deliveryAddress.postalCode) {
          state.employee.deliveryAddress.postalCode = postalCode;
        }
        if (city !== state.employee.deliveryAddress.city) {
          state.employee.deliveryAddress.city = city;
        }

        break;
      }
      case Types.SET_SALARY_METHOD:
        state.employee.salaryMethod = action.payload.salaryMethod;
        break;
      case Types.SET_CONTRACT_ACCEPTED:
        state.employee.contractAccepted = action.payload.contractAccepted;
        break;

      case Types.SET_NET_SALARY_CONVERTED:
        state.employerConfiguration.netSalaryConverted =
          action.payload.netSalaryConverted;
        break;

      case Types.SET_GROSS_SALARY_CONVERTED:
        state.employerConfiguration.grossSalaryConverted =
          action.payload.grossSalaryConverted;
        break;

      case Types.SET_AMOUNT_LOADED_ON_THE_CARD:
        state.employerConfiguration.amountLoadedOnTheCard =
          action.payload.amountLoadedOnTheCard;
        break;

      default:
        break;
    }
  }
);

export default simulatorReducer;
