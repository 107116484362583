import { breakpoints, styled, hexToRgb } from '@swibeco/shared';
import {
  Swiper,
  Skeleton as BaseSkeleton,
  MainContentWrapper,
  SwiperSlide,
} from '@swibeco/shared-web';
import { Text, Link, Heading } from '@swibeco/ui';

export const SliderWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.default.main};
  min-height: 319px;
  position: relative;
  ${breakpoints.sm_max} {
    min-height: 480px;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: inherit;
  }
`;
export const Skeleton = styled(BaseSkeleton)`
  width: 100vw;
  height: 100%;
`;
export const SliderContent = styled(MainContentWrapper)`
  ${breakpoints.md_max} {
    top: -20px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const BrandDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 20px 0;
  z-index: 1;
  min-height: 319px;
  ${breakpoints.sm_max} {
    border-radius: 4px;
    background: ${(props) => props.theme.colors.default.white};
    padding: 16px 0;
    text-align: center;
    min-height: 238px;
    margin-bottom: 6px;
  }
`;
export const TextDetails = styled.div`
  margin: 16px 0;
  ${breakpoints.sm} {
    max-width: 40%;
    min-height: 80px;
  }
`;

export const SliderTitle = styled(Heading)`
  ${breakpoints.lg} {
    max-width: 500px;
  }
  ${breakpoints.xl} {
    max-width: 600px;
  }
`;

/// width calculation has to acount for the difference in size of the `BaseNavigationButton` in desktop vs mobile view
export const SliderItemsContainer = styled.div`
  display: flex;
  width: calc(100% - 100px);
  .swiper {
    height: 41px !important;
  }
  ${breakpoints.md} {
    position: absolute;
    bottom: 20px;
  }
  ${breakpoints.sm_max} {
    width: calc(100% - 120px);
    .custom-bullet {
      left: 0 !important;
      margin: 1px 3px !important;
    }
    .swiper-pagination {
      width: 230px !important;
      text-align: justify !important;
      left: 0 !important;
      transform: translateX(0) !important;
      white-space: break-spaces !important;
    }
  }
`;
export const SliderItems = styled(Swiper)`
  background: ${(props) => props.theme.colors.default.white};
  border-radius: 69px;
  padding: 4px;
  margin: 0;
  gap: 6px;
  cursor: pointer;
  .swiper-slide {
    max-width: fit-content;
    margin: 0 5px;
    min-width: fit-content;
  }
  ${breakpoints.sm_max} {
    border-radius: 0;
    margin: 14px;
    width: auto !important;
    .swiper-wrapper {
      visibility: hidden;
    }
  }
  .swiper-pagination {
    ${breakpoints.sm} {
      visibility: hidden;
    }
  }
  ${breakpoints.sm_max} {
    background: transparent;
  }
  .swiper-pagination-bullet {
    background: ${(props) => props.theme.colors.default.white};
    width: 12px;
    height: 12px;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: ${(props) => props.theme.colors.default.black} !important;
    width: 13px;
    height: 13px;
    border: 2px solid ${(props) => props.theme.colors.default.white};
  }
  .swiper-horizontal {
    .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 5px !important;
    }
  }
`;
export const BrandSelected = styled.div`
  width: 6px;
  height: 6px;
  background: ${({ theme }) => theme.colors.default.black};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  right: 8px;
  bottom: 2px;
`;
export const BrandName = styled(Text)`
  line-height: 21px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.default.black};
  padding: 0 15px;
`;
export const SliderItem = styled(SwiperSlide, {
  shouldForwardProp: (prop) => !['selectedBrand'].includes(prop),
})<{ selectedBrand: boolean }>`
  padding: 4px 18px;
  ${({ selectedBrand, theme }) =>
    selectedBrand &&
    `background: ${hexToRgb(theme.colors.primary.main, 0.1)};
      border-radius: 40px;
    `};
  ${({ selectedBrand, theme }) =>
    selectedBrand === false &&
    `:hover{
         background: ${theme.colors.default.middle};
         border-radius: 40px;
       }
    `};
`;
export const SliderItemsActions = styled.div`
  z-index: 1;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BaseNavigationButton = styled.div`
  height: 40px;
  width: 40px;

  ${breakpoints.sm_max} {
    height: 50px;
    width: 50px;
  }
  margin: 0 5px;
  border-radius: 50%;
  display: inline-block;
  background: ${(props) => props.theme.colors.default.white};
  cursor: pointer;
  box-shadow: ${({ theme }) =>
    `0px 3px 6px ${hexToRgb(theme.colors.default.strong, 0.2)};`};
  :after {
    font-family: swiper-icons;
    color: ${(props) => props.theme.colors.primary.main};
    font-size: 30px;
    left: 50%;
  }
  :hover {
    background: ${(props) => props.theme.colors.default.middle};
  }
`;
export const GoToNextBrand = styled(BaseNavigationButton)`
  :after {
    content: 'next';
    font-size: 14px;
    position: relative;
    font-weight: 900;
    left: 18px;
    top: 8px;

    ${breakpoints.sm_max} {
      font-size: 18px;
      left: 22px;
      top: 12px;
    }
  }
`;
export const GoToPrevBrand = styled(BaseNavigationButton)`
  :after {
    content: 'prev';
    font-size: 14px;
    font-weight: 900;
    position: relative;
    left: 15px;
    top: 8px;

    ${breakpoints.sm_max} {
      font-size: 18px;
      left: 18px;
      top: 12px;
    }
  }
`;

export const GoToDeal = styled(Link)`
  margin-right: auto;
  ${breakpoints.sm_max} {
    margin: 0 auto;
  }
`;

export const CaptionSlider = styled(Text)`
  padding: 8px 20px;
  ${breakpoints.md} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    padding: 0;
  }
  ${breakpoints.lg} {
    max-width: 480px;
  }
  ${breakpoints.xl} {
    max-width: 600px;
  }
`;
