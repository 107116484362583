import React from 'react';

type EmojiProps = {
  label?: string;
  code: number;
};

const Emoji = React.memo(({ label, code, ...rest }: EmojiProps) => (
  <span
    {...rest}
    role="img"
    aria-label={label || undefined}
    aria-hidden={label ? undefined : true}
  >
    {String.fromCodePoint(code)}
  </span>
));

export default Emoji;
