import { UniverseActions, universeTypes } from './types';

const setContext = (context: string): UniverseActions => ({
  type: universeTypes.SET_CONTEXT,
  payload: context,
});

export const universeActions = {
  setContext,
};
