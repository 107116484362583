import { Flex } from '@chakra-ui/react';
import { Text } from '@swibeco/ui';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const Reward = () => {
  const { t } = useTranslation();

  return (
    <Flex
      align={{ base: 'center', lg: 'center' }}
      borderRadius="5px"
      padding="8.5px 16px"
      maxW="90%"
      flexDirection="column"
    >
      <Text>{t('core.ecommerce.homepage.tutorial.step.reward.congrats')}</Text>
      <Text>
        <Trans i18nKey="core.ecommerce.homepage.tutorial.step.no_reward.description" />
      </Text>
    </Flex>
  );
};
export default Reward;
