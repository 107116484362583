import { Flex, Icon } from '@chakra-ui/react';
import { Text } from './typography';
import React from 'react';
import { ReactComponent as SuccessOutlinedIcon } from './images/success_check_outlined.svg';
import { ReactComponent as UnsuccessfulIcon } from './images/unsuccessful_outlined.svg';

const SuccessCondition = ({ isSuccess = true, text = '' }) => (
  <Flex
    mb="8px"
    gap="8px"
    alignItems="center"
    data-testid={`condition-${isSuccess ? 'success' : 'fail'}`}
  >
    <Icon
      as={isSuccess ? SuccessOutlinedIcon : UnsuccessfulIcon}
      h="16px"
      w="16px"
    />
    <Text>{text}</Text>
  </Flex>
);

export default SuccessCondition;
