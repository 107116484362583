import { AbilityBuilder, Ability, InferSubjects } from '@casl/ability';
import { UserType } from '@swibeco/types';

export type CurrentUserAbilities = [
  'view' | 'access',
  InferSubjects<UserType> | 'BACKOFFICE'
];

export type UserAbilities = CurrentUserAbilities;

const buildUserPermissions = (
  builder: AbilityBuilder<Ability<UserAbilities>>,
  user: UserType
) => {
  if (user.permissions.VIEW_OWN_USER) {
    builder.can('view', 'CurrentUser', { id: user.id });
  }
  if (user.permissions.ACCESS_BACKOFFICE) {
    builder.can('access', 'BACKOFFICE');
  }
};

export default buildUserPermissions;
