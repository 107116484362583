import { AllResultsSlugs } from '@swibeco/shared';
import {
  ApiUniverseParams,
  BrandContext,
  CategoryContext,
  EventContext,
  UniverseContext,
  UniverseParams,
  SearchContext,
  BrandsContext,
} from '../hooks/useUniverseParams';

export const isCategoryPage = (
  universeParams: UniverseParams
): universeParams is ApiUniverseParams & CategoryContext =>
  universeParams.context === 'category';
export const isBrandPage = (
  universeParams: UniverseParams
): universeParams is ApiUniverseParams & BrandContext =>
  universeParams.context === 'brand';
export const isBrandsPage = (
  universeParams: UniverseParams
): universeParams is ApiUniverseParams & BrandsContext =>
  universeParams.context === 'brands';
export const isPlusBrandsPage = (
  universeParams: UniverseParams
): universeParams is ApiUniverseParams & BrandsContext =>
  universeParams.context === 'plus-brands';
export const isEventPage = (
  universeParams: UniverseParams
): universeParams is ApiUniverseParams & EventContext =>
  universeParams.context === 'event';
export const isUniversePage = (
  universeParams: UniverseParams
): universeParams is ApiUniverseParams & UniverseContext =>
  universeParams.context === 'universe';
export const isSearchResultsPage = (
  universeParams: UniverseParams
): universeParams is ApiUniverseParams & SearchContext =>
  universeParams.context === 'search';
export const isAllResultsPage = (
  universeParams: UniverseParams
): universeParams is ApiUniverseParams & CategoryContext =>
  universeParams.context === 'category' &&
  (Object.values(AllResultsSlugs) as string[]).includes(
    universeParams.category
  );
