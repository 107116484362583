import React, { useCallback, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { usePlatform } from '@swibeco/core';

type InformationsContextProps = {
  currentInformation: number;
  setCurrentInformation: (number: number) => void;
  toggleInformation: (
    information: number,
    question: string | React.ReactNode
  ) => void;
};

export const InformationsContext =
  React.createContext<InformationsContextProps>({} as InformationsContextProps);

type InformationsProps = {
  children: React.ReactNode;
};

const Informations = ({ children }: InformationsProps) => {
  const [currentInformation, setCurrentInformation] = useState(-1);
  const environment = usePlatform(window);

  const toggleInformation = useCallback(
    (information: number, question: string | React.ReactNode) => {
      if (information === currentInformation) {
        setCurrentInformation(-1);
      } else {
        setCurrentInformation(information);
        TagManager.dataLayer({
          dataLayer: {
            event: 'faq_click',
            environment,
            faq_subject: question,
          },
        });
      }
    },
    [environment, currentInformation]
  );

  const contextValues = useMemo(
    () => ({
      currentInformation,
      setCurrentInformation,
      toggleInformation,
    }),
    [currentInformation, setCurrentInformation, toggleInformation]
  );

  return (
    <InformationsContext.Provider value={contextValues}>
      {children}
    </InformationsContext.Provider>
  );
};

export default Informations;
