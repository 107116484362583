import { AnalyticsEvents } from '@swibeco/types';

interface DataLayerObject {
  dataLayer: {
    event: AnalyticsEvents;
    environment: string;
    localisation?: string | null;
    ecommerce: {
      currency?: string;
      value?: number;
      type?: string;
      payment_type?: string;
      tax?: number;
      transaction_id?: number;
      items?: any;
    };
  };
}

type EcommerceOptions = Partial<{
  currency?: string;
  value?: number;
  tax?: number;
  transaction_id?: number;
  type?: string;
  payment_type?: string;
}>;

const isDefined = <T>(value: T | undefined): value is T => value !== undefined;

const createDataLayerDealObject = (
  event: AnalyticsEvents,
  environment: string,
  ecommerceItems: any[],
  quantity?: number,
  localisation?: string | null,
  options: EcommerceOptions = {}
): DataLayerObject => {
  const items =
    ecommerceItems?.map((item) => ({
      item_name: item.shortTitle,
      item_id: item.id,
      price: item.finalPrice ?? item.price,
      item_brand: item.brand?.name || item.branding?.name,
      item_advantage: item.discount,
      quantity: quantity || Number(item.quantity) || 1,
      ...Object.fromEntries(
        item.categories?.map((category: string, index: number) => [
          `item_category${index + 1}`,
          category,
        ]) ?? []
      ),
    })) ?? [];

  const ecommerceObject = {
    currency: options.currency,
    value: options.value,
    type: options.type,
    payment_type: options.payment_type,
    tax: options.tax,
    transaction_id: options.transaction_id,
    items: items || [],
  };

  const ecommerceFiltered = Object.fromEntries(
    Object.entries(ecommerceObject).filter(([, value]) => isDefined(value))
  );

  const ecommerce = localisation
    ? { ...ecommerceFiltered, localisation }
    : ecommerceFiltered;

  const dataLayerObject: DataLayerObject = {
    dataLayer: {
      event,
      environment,
      ecommerce,
    },
  };
  return dataLayerObject;
};

export { createDataLayerDealObject };
