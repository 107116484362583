import React, { ReactElement } from 'react';
import { EnumProductType } from '@swibeco/types';
import { Image, Box, BoxProps, ImageProps } from '@chakra-ui/react';
import { ReactComponent as ProductVoucherIcon } from '../../assets/images/product-voucher.svg';

interface ProductImageProps {
  product: any;
  imageHasMargin?: boolean;
  displayBrand?: boolean;
  isStandalone?: boolean;
  isFlash?: boolean;
  isProductNew?: boolean;
  productImageContainerClass?: string;
}
export const ProductCardImageContainer = ({
  imageHasMargin,
  isStandalone,
  children,
  ...props
}: {
  imageHasMargin: boolean;
  isStandalone?: boolean;
  children: React.ReactNode;
} & BoxProps) => (
  <Box
    margin={imageHasMargin ? '8px' : 0}
    overflow="hidden"
    position="relative"
    borderRadius={isStandalone ? '5px' : '2px'}
    flex={1}
    {...props}
  >
    {children}
  </Box>
);
interface BaseProps {
  isVoucherProduct: boolean;
  isDropshipping: boolean;
  isStandalone?: boolean;
  src?: string;
}

export const ProductCardBackground = ({
  isVoucherProduct,
  isDropshipping,
  isStandalone,
  src,
  ...props
}: BaseProps & (BoxProps | ImageProps)) => {
  if (isVoucherProduct) {
    return (
      <Box
        filter={isVoucherProduct ? 'blur(4px)' : 'none'}
        transition="all 300ms ease-out"
        objectFit="cover"
        height={isStandalone ? '100%' : '171px'}
        width="100%"
        {...props}
      />
    );
  }
  return (
    <Image
      src={src}
      objectFit={isDropshipping ? 'contain' : 'cover'}
      height={isStandalone ? '100%' : '171px'}
      width="100%"
      {...props}
    />
  );
};

const ProductCardImageOverlay = (props: BoxProps) => (
  <Box
    backgroundColor="default.black"
    opacity="0.85"
    width="100%"
    height="100%"
    position="absolute"
    top="0"
    left="0"
    transition="opacity 300ms ease-out"
    {...props}
  />
);
const ProductVoucherTicket = ({
  isStandalone,
  ...props
}: {
  isStandalone?: boolean;
} & BoxProps) => (
  <Box
    alignItems="center"
    display="flex"
    position="absolute"
    bottom={isStandalone ? '0px' : '18px'}
    left="0"
    right="0"
    margin="0 auto"
    transition="bottom 300ms ease-out"
    transform={
      isStandalone
        ? 'rotate3d(0, 0, 1, -3deg) scale(0.8) translate(10%, 20%)'
        : 'rotate3d(0, 0, 1, -3deg)'
    }
    transformOrigin="left top"
    width={isStandalone ? '100%' : '65%'}
    height={isStandalone ? '100%' : 'auto'}
    {...props}
  />
);

interface ProductCardImageBrandProps extends BoxProps {
  children?: ReactElement;
}

const ProductCardImageBrand = ({
  children,
  ...props
}: ProductCardImageBrandProps) => (
  <Box
    backgroundColor="white"
    borderBottomRightRadius="3px"
    position="absolute"
    top="6px"
    left="6px"
    height="50px"
    width="100px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    {children}
  </Box>
);

export default function ProductImage({
  product,
  displayBrand = true,
  imageHasMargin = true,
  isStandalone = false,
  productImageContainerClass,
}: ProductImageProps) {
  const isVoucherProduct = product?.type === EnumProductType.Voucher;
  const displayLogo = product?.brand?.logoName;
  const isDropshipping = product?.type === EnumProductType.DropShipping;

  return (
    <Box
      className={productImageContainerClass}
      position="relative"
      height={isStandalone ? '100%' : 'auto'}
      overflow="hidden"
      display="flex"
    >
      <ProductCardImageContainer
        imageHasMargin={imageHasMargin}
        isStandalone={isStandalone}
      >
        <ProductCardBackground
          isVoucherProduct={isVoucherProduct}
          isDropshipping={isDropshipping}
          className="product-card-image"
          isStandalone={isStandalone}
          src={product?.images?.[0]?.path}
          alt={product.name}
          fontSize="9px"
        />
        {isVoucherProduct && (
          <>
            <ProductCardImageOverlay
              className="product-card-image-overlay"
              backgroundColor={`${product?.brand?.color}26`}
            />
            {/* {!isStandalone && (
            <Styles.ProductCardImageShortener className="position-absolute w-100" />
          )} */}
            <ProductVoucherTicket
              className="product-voucher-ticket"
              isStandalone={isStandalone}
            >
              <ProductVoucherIcon
                w="100%"
                h="99px"
                color={product?.brand?.color}
              />
              <Box
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                width="100%"
              >
                <Image
                  src={displayLogo}
                  alt={product?.brand?.translations.name}
                  maxWidth="100px"
                  maxHeight="60px"
                  height="auto"
                  transform={
                    isStandalone
                      ? 'rotate3d(0, 0, 1, -3deg) scale(0.8)'
                      : 'rotate3d(0, 0, 1, -3deg)'
                  }
                />
              </Box>
            </ProductVoucherTicket>
          </>
        )}
      </ProductCardImageContainer>
      {displayBrand && !isVoucherProduct && product.brand && (
        <ProductCardImageBrand>
          <Image
            src={displayLogo}
            alt={product?.brand?.logoThumbnailName}
            maxH="50px"
            w="100%"
            objectFit="contain"
            padding="4px"
          />
        </ProductCardImageBrand>
      )}
    </Box>
  );
}
