import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Row } from 'reactstrap';
import { MainContentWrapper } from '@swibeco/shared-web';
import { useIsTablet, selectors as coreSelectors } from '@swibeco/core';
import { syliusApi } from '@swibeco/ecommerce';
import { HydraResponse, MappedSyliusBrand } from '@swibeco/types';
import { ECOMMERCE_BRAND_ROOT } from '@swibeco/shared';
import { useSelector } from 'react-redux';
import { Brand } from './BrandItem';
import * as Styles from './Brands.styles';

const MOBILE_PAGE_SIZE = 40;
const DESKTOP_PAGE_SIZE = 120;

const Brands = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const isTablet = useIsTablet();
  const showMoreBrands = () => setShowMore(true);
  const locale = useSelector(coreSelectors.getLocale);

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery<HydraResponse<MappedSyliusBrand[]>>({
      queryFn: ({ pageParam }) => {
        const pageSize = isTablet ? MOBILE_PAGE_SIZE : DESKTOP_PAGE_SIZE;
        return syliusApi.getBrands(
          undefined,
          locale,
          {
            itemsPerPage: pageSize,
            withActiveProducts: true,
            'order[code]': 'asc',
            page: pageParam as number,
          },
          true
        );
      },
      queryKey: ['brands', isTablet],
      getNextPageParam: (lastPage) => {
        if (lastPage.searchParams.next?.page === undefined) {
          return undefined;
        }
        return parseInt(lastPage.searchParams.next?.page, 10);
      },
      initialPageParam: 1,
    });

  const brands = useMemo(() => {
    if (!data?.pages) {
      return [];
    }
    return data?.pages.reduce((acc, cv) => [...acc, ...cv.data], []);
  }, [data]);

  const formattedBrands = useMemo(() => {
    const result: MappedSyliusBrand[][] = [];

    if (!brands) return result;

    for (let i = 0; i < brands.length; i += 20) {
      const section = brands.slice(i, i + 20);
      result.push(section);
    }

    return result;
  }, [brands]);

  return brands?.length ? (
    <Styles.BrandsWrapper limitHeight={!showMore}>
      <MainContentWrapper>
        <Styles.Title as="h3">
          {t('core.ecommerce.homepage.discover_brands')}
        </Styles.Title>
        <Row>
          {formattedBrands.map((brandsInGroup) => (
            <Styles.StyledCol
              xs={6}
              sm={6}
              md={4}
              lg={2}
              key={brandsInGroup[0]?.slug}
            >
              {brandsInGroup.map((brand) => (
                <Styles.BrandItem key={brand.slug}>
                  <Brand
                    url={`${ECOMMERCE_BRAND_ROOT}/${brand.slug}`}
                    name={brand.name}
                  />
                </Styles.BrandItem>
              ))}
            </Styles.StyledCol>
          ))}
        </Row>
        {!showMore && (
          <Styles.ButtonWrapper>
            <Styles.StyledButton
              lightColor
              thin
              onClick={showMoreBrands}
              disabled={isLoading}
            >
              {t('core.ecommerce.homepage.discover_brands.see_all')}
            </Styles.StyledButton>
          </Styles.ButtonWrapper>
        )}
        {showMore && hasNextPage && (
          <Styles.LoadAllWrapper>
            <Styles.StyledButton
              lightColor
              thin
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {t('core.ecommerce.homepage.discover_brands.see_all')}
            </Styles.StyledButton>
          </Styles.LoadAllWrapper>
        )}
      </MainContentWrapper>
    </Styles.BrandsWrapper>
  ) : null;
};

export default Brands;
