import React, { createContext, useReducer, useContext, ReactNode } from 'react';

interface SurveyData {
  interest: number[];
  brands: string[];
  desires: string[];
  personalInfo: string;
}

type SurveyActionTypes =
  | 'SET_SURVEY'
  | 'ADD_INTEREST'
  | 'REMOVE_INTEREST'
  | 'ADD_DESIRES'
  | 'REMOVE_DESIRES'
  | 'ADD_BRAND'
  | 'REMOVE_BRAND';

interface BaseAction {
  type: SurveyActionTypes;
}

interface SetSurveyAction extends BaseAction {
  type: 'SET_SURVEY';
  payload: SurveyData;
}

interface AddInterestAction extends BaseAction {
  type: 'ADD_INTEREST';
  payload: number;
}

interface RemoveInterestAction extends BaseAction {
  type: 'REMOVE_INTEREST';
  payload: number;
}

interface AddDesiresAction extends BaseAction {
  type: 'ADD_DESIRES';
  payload: string;
}

interface RemoveDesiresAction extends BaseAction {
  type: 'REMOVE_DESIRES';
  payload: string;
}

interface AddBrandAction extends BaseAction {
  type: 'ADD_BRAND';
  payload: string;
}

interface RemoveBrandAction extends BaseAction {
  type: 'REMOVE_BRAND';
  payload: string;
}

type SurveyAction =
  | SetSurveyAction
  | AddInterestAction
  | RemoveInterestAction
  | AddDesiresAction
  | RemoveDesiresAction
  | AddBrandAction
  | RemoveBrandAction;

const surveyReducer = (state: SurveyData, action: SurveyAction): SurveyData => {
  switch (action.type) {
    case 'SET_SURVEY':
      return action.payload;
    case 'ADD_INTEREST':
      return {
        ...state,
        interest: [...state.interest, action.payload],
      };
    case 'REMOVE_INTEREST':
      return {
        ...state,
        interest: state.interest.filter(
          (interest) => interest !== action.payload
        ),
      };
    case 'ADD_DESIRES':
      return {
        ...state,
        desires: [...state.desires, action.payload],
      };
    case 'REMOVE_DESIRES':
      return {
        ...state,
        desires: state.desires.filter((desires) => desires !== action.payload),
      };
    case 'ADD_BRAND':
      return {
        ...state,
        brands: [...state.brands, action.payload],
      };
    case 'REMOVE_BRAND':
      return {
        ...state,
        brands: state.brands.filter((brand) => brand !== action.payload),
      };

    default:
      throw new Error('Unhandled action type');
  }
};

interface SurveyProviderProps {
  children: ReactNode;
}

export const SurveyStateContext = createContext<SurveyData | undefined>(
  undefined
);
export const SurveyDispatchContext = createContext<
  React.Dispatch<SurveyAction> | undefined
>(undefined);

export const useTutorialContext = () => useContext(SurveyStateContext);
export const useTutorialDispatch = () => useContext(SurveyDispatchContext);

export const SurveyContext = ({ children }: SurveyProviderProps) => {
  const [surveyData, dispatch] = useReducer(surveyReducer, {
    interest: [],
    brands: [],
    desires: [],
    personalInfo: '',
  });

  return (
    <SurveyStateContext.Provider value={surveyData}>
      <SurveyDispatchContext.Provider value={dispatch}>
        {children}
      </SurveyDispatchContext.Provider>
    </SurveyStateContext.Provider>
  );
};

export default SurveyContext;
