import { ecommerceApi } from '@swibeco/ecommerce';
import { Button } from '@swibeco/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import * as Styles from './InspireMe.styles';

const InspireMe = () => {
  const { t } = useTranslation();
  const { mutate, isPending } = useMutation({
    mutationFn: ecommerceApi.getInspireMe,
    onSuccess: (data) => {
      window.open(`/${data?.deal.url}`, '_self');
    },
  });

  return (
    <Styles.InspireMeWrapper data-testid="inspire-me">
      <Styles.InspireMeText important>
        {t('core.ecommerce.homepage.inspire_me.title')}
      </Styles.InspireMeText>
      <Button
        disabled={isPending}
        onClick={() => mutate()}
        thin
        color="primary"
      >
        {t('core.ecommerce.homepage.inspire_me.button')}
      </Button>
    </Styles.InspireMeWrapper>
  );
};

export default InspireMe;
