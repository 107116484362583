import { useGetCurrentUser } from '@swibeco/security';
import { useQuery } from '@tanstack/react-query';
import { userApi } from '@swibeco/ecommerce';
import { UpdateCurrentUserType } from '@swibeco/types';

export const useProfileRequestBody = () => {
  const currentUser = useGetCurrentUser();
  const { data: legacyCurrentUser } = useQuery({
    queryKey: ['legacy-current-user'],
    queryFn: userApi.getLegacyUserProfile,
  });

  if (!currentUser.data || !legacyCurrentUser) {
    return { requestBody: null };
  }

  const requestBody: Partial<UpdateCurrentUserType> = {
    gender: currentUser.data?.gender as 'm' | 'f' | 'o',
    newsletter: currentUser.data?.newsletter,
    phone: legacyCurrentUser.phone,
    phone_prefix: legacyCurrentUser.phonePrefix,
    recovery_email: currentUser.data?.recovery_email || '',
  };

  if (
    currentUser.data?.address?.city ||
    currentUser.data?.address?.postalCode ||
    currentUser.data?.address?.street
  ) {
    requestBody.address = {
      city: currentUser.data?.address?.city,
      complement_address: currentUser.data?.address?.complement,
      country: currentUser.data?.address?.country,
      postal_code: currentUser.data?.address?.postalCode,
      street_address: currentUser.data?.address?.street,
    };
  }

  return {
    requestBody,
  };
};
