import { Flex, Image } from '@chakra-ui/react';
import { Text } from '@swibeco/ui';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import swipointsIcon from '../../../../assets/icons/swipoints.svg';
import useTutorial from '../../hooks/useTutorial';

const Reward = () => {
  const { t } = useTranslation();
  const { giftCashAmount, giftSwipointsAmount } = useTutorial();

  return (
    <Flex
      align={{ base: 'center', lg: 'center' }}
      borderRadius="5px"
      padding="8.5px 16px"
      maxW="90%"
      flexDirection="column"
    >
      <Text>{t('core.ecommerce.homepage.tutorial.step.reward.congrats')}</Text>
      <Text data-testid="tutorial-swipoints-text">
        <Trans
          i18nKey="core.ecommerce.homepage.tutorial.step.reward.description"
          values={{ cashAmount: giftCashAmount }}
        />
      </Text>
      <Flex flexDirection="column" align="center" gap="8px" mt="34px">
        <Flex
          data-testid="tutorial-swipoints-conversion-text"
          alignItems="center"
          bg="#E7F5FF"
          borderRadius="48px"
          p="2"
        >
          <Image
            src={swipointsIcon}
            w="28px"
            h="28px"
            mr={1}
            alt="swipoints icon"
          />
          <Flex flexWrap="wrap">
            <Text bold>{giftSwipointsAmount}</Text>
            <Text m="0">{` = ${giftCashAmount}`}</Text>
          </Flex>
        </Flex>
        <Text
          data-testid="tutorial-swipoints-expiration-text"
          color="complementary.red"
        >
          {t('core.ecommerce.homepage.tutorial.reward.expiration')}
        </Text>
      </Flex>
    </Flex>
  );
};
export default Reward;
