import { breakpoints, styled } from '@swibeco/shared';
import { motion } from 'framer-motion';
import { chakra } from '@chakra-ui/react';
import { Chevron as OriginalChevron, Caption } from '@swibeco/ui';
import HeaderItem from '../../HeaderItem';

export const HeaderItemUserProfile = styled(HeaderItem)`
  ${breakpoints.md_max} {
    padding: 0 0 0 8px;
  }
  ${breakpoints.lg} {
    &:hover {
      .background {
        width: 45px;
        height: 45px;
        left: -3px;
        top: -4px;
        transform: none;
      }
    }
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .background {
    display: none;
  }
`;

export const ProfilePicture = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;

  ${breakpoints.lg} {
    width: 40px;
    height: 40px;
  }
`;

export const PlusUserBorder = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colors.primary.plusBlue};
  ${breakpoints.lg} {
    width: 39px;
    height: 39px;
  }
`;

export const PlusBanneMenuItem = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary.plusBlue};
  color: ${({ theme }) => theme.colors.default.white};
  display: flex;
  justify-content: center;
  align-items: center;
  ${breakpoints.lg} {
    margin-left: -2px;
    position: relative;
    left: 1px;
  }
`;

export const MenuWrapper = styled(motion.div)`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.default.black};
  width: 100vw;
  top: 90px;
  left: 0;
  display: flex;
  flex-direction: column;

  ${breakpoints.lg} {
    background-color: transparent;
    width: auto;
    right: 0;
    top: 52px;
    left: unset;
    position: absolute;
    border: 1px solid #d9d9d9;
  }
`;

export const InnerMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.default.light};

  ${breakpoints.lg} {
    background-color: ${({ theme }) => theme.colors.default.white};
  }
`;

export const Menu = styled(motion.ul)`
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
`;
/* eslint-disable indent,no-confusing-arrow */
export const MenuItem = styled.li<{ focused?: boolean }>`
  padding: 20px;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  align-content: space-between;
  background-color: ${({ focused, theme }) =>
    focused ? theme.colors.default.main : 'transparent'};

  &:hover {
    background-color: ${({ theme }) => theme.colors.default.middle};
  }

  &:after {
    content: ' ';
    height: 1px;
    width: ${({ focused }) => (focused ? '100%' : '80%')};
    background-color: ${({ theme, focused }) =>
      focused ? theme.colors.primary.strong : theme.colors.default.main};
    position: absolute;
    left: ${({ focused }) => (focused ? '0' : '20px')};
    bottom: 0;
  }

  &:last-of-type {
    &:after {
      width: 0;
    }
  }
`;

export const MenuItemText = chakra(Caption, {
  baseStyle: {
    flexGrow: '1',
    cursor: 'pointer',
    fontSize: '0.941rem',
    lineHeight: '22px',
    fontWeight: '500',
    whiteSpace: { lg: 'nowrap' },
    display: 'inherit',
    mr: { lg: '40px' },
    _hover: { color: 'primary.strong', textDecoration: 'none' },
  },
});

/* eslint-enable indent,no-confusing-arrow */

export const MenuChevron = styled(OriginalChevron)`
  margin-top: -2px;
  height: 22px;

  ${breakpoints.lg} {
    display: none;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${breakpoints.lg} {
    display: none;
  }
`;
