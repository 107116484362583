const getPayableSwipointsAmount = (
  totalAmount: number,
  swipointsBalance: number,
  payWithOnlySwipointsFeatureFlag?: boolean
) => {
  const swipointsToPay =
    (payWithOnlySwipointsFeatureFlag ? totalAmount : totalAmount - 1) * 10;
  const totalSwipoints =
    swipointsToPay > swipointsBalance ? swipointsBalance : swipointsToPay;
  const maxPaymentAccepted = totalSwipoints / 10;
  const swipointsToCashConversion = swipointsBalance / 10 ?? 0;

  return {
    maxPaymentAccepted,
    swipointsToCashConversion,
  };
};

export default getPayableSwipointsAmount;
