import { breakpoints, styled } from '@swibeco/shared';

/* eslint-disable indent,no-confusing-arrow */
export const PageContainer = styled.div<{ sidebarVisible: boolean }>`
  position: relative;
  min-height: 70vh;
  width: 100%;
  ${breakpoints.lg} {
    width: ${({ sidebarVisible }) =>
      sidebarVisible ? 'calc(100% - 120px)' : '100%'};
    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '120px' : '0')};
  }
`;
/* eslint-enable no-confusing-arrow */
export const LayoutWrapper = styled.div`
  width: 100%;
`;

export const ChildrenWrapper = styled.div`
  min-height: 70vh;
`;
