import { Flex, Box, BoxProps } from '@chakra-ui/react';
import { breakpoints, styled } from '@swibeco/shared';
import { ColorVariants } from '@swibeco/types';
import React, { useState } from 'react';
import { Fade } from 'reactstrap';
import {
  CloseIcon,
  DangerCrossIcon,
  InfoIcon,
  SuccessCheckIcon,
} from './iconography';
import { Text } from './typography';

export type ToastProps = {
  variant: ColorVariants;
  isOpen?: boolean;
  onCloseButtonClick?: () => void;
  onClose?: () => void;
  bordered?: boolean;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  legacy?: boolean;
} & BoxProps;

type StyledProps = Pick<ToastProps, 'variant' | 'bordered'>;

const Wrapper = styled.div<StyledProps>`
  background-color: ${({ theme }) => theme.colors.default.white};
  border-radius: 5px;
  box-shadow: 0 3px 6px
    ${({ variant, theme }) => {
      const variantMapping: { [key in ColorVariants]: string } = {
        [ColorVariants.Info]: `${theme.colors.primary.decorationLight}33`,
        [ColorVariants.Danger]: `${theme.colors.secondary.main}33`,
        [ColorVariants.Success]: `${theme.colors.complementary.light}33`,
        [ColorVariants.Warning]: `${theme.colors.secondary.lightYellow}33`,
      };

      return variantMapping[variant];
    }};
  ${({ variant, theme, bordered }) => {
    const variantMapping: { [key in ColorVariants]: string } = {
      [ColorVariants.Info]: theme.colors.primary.main,
      [ColorVariants.Danger]: theme.colors.secondary.main,
      [ColorVariants.Success]: theme.colors.complementary.light,
      [ColorVariants.Warning]: theme.colors.secondary.lightYellow,
    };
    return `border-left: ${
      bordered ? `5px solid ${variantMapping[variant]}` : 'none'
    }`;
  }};
  ${breakpoints.sm} {
    padding: 27px 21px;
  }
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 21px;
`;
const Body = styled.div`
  display: flex;

  ${breakpoints.sm} {
    flex-direction: row;
  }
`;
const IconWrapper = styled.div`
  align-self: center;
  justify-self: center;
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  align-self: center;
  margin-left: 10px;
`;

/**
 * @deprecated Will be replaced by chakra Toast.
 *
 * Please use `legacy={false}` option
 */
const ToastContent = ({
  variant,
  bordered,
  onCloseButtonClick,
  children,
  footer,
}: Pick<
  ToastProps,
  'variant' | 'bordered' | 'onCloseButtonClick' | 'children' | 'footer'
>) => {
  return (
    <Wrapper
      variant={variant}
      className="py-2 px-3 py-sm-4"
      bordered={bordered}
    >
      <Body>
        <IconWrapper className="mr-3">
          {variant === (ColorVariants.Info || ColorVariants.Warning) && (
            <InfoIcon variant={variant} />
          )}
          {variant === ColorVariants.Danger && <DangerCrossIcon />}
          {variant === ColorVariants.Success && <SuccessCheckIcon />}
        </IconWrapper>
        <div className="w-100 d-flex align-items-center">{children}</div>
        {onCloseButtonClick && (
          <CloseWrapper
            onClick={onCloseButtonClick}
            data-testid="close-wrapper"
          >
            <CloseIcon />
          </CloseWrapper>
        )}
      </Body>
      <div className="w-100 mt-2">{footer}</div>
    </Wrapper>
  );
};

const COLOR_KEYS_MAPPING = {
  [ColorVariants.Info]: 'primary.main',
  [ColorVariants.Danger]: 'secondary.main',
  [ColorVariants.Success]: 'complementary.light',
  [ColorVariants.Warning]: 'secondary.lightYellow',
};

const NewToast = ({
  variant,
  bordered,
  onCloseButtonClick,
  children,
  footer,
  ...props
}: Pick<
  ToastProps,
  'variant' | 'bordered' | 'onCloseButtonClick' | 'children' | 'footer'
>) => (
  <Flex
    position="relative"
    borderRadius={{ base: '6px', lg: '10px' }}
    borderLeftStyle="solid"
    borderLeftWidth={{ base: '5px', lg: '8px' }}
    borderColor={bordered ? COLOR_KEYS_MAPPING[variant] : 'transparent'}
    p={3}
    bg="default.white"
    minH="80px"
    w={{ base: '100vw', md: '527px' }}
    mr={{ base: '-0.5rem', sm: 'unset' }}
    padding="28px 24px"
    boxShadow="0px 3px 6px rgba(62, 97, 145, 0.2)"
    justify="space-between"
    {...props}
  >
    <Flex>
      {variant === (ColorVariants.Info || ColorVariants.Warning) && (
        <InfoIcon variant={variant} size="20px !important" />
      )}
      {variant === ColorVariants.Danger && (
        <DangerCrossIcon size="20px !important" />
      )}
      {variant === ColorVariants.Success && (
        <SuccessCheckIcon size="20px !important" />
      )}
      <Text color={COLOR_KEYS_MAPPING[variant]} ml="8px">
        {children}
      </Text>
    </Flex>
    <CloseIcon
      width="11px"
      height="11px"
      className="mt-2"
      onClick={onCloseButtonClick}
    />
    {footer && (
      <Box w="full" mt="2">
        {footer}
      </Box>
    )}
  </Flex>
);

const Toast = ({
  variant,
  isOpen,
  onCloseButtonClick,
  onClose,
  children,
  bordered = true,
  footer,
  legacy = true,
  ...props
}: ToastProps) => {
  if (legacy) {
    return (
      <Fade in={isOpen} className={isOpen ? '' : 'd-none'} data-testid="toast">
        <ToastContent
          variant={variant}
          bordered={bordered}
          onCloseButtonClick={onCloseButtonClick || onClose}
          footer={footer}
        >
          {children}
        </ToastContent>
      </Fade>
    );
  }
  return (
    <NewToast
      variant={variant}
      bordered={bordered}
      footer={footer}
      onCloseButtonClick={onCloseButtonClick || onClose}
      {...props}
    >
      {children}
    </NewToast>
  );
};

export const UncontrolledToast = (
  props: Omit<ToastProps, 'isOpen' | ' onCloseButtonClick'>
) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <Toast
      {...props}
      isOpen={isOpen}
      onCloseButtonClick={() => setOpen(!isOpen)}
    />
  );
};

export default Toast;
