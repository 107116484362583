import React from 'react';
import { styled } from '@swibeco/shared';
import { useTheme } from '@emotion/react';

type EditIconProps = {
  id?: string;
  width?: number;
  height?: number;
  color?: string;
};

const EditIconWrapper = styled.span<EditIconProps>`
  width: 20px;
`;

const EditIcon = ({
  id = 'edit-icon ',
  width,
  height,
  color,
}: EditIconProps) => {
  const theme = useTheme();
  return (
    <EditIconWrapper id={id} color={color}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill={color || theme.colors.primary.main}
        stroke={color || theme.colors.primary.main}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // eslint-disable-next-line
          fillRule="evenodd"
          // eslint-disable-next-line
          clipRule="evenodd"
          d="M17.7035 0.805069C16.6282 -0.268356 14.8871 -0.268356 13.8118 0.805069L2.44556 12.1723C2.44018 12.1777 2.44018 12.1833 2.43459 12.1833C2.42363 12.1999 2.40707 12.2162 2.3961 12.2328C2.3961 12.2383 2.39051 12.2383 2.39051 12.2437C2.37955 12.2603 2.37417 12.2712 2.36299 12.2878C2.35762 12.2934 2.35762 12.2988 2.35202 12.3044C2.34665 12.3209 2.34106 12.3319 2.33547 12.3484C2.33547 12.3538 2.33009 12.3538 2.33009 12.3594L0.0292777 19.2788C-0.038233 19.4758 0.0131541 19.694 0.161291 19.8402C0.265574 19.943 0.40597 20.0004 0.552182 20C0.611952 19.9989 0.67108 19.9897 0.728275 19.9725L7.64169 17.6659C7.64706 17.6659 7.64706 17.6659 7.65265 17.6605C7.67007 17.6553 7.68684 17.648 7.70211 17.6384C7.70641 17.6379 7.71028 17.636 7.71329 17.633C7.72963 17.622 7.75178 17.6108 7.76833 17.5999C7.78467 17.5889 7.80123 17.5723 7.81779 17.5614C7.82338 17.5558 7.82875 17.5558 7.82875 17.5504C7.83434 17.5448 7.84531 17.5394 7.8509 17.5283L19.195 6.18314C20.2683 5.10779 20.2683 3.36649 19.195 2.29134L17.7035 0.805069ZM1.42191 18.5853L3.09511 13.5595L6.44173 16.9064L1.42191 18.5853ZM18.419 5.41248L7.46561 16.3723L3.63457 12.5411L14.5936 1.5811C15.2382 0.937094 16.2827 0.937094 16.9273 1.5811L18.4244 3.07834C19.0641 3.72579 19.0617 4.76804 18.419 5.41248Z"
          fill={color || theme.colors.primary.main}
        />
      </svg>
    </EditIconWrapper>
  );
};

export default EditIcon;
