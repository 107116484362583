import { Button, Text } from '@chakra-ui/react';
import { actions, authenticationApi } from '@swibeco/security';
import { GlobalActions, JWT_STORAGE } from '@swibeco/shared';
import { useMutation } from '@tanstack/react-query';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as IconLogout } from '../../../../../assets/images/logout.svg';

type LogoutProps = {
  closeBottomSheet: () => void;
};
const Logout = ({ closeBottomSheet }: LogoutProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logout = useMutation({
    mutationFn: authenticationApi.logout,
    onSuccess: () => {
      JWT_STORAGE.token = null;
      dispatch(actions.userLoggedOut());
      dispatch(GlobalActions.user.reset());
    },
  });
  const onLogoutClick = () => {
    closeBottomSheet();
    logout.mutate();
  };
  return (
    <Button
      backgroundColor="transparent"
      onClick={onLogoutClick}
      display="flex"
      gap="8px"
      data-testid="header-userprofile-logout"
      _hover={{
        backgroundColor: 'secondary.decoration.red.palePink',
      }}
      _active={{
        backgroundColor: 'secondary.decoration.red.palePink',
      }}
    >
      <IconLogout w="24px" h="24px" />
      <Text variant="default-important" color="complementary.red">
        {t('core.header.userprofile.menu.logout')}
      </Text>
    </Button>
  );
};
export default Logout;
