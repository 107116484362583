import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, breakpoints } from '@swibeco/shared';

export const ContractWrapper = styled(Col)`
  line-height: 7px;
  font-weight: 500;
  font-size: 7px;
  color: ${({ theme }) => theme.colors.default.black};

  ${breakpoints.md} {
    font-size: 17px;
    line-height: 16px;
  }

  ${breakpoints.lg} {
    line-height: 23px;
    font-size: 18px;
  }
`;

export const Contract = styled(Col)`
  height: 300px;

  ${breakpoints.md} {
    height: 650px;
  }
`;

export const ContractPreview = styled.iframe`
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow: auto;
  border: 1px solid #d2d2d2;

  ${breakpoints.md} {
    padding: 10px;
  }

  &.preview-in-modal {
    border: none;
    padding: 0;
  }
`;

export const ContractPreviewModalButton = styled.div`
  top: 0;
  left: 15px;
  z-index: 2;
  height: 100%;
  width: calc(100% - 30px);
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  ${breakpoints.md} {
    display: none !important;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  width: 28px !important;
  height: 28px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.default.white};
`;

export const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .invalid-feedback {
    text-align: center;
  }

  .radio-container {
    display: flex;
    min-width: 100%;
    align-items: center;
    justify-content: center;

    .radio-label {
      font-size: 17px;
      font-weight: 500;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.default.black};

      ${breakpoints.md} {
        line-height: 23px;
        font-size: 18px;
      }

      :after {
        color: red;
        content: '  *';
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
`;
