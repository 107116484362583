import { breakpoints, css, styled } from '@swibeco/shared';
import { Text } from '@swibeco/ui';
import { Link } from 'react-router-dom';
import { Col as ColBase, Card, CardBody, CardImg } from 'reactstrap';

export const Col = styled(ColBase)`
  height: 252px;
  width: 252px;
  ${breakpoints.sm_max} {
    padding-left: 0 !important;
    padding-right: 10px !important;
    width: 75vw !important;
  }
`;

export const DealCard = styled(Card, {
  shouldForwardProp: (prop) => !['outOfStock'].includes(prop),
})`
  position: relative;
  pointer-events: ${({ outOfStock }) => (outOfStock ? 'none' : 'auto')};
  box-shadow: ${({ theme }) => `0 3px 6px ${theme.colors.shadow.primary}`};
  border: none !important;
  ${breakpoints.sm} {
    width: 310px !important;
    max-height: 312px !important;
    max-width: 350px !important;
  }

  ${breakpoints.sm_max} {
    width: 100% !important;
    height: 100% !important;
  }

  // fix for browser viewport wider than the phone screen in older iOS versions (<15.0)
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 100% !important;
    height: 100% !important;
    max-width: 350px !important;
  }
  &:hover .deal-card-image-overlay {
    opacity: 0.3;
  }

  &:hover .deal-card-image {
    transform: scale(1.02, 1.02);
    filter: none;
  }

  &:hover .deal-voucher-ticket {
    bottom: -1px;
  }

  container-type: inline-size;
`;
export const DealLink = styled(Link)`
  &:hover {
    color: ${({ theme }) => theme.colors.default.black}!important;
  }
`;
export const DealCardImageContainer = styled('div', {
  shouldForwardProp: (prop) => !['isBasket', 'imageHasMargin'].includes(prop),
})<{
  imageHasMargin: boolean;
  isBasket?: boolean;
}>`
  margin: ${({ imageHasMargin }) => (imageHasMargin ? '8px' : '0')};
  overflow: hidden;
  position: relative;

  ${({ isBasket }) =>
    isBasket
      ? css`
          border-radius: 5px;
          height: 100%;
        `
      : css`
          border-radius: 2px;
        `}
`;

export const DealCardImageOverlay = styled.div`
  background-color: ${({ theme }) => theme.colors.default.black};
  opacity: 0.6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 300ms ease-out;
`;

export const DealCardImageShortener = styled.div`
  background-color: ${({ theme }) => theme.colors.default.white};
  bottom: 0;
  height: 15% !important;
`;

export const DealCardImageBrand = styled.div`
  background-color: ${({ theme }) => theme.colors.default.white};
  border-bottom-right-radius: 3px;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 6rem;
  padding: 2px;

  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const DealCardImage = styled(CardImg, {
  shouldForwardProp: (prop) => !['isVoucherDeal', 'isBasket'].includes(prop),
})<{ isVoucherDeal: boolean }>`
  filter: ${({ isVoucherDeal }) => (isVoucherDeal ? 'blur(4px)' : 'none')};
  transition: all 300ms ease-out;
  max-height: 150px;
  width: 100%;
  object-fit: cover;
  ${({ isBasket }) => (isBasket ? 'height: 100%;' : '')}
`;

export const DealVoucherTicket = styled.div<{ isBasket?: boolean }>`
  align-items: center;
  display: flex;
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: bottom 300ms ease-out;
  transform: rotate3d(0, 0, -1, 3deg);
  transform-origin: left top;

  ${({ isBasket }) =>
    isBasket
      ? css`
          width: 90%;
          bottom: 7px;
        `
      : css`
          width: 80%;
          bottom: -7px;
        `}
`;

export const DealVoucherLogo = styled.img`
  width: 20%;
`;

export const DealVoucherText = styled.div`
  width: 65%;
`;

export const DealDiscountPill = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary.main}`};
  border-radius: 15px;
  padding: 2px 6px;
`;

export const Separator = styled.hr`
  border-top: ${({ theme }) => `1px solid ${theme.colors.default.middle}`};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const DealTypeIcon = styled.img`
  height: 35px;
  width: 35px;
`;

export const DealTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ShortTitle = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.default.black};

  ${breakpoints.md} {
    font-size: 17px;
    line-height: 23px;
  }
`;
export const DealPrice = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  ${breakpoints.md} {
    font-size: 17px;
    line-height: 23px;
  }
`;

export const ListPrice = styled(Text)`
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;

  ${breakpoints.md} {
    font-size: 15px;
    line-height: 21px;
  }
`;

export const DiscountPercent = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;

  ${breakpoints.md} {
    font-size: 17px;
    line-height: 23px;
  }
`;

export const DealCardBody = styled(CardBody)`
  padding: 12px 1.2rem 8px 1.2rem;
`;

export const DealTypeLabel = styled.span<{
  isFlash?: boolean;
  isInternal?: boolean;
}>`
  position: absolute;
  right: 0;
  top: 1.25rem;
  padding: 8px;
  border-radius: 3px 0px 0px 3px;
  min-width: 60px;
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.default.white};
  background-color: ${({ isFlash, isInternal, theme }) => {
    if (isInternal) {
      return theme.colors.default.black;
    }
    if (isFlash) {
      return theme.colors.complementary.red;
    }
    return theme.colors.default.black;
  }};
  ${breakpoints.md} {
    font-size: 15px;
    line-height: 16px;
    font-weight: 300;
  }
`;

export const FreeTextDiscount = styled(Text)`
  min-height: 28px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  ${breakpoints.md} {
    font-size: 17px;
    line-height: 23px;
  }

  @container (max-width: 320px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 28ch;
    overflow: hidden;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const ButtonContainer = styled.div`
  z-index: 124;
`;

export const OpacityFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.colors.default.white};
  z-index: 123;
`;

export const IconItem = styled.div`
  display: inline-block;
`;
