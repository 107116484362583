import { keyframes, styled } from '@swibeco/shared';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;
export const Skeleton = styled.div<{
  width?: number;
  height?: number;
  marginTop?: number;
}>`
  display: inline-block;
  height: ${(props) => `${props.height}px` || '14px'};
  width: ${(props) => `${props.height}px` || '100%'};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: ${(props) => props.theme.colors.default.main};
  background-image: ${(props) =>
    `linear-gradient(90deg, ${props.theme.colors.default.strong}, ${props.theme.colors.default.main}, ${props.theme.colors.default.strong});`};
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: ${(props) => props.marginTop || '0'};
`;
