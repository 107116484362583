import { AbilityBuilder, PureAbility } from '@casl/ability';
import { CurrentCompanyInformationType } from '@swibeco/types';

// Available abilities - 'view_dasboard', to add more just do: 'another_thing' | 'and_another'
export type CurrentCompanyAbilities =
  | 'view_new_dashboard'
  | 'view_basket_v2'
  | 'view_plus_landing';
export type CompanyAbilities = PureAbility<CurrentCompanyAbilities>;

const buildCompanyFeatures = (
  builder: AbilityBuilder<CompanyAbilities>,
  company: CurrentCompanyInformationType
) => {
  if (company?.features?.BASKET_V2) {
    builder.can('view_basket_v2');
  }
  if (company?.features?.PLUS_LANDING_PAGE) {
    builder.can('view_plus_landing');
  }
};

export default buildCompanyFeatures;
