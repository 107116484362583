import { styled, withAttrs, breakpoints, css } from '@swibeco/shared';
import { Link as LinkBase } from '@swibeco/ui';

export const ProductsSegment = styled.div<{ noSpacing?: boolean }>`
  background-color: ${({ theme }) => theme.colors.default.light};
  overflow-x: hidden;

  ${({ noSpacing }) =>
    noSpacing
      ? ''
      : css`
          padding-top: 50px;
          ${breakpoints.md} {
            padding-top: 70px;
          }
        `}
`;

export const Link = styled(LinkBase)`
  &:hover {
    color: ${({ theme, color }) =>
      theme.colors[color?.variant || 'primary'][color?.variantColor || 'main']};
    text-decoration: none;
  }
`;

export const Container = withAttrs(styled.div``, () => ({
  className:
    'd-flex flex-xs-row flex-md-row justify-content-start justify-content-md-start align-items-center',
}));
