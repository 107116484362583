import { keepPreviousData, useQuery } from '@tanstack/react-query';
import authenticationApi from '../authenticationApi';
import { useQueryOptionsType } from './types';

type GetCurrentUserHookOptions = useQueryOptionsType & { enabled?: boolean };

const useGetCurrentUser = (
  key = 'current-user',
  options: GetCurrentUserHookOptions = {}
) =>
  useQuery({
    queryFn: authenticationApi.getCurrentUser,
    queryKey: [key],
    retry: false,
    refetchOnWindowFocus: true,
    staleTime: 0,
    placeholderData: keepPreviousData,
    ...options,
  });

export default useGetCurrentUser;
