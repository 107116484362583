import { Box, Button, chakra, Flex } from '@chakra-ui/react';
import { Arrow, ArrowDirection, Icons, Text } from '@swibeco/ui';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';
import React, { useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentUser } from '@swibeco/security';
import { useColorVariant } from '@swibeco/shared';
import swipointsIcon from '../../../assets/icons/swipoints.svg';
import Brands from '../Steps/Brands/Brands';
import animationBag from '../Steps/Brands/animatedShoppingBag.json';
import Reward from '../Steps/Reward/Reward';
import animationSavings from '../Steps/Savings/animatedSavings.json';
import Welcome from '../Steps/Welcome/Welcome';
import animationWelcome from '../Steps/Welcome/animatedWelcome.json';
import { useTutorialDispatch } from './StepperContext';
import useTutorial from '../hooks/useTutorial';
import NoReward from '../Steps/NoReward/NoReward';

enum TutorialSteps {
  Welcome = 'welcome',
  Brands = 'brands',
  Questions = 'questions',
  Reward = 'reward',
  NoReward = 'no_reward',
}

interface LottieAnimationProps {
  animationData: any;
  name: string;
}

const MotionBox = motion(Box);
const upAndDownAnimation = {
  y: [0, -10, 0], // Moves up 10 pixels and back down
  transition: {
    duration: 2,
    ease: 'easeInOut',
    repeat: Infinity,
  },
};

const CheckmarkIcon = chakra(Icons.IconCheckSuccess);

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  animationData,
  name,
}) => {
  const animationContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
        name,
      });
    }

    // Cleanup function to destroy the Lottie animation
    return () => {
      lottie.destroy(name);
    };
  }, [animationData, name]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      w="96px"
      h="96px"
      mb="24px"
      bg="default.main"
      borderRadius="50%"
    >
      <div ref={animationContainer} />
    </Flex>
  );
};

const allSteps = {
  [TutorialSteps.Welcome]: {
    id: TutorialSteps.Welcome,
    lottie: animationSavings,
    description: 'core.ecommerce.homepage.tutorial.step.welcome.description',
    title: 'core.ecommerce.homepage.tutorial.step.welcome.title',
    content: <Welcome />,
    headerPictogram: (
      <LottieAnimation
        animationData={animationSavings}
        name={TutorialSteps.Welcome}
      />
    ),
  },
  [TutorialSteps.Brands]: {
    id: TutorialSteps.Brands,
    lottie: animationBag,
    description: 'core.ecommerce.homepage.tutorial.step.brands.description',
    title: 'core.ecommerce.homepage.tutorial.step.brands.title',
    content: <Brands />,
    headerPictogram: (
      <LottieAnimation
        animationData={animationBag}
        name={TutorialSteps.Brands}
      />
    ),
  },
  [TutorialSteps.Questions]: {
    id: TutorialSteps.Questions,
    lottie: animationWelcome,
    description: 'core.ecommerce.homepage.tutorial.step.questions.description',
    title: 'core.ecommerce.homepage.tutorial.step.questions.title',
    content: null,
    headerPictogram: (
      <LottieAnimation
        animationData={animationWelcome}
        name={TutorialSteps.Questions}
      />
    ),
  },
  [TutorialSteps.Reward]: {
    id: TutorialSteps.Reward,
    lottie: null,
    description: null,
    title: null,
    content: <Reward />,
    headerPictogram: (
      <MotionBox
        backgroundImage={swipointsIcon}
        h="70px"
        w="70px"
        mt="60px"
        backgroundSize="contain"
        animate={upAndDownAnimation}
      />
    ),
  },
  [TutorialSteps.NoReward]: {
    id: TutorialSteps.NoReward,
    lottie: null,
    description: null,
    title: null,
    content: <NoReward />,
    headerPictogram: <CheckmarkIcon w="40px" h="40px" mt="60px" />,
  },
};

const TutorialStepper = ({
  activeStep,
  setActiveStep,
  onClose,
}: {
  onClose: () => void;
  activeStep: number;
  setActiveStep: (activeStep: number) => void;
}) => {
  const { user } = useCurrentUser();
  const initialMain = useColorVariant('initial', 'main');
  const { welcomeGift } = useTutorial();
  const surveryDispatch = useTutorialDispatch();

  const steps = useMemo(() => {
    const basicSteps = [
      TutorialSteps.Welcome,
      TutorialSteps.Brands,
      TutorialSteps.Questions,
    ];
    const stepsToDisplay =
      welcomeGift > 0
        ? [...basicSteps, TutorialSteps.Reward]
        : [...basicSteps, TutorialSteps.NoReward];

    return stepsToDisplay.map((key) => allSteps[key]);
  }, [welcomeGift]);

  const hasCompletedSteps = activeStep - 1 === steps.length - 1;
  const { t } = useTranslation();

  if (!surveryDispatch) {
    throw new Error('surveryDispatch must be used within a SurveyProvider');
  }

  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;
  const handleNext = (activeStep: number) => {
    if (!isLastStep) {
      setActiveStep(activeStep + 1);
    } else {
      onClose();
    }
  };

  const getCloseText = useMemo(() => {
    if (hasCompletedSteps) {
      return t('core.sidebar.close');
    }

    if (isLastStep) {
      return t('core.ecommerce.homepage.tutorial.step.welcome.last');
    }

    if (isFirstStep) {
      return t('core.ecommerce.homepage.tutorial.step.welcome.next');
    }

    return t('core.ecommerce.homepage.tutorial.stepper.next');
  }, [hasCompletedSteps, isLastStep, isFirstStep, t]);

  return (
    <Flex
      flexDirection="column"
      gap="24px"
      align="center"
      justify="space-between"
      pb="60px"
      minHeight="440px"
    >
      {!hasCompletedSteps && (
        <Flex
          align="center"
          justify="center"
          flex={1}
          direction="column"
          gap="32px"
          w="100%"
        >
          <Flex align="center" direction="column" gap="8px">
            {steps[activeStep].headerPictogram}
            {steps[activeStep].title && (
              <Text data-testid="tutorial-firstname-text" bold>
                <Trans
                  i18nKey={steps[activeStep].title}
                  values={{ name: user?.firstName }}
                />
              </Text>
            )}
            {steps[activeStep].description && (
              <Text>{t(steps[activeStep].description)}</Text>
            )}
          </Flex>
          {steps[activeStep].content}
        </Flex>
      )}
      <Flex
        gap="7px"
        id="actions"
        justify={isFirstStep || isLastStep ? 'center' : 'space-between'}
        w="100%"
        direction={{
          base: 'column-reverse',
          md: 'row',
        }}
      >
        {!isLastStep && (
          <Button
            data-testid="tutorial-back-button"
            style={{
              display: activeStep === 0 || hasCompletedSteps ? 'none' : '',
            }}
            onClick={() => setActiveStep(activeStep - 1)}
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            display="flex"
            gap="8px"
            w={{
              base: 'full',
              md: 'auto',
            }}
          >
            <Arrow color={initialMain} direction={ArrowDirection.Left} />
            <Text color="initial.main">
              {t('core.ecommerce.homepage.tutorial.stepper.back')}
            </Text>
          </Button>
        )}
        <Flex direction="column" align="center" gap="8px">
          {isFirstStep && (
            <Text>
              {t('core.ecommerce.homepage.tutorial.step.welcome.guide')}
            </Text>
          )}
          <Button
            data-testid="tutorial-next-button"
            onClick={() => (isLastStep ? onClose() : handleNext(activeStep))}
            bg="initial.main"
            color="default.white"
            _hover={{ bg: 'initial.main' }}
            w={{
              base: 'full',
            }}
          >
            {getCloseText}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default TutorialStepper;
