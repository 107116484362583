import {
  PopoverBody as PopoverBodyBase,
  PopoverHeader as PopoverHeaderBase,
  UncontrolledPopover as UncontrolledPopoverBase,
} from 'reactstrap';
import { styled, breakpoints } from '@swibeco/shared';
import {
  Box,
  Popover as ChakraPopover,
  PopoverArrow as ChakraPopoverArrow,
  PopoverContent as ChakraPopoverContent,
  PopoverContentProps as ChakraPopoverContentProps,
  PopoverProps as ChakraPopoverProps,
  PopoverTrigger as ChakraPopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import React from 'react';

export const UncontrolledPopover = styled(UncontrolledPopoverBase)`
  .popover {
    max-width: 350px;
    margin-left: 5px !important;

    ${breakpoints.lg} {
      max-width: 460px;
      margin-left: 0;
    }
  }
`;

export const PopoverHeader = styled(PopoverHeaderBase)`
  display: flex;
  border-bottom: 0;
  padding: 10px 15px;
  align-items: center;
  font-size: 18px;
  background-color: ${({ theme }) => theme.colors.default.white};
  justify-content: flex-start;

  ${breakpoints.lg} {
    padding: 22px 32px;
  }
`;

export const PopoverBody = styled(PopoverBodyBase)`
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoints.lg} {
    margin: 0 0 20px;
  }
`;

export const PopoverTitle = styled.h5`
  line-height: 23px;
  padding-left: 8px;
  font-size: 18px;
`;

interface PopoverProps extends ChakraPopoverProps {
  popoverTrigger: React.ReactNode;
  children: React.ReactNode;
  popoverContentProps?: ChakraPopoverContentProps;
  popoverTriggerProps?: React.ComponentProps<typeof Box>;
  popoverContentWrapperProps?: React.ComponentProps<typeof Box>;
  showArrow?: boolean;
}

export const Popover = ({
  popoverTrigger,
  children,
  popoverContentProps,
  popoverTriggerProps,
  trigger = 'hover',
  showArrow = true,
  ...rest
}: PopoverProps) => {
  return (
    <ChakraPopover arrowPadding={0} trigger={trigger} {...rest}>
      <ChakraPopoverTrigger>
        <Box as="span" {...popoverTriggerProps}>
          {popoverTrigger}
        </Box>
      </ChakraPopoverTrigger>
      <Portal>
        <Box position="relative" zIndex={9999}>
          <ChakraPopoverContent p={4} {...popoverContentProps}>
            {showArrow && <ChakraPopoverArrow />}
            {children}
          </ChakraPopoverContent>
        </Box>
      </Portal>
    </ChakraPopover>
  );
};

export { PopoverBodyBase };
