import { createSlice } from '@reduxjs/toolkit';
import { UserTypeEnum } from '@swibeco/types';
import { BasicUserState } from '../types';

export const initialState: BasicUserState = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  gender: null,
  swipointsBalance: 0,
  profilePicture: null,
  accountCompleted: false,
  isFirstLogin: false,
  roles: [],
  type: undefined,
  cashbackPopupShown: true,
  recoveryEmail: '',
};

const globalUserSlice = createSlice({
  name: 'globalUser',
  initialState,
  reducers: {
    reset: () => initialState,
    setUser: (state: BasicUserState, action) => {
      Object.keys(state).forEach((k) => {
        if (k === 'profilePicture') {
          state[k] = action.payload[k] || null;
        } else {
          state[k] = action.payload[k];
        }
      });
    },
    updateUser(state: BasicUserState, action) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in action.payload) {
        if (
          Object.prototype.hasOwnProperty.call(action.payload, key) &&
          key in state
        ) {
          state[key] = action.payload[key];
        }
      }
    },
  },
});

const isUserFetched = (state: { global: { user: BasicUserState } }): boolean =>
  !!state.global.user.id;

/**
 * @TODO PERMISSION & ROLES
 * Use permission on a global scale thanks to CASL/ABILITY
 * Careful to role ROLE_DEALER or ROLE_SUPER_ADMIN : do we need to add it later on ? Normally, not, but we never knows ...
 */
const hasHRAdminRights = (state: {
  global: { user: BasicUserState };
}): boolean =>
  state.global.user.roles.includes('ROLE_HR_ADMIN') ||
  state.global.user.roles.includes('ROLE_DATA_MASTER');

const hasDataMasterRights = (state: {
  global: { user: BasicUserState };
}): boolean => state.global.user.roles.includes('ROLE_DATA_MASTER');

const isUserOnboarded = (state: {
  global: { user: BasicUserState };
}): boolean => state.global.user.accountCompleted;

const getSwipointsBalance = (state: {
  global: { user: BasicUserState };
}): number => state.global.user.swipointsBalance;

const getUserType = (state: {
  global: { user: BasicUserState };
}): UserTypeEnum | undefined => state.global.user.type;

const isCashbackPopupShown = (state: {
  global: { user: BasicUserState };
}): boolean => state.global.user.cashbackPopupShown;
const getUserData = (state: {
  global: { user: BasicUserState };
}): BasicUserState => state.global.user;

export const userSelectors = {
  isUserFetched,
  getSwipointsBalance,
  getUserType,
  getUserData,
  hasHRAdminRights,
  hasDataMasterRights,
  isUserOnboarded,
  isCashbackPopupShown,
};

export default globalUserSlice;
