import React from 'react';
import { styled, breakpoints } from '@swibeco/shared';
import successCheck from './images/icon_check_success.svg';

type IconProps = {
  sm?: boolean;
  className?: string;
  size?: string;
};

const Image = styled.img<IconProps>`
  width: ${({ size }) => size || '18px'};
  height: ${({ size }) => size || '18px'};

  ${breakpoints.sm} {
    width: ${({ size }) => size || '20px'};
    height: ${({ size }) => size || '20px'};
  }
`;

const SuccessCheckIcon = ({ sm, className, size }: IconProps) => (
  <Image
    src={successCheck}
    sm={sm}
    data-testid="success-icon"
    className={className}
    size={size}
  />
);

export default SuccessCheckIcon;
