import React from 'react';
import { styled, breakpoints, useColorVariant } from '@swibeco/shared';
import ReactSelect, {
  Props as RSNamedProps,
  components as RSComponents,
  SelectComponentsConfig,
} from 'react-select';
import { FormFeedback } from 'reactstrap';
import { ErrorMessage } from './ErrorMessage';
import Chevron, { ChevronDirection } from './iconography/Chevron';
import { RSStyles } from 'components';
import { OptionType } from 'typings';

export interface SelectProps extends RSNamedProps {
  disabled?: boolean;
  cleared?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatOptionLabel?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValueHook?: any;
  name: string; // required, because otherwhise react-select allows undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  // and we don't want undefined :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isMulti?: any;
  customComponents?: SelectComponentsConfig<any, boolean, any>;
  onMenuInputFocus?: any;
  placeholder?: string;
  onChangeHandler?: any;
  handleKeyDown?: (event: React.KeyboardEvent) => void;
  inputData?: string;
  setInputData?: (value: string) => void;
  styles?: RSStyles<any, boolean, any>;
  isSearchable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controller?: any;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  setSearchValue?: (value: string) => void;
  selectedOption?: OptionType | OptionType[];
  handleChange?: (value: OptionType | OptionType[] | null) => void;
  handleControlClick?: (event?: React.MouseEvent) => void;
  setMenuOpen?: (value: boolean) => void;
}

const StyledSelectWrapper = styled.div<{ invalid: boolean }>`
  .react-select-container {
    width: 100%;
  }

  .react-select__control {
    height: 45px;

    ${breakpoints.lg} {
      height: 40px;
    }

    .react-select__single-value {
      font-size: 17px;
      font-weight: 600;
      line-height: 21px;
      color: ${({ theme }) => theme.colors.default.black};

      ${breakpoints.lg} {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .react-select__control {
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid
      ${({ invalid, theme }) =>
        invalid ? theme.colors.complementary.red : theme.colors.default.middle};

    &:hover {
      border-color: ${({ invalid, theme }) =>
        invalid ? theme.colors.complementary.red : theme.colors.default.middle};
    }
  }

  .react-select__menu {
    margin: 0;
    font-size: 17px;
    z-index: 4;

    .react-select__menu-list {
      padding: 0;

      .react-select__option {
        font-size: 17px;
        font-weight: 400;
        line-height: 21px;
        color: ${({ theme }) => theme.colors.default.black};

        :hover {
          background-color: ${({ theme }) => theme.colors.default.main};
        }

        ${breakpoints.lg} {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
`;

const components = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DropdownIndicator: (provided: any) => (
    <RSComponents.DropdownIndicator {...provided}>
      <Chevron
        direction={
          // eslint-disable-next-line react/destructuring-assignment
          provided.selectProps.menuIsOpen
            ? ChevronDirection.Up
            : ChevronDirection.Down
        }
        color={useColorVariant('default', 'black')}
      />
    </RSComponents.DropdownIndicator>
  ),
};

const customStyles = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (style: any, state: any) => ({
    ...style,
    backgroundColor: 'transparent',
    fontWeight: state.isSelected ? '700 !important' : 'inherit',
  }),
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Select = React.forwardRef<any, SelectProps>(
  (
    {
      disabled = false,
      cleared,
      formatOptionLabel,
      name,
      errors,
      setValueHook,
      options,
      isMulti = false,
      defaultValue,
      customComponents,
      closeMenuOnSelect,
      // handleKeyDown,
      placeholder = 'Select an item',
      inputValue,
      setSearchValue,
      onInputChange,
      isSearchable = false,
      // onMenuInputFocus,
      styles,
      control,
      controller,
      // handleChange,
      value,
      ...rest
    }: SelectProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any
  ) => {
    const Controller = control && controller;
    const onChange = (selectedOption: any | any[]) => {
      // if (handleChange) {
      //   handleChange(selectedOption);
      // }
      if (setValueHook) {
        if (
          !isMulti &&
          !Array.isArray(selectedOption) &&
          selectedOption.value
        ) {
          setValueHook?.(name, selectedOption?.value, {
            shouldDirty: true,
            shouldValidate: true,
          });
        } else if (isMulti) {
          setValueHook?.(name, selectedOption, {
            shouldDirty: true,
            shouldValidate: true,
          });
        } else {
          setValueHook?.(name, undefined, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }
    };

    return (
      <>
        <StyledSelectWrapper invalid={Boolean(errors && errors[name]?.message)}>
          {control ? (
            <Controller
              name={name}
              control={control}
              data-testid="input-id"
              render={({ field }: { field: any }) => {
                return (
                  <ReactSelect
                    aria-invalid={Boolean(errors && errors[name]?.message)}
                    options={options}
                    isClearable={cleared}
                    value={value}
                    defaultValue={defaultValue}
                    isDisabled={disabled}
                    components={{ ...components, ...customComponents }}
                    styles={{ ...customStyles, ...styles }}
                    inputValue={inputValue}
                    onInputChange={onInputChange}
                    formatOptionLabel={formatOptionLabel}
                    placeholder={placeholder}
                    isSearchable={isSearchable}
                    classNamePrefix="react-select"
                    className="react-select-container"
                    isMulti={isMulti}
                    closeMenuOnSelect={closeMenuOnSelect}
                    {...{ setSearchValue }}
                    {...rest}
                    {...field}
                    onChange={(selectedOption: any, action: any) => {
                      onChange(selectedOption);
                      field.onChange(selectedOption, action);
                    }}
                  />
                );
              }}
            />
          ) : (
            <ReactSelect
              aria-invalid={Boolean(errors && errors[name]?.message)}
              {...rest}
              ref={ref}
              isClearable={cleared}
              name={name}
              value={value}
              defaultValue={defaultValue}
              options={options}
              isDisabled={disabled}
              components={components}
              styles={customStyles}
              onChange={onChange}
              formatOptionLabel={formatOptionLabel}
              placeholder="Select an item"
              classNamePrefix="react-select"
              className="react-select-container"
              closeMenuOnSelect={closeMenuOnSelect}
              {...{ setSearchValue }}
            />
          )}
        </StyledSelectWrapper>
        <FormFeedback className="d-flex">
          {errors && <ErrorMessage>{errors[name]?.message}</ErrorMessage>}
        </FormFeedback>
      </>
    );
  }
);

export default Select;
