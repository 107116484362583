import { breakpoints, styled } from '@swibeco/shared';
import { Text } from '@swibeco/ui';

export const DarkBackground = styled.div<{ show?: boolean }>`
  transition: all 0.4s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000e21;
  opacity: 0;
  z-index: 5;
  visibility: hidden;

  ${({ show }) => show && 'visibility: visible; opacity: 0.8;'}
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  z-index: 6;
  right: 0;
  top: 50%;
  width: 20%;
  text-align: center;

  span {
    margin: 0;
  }
`;

export const TextIcon = styled(Text)`
  font-size: 14px !important;
`;

export const Wrapper = styled.div<{ show?: boolean }>`
  background-color: ${({ theme }) => theme.colors.default.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 3px 6px #00000029;
  position: fixed;
  top: 70px;
  bottom: 0;
  z-index: 6;
  width: 80%;
  transition: all 0.4s;
  transform: translateX(-100%);

  ${({ show }) => show && 'transform: translateX(0);'}

  ${breakpoints.md} {
    background-color: ${({ theme }) => theme.colors.default.white};
    width: 120px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;

  img {
    margin: auto;
    width: 25px;

    ${breakpoints.md} {
      width: 30px;
    }
  }
`;
