import React from 'react';
import { Text as CText, type TextProps } from '@chakra-ui/react';

export type CaptionProps = TextProps & {
  children?: React.ReactNode;
};

const Caption = ({
  as = 'p',
  color,
  children,
  onClick,
  ...rest
}: CaptionProps) => (
  <CText
    as={as}
    color={color || 'default.black'}
    variant="caption"
    cursor={onClick ? 'pointer' : undefined}
    onClick={onClick}
    data-typo="caption"
    {...rest}
  >
    {children}
  </CText>
);

export default Caption;
