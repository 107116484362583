import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitle, Text } from '@swibeco/ui';
import * as Styles from './NewUserBanner.styles';
import flashDeal5 from '../../../assets/images/flashDeal5.svg';
import flashDeal10 from '../../../assets/images/flashDeal10.svg';

interface ColumnBlockProps {
  number: number;
  text: string;
}

const ColumnBlock = ({ number, text }: ColumnBlockProps) => (
  <Styles.Col centered maxWidth={180}>
    <Styles.Circle>{number}</Styles.Circle>
    <Text bold>{text}</Text>
  </Styles.Col>
);

interface NewUserBannerInterface {
  showSwipointsBanner: boolean;
}

const NewUserBanner = ({ showSwipointsBanner }: NewUserBannerInterface) => {
  const { t } = useTranslation();
  return (
    <>
      <Styles.DiscoverCol
        isSwipointsBannerShown={showSwipointsBanner}
        minWidth={340}
        maxWidth={430}
        widthFull
      >
        <Styles.HeadingAndFlashDeals>
          <SubTitle className="mb-2">
            {t('core.ecommerce.homepage.new_user_banner.title')}
          </SubTitle>
          <Text>{t('core.ecommerce.homepage.new_user_banner.sub_title')}</Text>
          <Styles.FlashDealInBanner top={-4} right={0}>
            <img
              height="45px"
              width="45px"
              src={flashDeal10}
              alt="flash-deal-10"
            />
          </Styles.FlashDealInBanner>
          <Styles.FlashDealInBanner top={20} right={45}>
            <img
              width="34px"
              height="34px"
              src={flashDeal5}
              alt="flash-deal-5"
            />
          </Styles.FlashDealInBanner>
        </Styles.HeadingAndFlashDeals>
      </Styles.DiscoverCol>
      <Styles.Col className="flex-fill" mdHidden />
      <ColumnBlock
        number={1}
        text={t('core.ecommerce.homepage.new_user_banner.step_one')}
      />
      <ColumnBlock
        number={2}
        text={t('core.ecommerce.homepage.new_user_banner.step_two')}
      />
      <ColumnBlock
        number={3}
        text={t('core.ecommerce.homepage.new_user_banner.step_three')}
      />
    </>
  );
};

export default NewUserBanner;
