import { styled } from '@swibeco/shared';

export const Badge = styled.div<{ count: number }>`
  width: 20px;
  height: 24px;
  position: relative;

  &::after {
    top: -3px;
    right: -11px;
    height: 16px;
    display: flex;
    padding: 0 5px;
    min-width: 16px;
    position: absolute;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    color: ${({ theme }) => theme.colors.default.white};
    padding: 1px 3px;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.7px;
    box-sizing: border-box;
    display: ${({ count }) => (count > 0 ? 'flex' : 'none')};
    content: '${({ count }) => (count > 99 ? '99+' : count)}';
    background-color: ${({ theme }) => theme.colors.complementary.red};
    border-radius: 999px;
    -moz-border-radius: 999px;
    -webkit-border-radius: 999px;
  }
  .background {
    display: none;
  }
`;
