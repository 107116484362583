import React, { useCallback, useRef, useState } from 'react';
import * as Styles from './SliderBrandImage.styles';

type SliderBrandImageProps = {
  src?: string;
  alt?: string;
};

const SliderBrandImage = ({ src, alt }: SliderBrandImageProps) => {
  const brandImageRef = useRef<HTMLImageElement>(null);
  const [size, setSize] = useState<'normal' | 'large'>('normal');

  const setInitialBrandImage = useCallback(() => {
    if (brandImageRef.current) {
      if (
        brandImageRef.current.naturalWidth /
          brandImageRef.current.naturalHeight >
        2
      ) {
        setSize('normal');
      } else {
        setSize('large');
      }
    }
  }, [brandImageRef]);

  return (
    <Styles.BrandLogo size={size}>
      <img
        onLoad={setInitialBrandImage}
        ref={brandImageRef}
        src={src}
        alt={alt}
      />
    </Styles.BrandLogo>
  );
};

export default SliderBrandImage;
