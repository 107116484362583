import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import BrandsShowcase from '../../../../assets/svgs/tutorial_brands.svg';

const Brands = () => (
  <Flex
    align={{ base: 'center', lg: 'flex-start' }}
    borderRadius="5px"
    padding="8.5px 16px"
    maxW="478px"
    flexDirection="column"
  >
    <Box
      backgroundImage={BrandsShowcase}
      w="19rem"
      h="80px"
      backgroundSize="contain"
    />
  </Flex>
);
export default Brands;
