export const hexToHsb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  const v: number = max;
  const d = max - min;
  const s = max === 0 ? 0 : d / max;
  if (max === min) {
    h = 0;
  } else {
    // eslint-disable-next-line default-case
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    b: Math.round(v * 100),
  };
};

export const rgbToHex = (r: number, g: number, b: number) => {
  // convert rgb to hex string
  const hex = [r.toString(16), g.toString(16), b.toString(16)].map((color) =>
    color.length === 1 ? `0${color}` : color
  );
  return `#${hex.join('')}`;
};

export const hsbToRgb = (h: number, s: number, b: number) => {
  let rgb = { r: 0, g: 0, b: 0 };
  const m = (b / 100) * (1 - s / 100);
  const n = (b / 100) * (1 - (s / 100) * (h / 60 - Math.floor(h / 60)));
  const k = (b / 100) * (1 - (s / 100) * (1 - (h / 60 - Math.floor(h / 60))));
  const hh = Math.floor(h / 60);
  // eslint-disable-next-line default-case
  switch (hh) {
    case 0:
      rgb = { r: b / 100, g: k, b: m };
      break;
    case 1:
      rgb = { r: n, g: b / 100, b: m };
      break;
    case 2:
      rgb = { r: m, g: b / 100, b: k };
      break;
    case 3:
      rgb = { r: m, g: n, b: b / 100 };
      break;
    case 4:
      rgb = { r: k, g: m, b: b / 100 };
      break;
    case 5:
      rgb = { r: b / 100, g: m, b: n };
      break;
  }
  return {
    r: Math.round(rgb.r * 255),
    g: Math.round(rgb.g * 255),
    b: Math.round(rgb.b * 255),
  };
};

export const hexToRgb = (
  hex: string,
  opacity = 1,
  result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
) =>
  result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )},${opacity})`
    : null;

export const hsbToHex = (h: number, s: number, b: number) => {
  // convert hsb to hex string
  const rgb = hsbToRgb(h, s, b);
  const hex = rgbToHex(rgb.r, rgb.g, rgb.b);
  return hex;
};
