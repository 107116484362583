import React from 'react';
import { Link, Text } from '@swibeco/ui';
import { ECOMMERCE_CORE } from '@swibeco/shared';
import { Product } from '@swibeco/types';
import { Box, Flex } from '@chakra-ui/react';
import * as Styles from './styles/SearchItem.styles';
import ProductImage from '../../ProductItem/ProductImage';
import ProductPrice from '../../ProductItem/ProductPrice';

const SearchItem = ({
  product,
  toggleSearchResults,
}: {
  product: Product;
  toggleSearchResults: () => void;
}) => (
  <Link
    to={`${ECOMMERCE_CORE}/product/${
      product?.defaultVariantData.slug || product?.slug
    }`}
    onClick={toggleSearchResults}
  >
    <Styles.SearchItemWrapper>
      <Styles.ProductImageWrapper>
        <ProductImage
          isStandalone
          product={product}
          displayBrand={false}
          isFlash={false}
          isProductNew={false}
        />
      </Styles.ProductImageWrapper>
      <Flex gap="8px" flexDir="column">
        <Text noOfLines={1} w="full">
          {product?.name}
        </Text>
        <Box transformOrigin="center left">
          <ProductPrice
            flexDir={{
              base: 'column',
              lg: 'row',
            }}
            justifyContent="flex-start"
            alignItems="flex-start"
            product={product}
          />
        </Box>
      </Flex>
    </Styles.SearchItemWrapper>
  </Link>
);

export default SearchItem;
