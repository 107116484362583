import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { ChakraBaseProvider, ChakraProviderProps } from '@chakra-ui/react';
import { ThemeType } from '@swibeco/types';

type UIProviderProps = {
  theme: ThemeType;
  customTheme: Pick<ChakraProviderProps, 'theme'>;
  children: React.ReactNode;
};

const UIProvider = ({ theme, customTheme, children }: UIProviderProps) => {
  return (
    <ChakraBaseProvider theme={customTheme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ChakraBaseProvider>
  );
};

export default UIProvider;
