import { breakpoints, styled } from '@swibeco/shared';
import { Text } from '@swibeco/ui';
import { ColorProps } from '@swibeco/types';
import { MainContentWrapper } from '@swibeco/shared-web';
import Modal from '@swibeco/ui/lib/components/Modal';

export const CircularIconButtons = styled.button<ColorProps>`
  border-radius: 100px;
  background: white;
  margin-left: 4px;
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const BasketModal = styled(Modal)`
  .modal-footer {
    justify-content: center;
    ${breakpoints.sm} {
      justify-content: space-between;
    }
  }

  a {
    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
`;

export const DecorationShape = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height',
})<{ height?: string }>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.default.main};
  border-radius: 200px 0 0 200px;
  width: 400px;
  height: ${({ height }) => height};
  right: 0;

  ${breakpoints.sm_max} {
    width: 30%;
  }
`;

export const SwiperWrapper = styled(MainContentWrapper)`
  .swiper {
    overflow: visible;
  }
  .custom-swiper-slide {
    min-width: 330px !important;

    ${breakpoints.lg} {
      margin-right: 20px !important;
    }
  }
`;

export const DealsTitle = styled(Text)`
  z-index: 3;
`;
