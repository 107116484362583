import { Box, Flex, useSteps } from '@chakra-ui/react';
import { ecommerceApi } from '@swibeco/ecommerce';
import { useColorVariant } from '@swibeco/shared';
import { Arrow, HoverBehavior, Text } from '@swibeco/ui';
import { ArrowDirection } from '@swibeco/ui/lib/components/iconography/Arrow';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as IconRewards } from '../../../assets/svgs/reward.svg';
import Tutorial from '../Modal/Modal';
import useTutorial from '../hooks/useTutorial';

const TutorialCallout = ({
  isOpen,
  onClose,
  onOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) => {
  const white = useColorVariant('default', 'white');
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { notViewedYet, closeTutorial, welcomeGift, giftCashAmount } =
    useTutorial();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 4,
  });
  const grantReward = useMutation({
    mutationFn: () => ecommerceApi.grantReward(),
  });
  const handleClose = async () => {
    onClose();
    if (activeStep === 3) {
      closeTutorial();
      await grantReward.mutateAsync();
    }
  };
  const handleOpen = () => {
    onOpen();
  };

  useEffect(() => {
    if (!notViewedYet) return;

    const searchParams = new URLSearchParams(location.search);
    const referrer = searchParams.get('referrer');

    if (referrer === 'onboarding') {
      onOpen();
      searchParams.delete('referrer');
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [notViewedYet, location.search, navigate, location.pathname, onOpen]);

  return (
    notViewedYet && (
      <Box
        data-testid="tutorial-callout"
        alignItems="center"
        background="primary.dark"
        borderRadius="3px"
        display="flex"
        gap="10px"
        h="72px"
        p="5px"
        w="298px"
        cursor="pointer"
        onClick={handleOpen}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="3px"
          boxSize="62px"
          background="default.white"
        >
          <Box as={IconRewards} boxSize="62px" p="8px" />
        </Flex>
        <Text className="mr-auto" color="default.white" bold>
          {activeStep === 0 && welcomeGift > 0 ? (
            <Trans
              i18nKey="core.ecommerce.homepage.tutorial_callouts.reward"
              values={{ cashAmount: giftCashAmount }}
            />
          ) : (
            t('core.ecommerce.homepage.tutorial_callouts.quick_tour')
          )}
        </Text>
        <HoverBehavior className="mr-1" opacity={0.2}>
          <Arrow
            className="m-1"
            color={white}
            direction={ArrowDirection.Right}
          />
        </HoverBehavior>
        <Tutorial
          isOpen={isOpen && notViewedYet}
          onClose={handleClose}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Box>
    )
  );
};
export default TutorialCallout;
