import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@swibeco/ui';
import { useColorVariant } from '@swibeco/shared';
import * as Styles from './AxaBanner.styles';
import AxaLogoBlue from '../../../assets/images/logo-AXA.svg';
import AxaUmbrella from '../../../assets/icons/axa_icons-umbrella.svg';
import AxaHome from '../../../assets/icons/axa_icons-home.svg';
import AxaSunbed from '../../../assets/icons/axa_icons-sunbed.svg';
import AxaEdit from '../../../assets/icons/axa_icons-edit.svg';

const AxaBanner = () => {
  const { t } = useTranslation();
  const defaultWhite = useColorVariant('default', 'white');

  return (
    <Styles.AxaBannerWrapper>
      <Styles.AxaFirstColumn>
        <Styles.AxaTitle>
          <Styles.AxaIconBubble className="mr-2">
            <Styles.AxaIcon src={AxaLogoBlue} alt="AXA" />
          </Styles.AxaIconBubble>
          <Text component="h3" important color={defaultWhite}>
            {t('core.ecommerce.homepage.axa.title')}
          </Text>
        </Styles.AxaTitle>
        <Text bold color={defaultWhite}>
          {t('core.ecommerce.homepage.axa.description')}
        </Text>
        <Link href="https://myaxa.axa.ch/" target="_blank">
          <Styles.AxaButton>
            {t('core.ecommerce.homepage.axa.cta')}
          </Styles.AxaButton>
        </Link>
      </Styles.AxaFirstColumn>
      <Styles.AxaSecondColumn>
        <Styles.AxaServices>
          <Styles.AxaIconBubble className="mr-2">
            <Styles.AxaIcon src={AxaUmbrella} alt="Overview" />
          </Styles.AxaIconBubble>
          <Text color={defaultWhite}>
            {t('core.ecommerce.homepage.axa.service1')}
          </Text>
        </Styles.AxaServices>
        <Styles.AxaServices>
          <Styles.AxaIconBubble className="mr-2">
            <Styles.AxaIcon src={AxaHome} alt="Overview" />
          </Styles.AxaIconBubble>
          <Text color={defaultWhite}>
            {t('core.ecommerce.homepage.axa.service2')}
          </Text>
        </Styles.AxaServices>
        <Styles.AxaServices>
          <Styles.AxaIconBubble className="mr-2">
            <Styles.AxaIcon src={AxaSunbed} alt="Overview" isAxaSunbed />
          </Styles.AxaIconBubble>
          <Text color={defaultWhite}>
            {t('core.ecommerce.homepage.axa.service3')}
          </Text>
        </Styles.AxaServices>
        <Styles.AxaServices>
          <Styles.AxaIconBubble className="mr-2">
            <Styles.AxaIcon src={AxaEdit} alt="Overview" />
          </Styles.AxaIconBubble>
          <Text color={defaultWhite}>
            {t('core.ecommerce.homepage.axa.service4')}
          </Text>
        </Styles.AxaServices>
      </Styles.AxaSecondColumn>
    </Styles.AxaBannerWrapper>
  );
};

export default AxaBanner;
