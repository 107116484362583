import { breakpoints, styled } from '@swibeco/shared';
import { Swiper, SwiperSlide } from '@swibeco/shared-web';
import bubble1 from '../../../assets/images/bubble_1.svg';

export const ShapeContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.default.main};
  ${breakpoints.sm_max} {
    position: relative;
    min-height: 198px;
    overflow: hidden;
  }
`;

const Shape = styled.div`
  position: absolute;
  display: block;
  list-style: none;
  ${breakpoints.sm_max} {
    display: none;
  }
`;

export const FirstShape = styled(Shape)`
  width: 308.88px;
  min-height: 258.18px;
  transform-origin: 80% 60%;
  transform: rotate(36deg);
  left: -216px;
  top: -129px;
  background-image: url('${bubble1}');
  opacity: 0.3;
`;

export const SecondShape = styled(Shape)`
  position: absolute;
  display: block;
  list-style: none;
  width: 417.88px;
  min-height: 328.18px;
  left: -141px;
  transform: rotate(-39deg);
  border-radius: 50%;
  top: -135px;
  border-radius: 50%;
  border: 1px dashed ${(props) => props.theme.colors.default.dark};
  opacity: 0.2;
`;

export const ThirdShape = styled(Shape)`
  position: absolute;
  display: block;
  list-style: none;
  width: 528.88px;
  min-height: 317.18px;
  left: -140px;
  transform: rotate(-39deg);
  border-radius: 50%;
  top: -74px;
  border-radius: 50%;
  border: 1px dashed ${(props) => props.theme.colors.default.dark};
  opacity: 0.2;
`;

export const ImageBackground = styled(SwiperSlide, {
  shouldForwardProp: (prop) => !['imageBackground'].includes(prop),
})<{
  imageBackground: string;
}>`
  min-width: 50vw;
  min-height: 319px;
  right: 0;
  background-image: ${({ imageBackground }) => `url(${imageBackground})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${breakpoints.sm_max} {
    width: 100%;
    min-height: 198px;
    max-height: 198px;
  }
`;
export const ImageBackgroundContainer = styled.div`
  position: absolute;
  width: 55vw;
  min-height: 319px;
  top: 0;
  right: 0;
  border-radius: 0 0 0 250px;
  overflow: hidden;
  ${breakpoints.sm_max} {
    width: 100%;
    min-height: 198px;
    max-height: 198px;
  }
`;
export const BackgroundSwiper = styled(Swiper)`
  .swiper-slide {
    ${breakpoints.sm_max} {
      width: 100% !important;
    }
  }
`;
export const Gradient = styled.div`
  min-height: 80px;
  width: 100%;
  background: linear-gradient(to bottom, transparent, #000);
  position: relative;
  top: 315px;
`;
