import { breakpoints, styled } from '@swibeco/shared';

/* eslint-disable indent */
export const HeaderItem = styled.div<{ noBorder?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  ${breakpoints.lg} {
    padding: 0 20px;
    border-right: ${({ theme, noBorder }) =>
      !noBorder && `1px solid ${theme.colors.default.middle}`};
    padding: ${({ noBorder }) => noBorder && '0 12px'};
    &:hover {
      .background {
        display: inline-block;
        position: absolute;
        width: 42px;
        height: 42px;
        background-color: ${({ theme }) => theme.colors.default.main};
        border-radius: 50px;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;
/* eslint-enable indent */
