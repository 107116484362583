import { BoxProps } from '@chakra-ui/react';
import React from 'react';
import { Col, ColProps } from 'reactstrap';
import * as Styles from './PageLayout.styles';

interface PageLayoutProps {
  sideContent?: React.ReactNode;
  headerContent?: React.ReactNode;
  fixedSide?: boolean;
  flexSideTop?: number;
  displayShapes?: boolean;
  children: React.ReactNode;
  containerProps?: BoxProps;
  sideContentProps?: ColProps;
}

/**
 *
 * @param sideContent - content to be displayed on the side of the page
 * @param fixedSide - if true, the side content will be fixed to the top of the page
 * @returns React.JSX.Element
 */

const PageLayout = ({
  children,
  sideContent,
  headerContent,
  fixedSide,
  displayShapes = true,
  flexSideTop,
  containerProps,
  sideContentProps,
}: PageLayoutProps) => (
  <Styles.Wrapper {...containerProps} displayShapes={displayShapes}>
    {headerContent && <Styles.Header>{headerContent}</Styles.Header>}
    <Styles.Layout>
      <Col
        lg={!sideContent ? 12 : 8}
        md={12}
        className="mb-lg-0 mb-3 px-0"
        data-testid="page-content"
      >
        <Styles.ColumnContent>{children}</Styles.ColumnContent>
      </Col>
      {sideContent && (
        <Col
          lg={4}
          md={12}
          {...sideContentProps}
          data-testid="page-side-content"
        >
          <Styles.ColumnContent fixed={fixedSide} flexSideTop={flexSideTop}>
            {sideContent}
          </Styles.ColumnContent>
        </Col>
      )}
    </Styles.Layout>
    {displayShapes && (
      <Styles.ShapeContainer>
        <Styles.FirstShape />
        <Styles.SecondShape />
        <Styles.ThirdShape />
      </Styles.ShapeContainer>
    )}
  </Styles.Wrapper>
);

export default PageLayout;
