import React from 'react';
import { Heading, type HeadingProps } from '@chakra-ui/react';

export type SubTitleProps = HeadingProps & {
  children: React.ReactNode;
};

const SubTitle = ({ as = 'h2', color, children, ...rest }: SubTitleProps) => (
  <Heading as={as} variant="sub-title" color={color} {...rest}>
    {children}
  </Heading>
);

export default SubTitle;
