import React from 'react';
import { Box, Flex, chakra } from '@chakra-ui/react';
import { SubTitle, Text } from '@swibeco/ui';
import { useTranslation } from 'react-i18next';
import dottedCircle from '../../../assets/svgs/dotted_circle_plus.svg';

type StepNumberProps = {
  step: number;
  size: string;
};

const DashedLine = chakra(Box, {
  baseStyle: {
    flexBasis: '16%',
    alignSelf: 'center',
    borderTopWidth: { base: '0', lg: '2px' },
    borderRightWidth: { base: '2px', lg: '0' },
    borderStyle: 'dashed',
    borderColor: 'default.middle',
  },
});

const StepNumber = ({ size, step = 1 }: StepNumberProps) => (
  <Flex
    backgroundRepeat="no-repeat"
    backgroundPosition="center"
    backgroundImage={`url(${dottedCircle})`}
    borderRadius="full"
    justifyContent="center"
    alignItems="center"
    h={size}
    w={size}
  >
    <Text bold>{step}</Text>
  </Flex>
);

const PlusHowToActivate = () => {
  const { t } = useTranslation();
  return (
    <Box as="section" w="100%" maxW="988px" m="0 auto" textAlign="center">
      <Box maxW={{ base: '297px', lg: 'unset' }} m="0 auto 39px auto">
        <SubTitle>{t('core.ecommerce.plus.how_to_activate.title')}</SubTitle>
      </Box>
      <Box display={{ base: 'flex', lg: 'block' }} justifyContent="center">
        <Box position="relative" mr={{ base: '13px', lg: 'unset' }}>
          <Flex
            justifyContent="space-around"
            gap={{ base: '68px', lg: '20px' }}
            flexBasis="316px"
            mb="12px"
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <StepNumber size="47px" step={1} />
            <StepNumber size="47px" step={2} />
            <StepNumber size="47px" step={3} />
          </Flex>
          <Flex
            paddingX={{ base: '0', lg: '47px' }}
            position="absolute"
            top={{ base: '0', lg: '50%' }}
            translateY="-50%"
            left="0"
            width="full"
            height={{ base: 'full', lg: 'unset' }}
            justifyContent={{ base: 'flex-start', lg: 'space-evenly' }}
            gap={{ base: '80px', lg: '20px' }}
            flexBasis={{ base: '45px', lg: '316px' }}
            mb="12px"
            flexDir={{ base: 'column', lg: 'row' }}
            pt={{ base: '60px', lg: 'unset' }}
          >
            <DashedLine />
            <DashedLine />
          </Flex>
        </Box>
        <Flex
          flexDir={{ base: 'column', lg: 'row' }}
          justifyContent="space-around"
          gap={{ base: '49px', lg: '20px' }}
          flexBasis="316px"
          textAlign={{ base: 'left', lg: 'center' }}
        >
          <Text>{t('core.ecommerce.plus.how_to_activate.step_one')}</Text>
          <Text>{t('core.ecommerce.plus.how_to_activate.step_two')}</Text>
          <Text>{t('core.ecommerce.plus.how_to_activate.step_three')}</Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default PlusHowToActivate;
