import React from 'react';
import { styled, breakpoints } from '@swibeco/shared';
import dangerCross from './images/icon_cross_danger.svg';

type IconProps = {
  sm?: boolean;
  size?: string;
};

const Image = styled.img<IconProps>`
  width: ${({ size }) => size || '20px'};
  height: ${({ size }) => size || '20px'};

  ${breakpoints.sm} {
    width: ${({ sm, size }) => (size || sm ? '25px' : '32px')};
    height: ${({ sm, size }) => (size || sm ? '25px' : '32px')};
  }
`;

const DangerCrossIcon = (props: IconProps) => (
  <Image src={dangerCross} {...props} data-testid="danger-icon" />
);

export default DangerCrossIcon;
