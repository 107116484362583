import { breakpoints, styled } from '@swibeco/shared';
import { Caption } from '@swibeco/ui';
import { MainContentWrapper } from '@swibeco/shared-web';
import { Link } from 'react-router-dom';
import { blackFilter } from '../../utils';

export const BottomCategoriesMenuWrapper = styled.div`
  transition: margin 0.3s linear;
  background-color: ${({ theme }) => theme.colors.default.main};
  padding: 0;
  display: block;
  z-index: 15;
  width: 100%;
  padding: 90px 0;
  user-select: none;
  overflow: hidden;
`;

export const BottomCategoriesContentWrapper = styled(MainContentWrapper)`
  ${breakpoints.sm_max} {
    width: 100%;
    padding: 0 15px;
  }
`;

export const BottomCategoriesMenuItem = styled.li<{ isFlashDeal?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 8px;
  text-align: center;
  padding: 0 10px;
  p {
    color: ${({ theme, isFlashDeal }) =>
      isFlashDeal
        ? theme.colors.complementary.red
        : theme.colors.default.strong};
  }
`;

export const BottomCategoriesImageWrapper = styled(Link)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.default.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 60px;
  height: 60px;
  align-items: center;
  box-shadow: 0px 3px 6px 0px ${({ theme }) => theme.colors.shadow.primary};
  :hover {
    background-color: ${({ theme }) => theme.colors.default.main};
  }
  img {
    max-width: 25px;
    max-height: 25px;
    width: 100%;
    filter: ${blackFilter};
  }
`;

export const BottomCategoriesCaption = styled(Caption)`
  text-decoration: uppercase;
  ${breakpoints.sm_max} {
    max-width: 80px;
  }
`;
