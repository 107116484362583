import React from 'react';
import { breakpoints, styled } from '@swibeco/shared';
import { ColorProps, StyledColorProps } from '@swibeco/types';

export type HeadingOrder = 1 | 2 | 3 | 4 | 5 | 6;
export type HeadingTag = `h${HeadingOrder}`;
export type HeadingSize = HeadingTag | React.CSSProperties['fontSize'];
type StyledHeadingProps = StyledColorProps & {
  as?: React.ElementType;
  size: HeadingTag;
  weight?: 'normal' | 'light' | 'bold';
};

const headingsStyles = {
  desktop: {
    h1: {
      normal: {
        fontSize: '36px',
        lineHeight: '50px',
        fontWeight: 700,
      },
    },
    h2: {
      normal: {
        fontSize: '32px',
        lineHeight: '41px',
        fontWeight: 700,
      },
    },
    h3: {
      normal: {
        fontSize: '26px',
        lineHeight: '33px',
        fontWeight: 500,
      },
      light: {
        fontSize: '26px',
        lineHeight: '33px',
        fontWeight: 600,
      },
      bold: {
        fontSize: '26px',
        lineHeight: '33px',
        fontWeight: 700,
      },
    },
  },
  mobile: {
    h1: {
      normal: {
        fontSize: '29px',
        lineHeight: '53px',
        fontWeight: 700,
      },
    },
    h2: {
      normal: {
        fontSize: '24px',
        lineHeight: '33px',
        fontWeight: 700,
      },
    },
    h3: {
      normal: {
        fontSize: '22px',
        lineHeight: '30px',
        fontWeight: 500,
      },
      light: {
        fontSize: '1.529rem',
        lineHeight: '33px',
        fontWeight: 600,
      },
      bold: {
        fontSize: '22px',
        lineHeight: '30px',
        fontWeight: 700,
      },
    },
  },
};

const StyledHeading = styled.div<StyledHeadingProps>`
  font-size: ${({ size }) => headingsStyles.desktop[size].normal.fontSize};
  line-height: ${({ size }) => headingsStyles.desktop[size].lineHeight};
  font-weight: ${({ size, weight }) =>
    headingsStyles.desktop[size][weight].fontWeight};
  color: ${({ theme, variant, variantColor }) =>
    theme.colors[variant][variantColor]};
  margin: 0;

  ${breakpoints.sm_max} {
    font-size: ${({ size }) => headingsStyles.mobile[size].fontSize};
    line-height: ${({ size }) => headingsStyles.mobile[size].lineHeight};
    font-weight: ${({ size, weight }) =>
      headingsStyles.mobile[size][weight].fontWeight};
  }
`;

export type StyledHeading = ColorProps & {
  level?: HeadingTag;
  size?: HeadingTag;
  className?: string;
  weight?: 'normal' | 'light' | 'bold';
  children?: React.ReactNode;
};

const Heading = ({
  level = 'h1',
  size = level || 'h1',
  className,
  color,
  children,
  weight,
}: StyledHeading) => (
  <StyledHeading
    as={level}
    className={className}
    variant={color?.variant || 'default'}
    variantColor={color?.variantColor || 'black'}
    weight={weight || 'normal'}
    size={size}
  >
    {children}
  </StyledHeading>
);

export default Heading;
