import { hasCashback } from '@swibeco/shared';
import {
  BasketType,
  DealProductNatureType,
  BasketResponse,
} from '@swibeco/types';

const calculateBasketDetails = (
  data: BasketResponse,
  allocatedSwipoints: number
): BasketType => {
  const { basketItems } = data;
  const totalProducts: number = basketItems.reduce(
    (partial, product) => partial + product.finalPrice * product.quantity,
    0
  );
  const totalSavings: number = basketItems.reduce(
    (saving, product) =>
      saving + (product.listPrice - product.finalPrice) * product.quantity,
    0
  );
  const totalCashback: number = basketItems.reduce(
    (saving, product) =>
      hasCashback(product)
        ? saving + (product.cashbackAmount ?? 0) * product.quantity
        : saving,
    0
  );
  const hasPhysical: boolean = basketItems.some(
    (product) => product.type === DealProductNatureType.PHYSICAL
  );
  const hasDigital: boolean = basketItems.some(
    (product) => product.type === DealProductNatureType.DIGITAL
  );
  const hasSubscription: boolean = basketItems.some(
    (product) => product.type === DealProductNatureType.SUBSCRIPTION
  );

  return {
    products: basketItems,
    totalAmount: totalProducts + data.deliveryPrice,
    finalAmountToPay: totalProducts + data.deliveryPrice - allocatedSwipoints,
    totalSavings,
    totalCashback,
    totalProducts,
    totalDelivery: data.deliveryPrice,
    hasPhysical,
    hasDigital,
    hasSubscription,
  };
};

export { calculateBasketDetails };
