import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type * as rtk from '@reduxjs/toolkit';

export interface FlexState {
  preview: boolean;
}

const initialState: FlexState = {
  preview: false,
};

const flexSlice = createSlice({
  name: 'flex',
  initialState,
  reducers: {
    reset: () => initialState,
    setPreview: (state: FlexState, action: PayloadAction<boolean>) => {
      state.preview = action.payload;
    },
  },
});

const isPreviewMode = (state: { flex: FlexState }): boolean =>
  state.flex.preview;

export const actions = { ...flexSlice.actions };
export const selectors = {
  isPreviewMode,
};
export const { reducer } = flexSlice;
