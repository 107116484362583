import { styled, withAttrs } from '@swibeco/shared';

export const ScrollTopButtonWrapper = withAttrs(
  styled.div`
    background: ${({ theme }) => theme.colors.default.main};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.colors.default.middle};
    }
  `,
  () => ({
    className: 'py-3',
  })
);

export const ScrollTopButtonInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
