import React from 'react';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Text } from '@swibeco/ui';
import {
  ScrollTopButtonWrapper,
  ScrollTopButtonInnerContainer,
} from './ScrollTopButton.styles';

const ScrollTopButton = () => {
  const { t } = useTranslation();

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ScrollTopButtonWrapper onClick={handleScrollTop}>
      <ScrollTopButtonInnerContainer>
        <Text color="default.black" className="mr-2">
          <FontAwesomeIcon icon={faAngleUp} />
        </Text>
        <Text color="default.black">{t('core.ecommerce.scrollTop')}</Text>
      </ScrollTopButtonInnerContainer>
    </ScrollTopButtonWrapper>
  );
};

export default ScrollTopButton;
