import React from 'react';
import * as Styles from './MenuLink.styles';

type MenuLinkProps = {
  text: string;
  icon?: string;
  id: string;
  isActive?: boolean;
  iconComponent?: React.ReactNode;
};
const MenuLink = ({
  text,
  icon,
  id,
  isActive,
  iconComponent,
}: MenuLinkProps) => (
  <Styles.Link id={id} isActive={isActive}>
    <Styles.Icon>{iconComponent || <Styles.Image src={icon} />}</Styles.Icon>
    <Styles.LinkText>{text}</Styles.LinkText>
  </Styles.Link>
);

export default MenuLink;
