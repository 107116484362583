import React from 'react';
import { Collapse } from 'reactstrap';
import * as Styles from './StepElement.styles';

type StepElementProps = {
  title: React.ReactNode;
  color?: string;
  icon?: any;
  children: React.ReactNode;
};

const StepElement = ({ title, color, icon, children }: StepElementProps) => (
  <Styles.Wrapper className="w-100">
    <Styles.Header className="d-flex align-items-center justify-content-between px-4 py-3">
      <div className="d-flex align-items-md-center flex-column flex-md-row">
        <div className="d-flex flex-row">
          {icon && (
            <Styles.StepExplanationIcon data-testid="step-icon">
              {icon}
            </Styles.StepExplanationIcon>
          )}
          <Styles.Text
            hexColor={color}
            component="h1"
            className="flex-grow-1"
            bold
          >
            {title}
          </Styles.Text>
        </div>
      </div>
    </Styles.Header>
    <Collapse isOpen>
      <Styles.StepElementContent>{children}</Styles.StepElementContent>
    </Collapse>
  </Styles.Wrapper>
);

export default StepElement;
