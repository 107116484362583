export type UniverseStore = {
  context: string;
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum universeTypes {
  SET_CONTEXT = 'SET_CONTEXT',
}
type Payload = {
  [universeTypes.SET_CONTEXT]: string;
};

export type UniverseActions = ActionMap<Payload>[keyof ActionMap<Payload>];
