/* eslint-disable no-nested-ternary */
import React from 'react';
import { breakpoints, styled } from '@swibeco/shared';
import Caption from './typography/Caption';
import { ColorVariants, Alignment } from '@swibeco/types';
import { InfoIcon } from './iconography';

export type AlertProps = {
  variant: ColorVariants;
  noBackground?: boolean;
  className?: string;
  titleInline?: string;
  iconAlignment?: Alignment;
  justifyContent?: Alignment;
  children: React.ReactNode;
};

const Wrapper = styled.div<AlertProps>`
  border-radius: 5px;
  background-color: ${({ variant, noBackground, theme }) => {
    if (noBackground) {
      return 'transparent';
    }

    const variantMapping: { [key in ColorVariants]: string } = {
      [ColorVariants.Info]: theme.colors.primary.decorationLight,
      [ColorVariants.Danger]: theme.colors.secondary.mainLight,
      [ColorVariants.Success]: `${theme.colors.complementary.light}33`,
      [ColorVariants.Warning]: theme.colors.secondary.lightYellow,
    };

    return variantMapping[variant];
  }};

  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
  text-align: center;

  ${breakpoints.sm} {
    flex-direction: row;
    text-align: start;
  }
`;

const IconWrapper = styled.div<AlertProps>`
  align-self: ${({ iconAlignment }) => iconAlignment};

  ${breakpoints.sm_max} {
    margin-bottom: 8px !important;
    align-self: center;
  }
`;

const ContentWraper = styled.div<AlertProps>`
  font-size: ${({ noBackground }) => (noBackground ? '15px' : '17px')};
  ${breakpoints.sm_max} {
    font-size: ${({ noBackground }) => (noBackground ? '13px' : '17px')};
  }
`;

const Alert = ({
  variant,
  noBackground,
  children,
  className,
  titleInline,
  iconAlignment = 'center',
  justifyContent = 'center',
}: AlertProps) => (
  <Wrapper
    variant={variant}
    noBackground={noBackground}
    className={`p-3 ${className || ''}`}
    justifyContent={justifyContent}
  >
    <IconWrapper
      variant={variant}
      iconAlignment={iconAlignment}
      className={`mb-3 mb-sm-0 mr-sm-3 ${
        titleInline ? 'd-flex align-items-center justify-content-center' : ''
      }`}
    >
      <InfoIcon variant={variant} />
      {!!titleInline && (
        <Caption ml="2" fontWeight="bold">
          {titleInline}
        </Caption>
      )}
    </IconWrapper>
    <ContentWraper variant={variant} noBackground={noBackground}>
      {children}
    </ContentWraper>
  </Wrapper>
);

export default Alert;
