import { useQuery } from '@tanstack/react-query';
import {
  CompanyThemeType,
  CurrentCompanyInformationType,
} from '@swibeco/types';
import { ERROR_PAGE, JWT_STORAGE } from '@swibeco/shared';
import { useEffect } from 'react';
import authenticationApi from '../authenticationApi';
import { AppFeatureAbility } from '../features';
import { useQueryOptionsType } from './types';

type GetCurrentCompanyHookOptions = useQueryOptionsType & { enabled?: boolean };

const useGetCurrentCompanyInformation = (
  companyMainColor: string,
  key: string,
  options: GetCurrentCompanyHookOptions = {}
) => {
  const query = useQuery({
    queryKey: [key, JWT_STORAGE.token],
    queryFn: authenticationApi.getCurrentCompany,
    placeholderData: <
      CurrentCompanyInformationType & { ability?: AppFeatureAbility }
    >{
      kind: 'CurrentCompany',
      theme: <CompanyThemeType>{
        main_color: companyMainColor || '#006CFF',
      },
      can_manage_users: false,
      display_login_logo: true,
      contract_start_date: null,
      authentication_methods: [],
      subdomain_belongs_to_company: true,
      dealer_is_axa: false,
      has_inhousedeals: true,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retry: (count, error: any) => count < 2 && error.response?.status === 401,
    ...options,
  });
  useEffect(() => {
    if (query.error) {
      if (window.location.pathname !== `/v2${ERROR_PAGE}`) {
        window.location.assign(`/v2${ERROR_PAGE}`);
      }
    }
  }, [query.error]);
  return query;
};

export default useGetCurrentCompanyInformation;
