import React from 'react';
import { styled, breakpoints } from '@swibeco/shared';
import {
  Input as RSInput,
  Label as RSLabel,
  InputProps as RSInputProps,
  FormFeedback,
} from 'reactstrap';
import { ErrorMessage } from './ErrorMessage';

const Container = styled.div`
  margin: 5px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
`;

const Label = styled(RSLabel, {
  shouldForwardProp: (prop) => !['labelColor'].includes(prop as string),
})<{ labelColor?: string }>`
  font-size: 17px;
  font-weight: 500;
  margin-left: 11px;
  line-height: 24px;
  font-family: 'museo-sans';
  transition: color 0.2s ease-in;
  color: ${({ labelColor, theme }) => labelColor || theme.colors.default.black};

  ${breakpoints.lg} {
    font-size: 18px;
  }
`;

const Fill = styled.div`
  top: -4px;
  z-index: 1;
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 100%;
  pointer-events: none;
  background: transparent;
  transition: background 0.2s ease-in;

  &::before {
    top: 50%;
    left: 50%;
    opacity: 1;
    width: 20px;
    content: '';
    height: 20px;
    position: absolute;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    border: 2px solid ${({ theme }) => theme.colors.default.black};
  }
`;

const InputDesign = styled(RSInput, {
  shouldForwardProp: (prop) => !['checkedLabelColor'].includes(prop as string),
})<{
  checkedLabelColor?: string;
}>`
  top: 0;
  margin: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ * .radio-filler {
      transition: background 0.2s ease-out;
      background: ${({ theme }) => theme.colors.primary.main};

      &::before {
        border: 2px solid ${({ theme }) => theme.colors.primary.main};
      }
    }

    &:disabled {
      & ~ * .radio-filler {
        background: ${({ theme }) => theme.colors.default.strong};
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    & ~ * .radio-filler {
      background-color: #e9e9e9;
      width: 18px;
      height: 18px;
    }
    & ~ .radio-label {
      color: ${({ theme }) => theme.colors.default.strong};
    }

    & ~ * .radio-filler::before {
      border: 2px solid ${({ theme }) => theme.colors.default.strong};
    }
  }
`;

export interface RadioProps extends Omit<RSInputProps, 'type'> {
  name: string;
  label?: React.ReactNode;
  labelColor?: string;
  checkedLabelColor?: string;
  isGrouped: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
}

const Radio = React.forwardRef<any, RadioProps>(
  (
    {
      name,
      label,
      labelColor,
      checkedLabelColor,
      isGrouped = false,
      errors,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <Container className="radio-container">
          <InputDesign
            checkedLabelColor={checkedLabelColor}
            className="radio-input"
            type="radio"
            name={name}
            {...rest}
            innerRef={ref}
            data-testid={rest['data-testid']}
          />
          <Label labelColor={labelColor} className="radio-label">
            {label}
          </Label>
          <div>
            <Fill className="radio-filler" />
          </div>
        </Container>

        {!isGrouped && (
          <FormFeedback className="d-flex mb-2">
            {errors && <ErrorMessage>{errors[name]?.message}</ErrorMessage>}
          </FormFeedback>
        )}
      </>
    );
  }
);

Radio.displayName = 'Radio';

export default Radio;
