import React from 'react';
import * as Styles from './Background.styles';

type BackgroundProps = {
  children: React.ReactNode;
};

const Background = ({ children }: BackgroundProps) => (
  <Styles.Background>
    {children}
    <Styles.ShapeContainer>
      <Styles.FirstShape />
      <Styles.SecondShape />
      <Styles.ThirdShape />
    </Styles.ShapeContainer>
  </Styles.Background>
);

export default Background;
