import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ECOMMERCE_HOME } from '@swibeco/shared';
import * as Styles from './MenuItem.styles';

type MenuItemProps = {
  icon: React.ReactNode;
  to: {
    reload: boolean;
    url: string;
  };
  children: React.ReactNode;
};

const MenuItem = ({ icon, children, to: { url, reload } }: MenuItemProps) => {
  const queryClient = useQueryClient();

  const redirectToUrl = () => {
    queryClient
      .cancelQueries()
      .then(() => window.location.assign(ECOMMERCE_HOME));
  };

  return (
    <Styles.MenuItem
      className="my-2 my-md-0 mx-4 mx-md-0 pr-3 px-md-2"
      data-testid="menu-item"
      to={url}
      onClick={reload ? redirectToUrl : undefined}
    >
      <Styles.IconContainer>{icon}</Styles.IconContainer>
      <Styles.IconText component="div" className="text-center">
        {children}
      </Styles.IconText>
    </Styles.MenuItem>
  );
};

export default MenuItem;
