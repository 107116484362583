import React from 'react';

/* eslint-disable operator-linebreak, indent */
const withAttrs =
  <P extends Record<string, unknown>>(
    Component: React.ComponentType<P>,
    fn: (arg0: P) => Partial<P>
  ) =>
  (props: P) => {
    const attrs = fn(props);
    return <Component {...props} {...attrs} />;
  };
/* eslint-enable operator-linebreak, indent */

export default withAttrs;
