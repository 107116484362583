import { CheckoutContext } from '@swibeco/core';
import { DraftModeProvider, UniverseProvider } from '@swibeco/ecommerce';
import { DEFAULT_SORT, UNIVERSE } from '@swibeco/shared';
import { Loader } from '@swibeco/ui';
import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Outlet,
  Route,
  Routes,
  matchPath,
  useLocation,
} from 'react-router-dom';

import UniverseRouter from './UniverseRouter';
import PlusLandingPage from '../pages/PlusLandingPage/PlusLandingPage';
import useRouterParams from '../hooks/useRouterParams';
import Announcement from '../components/Announcement/Announcement';

const ConfirmationPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Checkout/ConfirmationPage')
);

const CheckoutFunnel = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Checkout/CheckoutFunnel')
);

const HomePage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/HomePage')
);

const ProductPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/ProductPage')
);

const FavouritesPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Favourites')
);

const OrdersPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/OrdersPage')
);
const BasketPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Checkout/BasketPage')
);
const SearchResultsPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/SearchResultsPage')
);
const ProfilePage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/User/ProfilePage')
);
const SwipointsPage = React.lazy(
  /* istanbul ignore next */ () => import('../pages/Wallet/SwipointsPage')
);

const Router = () => {
  const { params } = useRouterParams();
  const location = useLocation();
  const isUniversePage = useMemo(
    () => matchPath(location.pathname, UNIVERSE),
    [location.pathname]
  );

  const getDefaultValues = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const buyableWithSwipoints = urlSearchParams.get('buyableWithSwipoints');
    const urlBuyableWithSwipoints =
      buyableWithSwipoints === 'true' || buyableWithSwipoints === 'false'
        ? buyableWithSwipoints === 'true'
        : undefined;
    /*  useRouterParams doesn't have access to DraftModeProvider
            set params from URLSearchParams to cover default values cases */
    return {
      sort: {
        value: params.sort || urlSearchParams.get('sort') || DEFAULT_SORT,
      },
      brands:
        params.brands ||
        urlSearchParams
          .get('brands')
          ?.split(',')
          .map((b) => ({ label: b, value: b })) ||
        [],
      buyableWithSwipoints:
        params.buyableWithSwipoints || urlBuyableWithSwipoints || false,
      reimbursable:
        params.reimbursable || urlSearchParams.get('reimbursable') || false,
      hasNewOffers:
        params.hasNewOffers || urlSearchParams.get('hasNewOffers') || false,
      offerType: params.offerType
        ? params.offerType.map((offerType) => offerType.value)
        : urlSearchParams.get('offerType')?.split(','),
      offersFrom: params.offersFrom
        ? params.offersFrom.map((from) => from.value)
        : urlSearchParams.get('offersFrom')?.split(','),
      endingSoon:
        params.endingSoon || urlSearchParams.get('endingSoon') || false,
    };
  }, [location.search, params]);

  const filterFormContext = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(),
    shouldUnregister: false,
  });

  useEffect(() => {
    filterFormContext.reset(getDefaultValues());
  }, [filterFormContext, getDefaultValues]);

  useEffect(() => {
    if (isUniversePage) {
      filterFormContext.reset(getDefaultValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUniversePage]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="*" element={<Announcement />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/user/profile" element={<ProfilePage />} />
        <Route path="/wallet/swipoints" element={<SwipointsPage />} />
        <Route path="/user/orders" element={<OrdersPage />} />
        <Route path="/checkout-confirmation" element={<ConfirmationPage />} />
        <Route path="/favourites" element={<FavouritesPage />} />
        <Route
          element={
            <CheckoutContext>
              <Outlet />
            </CheckoutContext>
          }
        >
          <Route path="/basket" element={<BasketPage />} />
          <Route path="/checkout" element={<CheckoutFunnel />} />
        </Route>
        <Route path="/product/:product" element={<ProductPage />} />
        <Route path="/plus" element={<PlusLandingPage />} />
        <Route
          element={
            <UniverseProvider>
              <FormProvider {...filterFormContext}>
                <DraftModeProvider>
                  <Outlet />
                </DraftModeProvider>
              </FormProvider>
            </UniverseProvider>
          }
        >
          <Route
            path="/deals/:context/:contextValue?"
            element={<SearchResultsPage />}
          />
        </Route>
        <Route
          element={
            <UniverseProvider>
              <FormProvider {...filterFormContext}>
                <DraftModeProvider>
                  <UniverseRouter />
                </DraftModeProvider>
              </FormProvider>
            </UniverseProvider>
          }
          path="/universe/:context/:contextValue?/:subSection?/:values?"
        />
      </Routes>
    </Suspense>
  );
};

export default Router;
