import { useEffect, useState } from 'react';
import { OSType } from '@swibeco/types/lib/Common/Technology';

const useDetectOs = () => {
  const [os, setOs] = <any>useState();

  const findOs = () => {
    if (navigator.userAgent.indexOf('like Mac') !== -1) return OSType.iOs;
    if (navigator.userAgent.indexOf('Android') !== -1) return OSType.Android;
    if (navigator.userAgent.indexOf('Win') !== -1) return OSType.Windows;
    if (navigator.userAgent.indexOf('Mac') !== -1) return OSType.Mac;
    if (navigator.userAgent.indexOf('Linux') !== -1) return OSType.Linux;
    return OSType.Unknown;
  };

  useEffect(() => {
    setOs(findOs());
  });

  return os;
};

export default useDetectOs;
