import React, { ForwardedRef, forwardRef } from 'react';
import { BottomSheetProps } from '@swibeco/types';
import CloseIcon from './iconography/CloseIcon';
import { BottomSheet as ReactBottomSheet } from 'react-spring-bottom-sheet';
import * as Styles from './styles/BottomSheet.styles';
import 'react-spring-bottom-sheet/dist/style.css';
import { RefHandles } from 'react-spring-bottom-sheet/dist/types';

const BottomSheet = forwardRef(
  (
    { children, setIsOpen, isOpen = false, zIndex = 21 }: BottomSheetProps,
    ref
  ) => {
    const close = () => setIsOpen(false);
    return (
      <ReactBottomSheet
        style={{ zIndex, pointerEvents: 'auto' }}
        className="position-relative"
        open={isOpen}
        onDismiss={close}
        defaultSnap={({ lastSnap, maxHeight }) => lastSnap ?? maxHeight * 0.5}
        snapPoints={({ maxHeight }) => [
          maxHeight * 0.3,
          maxHeight * 0.5,
          maxHeight * 0.8,
          maxHeight * 0.9,
        ]}
        header={
          <Styles.Header>
            <Styles.CloseWrapper>
              <CloseIcon onClick={close} />
            </Styles.CloseWrapper>
          </Styles.Header>
        }
        ref={ref as ForwardedRef<RefHandles>}
      >
        <Styles.Content>{children}</Styles.Content>
      </ReactBottomSheet>
    );
  }
);

export default BottomSheet;
