import { breakpoints, styled } from '@swibeco/shared';

export const Logo = styled.img`
  margin-left: auto;
  margin-right: auto;
  max-height: 32px;
  max-width: 200px;

  ${breakpoints.sm_max} {
    max-width: 105px;
    max-height: 42px;
  }
`;
