import React, { forwardRef } from 'react';
import { styled } from '@swibeco/shared';
import {
  NumericFormat,
  NumericFormatProps,
  // NumberFormatProps,
  // NumberFormatValues,
} from 'react-number-format';
import { Input as RSInput, FormFeedback, InputGroup } from 'reactstrap';
import { ErrorMessage } from './ErrorMessage';

const InputDesign = styled<any>(NumericFormat, {
  shouldForwardProp: () => true,
})`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.default.black};
  text-align: ${({ textalign }) => textalign || 'start'};
  border-radius: 3px !important;
  border-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.default.middle};
  height: auto;

  &.is-invalid {
    color: ${({ theme }) => theme.colors.default.black};
    border: ${({ theme }) => `1px solid ${theme.colors.secondary.main}`};
  }

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.default.strong}`};
  }

  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.default.black};
    border: ${({ theme }) => `1px solid ${theme.colors.primary.main}`};

    &.is-invalid {
      color: ${({ theme }) => theme.colors.default.black};
      border: ${({ theme }) => `1px solid ${theme.colors.secondary.main}`};
    }
  }
`;

export interface NumberInputProps extends NumericFormatProps {
  name: string;
  textalign?: string;
  fontSize?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...event: any[]) => void;
  before?: React.ReactNode;
  after?: React.ReactNode;
}

const NumberInput = forwardRef<typeof NumericFormat, NumberInputProps>(
  (
    {
      name,
      textalign,
      fontSize = '0.9375rem',
      errors,
      onChange,
      before,
      after,
      ...rest
    },
    ref
  ) => (
    <>
      <InputGroup>
        {before}
        <InputDesign
          name={name}
          textalign={textalign}
          fontSize={fontSize}
          customInput={RSInput}
          {...rest}
          valueIsNumericString
          onValueChange={(values: any) => {
            if (onChange) onChange(values.value);
          }}
          getInputRef={ref}
          aria-invalid={Boolean(errors && errors[name]?.message)}
          className={errors && errors[name]?.message ? 'is-invalid' : ''}
        />
        {after}
      </InputGroup>

      <FormFeedback className="d-flex">
        {errors && <ErrorMessage>{errors[name]?.message}</ErrorMessage>}
      </FormFeedback>
    </>
  )
);

NumberInput.displayName = 'NumberInput';

export default NumberInput;
