import {
  AddressesType,
  AddressType,
  BasketType,
  CheckoutErrorsType,
} from '@swibeco/types';
import { CheckoutActions, CheckoutTypes } from '../checkoutTypes';

const setAddresses = (addresses: AddressesType): CheckoutActions => ({
  type: CheckoutTypes.SET_ADDRESSES,
  payload: addresses,
});

const setBillingAddress = (address: AddressType): CheckoutActions => ({
  type: CheckoutTypes.SET_BILLING_ADDRESS,
  payload: address,
});

const setDeliveryAddress = (address: AddressType): CheckoutActions => ({
  type: CheckoutTypes.SET_DELIVERY_ADDRESS,
  payload: address,
});

const setBasket = (basket: BasketType): CheckoutActions => ({
  type: CheckoutTypes.SET_BASKET,
  payload: basket,
});

const setAllocatedSwipoints = (swipoints: number): CheckoutActions => ({
  type: CheckoutTypes.SET_ALLOCATED_SWIPOINTS,
  payload: swipoints,
});
const setUseDeliveryAddressForBilling = (
  useDeliveryAddressForBilling: boolean
): CheckoutActions => ({
  type: CheckoutTypes.SET_USE_DELIVERY_ADDRESS_FOR_BILLING,
  payload: useDeliveryAddressForBilling,
});

const setErrors = (errors: CheckoutErrorsType): CheckoutActions => ({
  type: CheckoutTypes.SET_ERRORS,
  payload: errors,
});

export const checkoutActions = {
  setAddresses,
  setBasket,
  setAllocatedSwipoints,
  setBillingAddress,
  setDeliveryAddress,
  setErrors,
  setUseDeliveryAddressForBilling,
};
