import { CompanyFeatureType } from "../FeatureFlag";
import { AddressType } from "../Address";
import { LanguageType } from "../Language";

export type AuthenticationMethod = {
  main_color: string;
  text_color: string;
  label: string;
  type: string;
};

export enum AuthenticationMethodType {
  SSO = "sso",
}

export type DealerInformationType = {
  id: number;
  trade_name: string | null;
  logo: string | null;
  has_sso: boolean;
};

export type CompanyContactType = {
  email: string | null;
};

export type CompanyAutomaticTopupType = {
  below_amount: number;
  add_amount: number;
  active: boolean;
};

export type CompanyThemeFaviconType = {
  path: string | null;
  mime_type: string | null;
};

export type CompanyExcerptType = {
  id: number;
  name: string;
  main_color: string;
};

export type CompanyThemeType = {
  main_color: string;
  text_color: string;
  favicon: CompanyThemeFaviconType;
  logo: string;
  cover: string;
  partner_logo: string | null;
  dealer_logo: string | null;
};

export type CompanyInformationsType = {
  name: string;
  gender: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  language: string;
  companyName: string;
  function?: string;
};

export type CurrentCompanyInformationType = {
  kind: "CurrentCompany";
  id?: number;
  dealer?: DealerInformationType;
  name?: string;
  domain?: string;
  trade_name?: string;
  phone_number?: string;
  contact?: CompanyContactType;
  language?: LanguageType;
  activity_area?: number;
  type?: string;
  address?: AddressType;
  automatic_topup?: CompanyAutomaticTopupType;
  display_login_logo: boolean;
  theme: CompanyThemeType;
  available_swipoints?: number;
  external_id?: number;
  onboarding_complete?: boolean;
  onboarding_tutorial_gift_amount?: number;
  has_sso?: boolean;
  contract_start_date: Date | null;
  active_solutions?: null;
  authentication_methods: AuthenticationMethod[];
  subdomain_belongs_to_company: boolean;
  can_manage_users: boolean;
  features?: { [key in CompanyFeatureType]: boolean };
  dealer_is_axa: boolean;
  contract_type?: string;
  ability?: any;
  has_inhouse_deals?: boolean;
};

export type GetCurrentCompanyResponseType = {
  id?: number;
  dealer?: DealerInformationType;
  name?: string;
  domain?: string;
  trade_name?: string;
  phone_number?: string;
  contact?: CompanyContactType;
  language?: LanguageType;
  activity_area?: number;
  type?: string;
  address?: AddressType;
  automatic_topup: CompanyAutomaticTopupType;
  display_login_logo: boolean;
  theme: CompanyThemeType | null;
  available_swipoints?: number;
  external_id?: number;
  onboarding_complete?: boolean;
  onboarding_tutorial_gift_amount?: number;
  contract_start_date?: string;
  active_solutions?: null;
  can_manage_users?: boolean;
  authentication_methods: AuthenticationMethod[];
  features?: { [key in CompanyFeatureType]: boolean };
  dealer_is_axa: boolean;
};

export enum MealTaxDeductionType {
  None,
  Half,
  Full,
}

export enum SalaryMethod {
  Gross = "gross",
  Net = "net",
}

export enum DeliveryCountryType {
  defaultDeliveryCountry = "CH",
}

export enum EmployeeContributionType {
  Complete,
  Equivalent,
  NoParticipation,
}

/**
 * Redirection regarding the features
 * Reload means to use window.location.assign (true) or push (false)
 * URL is the target
 */
export type CompanyRedirection = {
  reload: boolean;
  url: string;
};

export type EmployerNameResponse = {
  first_name: string;
  last_name: string;
};
