import { Image } from "Image";
import { MappedSyliusBrand, SyliusBrand } from "SyliusBrand";
import { Taxon } from "Taxon";
import { Category, Subcategory, SyliusCategory } from "../Category";

export enum EnumProductType {
  Physical = "physical",
  Voucher = "voucher",
  SwipointsPack = "swipoints_pack",
  GenericCode = "generic_code",
  DropShipping = "dropshipping",
  Subscription = "subscription",
  LandingPage = "landing_page",
}
export enum BRANDS {
  Adidas = "Adidas",
  Blacksocks = "Blacksocks",
  Coop = "Coop",
  Christ = "Christ",
  Decathlon = "Decathlon",
  DRBurgener = "DRBurgener",
  HM = "H&M",
  IKEA = "IKEA",
  ImportParfumerie = "Import Parfumerie",
  Interdiscount = "Interdiscount",
  Jumbo = "Jumbo",
  Lumimart = "Lumimart",
  Livique = "Livique",
  Manor = "Manor",
  MediaMarkt = "MediaMarkt",
  Rituals = "Rituals",
  OrellFüssli = "Orell Füssli",
}

export interface ProductBreadcrumbsParams {
  c: string;
  s: string;
}

export interface ProductBreadcrumbsParamsData {
  c: Category | undefined;
  s: Subcategory | undefined;
}
export interface SyliusProductBreadcrumbsParamsData {
  c: SyliusCategory | undefined;
  s: SyliusCategory | undefined;
}

export interface DefaultVariantData {
  code: string;
  deliveryDescription: string;
  freeTextDiscount: string;
  id: number;
  isFlash: boolean;
  price: number;
  productId: number;
  slug: string;
  cashbackAmount: number;
  shortName: string;
}
export interface Product {
  type: EnumProductType;
  defaultVariantData: DefaultVariantData;
  startDate: Date;
  endDate: string;
  recentUntilDate: Date;
  brand?: SyliusBrand;
  isFreeAmount: boolean;
  isExternalPayment: boolean;
  isPrivate: boolean;
  defaultVariantSlug: string;
  variantsMinimumPrice: number;
  variantsMaximumDiscount: number;
  mainTaxon: Taxon;
  isConfigurable: boolean;
  images: Image[];
  id: number;
  code: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  slug: string;
}

export type MappedProduct = Omit<Product, "brand"> & {
  brand?: MappedSyliusBrand;
};
