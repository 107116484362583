import React, { useEffect } from 'react';
import { useScrollBlock } from '../../hooks';
import * as Styles from './UnderlayMask.styles';

interface UnderlayMaskProps {
  blockScroll?: boolean;
  fullscreen?: boolean;
  zIndex?: number;
  onClick?: () => void;
  onMouseEnter?: () => void;
}
const UnderlayMask = ({
  blockScroll = true,
  fullscreen = false,
  zIndex,
  onClick,
  onMouseEnter,
}: UnderlayMaskProps) => {
  const { allowScroll, blockScroll: blockScrollFn } = useScrollBlock();

  useEffect(() => {
    if (blockScroll) {
      blockScrollFn();

      return () => allowScroll();
    }

    return () => null;
  });

  return (
    <Styles.MaskWrapper>
      <Styles.Mask
        fullscreen={fullscreen}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        zIndex={zIndex}
      />
    </Styles.MaskWrapper>
  );
};

export default UnderlayMask;
