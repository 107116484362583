import {
  authenticationApi,
  useCurrentCompany,
  useCurrentUser,
} from '@swibeco/security';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNumberFormatter } from '@swibeco/shared';

const useTutorial = () => {
  const { user, refetchUser } = useCurrentUser();
  const { company } = useCurrentCompany();
  const formatter = useNumberFormatter();
  const formatterNumber = useNumberFormatter('decimal');
  const updateDashboardTutorialFlagMutation = useMutation({
    mutationFn: async () => authenticationApi.updateDashboardTutorialFlag,
  });
  const notViewedYet = !user?.onboardingTutorialCompletedAt;

  const [isOpen, setOpen] = useState(notViewedYet);

  const welcomeGift = company?.onboarding_tutorial_gift_amount ?? 0;

  const giftCashAmount = useMemo(
    () => formatter(welcomeGift / 10),
    [welcomeGift, formatter]
  );
  const giftSwipointsAmount = useMemo(
    () => formatterNumber(welcomeGift),
    [welcomeGift, formatterNumber]
  );

  useEffect(() => {
    if (!isOpen && notViewedYet) {
      setOpen(true);
    }
  }, [notViewedYet, isOpen]);

  const handleTutorialClose = useCallback(async () => {
    await updateDashboardTutorialFlagMutation.mutateAsync();
    if (refetchUser) {
      refetchUser();
    }
    setOpen(false);
  }, [refetchUser, updateDashboardTutorialFlagMutation]);

  return {
    closeTutorial: handleTutorialClose,
    notViewedYet,
    isOpen,
    setOpen,
    welcomeGift,
    giftCashAmount,
    giftSwipointsAmount,
  };
};

export default useTutorial;
