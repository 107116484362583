import React from 'react';
import { Input as RSInput, InputProps, InputGroup } from 'reactstrap';
import { styled } from '@swibeco/shared';

const InputDesign = styled(RSInput, { shouldForwardProp: () => true })`
  width: 100%;
  margin: 5px 0;
  background-color: transparent;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    background: #d3d3d3;
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 3px;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  &::-webkit-slider-thumb {
    margin-top: -4.5px;
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.primary.main};
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &:focus::-webkit-slider-runnable-track {
    background: #e0e0e0;
  }
  &::-moz-range-track {
    background: #d3d3d3;
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 3px;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.primary.main};
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    cursor: pointer;
  }
  &::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 5.5px 0;
    color: transparent;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  &::-ms-fill-lower {
    background: #c6c6c6;
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 6px;
  }
  &::-ms-fill-upper {
    background: #d3d3d3;
    border: 0px solid rgba(1, 1, 1, 0);
    border: 0;
    border-radius: 6px;
  }
  &::-ms-thumb {
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.primary.main};
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  &:focus::-ms-fill-lower {
    background: #d3d3d3;
  }
  &:focus::-ms-fill-upper {
    background: #e0e0e0;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align: auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    & {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }

  @-moz-document url-prefix() {
    margin: -8px 0;
  }
`;

const MinMax = styled('span', { shouldForwardProp: (prop) => prop !== 'max' })<{
  max?: boolean;
}>`
  position: absolute;
  left: ${({ max }) => (max ? '100%' : '0')};
  transform: translateX(${({ max }) => (max ? '-100%' : '0')});
  top: 13px;
  font-weight: 300;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.default.strong};
`;

const BubbleWrapper = styled.div<{ value?: number }>`
  ${({ theme, value }) =>
    typeof value !== 'undefined' &&
    `
  position: relative;
  margin-top: 2.375rem;

  &:before {
    content: '${value}';
    left: calc(${value}% + ${8 - value * 0.15}px);
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    height: 1.8125rem;
    width: 1.8125rem;
    bottom: 0.9375rem;
    font-size: 18px;
    font-weight: 500;
    background: ${theme.colors.primary.main};
    border-radius: 50%;
    color: ${theme.colors.default.light};
    position: absolute;
    transform: translateX(-50%);
  }`}
`;

export type RangeInputProps = Omit<InputProps, 'type'> & {
  bubble?: number;
};

const RangeInput = React.forwardRef<HTMLInputElement, RangeInputProps>(
  ({ min, max, format, bubble, ...rest }, ref) => (
    <BubbleWrapper value={bubble}>
      <InputGroup>
        {typeof min !== 'undefined' && (
          <MinMax>
            {min}
            {format}
          </MinMax>
        )}
        <InputDesign
          type="range"
          min={min}
          max={max}
          {...rest}
          innerRef={ref}
        />
        {typeof max !== 'undefined' && (
          <MinMax max>
            {max}
            {format}
          </MinMax>
        )}
      </InputGroup>
    </BubbleWrapper>
  )
);

RangeInput.displayName = 'RangeInput';

export default RangeInput;
