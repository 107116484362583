import { styled } from '@swibeco/shared';

export const Switch = styled.div<{ active?: boolean }>`
  border-radius: 10px 10px 0 0;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary.main : theme.colors.primary.decorationLight};
  position: relative;
  flex: 1 1 0;
  cursor: pointer;
`;

export const Tag = styled.span`
  position: absolute;
  width: 100%;
  top: -10px;
  left: 0;
`;
