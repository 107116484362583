import { combineReducers } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type * as rtk from '@reduxjs/toolkit';
import globalUserSlice, { userSelectors } from './user/userSlice';
import globalCompanySlice, { companySelectors } from './company/companySlice';

export const GlobalReducers = combineReducers({
  user: globalUserSlice.reducer,
  company: globalCompanySlice.reducer,
});

export const GlobalActions = {
  user: globalUserSlice.actions,
  company: globalCompanySlice.actions,
};

export const GlobalSelectors = {
  company: companySelectors,
  user: userSelectors,
};
