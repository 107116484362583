import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@swibeco/ui';
import { ECOMMERCE_HOME, useColorVariant } from '@swibeco/shared';
import { selectors as securitySelectors } from '@swibeco/security';
import {
  actions,
  selectors as coreSelectors,
  useIsMobile,
  useIsTablet,
} from '@swibeco/core';
import * as Styles from './SideBar.styles';
import MenuItem from '../Menu/MenuItem';
import PerksAndDiscount from '../../assets/images/PerksAndDiscount';
import axaPictoImg from '../../assets/images/picto-axa-logo.png';

const SideBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const whiteColor = useColorVariant('default', 'white');
  const isAuthenticated = useSelector(securitySelectors.isAuthenticated);
  const menuIsVisible = useSelector(coreSelectors.isMenuVisible);
  const isSidebarVisible = useSelector(coreSelectors.isSidebarVisible);
  const isDealerAxa = useSelector(coreSelectors.isDealerAxa);
  const isMobileOrTablet = isMobile || isTablet;

  const axaIcon = (
    <Styles.ImgContainer>
      <img src={axaPictoImg} alt="axa-picto" />
    </Styles.ImgContainer>
  );

  return isAuthenticated && isSidebarVisible ? (
    <div>
      <Styles.DarkBackground
        show={isMobileOrTablet ? menuIsVisible : false}
        onClick={() => dispatch(actions.toggleMenu())}
        data-testid="dark-background"
      >
        <Styles.CloseIconContainer>
          <CloseIcon color={whiteColor} />
          <Styles.TextIcon color={whiteColor}>
            {t('core.sidebar.close')}
          </Styles.TextIcon>
        </Styles.CloseIconContainer>
      </Styles.DarkBackground>

      <Styles.Wrapper
        show={isMobileOrTablet ? menuIsVisible : true}
        className="sidebar"
      >
        <MenuItem
          icon={<PerksAndDiscount />}
          to={{ url: ECOMMERCE_HOME, reload: true }}
        >
          {t('core.sidebar.menu_item.benefits_platform')}
        </MenuItem>
        {isDealerAxa && (
          <MenuItem icon={axaIcon} to={{ url: 'axa-benefits', reload: false }}>
            {t('core.sidebar.menu_item.axa_benefits')}
          </MenuItem>
        )}
      </Styles.Wrapper>
    </div>
  ) : null;
};

export default SideBar;
