import { API, LegacyAPI } from '@swibeco/shared';
import snakeCaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import {
  UserOrdersType,
  CreateOrderType,
  OrderConfirmationResponseType,
  OrderPaymentStatus,
  AddressUpdateResponseType,
  AddressUpdateType,
  VoucherInfoType,
  BaseDeal,
  RandomDealResponse,
  InvoiceType,
  PaginationResponse,
  DealSharedCodeInfo,
  ProductPageDeal,
  ExploreDeals,
  Advertisement,
  UserSubscription,
} from '@swibeco/types';
import { mapSortToOrderParams } from '../utils/mapSortToOrderParams';
import universeApi from './universeApi';
import basketApi from './basketApi';

export default {
  ...universeApi,
  ...basketApi,
  getDealSharedCodeInfo: async (
    locale: string,
    dealId?: number
  ): Promise<DealSharedCodeInfo> => {
    const response = await API.get<DealSharedCodeInfo>(
      `/deals/${dealId}/shared-code`,
      { params: { locale } }
    );

    return camelcaseKeys(response.data);
  },

  getBasketItemsCount: async (): Promise<number> => {
    const response = await API.get<number>('/user/basket/items/count');

    return response.data;
  },

  /**
   * Get link for a random deal.
   * @returns {RandomDealResponse} A random deal:
   */
  getInspireMe: async (): Promise<RandomDealResponse> => {
    const response = await API.get<RandomDealResponse>('/homepage/inspire-me');
    return response.data;
  },

  /**
   * Get list of user ordered vouchers.
   *
   * @returns {UserOrdersType[]} The list of vouchers:
   */
  getUserOrderVouchers: async (limit: number, locale: string) => {
    const response = await API.get<UserOrdersType[]>('/user/order/vouchers', {
      params: {
        limit,
        locale,
      },
    });

    return response.data;
  },

  getWishlistCount: async (): Promise<number> => {
    const response = await API.get<number>('/user/wishlist/items/count');

    return response.data;
  },

  getInvoicesCount: async (): Promise<number> => {
    const response = await API.get<number>('/user/order/invoices/count');
    return response.data;
  },

  getInvoices: async (
    nextPageUrl?: string
  ): Promise<PaginationResponse<InvoiceType>> => {
    const response = await LegacyAPI.get<PaginationResponse<InvoiceType>>(
      `/users/invoices${
        nextPageUrl ?? '?page=1&page_size=15'
      }&sort_field=createdAt&sort_order=desc`
    );
    return camelcaseKeys(response.data);
  },

  /**
   * Get list of bestsellers.
   *
   * @returns {BaseDeal[]} The list of bestsellers:
   */
  getBestSellers: async (
    limit: number,
    locale: string
  ): Promise<BaseDeal[]> => {
    const response = await API.get('/deals/bestsellers', {
      params: {
        limit,
        locale,
      },
    });

    return camelcaseKeys(response.data, { deep: true });
  },
  /**
   * Get list of axa Deals.
   *
   * @returns {BaseDeal[]} The list of axa Deals:
   */
  getAxaDeals: async (limit: number, locale: string): Promise<BaseDeal[]> => {
    const response = await API.get('/deals', {
      params: {
        limit,
        locale,
        criteria: 'myaxa-bestsellers',
      },
    });

    return camelcaseKeys(response.data, { deep: true });
  },
  addItemToBasket: async (dealId: number, quantity: number) => {
    const response = await API.post(
      '/user/basket/item/add',
      snakeCaseKeys({
        dealId,
        quantity,
      })
    );
    return response.data;
  },

  /**
   * @return {OrderConfirmationResponseType} Returns order confirmation data:
   */
  createOrder: async (
    orderData: CreateOrderType
  ): Promise<OrderConfirmationResponseType> => {
    const response = await API.post('/order/create', {
      swipoints: orderData.swipoints,
      save_card: orderData.saveCard,
      payment_methods: orderData.paymentMethods,
      wallets: orderData.wallets,
      delivery_address: orderData.deliveryAddress,
      billing_address: orderData.billingAddress,
    });
    return response.data;
  },

  /**
   * @return {OrderPaymentStatus} Returns order confirmation data:
   */
  checkPayment: async (
    orderId: number | string
  ): Promise<OrderPaymentStatus> => {
    const response = await API.put(`/orders/${orderId}/check-payment`);
    return {
      ...response.data,
      order: {
        ...response.data.order,
        order_items: camelcaseKeys(response.data.order.order_items, {
          deep: true,
        }),
      },
    };
  },

  updateAddress: async (
    addressUpdate: AddressUpdateType
  ): Promise<AddressUpdateResponseType> => {
    const response = await LegacyAPI.put('/users/basket/address', {
      address: {
        address1: addressUpdate.address.street,
        address2: addressUpdate.address.complement,
        city: addressUpdate.address.city,
        country: addressUpdate.address.country,
        firstname: addressUpdate.address.firstName,
        lastname: addressUpdate.address.lastName,
        phone: addressUpdate.address.phone,
        phone_prefix: addressUpdate.address.phonePrefix,
        set: false,
        zip: addressUpdate.address.postalCode,
      },
      type: addressUpdate.type,
    });
    return response.data;
  },

  /**
   * API Call to get voucher info from VMT.
   *
   * @returns {VoucherInfoType} Voucher info data:
   */
  getVoucherInfo: async (dealId?: number): Promise<VoucherInfoType> => {
    const response = await API.get<VoucherInfoType>(`/vouchers/${dealId}`);
    return response.data;
  },
  /**
   * Get list of Deals by criteria.
   *
   * @returns {BaseDeal[]} The list of Deals:
   */
  getDeals: async (
    limit: number,
    locale: string,
    criteria: string
  ): Promise<BaseDeal[]> => {
    const response = await API.get('/deals', {
      params: {
        limit,
        locale,
        criteria,
      },
    });

    return camelcaseKeys(response.data, { deep: true });
  },

  getDeal: async (id: string, locale: string): Promise<ProductPageDeal> => {
    const response = await API.get(`/deals/${id}`, {
      params: {
        locale,
      },
    });

    return camelcaseKeys(response.data, { deep: true });
  },

  getExploreDiscounts: async (locale: string): Promise<ExploreDeals[]> => {
    const response = await API.get('/homepage/deals', {
      params: { locale },
    });

    return camelcaseKeys(response.data, { deep: true });
  },

  searchDeals: async (
    query: string,
    locale: string,
    context?: string,
    offerType?: string,
    offerFrom?: string,
    sort?: string,
    orderBy?: string,
    orderDrection?: string,
    page = 1,
    limit = 15
  ): Promise<PaginationResponse<BaseDeal>> => {
    let params = {};
    if (context !== null) {
      params = { context, value: query, locale, page_size: limit };
    }
    if (context !== null && offerType !== null) {
      params = {
        context,
        value: query,
        locale,
        page_size: limit,
        page,
        filter_offer_types: offerType == null ? [] : [offerType],
        filter_offer_from: offerFrom,
        order_by: orderBy,
        order_drection: orderDrection,
      };
    }
    if (sort) {
      params = {
        ...params,
        ...mapSortToOrderParams(sort),
      };
    }
    const response = await API.get('/deals/search', { params });
    return camelcaseKeys(response.data, { deep: true });
  },
  grantReward: async () => {
    const response = await API.post('/users/tutorial/finish');
    return response.data;
  },
  addItemToFavourites: async (id: number) => {
    const response = await LegacyAPI.post('/users/favorites', {
      favorites: [id],
    });
    return response.data;
  },

  deleteFromFavourites: async (id: number) => {
    const response = await LegacyAPI.delete(`/users/favorites/${id}`);
    return response.data;
  },

  getAds: async (): Promise<PaginationResponse<Advertisement>> => {
    const response = await API.get('/advertisement/brands');
    return response.data;
  },

  getUserSubscription: async (): Promise<UserSubscription> => {
    const response = await API.get('/users/subscription');
    return response.data;
  },
  updateCashbackPopupShown: async () => {
    await API.patch('/users/cashback-popup-shown');
  },

  getCategoryAds: async (
    slug: string,
    locale: string
  ): Promise<PaginationResponse<Advertisement>> => {
    const response = await API.get(`/advertisement/categories/${slug}/brands`, {
      params: { locale },
    });
    return response.data;
  },
};
