import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Icon } from '@chakra-ui/react';

import {
  ErrorLabel,
  InputContainer,
  InputStyle,
  Label,
  LabelBottom,
  LabelBottomContainer,
  LinkControl,
  Optional,
} from './FormInput.styles';
import { ErrorTextAlignInput, RefReturn } from '../types';
import Select from '../../Select';
import { ReactComponent as EyeOpenIcon } from '../../images/eye-open.svg';
import { ReactComponent as EyeClosedIcon } from '../../images/eye-closed.svg';

export interface InputProps {
  id?: string;
  type?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  optionalMessage?: string;
  optionalIcon?: React.ReactNode;
  state?: string;
  errorString?: string | null;
  border?: boolean;
  bg?: string;
  color?: string;
  autocomplete?: string;
  maxLength?: number;
  align?: string;
  size?: number;
  value?: string | number | string[];
  noMargin?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  as?: React.ElementType;
  colorLabel?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: any;
  canHideInputValue?: boolean;
  shouldBeGreyAtMount?: boolean;
  mask?: string;
  disabled?: boolean;
  errorTextAlign?: ErrorTextAlignInput;
  min?: number | undefined | string;
  labelBottom?: {
    href?: string;
    text: string;
  };
  labelLetterSpacing?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectOptions?: any;
  customPaddingTopAndBottom?: number;
  icon?: string;
  customChangeValue?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controller?: any;
}

const FormInput = React.forwardRef(
  (
    {
      required = false,
      optionalMessage,
      optionalIcon,
      type,
      disabled,
      placeholder,
      label,
      name = '',
      state = '',
      errorString = '',
      border,
      bg,
      color,
      autocomplete,
      maxLength,
      size,
      align,
      as,
      noMargin,
      onChange = () => {
        /* default behaviour */
      },
      colorLabel,
      formState,
      shouldBeGreyAtMount,
      setValue,
      labelBottom,
      labelLetterSpacing,
      errorTextAlign,
      min,
      selectOptions,
      onFocus,
      onBlur,
      canHideInputValue,
      value,
      control,
      controller,
      ...rest
    }: InputProps,
    ref: RefReturn
  ) => {
    const theme = useTheme();
    const colorGrey = theme?.colors?.default.black;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // eslint-disable-next-line no-nested-ternary
    const newColor = shouldBeGreyAtMount
      ? !formState?.dirtyFields?.[name.split('.')?.[0]]?.[name.split('.')?.[1]]
        ? colorGrey
        : undefined
      : color;

    const [hideInputValue, setHideInputValue] = useState(
      Boolean(canHideInputValue)
    );

    const toggleHideInputValue = () => setHideInputValue((prev) => !prev);

    const getInputBackgroundColor = () => {
      if (disabled) {
        return theme.colors.default.middle;
      }
      if (bg === 'grey') {
        return theme.colors?.default.black;
      }
      return bg;
    };

    return (
      <InputContainer
        as={as}
        state={state}
        error={!!errorString}
        noMargin={noMargin}
        selectOptions={selectOptions}
      >
        {label && (
          <Label
            state={state}
            isRequired={required}
            border={border}
            letterSpacing={labelLetterSpacing}
            data-testid="input-label"
            color={colorLabel}
            htmlFor={selectOptions ? 'aria-example-input' : name}
            displayOptional={!!optionalMessage}
          >
            {label}
            {optionalIcon}
            {optionalMessage ? (
              // eslint-disable-next-line react/jsx-one-expression-per-line
              <Optional>({optionalMessage})</Optional>
            ) : undefined}
          </Label>
        )}
        {selectOptions ? (
          <Select
            name={name}
            value={value}
            options={selectOptions}
            disabled={disabled}
            ref={ref}
            setValueHook={setValue}
            control={control}
            controller={controller}
          />
        ) : (
          <InputStyle
            data-testid={`input-${name}`}
            id={name}
            type={hideInputValue ? 'password' : type}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            state={state}
            required={required}
            border={border}
            ref={ref}
            align={align}
            size={size}
            aria-label={label}
            autoComplete={autocomplete}
            bg={getInputBackgroundColor()}
            error={!!errorString}
            color={disabled ? theme.colors.default.dark : newColor}
            maxLength={maxLength}
            min={min}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(name, e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
            canHideInputValue={canHideInputValue}
            {...rest}
          />
        )}

        {labelBottom && (
          <LabelBottomContainer>
            {labelBottom.href ? (
              <LinkControl href={labelBottom.href}>
                <LabelBottom errorString={!!errorString}>
                  {labelBottom.text}
                </LabelBottom>
              </LinkControl>
            ) : (
              <LabelBottom>{labelBottom.text}</LabelBottom>
            )}
          </LabelBottomContainer>
        )}
        {errorString && (
          <ErrorLabel {...{ errorTextAlign }} data-testid="input-error">
            {errorString}
          </ErrorLabel>
        )}
        {!selectOptions && canHideInputValue && (
          <Icon
            data-testid="input-eye"
            as={hideInputValue ? EyeClosedIcon : EyeOpenIcon}
            cursor="pointer"
            h="24px"
            w="26px"
            pos="absolute"
            right="16px"
            top="48%"
            onClick={toggleHideInputValue}
          />
        )}
      </InputContainer>
    );
  }
);

export default FormInput;
