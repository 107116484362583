import { styled, breakpoints } from '@swibeco/shared';
import { SuccessCheckIcon } from '@swibeco/ui';

export const Wrapper = styled.div`
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow.primary};
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: ${({ theme }) => theme.colors.default.white};
`;

export const Header = styled.div<{ currentStep: boolean }>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  cursor: ${({ currentStep }) => (currentStep ? 'pointer' : 'auto')};
`;

export const StepIcon = styled(SuccessCheckIcon)<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 16px;
  height: auto;
  ${breakpoints.md} {
    width: 30px;
  }
`;

export const StepExplanationIcon = styled.img`
  ${breakpoints.md} {
    height: 1.7rem;
  }
  height: 1.125rem;
`;

export const VerticalLine = styled.span`
  display: inline-block;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.default.dark};
  height: 1.5rem;
  margin-left: 5px;
`;
