/* LocalStorage equivalent of `useFlag` until the flag strategy is finalized */
const useLocalFlag = (flagKey: string, force?: boolean): any => {
  if (typeof force !== 'undefined') {
    return force;
  }
  const flagValue = localStorage.getItem(flagKey);
  return flagValue;
};

export default useLocalFlag;
