import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from '@swibeco/types';
import {
  DOWNLOAD_APP,
  ECOMMERCE_HOME,
  ERROR_PAGE,
  REACT_ONBOARDING_DOMAIN,
} from '@swibeco/shared';
import { actions, selectors } from '../securitySlice';
import { useCurrentUser, useCurrentCompany } from '../contexts';

const redirectToHomepage = (
  referrer: Location | undefined,
  referrerOnboarding: string | null
): Location | undefined => {
  const homePaths = [undefined, '', '/', '/home'];
  const isHome = homePaths.includes(referrer?.pathname);
  if (referrer && isHome) {
    /* eslint-disable no-param-reassign */
    referrer.pathname = '/core/home';
    if (referrerOnboarding) {
      referrer.search = '?referrer=onboarding';
    }
    /* eslint-enable */
    return referrer;
  }
  return referrer;
};
const hasHRAdminRights = (user: UserType): boolean =>
  !!user.roles?.includes('ROLE_HR_ADMIN') ||
  !!user.roles?.includes('ROLE_DATA_MASTER');
const hasDataMasterRights = (user: UserType): boolean =>
  !!user.roles?.includes('ROLE_DATA_MASTER');

const useRedirectAfterLogin = () => {
  const { returnTo } = useParams<{ returnTo?: string }>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const referrerOnboarding = queryParams.get('referrer');
  const { state } = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectors.isAuthenticated);
  const { company } = useCurrentCompany();

  const { user: currentUser } = useCurrentUser();
  const home = `${window.location.origin}/v2${ECOMMERCE_HOME}`;
  const isSelectCompanyUrl =
    window.location?.pathname === '/v2/security/choose-sso-company';

  const urlDomain = window.location.host.split('.')[0];

  const domainCondition =
    company?.domain && company?.domain !== urlDomain && !isSelectCompanyUrl;

  const companyOnboardingCondition =
    company &&
    'onboarding_complete' in company &&
    !company?.onboarding_complete &&
    currentUser &&
    'id' in currentUser;

  const userOnboardingCondition =
    company &&
    'onboarding_complete' in company &&
    company?.onboarding_complete &&
    currentUser &&
    'id' in currentUser &&
    !currentUser?.accountCompleted;

  useEffect(() => {
    const isAppDomain = urlDomain === 'app';
    // 1. Check the company onboarding status and redirect accordingly
    if (companyOnboardingCondition) {
      if (hasHRAdminRights(currentUser)) {
        const redirectionURL = window.location.origin.replace(
          `${urlDomain}.`,
          REACT_ONBOARDING_DOMAIN
        );
        if (!hasDataMasterRights(currentUser)) {
          window.location.assign(redirectionURL);
          return;
        }
      }
      // what to do with reglar users if the thier company has not completed onboardng >> Error page
      navigate(`${ERROR_PAGE}?msg=company-not-onboarded`);
      return;
    }

    // 2. Check if the user has completed onboarding & open to user profile if not (SSO case)
    if (userOnboardingCondition) {
      dispatch(actions.setOnboardingModalVisibility(true));
      return;
    }

    // 3. for regular users that arrived via a generic domain replace with their own company domain
    if (domainCondition) {
      if (isAppDomain) {
        const redirectionURL = window.location.href.replace(
          'app.',
          `${company?.domain}.`
        );

        window.location.assign(redirectionURL);
      }
    }

    // 4. regular redirection to homepage or refferer page after login
    if (isAuthenticated && currentUser && state?.referrer) {
      navigate(
        redirectToHomepage(state.referrer, referrerOnboarding) as Location
      );
      return;
    }

    // 5. redirect back to follow a returnTo param
    if (isAuthenticated && currentUser && returnTo) {
      const decoded = decodeURIComponent(returnTo);
      const returnToUrl = `${window.location.origin}/${decoded}`;
      window.location.assign(returnToUrl);
      return;
    }

    // 6. redirect user with multiple companies coming from sso
    if (isAuthenticated && currentUser && isSelectCompanyUrl) {
      navigate('/security/choose-sso-company');
      return;
    }

    // 7. Bypass redirecting to homepage
    if (window.location?.pathname?.startsWith(`/v2${DOWNLOAD_APP}`)) {
      return;
    }

    // 8. simple redirect to homepage for a simple user
    if (
      isAuthenticated &&
      currentUser &&
      !window.location?.pathname?.startsWith('/v2/core')
    ) {
      if (!isAppDomain) {
        navigate(
          referrerOnboarding ? '/core/home?referrer=onboarding' : '/core/home'
        );
      } else {
        window.location.assign(home);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, company, isAuthenticated, navigate, state, returnTo]);
};

export default useRedirectAfterLogin;
