import React, { useMemo } from 'react';
import { chakra, Box, Center, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { FormFeedback } from 'reactstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { UnderlayMask, useUniqueToast } from '@swibeco/shared-web';
import {
  Button,
  Checkbox,
  ErrorMessage,
  Label,
  Radio,
  Text,
  Toast,
} from '@swibeco/ui';
import {
  useGetCurrentUser,
  actions as securityActions,
  selectors as securitySelectors,
} from '@swibeco/security';
import {
  ColorVariants,
  LegacySubmitActivationDataType,
  UpdateCurrentUserType,
} from '@swibeco/types';
import { userApi } from '@swibeco/ecommerce';
import { useProfileRequestBody } from '../../hooks/useProfileRequestBody';

type FormFields = {
  gender: 'm' | 'f' | 'o';
  newsletter: boolean;
  terms: boolean;
};

const CompleteActivationModal = ({
  notViewedYet,
  onOpen,
}: {
  notViewedYet: boolean;
  onOpen: () => void;
}) => {
  const isOnboardingModalVisible = useSelector(
    securitySelectors.isOnboardingModalVisible
  );
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const uniqueToast = useUniqueToast();
  const { data: currentUser } = useGetCurrentUser();
  const { requestBody } = useProfileRequestBody();
  const dispatch = useDispatch();

  const profileUpdate = useMutation({
    mutationFn: async (data: {
      id: number;
      params: Partial<UpdateCurrentUserType>;
    }) => userApi.updateUserProfile(data),
  });

  const profileActivation = useMutation({
    mutationFn: async (data: LegacySubmitActivationDataType) =>
      userApi.submitLegacyActivationData(data),
  });

  const GENDERS = useMemo(
    () => [
      {
        label: t('forms.field.gender.male'),
        value: 'm',
      },
      {
        label: t('forms.field.gender.female'),
        value: 'f',
      },
      {
        label: t('forms.field.gender.other'),
        value: 'o',
      },
    ],
    [t]
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        gender: yup
          .string()
          .oneOf(['f', 'm', 'o'])
          .required(t('forms.field.gender.validation.required'))
          .typeError(t('forms.field.gender.validation.required')),
        newsletter: yup.boolean().notRequired(),
        terms: yup
          .boolean()
          .required()
          .oneOf([true], t('forms.field.terms_conditions.validation.required')),
      }),
    [t]
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormFields>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    values: {
      gender: currentUser?.gender || 'm',
      newsletter: currentUser?.newsletter || false,
      terms: false,
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const onSubmit = async (data: FormFields) => {
    if (currentUser?.id) {
      profileUpdate.mutate(
        {
          id: currentUser.id,
          params: {
            ...requestBody,
            gender: data.gender,
            newsletter: data.newsletter,
          },
        },
        {
          onSuccess() {
            if (currentUser.id) {
              profileActivation.mutate(
                {
                  user: currentUser.id,
                  birth_date: currentUser.birthDate?.toISOString() || null,
                  terms_accepted: data.terms,
                },
                {
                  onSuccess() {
                    queryClient.invalidateQueries({
                      queryKey: ['current-user'],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ['legacy-current-user'],
                    });
                    dispatch(
                      securityActions.setOnboardingModalVisibility(false)
                    );
                    if (notViewedYet) {
                      onOpen();
                    }
                  },
                  onError() {
                    uniqueToast({
                      id: 'complete-activation-error',
                      position: 'top-right',
                      isClosable: true,
                      render: ({ onClose }) => (
                        <Toast
                          variant={ColorVariants.Danger}
                          onCloseButtonClick={onClose}
                          legacy={false}
                        >
                          {t('core.profile.toast.system_error')}
                        </Toast>
                      ),
                    });
                  },
                }
              );
            }
          },
          onError() {
            uniqueToast({
              id: 'complete-activation-error',
              position: 'top-right',
              isClosable: true,
              render: ({ onClose }) => (
                <Toast
                  variant={ColorVariants.Danger}
                  onCloseButtonClick={onClose}
                  legacy={false}
                >
                  {t('core.profile.toast.system_error')}
                </Toast>
              ),
            });
          },
        }
      );
    }
  };

  if (!isOnboardingModalVisible) return null;
  return (
    <>
      <UnderlayMask fullscreen zIndex={2000} />
      <Center
        backdropFilter="blur(10px)"
        width="100vw"
        height="100vh"
        position="fixed"
        top="0"
        left="0"
        zIndex="2000"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            alignItems="center"
            backgroundColor="default.white"
            borderRadius="6px"
            flexDirection="column"
            gap="8px"
            maxWidth="812px"
            padding="30px 20px"
            width="100%"
          >
            <Text>🎉</Text>
            <Text important>
              {t('core.ecommerce.complete_activation.title')}
            </Text>
            <Text>{t('core.ecommerce.complete_activation.subtitle')}</Text>
            <Box mt="32px" mb="60px">
              <Box px="20px">
                <Label required>{t('forms.field.gender.label')}</Label>
                <Flex gap="16px">
                  {GENDERS.map(({ value, label }) => (
                    <div>
                      <Radio
                        key={value}
                        label={label}
                        value={value}
                        errors={errors}
                        {...register('gender')}
                        setValue={setValue}
                        isGrouped
                      />
                    </div>
                  ))}
                </Flex>
                <FormFeedback className="d-flex mb-2">
                  {errors && (
                    <ErrorMessage>{errors?.gender?.message}</ErrorMessage>
                  )}
                </FormFeedback>
              </Box>
              <Box
                backgroundColor="primary.decorationLight"
                borderRadius="5px"
                padding="20px"
                mb="24px"
              >
                <Label>{t('forms.field.newsletter.label')}</Label>
                <Checkbox
                  label={t('forms.field.newsletter.text_long.deals_discounts')}
                  errors={errors}
                  {...register('newsletter')}
                  {...(errors?.newsletter?.message
                    ? { fillColor: ['complementary', 'red'] }
                    : {})}
                />
                <Text variant="caption" color="default.dark" ml="40px">
                  {t('forms.field.newsletter.text_long.twice_month')}
                </Text>
              </Box>
              <Box px="20px">
                <Checkbox
                  label={
                    <Trans
                      i18nKey="forms.field.terms_conditions.text"
                      components={{
                        a: <chakra.a target="_blank" color="primary.main" />,
                      }}
                    />
                  }
                  errors={errors}
                  {...register('terms')}
                  {...(errors?.terms?.message
                    ? { fillColor: ['complementary', 'red'] }
                    : {})}
                />
              </Box>
            </Box>
            <Button color="primary">
              {t('core.ecommerce.complete_activation.cta.text')}
            </Button>
          </Flex>
        </form>
      </Center>
    </>
  );
};

export default CompleteActivationModal;
