import { breakpoints, styled } from '@swibeco/shared';
import { Link } from '@swibeco/ui';

export const BrandName = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.default.white};
  font-weight: 400;
  font-size: 0.765rem;
  line-height: 17px;

  &:hover {
    text-decoration: none;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.default.white};
  }

  ${breakpoints.md} {
    font-weight: 600;
    font-size: 0.882rem;
    line-height: 21px;
  }
`;
