import { breakpoints, styled } from '@swibeco/shared';
import { Text } from '@swibeco/ui';

// this inline color is used to not introduce old angular colors in out design system
export const Link = styled.div<{ isActive?: boolean }>`
  padding: 5px 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 80px;
  
  ${({ isActive, theme }) =>
    isActive ? `background-color: ${theme.colors.legacy?.default.grey};` : ''}

  &:hover{
    background-color: ${({ theme }) => theme.colors.legacy?.default.grey};
  }
  ${breakpoints.lg_max} {
    flex-direction: row;
    height: auto;
    align-items: initial;
  }
}
`;

export const Icon = styled.span`
  min-height: 34px;
  min-width: 40px;
  color: ${({ theme }) => theme.colors.default.white};
  ${breakpoints.sm} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Image = styled.img`
  height: 20px;
  width: 20px;
`;
export const LinkText = styled(Text)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.legacy?.default.light};
  text-decoration: uppercase;
  ${breakpoints.lg} {
    max-width: 75px;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
  }
  ${breakpoints.lg_max} {
    width: 80%;
    display: block;
  }
`;
