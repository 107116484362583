import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentCompanyInformationType } from '@swibeco/types';
import { BasicCompanyState } from '../types';

export const initialState: BasicCompanyState = {
  display_login_logo: false,
  theme: {
    main_color: '',
    text_color: '',
    favicon: {
      path: '',
      mime_type: '',
    },
    logo: '',
    cover: '',
    partner_logo: '',
    dealer_logo: '',
  },
  contract_start_date: null,
  authentication_methods: [],
  subdomain_belongs_to_company: false,
  can_manage_users: false,
  vouchers_v2: false,
  features: {
    PAY_WITH_ONLY_SWIPOINTS: false,
    BASKET_V2: false,
    PLUS_LANDING_PAGE: false,
  },
  dealer_is_axa: false,
  contract_type: '',
};

const globalCompanySlice = createSlice({
  name: 'globalCompany',
  initialState,
  reducers: {
    reset: () => initialState,
    setCompany: (
      state: BasicCompanyState,
      action: PayloadAction<CurrentCompanyInformationType>
    ) => {
      state.id = action.payload.id;
      state.dealer = action.payload.dealer;
      state.name = action.payload.name;
      state.domain = action.payload.domain;
      state.trade_name = action.payload.trade_name;
      state.phone_number = action.payload.phone_number;
      state.contact = action.payload.contact;
      state.language = action.payload.language;
      state.activity_area = action.payload.activity_area;
      state.type = action.payload.type;
      state.address = action.payload.address;
      state.automatic_topup = action.payload.automatic_topup;
      state.display_login_logo = action.payload.display_login_logo;
      state.theme = action.payload.theme;
      state.available_swipoints = action.payload.available_swipoints;
      state.external_id = action.payload.external_id;
      state.onboarding_complete = action.payload.onboarding_complete;
      state.has_sso = action.payload.has_sso;
      state.contract_start_date = action.payload.contract_start_date;
      state.active_solutions = action.payload.active_solutions;
      state.authentication_methods = action.payload.authentication_methods;
      state.subdomain_belongs_to_company =
        action.payload.subdomain_belongs_to_company;
      state.can_manage_users = action.payload.can_manage_users;
      state.features = action.payload.features;
      state.dealer_is_axa = action.payload.dealer_is_axa;
      state.contract_type = action.payload.contract_type;
    },
    setCompanyDealerInfo: (state, action: PayloadAction<boolean>) => {
      state.dealer_is_axa = action.payload;
    },
  },
});

const getCurrentCompany = (state: {
  global: { company: CurrentCompanyInformationType };
}) => state.global.company;

const isDealerAxa = (state: {
  global: { company: CurrentCompanyInformationType };
}) => state.global.company.dealer_is_axa;

const getMainColor = (state: {
  global: { company: CurrentCompanyInformationType };
}) => state.global.company.theme.main_color;

const companyTheme = createSelector(
  getCurrentCompany,
  (state: CurrentCompanyInformationType) => state.theme
);

export const companySelectors = {
  getCurrentCompany,
  isDealerAxa,
  getMainColor,
  companyTheme,
};

export default globalCompanySlice;
