import { selectors as coreSelectors } from '@swibeco/core';
import { selectors as securitySelectors } from '@swibeco/security';
import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SideBar from '../SideBar/SideBar';
import * as Styles from './Layout.styles';

type LayoutProps = {
  children: JSX.Element;
  showSidebar?: boolean;
  showFooter?: boolean;
};

export const Layout = ({
  showSidebar = false,
  showFooter = true,
  children,
}: LayoutProps) => {
  const isAuthenticated = useSelector(securitySelectors.isAuthenticated);
  const isSidebarVisible = useSelector(coreSelectors.isSidebarVisible);

  return (
    <Styles.LayoutWrapper id="page-layout">
      <Header enableDownloadAppBanner enableAxaBanner />
      {/* Sidebar is hidden by default */}
      {showSidebar && <SideBar />}
      <Styles.PageContainer
        sidebarVisible={!!isAuthenticated && isSidebarVisible}
      >
        <Styles.ChildrenWrapper>{children}</Styles.ChildrenWrapper>
        {showFooter && <Footer />}
      </Styles.PageContainer>
    </Styles.LayoutWrapper>
  );
};
