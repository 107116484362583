import React, { useCallback, useRef } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { Text } from '@swibeco/ui';
import {
  DOWNLOAD_APP,
  getDeviceOs,
  getAppStoreLink,
  isNativeMobile,
  useExecuteOnView,
} from '@swibeco/shared';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { usePlatform } from '@swibeco/core';
import { AnalyticsEvents } from '@swibeco/types';
import { ReactComponent as SwibecoSquareRounded } from '../../../assets/logos/swibeco_square_rounded.svg';

const DownloadAppBanner = () => {
  const { t } = useTranslation();
  const environment = usePlatform(window);
  const ref = useRef<HTMLDivElement>(null);
  const isMobileBrowser = isNativeMobile(window);
  const appLink = getAppStoreLink(window);
  const qrCodeLink = `${window.location.protocol}//${window.location.host}/v2${DOWNLOAD_APP}`;
  const deviceOs = getDeviceOs(window);

  useExecuteOnView(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.VIEW_DOWNLOAD_APP_BANNER,
        environment,
        device_os: deviceOs,
        redirect_link: isMobileBrowser ? appLink : qrCodeLink,
      },
    });
  }, ref);

  const onClick = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.CLICK_DOWNLOAD_APP_BANNER,
        environment,
        device_os: deviceOs,
        redirect_link: appLink,
      },
    });
  }, [environment, appLink, deviceOs]);

  return (
    <Flex
      align="center"
      justify="center"
      bgColor="primary.mainLight"
      gap="30px"
      py="5px"
      px="20px"
      ref={ref}
    >
      <Flex align="center" gap="5px">
        <SwibecoSquareRounded />
        <Text fontSize={{ base: '12px', md: '15px' }}>
          {t('core.header.app.download')}
        </Text>
      </Flex>
      {isMobileBrowser && (
        <a href={appLink} target="_blank" rel="noreferrer">
          <Button
            bgColor="initial.main"
            color="default.white"
            _hover={{ bgColor: 'initial.strong' }}
            _active={{ bgColor: 'initial.strong' }}
            size="sm"
            onClick={onClick}
          >
            {t('core.generic.open')}
          </Button>
        </a>
      )}
      {!isMobileBrowser && (
        <QRCode size={50} value={qrCodeLink} viewBox="0 0 50 50" />
      )}
    </Flex>
  );
};

export default DownloadAppBanner;
