import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { universeReducer } from './store';
import { UniverseActions, UniverseStore } from './types';
import { universe } from './initialState';

const UniverseStoreContext = createContext<UniverseStore>({} as UniverseStore);
export const UniverseDispatchContext = createContext<Dispatch<UniverseActions>>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  /* istanbul ignore next */ () => {}
);

export const useUniverseContext = (): [
  UniverseStore,
  Dispatch<UniverseActions>
] => [useContext(UniverseStoreContext), useContext(UniverseDispatchContext)];

type UniverseProviderProps = {
  children: React.ReactNode;
};

const UniverseProvider = ({ children }: UniverseProviderProps) => {
  const [state, dispatch] = useReducer(universeReducer, {
    ...universe,
  });
  return (
    <UniverseStoreContext.Provider value={state}>
      <UniverseDispatchContext.Provider value={dispatch}>
        {children}
      </UniverseDispatchContext.Provider>
    </UniverseStoreContext.Provider>
  );
};

export default UniverseProvider;
