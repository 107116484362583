import React from 'react';
import * as Styles from './styles/Badge.styles';

type BadgeProps = {
  count?: number;
  children: React.ReactNode;
};

export const Badge = ({ count = 0, children }: BadgeProps) => (
  <Styles.Badge count={count}>{children}</Styles.Badge>
);
