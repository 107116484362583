import React from 'react';
import * as Styles from './styles/BasePicto.styles';

const PerksAndDiscount = () => (
  <Styles.Wrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 30 41.824"
    >
      <path
        className="perks-style"
        d="M21.833 17.969a.753.753 0 01-.752-.755V7.419a5.829 5.829 0 10-11.658 0v9.786a.752.752 0 11-1.5 0V7.419a7.333 7.333 0 1114.665 0v9.786a.757.757 0 01-.751.763z"
      />
      <path
        className="perks-style"
        d="M28.054 11.708a2.087 2.087 0 00-2.078-1.956H23.7v7.089a1.879 1.879 0 11-1.879-1.9 1.773 1.773 0 01.752.164V9.753H10.548v7.089a1.879 1.879 0 11-1.879-1.9 1.773 1.773 0 01.752.164V9.754H4.015a2.1 2.1 0 00-2.078 1.956L0 39.569a2.1 2.1 0 001.933 2.25h25.979a2.1 2.1 0 002.083-2.108q0-.073-.005-.146z"
      />
      <g transform="translate(10.437 23.746)">
        <ellipse
          cx={1.621}
          cy={1.64}
          rx={1.621}
          ry={1.64}
          transform="translate(0 .586)"
          fill="#fff"
        />
        <ellipse
          cx={1.632}
          cy={1.651}
          rx={1.632}
          ry={1.651}
          transform="translate(6.36 6.109)"
          fill="#fff"
        />
        <path d="M3.007 9.688H1.225L6.939 0h1.777z" fill="#fff" />
      </g>
    </svg>
  </Styles.Wrapper>
);

export default PerksAndDiscount;
