import { breakpoints, styled } from '@swibeco/shared';
import { Caption } from '@swibeco/ui';

export const SwipointsBox = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  box-shadow: 0px 3px 6px rgba(62, 97, 145, 0.2);
  border-radius: 48px;
  position: relative;
  left: -10px;
  background-color: #e7f5ff;
  padding: 4px 8px;
  min-width: 74px;
  gap: 3px;

  ${breakpoints.sm} {
    height: 28px;
    min-width: 81px;
    padding: 5px 8px 5px 6px;
  }
  img {
    margin-top: 3px;
  }
`;
export const SwipointsValue = styled(Caption)`
  margin-top: 3px;
  ${breakpoints.sm} {
    font-size: 13px;
    line-height: 20px;
  }
`;
