import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { arrayFrom, useTheme } from '@swibeco/shared';
import { MainContentWrapper } from '@swibeco/shared-web';
import { darkenLighten } from '@swibeco/ui';
import * as Styles from './TopCategoryMenuSkeletonLoading.styles';
import * as TopCategoryMenuStyles from '../TopCategoryMenu.styles';

const TopCategoryMenuSkeletonLoading = () => {
  const theme = useTheme();
  return (
    <TopCategoryMenuStyles.TopCategoryMenuWrapper
      menuIsVisible={false}
      showDesktopMenu
    >
      <MainContentWrapper>
        <TopCategoryMenuStyles.TopCategoryMenu>
          {arrayFrom(11).map((i) => (
            <TopCategoryMenuStyles.TopCategoryMenuItem key={i}>
              <Styles.TopCategoryItemContainer>
                <Styles.Link>
                  <Styles.Icon>
                    <Skeleton
                      height="20px"
                      width="20px"
                      baseColor={theme.colors.secondary.ebonyClay}
                      highlightColor={darkenLighten(
                        theme.colors.secondary.ebonyClay,
                        20
                      )}
                    />
                  </Styles.Icon>
                  <Skeleton
                    height="14px"
                    width="51px"
                    baseColor={theme.colors.secondary.ebonyClay}
                    highlightColor={darkenLighten(
                      theme.colors.secondary.ebonyClay,
                      20
                    )}
                  />
                </Styles.Link>
              </Styles.TopCategoryItemContainer>
            </TopCategoryMenuStyles.TopCategoryMenuItem>
          ))}
        </TopCategoryMenuStyles.TopCategoryMenu>
      </MainContentWrapper>
    </TopCategoryMenuStyles.TopCategoryMenuWrapper>
  );
};

export default TopCategoryMenuSkeletonLoading;
