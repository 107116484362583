import { styled, breakpoints } from '@swibeco/shared';
import { Text as BaseText } from '@swibeco/ui';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.default.white};
  border-radius: 0 0 5px 5px;
`;

export const Header = styled.div`
  box-shadow: 0px 3px 6px rgba(62, 97, 145, 0.2);
  border-radius: 5px;
  position: relative;
  z-index: 2;
  ${breakpoints.sm_max} {
    padding: 1.5rem 0 !important;
  }
  > div {
    ${breakpoints.sm_max} {
      padding-left: 16px;
    }
  }
`;

export const Text = styled(BaseText, {
  shouldForwardProp: (prop) => !['hexColor'].includes(prop),
})<{ hexColor?: string }>`
  ${({ hexColor }) => `color: ${hexColor} !important;`}
`;

export const StepExplanationIcon = styled.span`
  margin-right: 9px;
  height: 1.125rem;
  width: 32px;

  ${breakpoints.md} {
    height: 1.7rem;
  }
`;

export const StepElementContent = styled.div`
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0 0 5px 5px;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.default.white};
  padding: 14px 86px 17px 64px;
  ${breakpoints.sm_max} {
    padding: 1.2rem 1rem;
  }
`;
