import { Box, BoxProps, chakra } from '@chakra-ui/react';
import { breakpoints, styled } from '@swibeco/shared';
import React from 'react';
import { Row } from 'reactstrap';
import bubble1 from '../../assets/images/bubble_1.svg';
import bubble2 from '../../assets/images/bubble_2.svg';
import bubble3 from '../../assets/images/bubble_3.svg';

export const Wrapper = chakra(
  (props: BoxProps) => (
    <Box
      width="100%"
      paddingBottom="200px"
      paddingTop="32px"
      backgroundColor="default.backgroundColor"
      position="relative"
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => !['displayShapes'].includes(prop),
  }
);

export const Header = styled.div`
  width: 90%;
  max-width: 1440px;
  margin: auto;
  padding: 1rem;
`;

export const Layout = styled(Row)`
  width: 90%;
  max-width: 1440px;
  margin: auto;
  position: relative;
`;

export const ColumnContent = styled('div', {
  shouldForwardProp: (prop) => !['fixed', 'flexSideTop'].includes(prop),
})<{
  fixed?: boolean;
  flexSideTop?: number;
}>`
  ${breakpoints.lg} {
    ${({ fixed, flexSideTop }) =>
      fixed &&
      `
    position: sticky;
    top: ${flexSideTop || 100}px;
  `}
  }
  z-index: 3;
`;

export const ShapeContainer = styled.ul`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: none;

  ${breakpoints.md} {
    display: block;
  }
`;

const Shape = styled.li`
  position: absolute;
  display: block;
  list-style: none;
`;

export const FirstShape = styled(Shape)`
  width: 780px;
  height: 620px;
  background-image: url('${bubble1}');

  top: 1px;
  left: -300px;
`;

export const SecondShape = styled(Shape)`
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  width: 1400px;
  height: 990px;
  background-image: url('${bubble2}');
`;

export const ThirdShape = styled(Shape)`
  top: 2802px;
  left: -800px;
  width: 1700px;
  height: 1600px;
  background-image: url('${bubble3}');
`;
