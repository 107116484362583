import React from 'react';

type PlusIconProps = {
  width?: number;
  height?: number;
};

const PlusIcon = ({ width = 22, height = 30 }: PlusIconProps) => (
  <svg
    viewBox="0 0 22 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <g clipPath="url(#a)" fill="#1B2A6A">
      <path d="M8.491 18.29 6.04 30H1.357l4-18.86h4.276l-.512 3.384" />
      <path d="M14.216 7.92c.532.101.998.28 1.395.54.397.262.713.63.949 1.101.235.472.353 1.07.353 1.79 0 .969-.18 1.79-.537 2.463a4.53 4.53 0 0 1-1.432 1.623 6.03 6.03 0 0 1-2.062.894 10.43 10.43 0 0 1-2.435.279h-.429c-.187 0-.385-.006-.595-.018-.115-.005-.601-.12-.705-.132l-1.161 4.359c.12.02.284.04.491.06a20.662 20.662 0 0 0 1.633.075h.576c1.46 0 2.898-.167 4.31-.5 1.412-.333 2.668-.902 3.773-1.704 1.1-.802 1.986-1.853 2.657-3.15.67-1.295 1.003-2.907 1.003-4.835 0-1.284-.224-2.39-.67-3.316a6.029 6.029 0 0 0-1.878-2.278c-.805-.592-1.745-1.032-2.823-1.316-1.079-.285-2.249-.425-3.511-.425l1.095 4.494.003-.003Z" />
      <path d="m9.196 11.584 4.198 2.402a.397.397 0 0 0 .28.05.413.413 0 0 0 .287-.601C11.965 9.656 10.392 4.608 9.51.324a.41.41 0 0 0-.748-.132C6.485 3.927 3.293 8.145.14 11.027c-.305.279-.06.781.345.71l4.767-.84-3.29 18.39c-.083.47.593.652.754.2l6.481-17.903Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v30H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PlusIcon;
