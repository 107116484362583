import { breakpoints, styled } from '@swibeco/shared';
import { Button, PopoverBody } from '@swibeco/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderItem from '../HeaderItem';

export const HeaderItemMyBasket = styled(HeaderItem)`
  ${breakpoints.lg} {
    padding: 0 20px 0 12px;
  }
  .chakra-popover__popper {
    z-index: 2001;
  }
`;

export const Badge = styled.div<{ count: number }>`
  width: 20px;
  height: 24px;
  position: relative;

  &::after {
    top: -3px;
    right: -11px;
    height: 16px;
    display: flex;
    padding: 0 5px;
    min-width: 16px;
    position: absolute;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;

    color: ${({ theme }) => theme.colors.default.white};
    padding: 1px 3px;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.7px;
    box-sizing: border-box;

    display: ${({ count }) => (count > 0 ? 'flex' : 'none')};
    content: '${({ count }) => (count > 99 ? '99+' : count)}';
    background-color: ${({ theme }) => theme.colors.complementary.red};

    border-radius: 999px;
    -moz-border-radius: 999px;
    -webkit-border-radius: 999px;
  }
`;

export const MyBasketIcon = styled(FontAwesomeIcon)`
  transform: translate(-15%, 5%);
`;

export const ToastWrapper = styled.div<{ visible: boolean }>`
  ${({ theme, visible }) =>
    visible &&
    `position: absolute;
      top: 60px;
      right: 0px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        top: -6px;
        bottom: -6px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${theme.colors.default.white};
        left: calc(50% + 40px);
        z-index: -1;
      }`};
  ${breakpoints.sm_max} {
    ${({ visible }) =>
      visible &&
      `
        left: 0;
        transform: translate(-50%);
        width: 90vw;
        &:after {
          display: none;
        }
      }`};
  }
`;
export const PopoverBodyStyled = styled(PopoverBody)`
  flex-direction: column;
`;

export const SeeMyBasket = styled(Button)`
  width: 100%;
`;
