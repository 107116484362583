import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps as RSModalProps,
} from 'reactstrap';
import { styled, breakpoints, useColorVariant } from '@swibeco/shared';
import { CloseIcon } from './iconography';

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 12px;
    border: none;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  h5 {
    width: 100%;

    ${breakpoints.sm_max} {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  ${breakpoints.sm} {
    padding: 3rem;
  }
`;

const CloseIconWrapper = styled.span``;

export type ModalProps = RSModalProps & {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  isOpen: boolean;
  toggle?: () => void;
};

const ModalComponent = ({
  header,
  footer,
  isOpen,
  toggle,
  children,
  ...rest
}: ModalProps) => {
  // Avoid the conditional call to the hook and respect react-hooks/rules-of-hooks
  const defaultWhiteColor = useColorVariant('default', 'white');

  return (
    <StyledModal isOpen={isOpen} toggle={toggle} data-testid="modal" {...rest}>
      {header && (
        <StyledModalHeader
          toggle={toggle}
          close={
            <CloseIconWrapper onClick={toggle} data-testid="close">
              <CloseIcon color={defaultWhiteColor} />
            </CloseIconWrapper>
          }
          data-testid="modal-header"
        >
          {header}
        </StyledModalHeader>
      )}
      <StyledModalBody data-testid="modal-body">{children}</StyledModalBody>
      {footer && <ModalFooter data-testid="modal-footer">{footer}</ModalFooter>}
    </StyledModal>
  );
};

export default ModalComponent;
