import { breakpoints, styled, withAttrs } from '@swibeco/shared';
import { Link, Text } from '@swibeco/ui';
import { Col } from 'reactstrap';
import { whiteFilter } from '../../utils';

type TopCategoryMenuWrapperProps = {
  menuIsVisible: boolean;
  showDesktopMenu: boolean;
};

export const TopCategoryMenuWrapper = styled.div<TopCategoryMenuWrapperProps>`
  background-color: ${({ theme }) => theme.colors.secondary.ebonyClay};
  width: 100vw;
  height: 72px;
  width: 100%;
  display: block;
  position: sticky;
  color: ${({ theme }) => theme.colors.default.white};
  top: ${({ showDesktopMenu }) => (showDesktopMenu ? '70px' : '-20px')};
  left: 0;
  z-index: 10;
  transition: top 400ms ease 0s;
  ${breakpoints.lg_max} {
    display: block;
    ${({ menuIsVisible }) =>
      !menuIsVisible
        ? 'transform: translateX(-100%);'
        : 'transform: translateX(-10%);'}
    z-index: 2002;
    transition: transform 0.3s;
    background-color: ${({ theme }) => theme.colors.secondary.ebonyClay};
    padding: 25px;
    position: fixed;
    width: 82vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
  ${breakpoints.lg} {
    height: 80px;
  }
`;
export const TopCategoryMenu = styled.ul`
  list-style-type: none;
  padding: 0px;
  height: 72px;
  display: flex;
  justify-content: center;
  ${breakpoints.lg_max} {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    padding: 18px 0px;
  }
  ${breakpoints.lg} {
    height: 80px;
  }
`;

export const TopCategoryMenuItem = styled.li`
  display: inline-block;
  height: 80px;
  margin: 0 10px 0 0;
  :hover {
    p {
      opacity: 1;
    }
  }
  p {
    opacity: 0.7;
  }
  ${breakpoints.lg} {
    height: 80px;
  }
  ${breakpoints.lg_max} {
    max-width: 400px;
    width: 100%;
    height: auto;
    margin: 0 10px 10px 0;
  }
`;
export const Divider = styled.li`
  color: ${({ theme }) => theme.colors.legacy?.default.light};
  border-top: 1px solid;
  margin: 8px 20px;
  width: 75px;
`;

export const TopCategoryMenuLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'noFilter',
})<{ noFilter?: boolean }>`
  padding: 0 10px;
  text-decoration: none !important;
  p {
    text-transform: uppercase;
  }
  ${breakpoints.lg} {
    height: 80px;
  }
  svg {
    filter: ${({ noFilter }) => (noFilter ? 'none' : whiteFilter)};
    height: 23px;
    width: 23px;
  }
  img {
    filter: ${({ noFilter }) => (noFilter ? 'none' : whiteFilter)};
    height: 23px;
    width: 23px;
  }
`;

export const SeeAllLink = styled(Link)`
  font-weight: 600;
  line-height: 20px;
`;

export const CategoryItem = styled(Link)`
  color: ${({ theme }) => theme.colors.legacy?.default.black};
  display: inline-block;
  font-size: 17px;
  font-weight: normal;
  :hover {
    color: ${({ theme }) => theme.colors.primary.main};
    font-weight: bold;
    text-decoration: none;
  }
`;
export const TopDealItem = styled(Link)`
  display: inline-block;
`;
export const ListTitle = withAttrs(
  styled(Text)`
    color: ${({ theme }) => theme.colors.legacy?.default.black};
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.32px;
  `,
  () => ({
    className: '',
  })
);
export const CategoriesList = withAttrs(styled(Col)``, () => ({
  className: 'd-flex flex-wrap col-12 col-md-12 pt-3 pb-4 pl-0 mb-2',
}));
export const SubCategoryContainer = withAttrs(
  styled.div`
    width: 84vw;
    max-width: 1464px;
  `,
  () => ({
    className: 'col-12 p-4',
  })
);
export const TopDealList = withAttrs(styled(Col)``, () => ({
  className: 'col-12 col-md-12 pt-3 pb-3 pl-0',
}));
export const TopDeal = withAttrs(
  styled.img`
    width: 13vw;
    height: 100%;
    object-fit: cover;
  `,
  () => ({
    className: 'col-12 pl-0',
  })
);
