import { breakpoints, styled } from '@swibeco/shared';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1100;
`;
export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.default.white};
  width: 100%;
  height: 70px;
  padding-bottom: 8px;
  display: flex;
  box-shadow: 0 3px 6px #00000029;
`;
export const IconsWrapper = styled.div`
  ${breakpoints.lg_max} {
    margin: 0 auto;
    max-width: 90%;
    flex: auto;
  }
`;
