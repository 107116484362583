import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react';
import { useCallback } from 'react';

const useUniqueToast = () => {
  const toast = useToast();

  return useCallback(
    (options: UseToastOptions & { id: ToastId }, reuse = false) => {
      const localOptions: UseToastOptions & { id: ToastId } = {
        duration: 3000,
        ...options,
        containerStyle: {
          position: 'absolute',
          top: { base: '100px', md: '170px' },
          ...options.containerStyle,
        },
      };
      const { id } = localOptions;
      if (toast.isActive(id) && !reuse) {
        toast.close(id);
        return setTimeout(() => toast(localOptions), 300);
      }
      if (toast.isActive(id) && reuse) {
        return toast.update(id, localOptions);
      }
      return toast(localOptions);
    },
    [toast]
  );
};

export default useUniqueToast;
