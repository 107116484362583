import {
  EnumProductType,
  MappedProduct,
  MappedProductVariant,
} from '@swibeco/types';

type ProductOrVariant = MappedProduct | MappedProductVariant;

export const productHasCashback = (productVariant: MappedProductVariant) =>
  productVariant?.cashbackAmount ? productVariant.cashbackAmount > 0 : false;

export const variantsHasCashback = (productVariant: MappedProductVariant[]) =>
  productVariant?.some((productVariant) => productHasCashback(productVariant));

export const isProductPurchaseable = (
  productOrVariant: ProductOrVariant
): boolean => {
  if (productOrVariant && 'product' in productOrVariant) {
    return (
      productOrVariant.product.type === EnumProductType.Physical ||
      productOrVariant.product.type === EnumProductType.Voucher ||
      productOrVariant.product.type === EnumProductType.DropShipping ||
      productOrVariant.product.type === EnumProductType.SwipointsPack
    );
  }
  return (
    productOrVariant.type === EnumProductType.Physical ||
    productOrVariant.type === EnumProductType.Voucher ||
    productOrVariant.type === EnumProductType.DropShipping ||
    productOrVariant.type === EnumProductType.SwipointsPack
  );
};
