import React from 'react';
import 'react-phone-number-input/style.css';
import { styled } from '@swibeco/shared';
import PhoneInput, { Props as PhoneInputProps } from 'react-phone-number-input';
import { FormFeedback } from 'reactstrap';
import { ErrorMessage } from './ErrorMessage';

const InputDesign = styled.div`
  .PhoneInputInput {
    outline: none;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 25px;
    background-color: ${({ theme }) => theme.colors.default.white};
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    font-size: 16px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.primary.main};
    text-align: center;
    border-radius: 5px !important;
    border-color: transparent;
    box-shadow: ${({ theme }) => `0 0 6px 0 ${theme.colors.shadow.secondary}`};

    &:active,
    &:focus {
      color: ${({ theme }) => theme.colors.primary.main};
      border: ${({ theme }) => `2px solid ${theme.colors.primary.main}`};
      box-shadow: ${({ theme }) =>
        `0 0 6px 0 ${theme.colors.shadow.secondary}`};
    }

    &:hover {
      border: ${({ theme }) => `2px solid ${theme.colors.default.strong}`};
    }
  }

  &.is-invalid {
    .PhoneInputInput {
      color: ${({ theme }) => theme.colors.secondary.main};
      box-shadow: ${({ theme }) =>
        `0 0 6px 0 ${theme.colors.secondary.main}3D`};
      border: 2px solid transparent;

      &:active,
      &:focus {
        color: ${({ theme }) => theme.colors.secondary.main};
        box-shadow: ${({ theme }) =>
          `0 0 6px 0 ${theme.colors.secondary.main}3D`};
        border: ${({ theme }) => `2px solid ${theme.colors.secondary.main}`};
      }
    }
  }
`;

type InputProps = PhoneInputProps & {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
};
//add forwardRef to phoneNumberInput
const PhoneNumberInput = React.forwardRef<any, InputProps>(
  ({ errors, name, ...rest }, ref) => (
    <>
      <InputDesign
        className={errors && errors[name]?.message ? 'is-invalid' : ''}
      >
        <PhoneInput name={name} {...rest} ref={ref} />
      </InputDesign>

      <FormFeedback className="d-flex mb-2">
        {errors && <ErrorMessage>{errors[name]?.message}</ErrorMessage>}
      </FormFeedback>
    </>
  )
);

export default PhoneNumberInput;
