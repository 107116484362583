import { breakpoints, styled } from '@swibeco/shared';

export const BrandLogo = styled.div<{ size: 'normal' | 'large' }>`
  height: ${({ size }) => (size === 'large' ? '50px' : '40px')};

  img {
    height: 100%;
  }

  ${breakpoints.sm_max} {
    height: ${({ size }) => (size === 'large' ? '50px' : '40px')};
    max-width: 180px;
    margin: auto;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`;
