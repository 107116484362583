import { breakpoints, styled } from '@swibeco/shared';
import { Text } from '@swibeco/ui';

export const FlexContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center
  align-items: center;
`;

export const Link = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 71px;
  ${breakpoints.lg_max} {
    flex-direction: row;
    height: 41px;
    align-items: baseline;
  }
}
`;
export const Icon = styled.span`
  min-height: 34px;
  min-width: 40px;
  ${breakpoints.sm} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LinkText = styled(Text)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.legacy?.default.light};
  ${breakpoints.lg} {
    max-width: 70px;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
  }
  ${breakpoints.lg_max} {
    width: 80%;
    display: block;
  }
`;

export const TopCategoryItemContainer = styled.div`
  padding: 0 10px;
`;
