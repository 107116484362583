import { API, LegacyAPI } from '@swibeco/shared';
import { Brand, CategoryV1, PaginationResponse } from '@swibeco/types';
import camelcaseKeys from 'camelcase-keys';

export default {
  getTopCategories: async (locale: string): Promise<CategoryV1[]> => {
    const response = await LegacyAPI.get('/dealcategories/top', {
      params: { locale },
    });
    return response.data.data;
  },
  getTopCategory: async (slug: string): Promise<CategoryV1> => {
    const response = await API.get(`/categories/${slug}`);
    return response.data.data;
  },
  getSliderDeals: async (locale: string): Promise<Brand[]> => {
    const response = await API.get('/homepage/slider', {
      params: { locale },
    });
    return camelcaseKeys(response.data);
  },
  getAllBrands: async (
    pageSize = 120
  ): Promise<PaginationResponse<Pick<Brand, 'logo' | 'slug' | 'name'>>> => {
    const response = await API.get('/brands', {
      params: { page_size: pageSize },
    });
    return camelcaseKeys(response.data);
  },
};
