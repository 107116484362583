import { breakpoints, styled } from '@swibeco/shared';
import { Text } from '@swibeco/ui';

export const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.primary.footerBlue};
  width: 100%;
  z-index: 8;
  position: relative;
`;

export const Logo = styled.img`
  max-width: 160px;
  height: auto;
`;

export const SectionTitle = styled(Text)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.default.white};
  font-size: 17px;
`;

export const SectionText = styled(Text)<{
  hoverable?: boolean;
  black?: boolean;
}>`
  display: inline-flex;
  color: ${({ theme, black }) =>
    black ? theme.colors.default.black : theme.colors.default.white};
  position: relative;
  font-size: 14px;
  line-height: 14px;

  &:after {
    position: absolute;
    height: 1px;
    width: 0;
    left: 0;
    transition: width 0.3s ease-in-out;
    bottom: -3px;
    content: ' ';
    background-color: ${({ theme }) => theme.colors.default.white};
  }

  &:hover {
    color: ${({ theme, black }) =>
      black ? theme.colors.default.black : theme.colors.default.white};
    text-decoration: none;

    &:after {
      width: ${({ hoverable }) => (hoverable ? '100%' : '0')};
    }
  }
`;
/* eslint-disable indent, operator-linebreak */
export const Address = styled.div<{ bordered?: boolean }>`
  flex: 1;
  position: relative;
  ${({ bordered }) => bordered && 'border-right: 2px solid #495FBE;'}
  ${({ bordered }) => !bordered && 'padding-left: 20px;'}

  ${breakpoints.lg} {
    ${({ bordered }) =>
      bordered &&
      `
        border-right: none;
        padding-bottom: 15px;

        &:after {
            content: ' ';
            background-color: #495FBE;
            height: 2px;
            width: 30%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    `}

    ${({ bordered }) => !bordered && 'padding-left: 0; padding-top: 15px'}
  }
`;
/* eslint-enable indent, operator-linebreak */

export const SocialContainer = styled.div`
  width: 50%;

  ${breakpoints.lg} {
    width: 100%;
  }
`;

export const Social = styled.img`
  width: auto;
  height: 20px;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`;

export const Copyrights = styled.div<{ white?: boolean }>`
  background-color: ${({ theme, white }) =>
    white ? theme.colors.default.white : theme.colors.default.black};
`;

export const DealerLogo = styled.img`
  max-height: 50px;
  max-width: 220px;
  margin-top: 16px;
`;
