import { breakpoints, styled } from '@swibeco/shared';
import Caption from '../../typography/Caption';
import {
  InputCProps,
  InputSProps,
  LabelProps,
  ErrorPhoneLabelProps,
} from '../types';

export const PhoneInputContainer = styled.div<InputCProps>`
  ${({ noMargin }) => !noMargin && 'margin-bottom: 1.4rem;'};
  position: relative;
  > p {
    color: ${({ theme, errorString }) =>
      errorString !== ''
        ? theme.colors.complementary.red
        : theme.colors.default.black};
    font-size: 15px;
    max-width: 100%;
    position: absolute;
  }
  ${breakpoints.sm_max} {
    ${({ noMargin }) => !noMargin && 'margin-bottom: 0.6rem;'};
  }
`;

export const PhoneLabel = styled.label<LabelProps>`
  display: block;
  margin: 0 0 4px 0;
  line-height: 14px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? '1.272px'};
  color: ${({ theme }) => `${theme.colors.default.black}`};
  ${({ theme, isRequired }) =>
    isRequired &&
    `::after {
        content: ' *';
        color: ${theme.colors.complementary.red} `};
`;
export const SpecialPlaceHolder = styled(Caption)<
  Pick<ErrorPhoneLabelProps, 'errorString'>
>`
  width: 100%;
  color: black;
  text-align: right;
  font-size: 14px;
  pointer-events: none;
  transition: 0.1s ease all;
  position: absolute;
  top: 27px;
  right: 10px;
  opacity: 0.6;
  maring: 0;
  ${({ errorString }) =>
    errorString !== '' ? 'margin-bottom: 0;' : 'margin-bottom: 4px;'};
`;
export const TelInputContainer = styled.div<InputSProps>`
  .react-tel-input {
    .selected-flag {
      background-color: transparent;
      border: unset;
      &.invalid-number {
        background-color: transparent;
        border: unset;
      }
    }
    .flag-dropdown {
      background-color: transparent;
      border: unset;
    }
    input {
      font-size: ${({ size }) => (size ? `${size}pt` : '1em')};
      font-family: museo-sans;
      font-weight: 400;
      height: 40px;
      border-radius: 2px;
      width: 100%;
      color: ${({ theme, color }) => color || theme.colors.default.black};
      text-align: ${({ align }) => align || 'left'};
      ${({ bg, theme }) =>
        bg && `background-color: ${bg || theme.colors.primary.light}`}

      ${({ theme, state }) =>
        state &&
        theme.colors.primary.light &&
        `
      border-bottom: 2px solid ${theme.colors.primary.light};
    `};
      &.invalid-number {
        background-color: transparent;
        border: unset;
      }
      &:focus {
        ${({ theme, state }) =>
          !(state && theme.colors.primary.light) &&
          `
  `};
      }

      &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.primary.light};
        opacity: 0.5;
      }

      &::-moz-placeholder {
        color: ${({ theme }) => theme.colors.primary.light};
        opacity: 0.5;
      }

      &::placeholder {
        color: ${({ theme }) => theme.colors.primary.light};
        opacity: 0.5;
      }
    }
  }
  .flag-dropdown {
    background-color: transparent;
  }
  .arrow {
    left: 27px;
  }
`;

export const ErrorPhoneLabel = styled.p<ErrorPhoneLabelProps>`
  width: 100%;
  text-align: ${({ errorTextAlign }) => errorTextAlign || 'left'};
  color: ${({ theme, errorString }) =>
    errorString !== ''
      ? theme.colors.complementary.red
      : theme.colors.default.black};
  font-size: 15px;
  max-width: 100%;
  margin-bottom: 6px;
`;
