import React from 'react';
import { useTheme } from '@emotion/react';

type DigitalDeliveryIconProps = {
  width?: number;
  height?: number;
  color?: string;
};

const DigitalDeliveryIcon = ({
  width,
  height,
  color,
}: DigitalDeliveryIconProps) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <path
        d="M26.778 8.824c.002.065.01.129.01.195 0 3.181-2.626 5.76-5.866 5.76H.365c.64 1.872 2.436 3.222 4.556 3.222h20.27c2.655 0 4.808-2.115 4.808-4.723 0-2.061-1.346-3.81-3.22-4.454Z"
        fill="#D7E4F2"
      />
      <path
        d="M24.923 8.458a1 1 0 0 0 .79.828c1.846.38 3.186 1.947 3.186 3.774 0 2.1-1.775 3.862-4.04 3.862H5.05c-2.265 0-4.04-1.763-4.04-3.862 0-1.599 1.023-2.994 2.52-3.577a1 1 0 0 0 .63-1.052 6.22 6.22 0 0 1-.047-.75c0-3.504 2.955-6.406 6.676-6.406 2.657 0 4.934 1.486 6.01 3.615a1 1 0 0 0 1.373.426 4.68 4.68 0 0 1 2.254-.574c2.31 0 4.176 1.64 4.497 3.716Z"
        stroke={color || theme.colors.primary.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m9.173 9.309 1.59 1.533 1.589-1.533M10.762 10.843V6M7.316 13.41h7.085"
        stroke={color || theme.colors.primary.main}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DigitalDeliveryIcon;
