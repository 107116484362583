import React, { useEffect } from 'react';
import { Loader } from '@swibeco/ui';
import { getAppStoreLink, getDeviceOs } from '@swibeco/shared';
import TagManager from 'react-gtm-module';
import { AnalyticsEvents } from '@swibeco/types';
import { usePlatform } from '@swibeco/core';

const DownloadApp = () => {
  const environment = usePlatform(window);
  const storeLink = getAppStoreLink(window);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.SCAN_DOWNLOAD_APP_BANNER,
        environment,
        device_os: getDeviceOs(window),
        redirect_link: storeLink,
      },
    });

    window.location.assign(storeLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader />;
};

export default DownloadApp;
