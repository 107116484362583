import React from 'react';
import { styled, useTheme } from '@swibeco/shared';
import { ColorProps, StyledColorProps } from '@swibeco/types';

export type StyledOpenIconProps = StyledColorProps;

const OpenIconWrapper = styled.span`
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
`;

export type OpenIconProps = ColorProps;

const OpenIcon = ({ color }: OpenIconProps) => {
  const theme = useTheme();

  return (
    <OpenIconWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 19.647 19.647"
      >
        <g
          transform="translate(-1 10) rotate(-45)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="3px"
          stroke={
            theme.colors[color?.variant || 'default']?.[
              color?.variantColor || 'black'
            ]
          }
        >
          <line y1="15.404" x2="15.404" />
          <line x1="15.404" y1="15.404" />
        </g>
      </svg>
    </OpenIconWrapper>
  );
};

export default OpenIcon;
