import { styled } from '@swibeco/shared';

export const CloseWrapper = styled.div`
  position: absolute;
  right: 3px;
  bottom: 4px;
  z-index: 9;
`;

export const Header = styled.div`
  position: relative;
  height: 33px;
`;

export const Content = styled.div`
  position: relative;
`;
