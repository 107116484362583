import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteProps, useLocation } from 'react-router-dom';
import { selectors } from '@swibeco/security';

type AuthenticatedRouteProps = RouteProps;

const AuthenticatedRoute = ({ children }: AuthenticatedRouteProps) => {
  const isAuthenticated = useSelector(selectors.isAuthenticated);
  const location = useLocation();
  if (!isAuthenticated) {
    return (
      <Navigate
        to="/security/login"
        state={{
          referrer: location,
        }}
      />
    );
  }

  return children;
};

export default AuthenticatedRoute;
