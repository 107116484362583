import { styled } from '@swibeco/shared';

// This wrapper defines the main margin of the content.
// It can be reused in the core-web package.
const MainContentWrapper = styled.div`
  width: 90%;
  max-width: 1440px;
  margin: auto;
`;

export default MainContentWrapper;
