import React, { useMemo } from 'react';
import { GlobalSelectors } from '@swibeco/shared';
import { useSelector } from 'react-redux';
import { ecommerceApi } from '@swibeco/ecommerce';
import { useQuery } from '@tanstack/react-query';
import { SwipointsBanner } from './SwipointsBanner';
import { NewUserBanner } from './NewUserBanner';
import * as Styles from './Banner.styles';
import leftHill from '../../assets/images/leftHill.svg';
import rightHill from '../../assets/images/rightHill.svg';
import { BannerSkeletonLoading } from './BannerSkeletonLoading';

const Banner = () => {
  const swipointsBalance = useSelector(
    GlobalSelectors.user.getSwipointsBalance
  );
  const { data: invoicesCount, isLoading } = useQuery<number>({
    queryKey: ['invoices-count'],
    queryFn: ecommerceApi.getInvoicesCount,
  });
  const showSwipointsBanner = useMemo(
    () => swipointsBalance > 0,
    [swipointsBalance]
  );

  const hasInvoices = useMemo(
    () => Boolean(invoicesCount && invoicesCount > 0),
    [invoicesCount]
  );

  if (isLoading) return <BannerSkeletonLoading />;

  return !hasInvoices ? (
    <Styles.BannerWrapper
      id="banner-container"
      data-testid="banner"
      isSwipointBannerShown={showSwipointsBanner}
    >
      <Styles.BannerContent>
        <Styles.Row>
          <NewUserBanner showSwipointsBanner={showSwipointsBanner} />
        </Styles.Row>
      </Styles.BannerContent>
      {showSwipointsBanner && (
        <SwipointsBanner swipointsBalance={swipointsBalance} />
      )}
      <img id="left-hill" src={leftHill} alt="left hill" />
      <img id="right-hill" src={rightHill} alt="right hill" />
    </Styles.BannerWrapper>
  ) : null;
};
export default Banner;
