import React, { InputHTMLAttributes } from 'react';
import { styled } from '@swibeco/shared';
import {
  Input as RSInput,
  InputProps as RSInputProps,
  Label as RSLabel,
  LabelProps as RSLabelProps,
} from 'reactstrap';
import { ColorProps } from '@swibeco/types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  width: 50px;
  height: 31px;
  display: flex;
  position: relative;
  flex-shrink: 0;
`;

type LabelProps = RSLabelProps & ColorProps;

const Label = styled(RSLabel, {
  shouldForwardProp: (prop) => !['color'].includes(prop),
})<LabelProps>`
  margin: 0 8px;
  font-size: 17px;
  color: ${({ theme, color }) =>
    theme.colors[color?.variant || 'default'][color?.variantColor || 'strong']};
`;

const InputDesign = styled(RSInput, {
  shouldForwardProp: (prop) => prop !== 'inversed',
})<{
  inversed: boolean;
  background?: string;
}>`
  top: 0;
  margin: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ .input-switcher {
      &:before {
        transform: translateX(
          ${({ inversed }) => (inversed ? '-20px' : '20px')}
        );
      }
    }
  }

  &:checked {
    & ~ .input-switcher {
      background-color: ${({ theme, background }) =>
        background || theme.colors.primary.main};
    }
  }
`;

const Switcher = styled.span<{ inversed: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  transition: 0.3s;
  position: absolute;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.default.middle};

  &:before {
    bottom: 5px;
    content: '';
    width: 21px;
    height: 21px;
    transition: 0.3s;
    position: absolute;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.default.white};
    ${({ inversed }) => (inversed ? 'right' : 'left')}: 5px;
  }
`;

type BaseToggleProps = Omit<RSInputProps, 'type'> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export interface ToggleProps extends BaseToggleProps {
  name: string;
  background?: string;
  inversed?: boolean;
  labelUnchecked?: React.ReactNode;
  labelChecked?: React.ReactNode;
  inputRef?: React.Ref<RSInput>;
  labelProps: LabelProps;
}

const Toggle = React.forwardRef<RSInput, ToggleProps>(
  (
    {
      name,
      inversed = false,
      labelUnchecked,
      labelChecked,
      background,
      labelProps,
      ...rest
    }: ToggleProps,
    ref
  ) => {
    return (
      <Wrapper>
        {labelUnchecked && <Label {...labelProps}>{labelUnchecked}</Label>}
        <Container>
          <InputDesign
            type="checkbox"
            name={name}
            background={background}
            inversed={inversed}
            ref={ref}
            {...rest}
          />
          <Switcher className="input-switcher" inversed={inversed} />
        </Container>
        {labelChecked && <Label {...labelProps}>{labelChecked}</Label>}
      </Wrapper>
    );
  }
);

export default Toggle;
