import { Image } from "Image";
import { MappedProductVariant, ProductVariant } from "ProductVariant";
import { Subcategory } from "./SubCategory";
import { BaseDeal, TopDeal, TranslatedName, Translation } from "../Deal";
import { Brand } from "../Brand";

export enum CategoryTypeEnum {
  MENU = "menu",
  PURCHASING_POWER = "purchasingpower",
  SWIPOINTS = "swipoints",
  BESTSELLERS = "bestsellers",
  SELECTION = "selection",
  ALL = "all",
  LATESTDEALS = "latestdeals",
}
export interface Category {
  id: number;
  title: string;
  slug: string;
  icon: string;
  redirectLink?: string;
  url: string;
  subcategories: Subcategory[];
  deals: BaseDeal[];
  backgroundImage: string;
  brands: Brand[];
  type: CategoryTypeEnum;
}
export interface CategoryTranslation {
  name: string;
  slug: string;
  locale: string;
}
export interface SyliusCategory {
  id: number;
  code: string;
  description?: string;
  images: Image[];
  isHomePage: boolean;
  name: string;
  optimizedChildren: SyliusCategory[];
  optimizedVariants: ProductVariant[];
  redirectLink?: string;
  slug: string;
  translations: { [locale: string]: CategoryTranslation };
  type: CategoryTypeEnum;
}

export type MappedSyliusCategory = Omit<
  SyliusCategory,
  "optimizedChildren" | "optimizedVariants"
> & {
  optimizedChildren: MappedSyliusCategory[];
  optimizedVariants: MappedProductVariant[];
  translationsMap: CategoryTranslation;
};

/**
 * @deprecated
 * @TODO Remove this interface and use Category once the user journey is completely on React
 */
export interface CategoryV1 {
  id: number;
  url: string;
  slug: string;
  translated_name: TranslatedName;
  parent_id: number | null;
  image_url: null | string;
  image_cover_url: null;
  children: CategoryV1[];
  categories: CategoryV1[];
  menu_font: null;
  menu_color: null;
  type: CategoryTypeEnum;
  link: null;
  special_type: number;
  translation: Translation;
  thumbnail_url: null;
  is_category: boolean;
  top_deals?: TopDeal[];
}
