import React, { useContext, useReducer } from 'react';
import { checkoutReducer } from '../store';
import { user, basket, swibeco, errors } from '../store/checkoutInitialState';
import { CheckoutStore, CheckoutActions } from '../store/checkoutTypes';

export const CheckoutStoreContext = React.createContext<CheckoutStore>(
  {} as CheckoutStore
);

export const CheckoutDispatchContext = React.createContext<
  React.Dispatch<CheckoutActions>
>(
  /* istanbul ignore next */ () => {
    /* do nothing */
  }
);

export const useCheckoutContext = () => useContext(CheckoutStoreContext);
export const useCheckoutDispatch = () => useContext(CheckoutDispatchContext);

type CheckoutContextProps = {
  children: React.ReactNode;
};

const CheckoutContext = ({ children }: CheckoutContextProps) => {
  const [state, dispatch] = useReducer(checkoutReducer, {
    user,
    basket,
    swibeco,
    errors,
  });
  return (
    <CheckoutStoreContext.Provider value={state}>
      <CheckoutDispatchContext.Provider value={dispatch}>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStoreContext.Provider>
  );
};

export default CheckoutContext;
