import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import * as Styles from './DealsListSkeletonLoading.styles';

const DealCardSkeletonLoading = () => (
  <Styles.DealCardWrapper className="deal-card-wrapper">
    <div>
      <Skeleton height="150px" width="294px" />
      <Styles.LabelWrapper>
        <Skeleton height="23px" width="109px" className="mb-1" key="top" />
        <Skeleton height="23px" width="200px" key="bottom" />
      </Styles.LabelWrapper>
    </div>
    <Styles.IconsWrapper>
      <Styles.Separator />
      <Skeleton circle height="35px" width="35px" />
    </Styles.IconsWrapper>
  </Styles.DealCardWrapper>
);

export default DealCardSkeletonLoading;
