import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { styled } from '@swibeco/shared';
import camera from './images/camera.svg';
import avatarBackground from './images/avatar-background.svg';
import { Box, Img } from '@chakra-ui/react';

type DefaultAvatarProps = {
  src: string | null;
  userInfo: {
    firstName: string;
    lastName: string;
  };
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  tooltipInfo: string;
};

const BaseCSSRules = () => css`
  width: 69px;
  height: 69px;
  border-radius: 50%;
`;

const StyledAvatarBox = styled.span`
  svg {
    ${BaseCSSRules};
    border: ${({ theme }) => `1px solid ${theme.colors.default.middle}`};
    background-image: url(${avatarBackground});
  }
`;

type DefaultImgProps = {
  src: string;
};

const StyledImgAvatar = styled.img<DefaultImgProps>`
  ${BaseCSSRules};
  object-fit: cover;
`;

const DefaultAvatar = ({
  src,
  userInfo,
  onClick,
  tooltipInfo,
}: DefaultAvatarProps) => {
  const userInitials = useMemo(
    () =>
      `${userInfo.firstName.split('')[0]}${
        userInfo.lastName.split('')[0]
      }`.toUpperCase(),
    [userInfo]
  );

  return (
    <Box
      cursor="pointer"
      minW="110px"
      pos="relative"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={tooltipInfo}
      onClick={onClick}
    >
      {src ? (
        <StyledImgAvatar
          src={src}
          alt="Your personal picture"
          data-testid="personal-avatar"
        />
      ) : (
        <StyledAvatarBox data-testid="default-avatar">
          <svg>
            <text
              fill="#ffffff"
              fontSize="25"
              textAnchor="middle"
              x="32"
              y="45"
            >
              {userInitials}
            </text>
          </svg>
        </StyledAvatarBox>
      )}
      <Img
        w="40px"
        pos="absolute"
        right="10px"
        bottom="-20px"
        src={camera}
        alt={tooltipInfo}
      />
    </Box>
  );
};

export default DefaultAvatar;
