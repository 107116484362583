enum DeviceOs {
  Ios = 'ios',
  Android = 'android',
  Desktop = 'desktop',
}

const getUserAgent = (window: Record<string, any>): string => {
  const navigator = window?.navigator;
  const userAgent = navigator?.userAgent?.toLowerCase();

  return userAgent;
};

export const isAndroidWebView = (window: Record<string, any>): boolean => {
  const userAgent = getUserAgent(window);

  return !!userAgent.includes('wv');
};

export const isIosWebView = (window: Record<string, any>): boolean => {
  const userAgent = getUserAgent(window);

  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios ? !window?.navigator?.standalone && !safari : false;
};

export const isNativeIos = (window: Record<string, any>): boolean => {
  const userAgent = getUserAgent(window);

  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios ?? false;
};

export const isNativeAndroid = (window: Record<string, any>): boolean => {
  const userAgent = getUserAgent(window);

  const ios = /android/.test(userAgent);

  return ios ?? false;
};

export const isNativeMobile = (window: Record<string, any>): boolean => {
  const userAgent = getUserAgent(window);

  const native = /android|iphone|ipod|ipad/.test(userAgent);

  return native ?? false;
};

export const getDeviceOs = (window: Record<string, any>) => {
  if (isNativeIos(window)) return DeviceOs.Ios;
  if (isNativeAndroid(window)) return DeviceOs.Android;
  return DeviceOs.Desktop;
};
