import React, { useCallback, useMemo, useRef, useState } from 'react';
import StepButton from './StepButton';

type StepperContextProps = {
  numberOfSteps: number;
  currentStep: number;
  next: () => void;
  goTo: (step: number) => void;
  isStepValid: boolean;
  setStepValid: (valid: boolean, step: number) => void;
};

export const StepperContext = React.createContext<StepperContextProps>(
  {} as StepperContextProps
);

type StepperProps = {
  onFinish: () => void;
  buttonContent: {
    previous: string;
    next: string;
    validate: string;
    validationError: string;
  };
  errors?: {
    errorState: boolean;
    details: string;
  };
  children: React.ReactNode;
};

const Stepper = ({
  children,
  onFinish,
  buttonContent,
  errors,
}: StepperProps) => {
  const numberOfSteps = useMemo(
    () => Math.max(React.Children.toArray(children).length, 0),
    [children]
  );

  const [stepValidity, setStepValidity] = useState<boolean[]>(
    new Array<boolean>().fill(false, 0, numberOfSteps)
  );
  const [currentStep, setCurrentStep] = useState(0);

  // Creation of a new element to scroll smoothly to the parent section
  const sectionEl = useRef<HTMLElement>(null);

  const next = useCallback(() => {
    if (currentStep === numberOfSteps - 1) {
      onFinish();
    } else {
      setCurrentStep(currentStep + 1);
      sectionEl?.current?.scrollIntoView();
    }
  }, [currentStep, numberOfSteps, onFinish]);

  const goTo = useCallback(
    (step: number) => {
      /* istanbul ignore else */
      if (
        step - 1 !==
        currentStep // it is not the same step
      ) {
        setCurrentStep(step - 1);
      }
    },
    [currentStep]
  );

  const setStepValid = useCallback(
    (valid: boolean, step: number) => {
      /* istanbul ignore else */
      if (stepValidity[step - 1] !== valid) {
        const newSteps = [...stepValidity];
        newSteps[step - 1] = valid;
        setStepValidity(newSteps);
      }
    },
    [stepValidity]
  );

  const contextValues = useMemo(
    () => ({
      numberOfSteps,
      currentStep: currentStep + 1,
      next,
      goTo,
      isStepValid: stepValidity[currentStep],
      setStepValid,
    }),
    [currentStep, next, numberOfSteps, goTo, stepValidity, setStepValid]
  );

  return (
    <section ref={sectionEl}>
      <StepperContext.Provider value={contextValues}>
        {children}
        <StepButton
          buttonContent={buttonContent}
          step={currentStep}
          errors={errors}
        />
      </StepperContext.Provider>
    </section>
  );
};

export default Stepper;
