import React from 'react';
import { BaseDeal, EnumDealOfferType } from '@swibeco/types';
import { Button } from '@swibeco/ui';
import { ButtonProps as RSButtonProps } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@swibeco/shared';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NewTabIcon } from '../../assets/images/new-tab.svg';
import { ReactComponent as CartIcon } from '../../assets/images/cart.svg';

type DealItemButtonProps = Pick<BaseDeal, 'url' | 'offerType' | 'promoLink'> &
  Partial<Pick<BaseDeal, 'isInternal' | 'slug'>> & {
    onAddItem: () => void;
    onOpenVoucherModal: () => void;
    disabled?: boolean;
    dealRoute: string;
  };

export const ButtonWithHover = ({
  children,
  disabled,
  ...rest
}: RSButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      thin
      color="primary"
      lightColor={false}
      disabled={disabled}
      disabledColor={theme.colors.default.white}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const DealItemButton = ({
  offerType,
  promoLink,
  onAddItem,
  onOpenVoucherModal,
  disabled,
  isInternal,
  dealRoute,
}: DealItemButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canBeAddedtoCart = (offerType: EnumDealOfferType) =>
    offerType === EnumDealOfferType.Physical ||
    offerType === EnumDealOfferType.Voucher;
  if (isInternal) {
    return canBeAddedtoCart(offerType) ? (
      <ButtonWithHover
        data-testid="deal-item-button-default"
        disabled={disabled}
        onClick={onAddItem}
      >
        <CartIcon />
      </ButtonWithHover>
    ) : (
      <ButtonWithHover
        data-testid="deal-item-button-default"
        disabled={disabled}
        onClick={() => navigate(dealRoute)}
      >
        {t('core.deal_item.button.details')}
      </ButtonWithHover>
    );
  }

  switch (offerType) {
    case EnumDealOfferType.PromoCode:
      return (
        <ButtonWithHover disabled={disabled} onClick={onOpenVoucherModal}>
          {t('core.deal_item.button.code')}
        </ButtonWithHover>
      );
    case EnumDealOfferType.PromoLink: {
      if (!promoLink) {
        return (
          <ButtonWithHover
            data-testid="deal-item-button-default"
            disabled={disabled}
            onClick={() => navigate(dealRoute)}
          >
            {t('core.deal_item.button.details')}
          </ButtonWithHover>
        );
      }
      return (
        <ButtonWithHover
          data-testid="deal-item-button-promo-link"
          disabled={disabled}
          onClick={() => window.open(promoLink, '_blank', 'noopener')}
        >
          <NewTabIcon />
        </ButtonWithHover>
      );
    }
    case EnumDealOfferType.Physical:
    case EnumDealOfferType.Voucher:
      return (
        <ButtonWithHover
          data-testid="deal-item-button-add-to-cart"
          disabled={disabled}
          onClick={onAddItem}
        >
          <CartIcon />
        </ButtonWithHover>
      );
    default:
      return (
        <ButtonWithHover
          data-testid="deal-item-button-default"
          disabled={disabled}
          onClick={() => navigate(dealRoute)}
        >
          {t('core.deal_item.button.details')}
        </ButtonWithHover>
      );
  }
};
