import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as IconSwipointsTutorial } from '../../../assets/images/swipoints-tutorial.svg';
import { TutorialStepper } from '../Stepper';
import TutorialContext from '../Stepper/StepperContext';
import useTutorial from '../hooks/useTutorial';

type TutorialProps = {
  isOpen: boolean;
  onClose: () => void;
  activeStep: number;
  setActiveStep: (index: number) => void;
};
const totalSegments = 4;

const SegmentedProgressBar = ({ activeIndex }: { activeIndex: number }) => {
  const bgColor = (index: number) =>
    index === activeIndex ? 'white' : 'blue.200';

  return (
    <Flex width="12.5rem" p={1} borderRadius="md">
      {Array.from({ length: totalSegments }, (_, index) => (
        <Box
          data-testid={`tutorial-${index}-progress-bar`}
          key={index}
          flex="1"
          height="6px"
          w="46px"
          bg={bgColor(index)}
          mx={1}
          borderRadius="md"
        />
      ))}
    </Flex>
  );
};

const Tutorial = ({
  isOpen,
  onClose,
  activeStep,
  setActiveStep,
}: TutorialProps) => {
  const { t } = useTranslation();
  const { welcomeGift, giftCashAmount } = useTutorial();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'xs',
        lg: '4xl',
      }}
    >
      <ModalOverlay />
      <ModalContent
        data-testid="tutorial-modal"
        top={{
          base: '0',
          lg: '10%',
        }}
      >
        {activeStep !== 3 && (
          <Flex
            m={4}
            backgroundColor="secondary.decorationBlue"
            color="white"
            h={{
              base: '90px',
              md: '47px',
            }}
            alignItems="center"
            w="90%"
            borderRadius={4}
          >
            {welcomeGift > 0 && (
              <Box
                h={{
                  base: '200px',
                  md: '80px',
                }}
                w={{
                  base: '50px',
                  md: '40px',
                }}
                position="absolute"
              >
                <IconSwipointsTutorial width="100%" height="100%" />
              </Box>
            )}
            <Flex
              direction={{
                base: 'column',
                md: 'row',
              }}
              alignItems="center"
              justifyContent={{
                base: 'baseline',
                md: 'space-between',
              }}
              pos="relative"
              padding="2"
              w="100%"
            >
              <Text data-testid="tutorial-header" m="0">
                {welcomeGift > 0 ? (
                  <Trans
                    i18nKey="core.ecommerce.homepage.tutorial.modal.reward"
                    values={{ cashAmount: giftCashAmount }}
                  />
                ) : (
                  t('core.ecommerce.homepage.tutorial.modal.no_reward')
                )}
              </Text>
              <SegmentedProgressBar activeIndex={activeStep} />
            </Flex>
          </Flex>
        )}
        <ModalCloseButton
          data-testid="tutorial-close-button"
          pos="absolute"
          top="30px"
          right="30px"
          color={{
            base: 'default.white',
            lg: 'default.black',
          }}
        />
        <ModalBody>
          <TutorialContext>
            <TutorialStepper
              onClose={onClose}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </TutorialContext>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Tutorial;
