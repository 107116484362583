import React from 'react';
import { styled } from '@swibeco/shared';

type QuestionMarkProps = {
  id?: string;
};

const QuestionMarkWrapper = styled.span`
  width: 20px;
  cursor: pointer;
`;

const QuestionMark = ({ id = 'questionMark' }: QuestionMarkProps) => (
  <QuestionMarkWrapper id={id}>
    <svg
      id="Tooltip_light"
      data-name="Tooltip/light"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <circle
        id="Ellipse_33"
        data-name="Ellipse 33"
        cx="10"
        cy="10"
        r="10"
        fill="#f9f9f9"
      />
      <text
        id="_"
        data-name="?"
        transform="translate(6.933 15)"
        fill="#707070"
        fontSize="13"
        fontFamily="museo-sans"
        fontWeight="700"
      >
        <tspan x="0" y="0">
          ?
        </tspan>
      </text>
    </svg>
  </QuestionMarkWrapper>
);

export default QuestionMark;
