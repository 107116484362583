import React from 'react';
import { InputProps, InputGroup } from 'reactstrap';
import * as Styles from './styles/Slider.styles';

export type RangeInputProps = Omit<InputProps, 'type'> & {
  bubble?: number;
};

const Slider = React.forwardRef<HTMLInputElement, RangeInputProps>(
  ({ min, max, formatCallback, value, bubble, ...rest }, ref) => (
    <Styles.BubbleWrapper value={bubble}>
      <InputGroup>
        {typeof min !== 'undefined' && (
          <Styles.MinMax>{formatCallback(min)}</Styles.MinMax>
        )}
        <Styles.InputDesign
          type="range"
          value={value.length === 0 ? '0' : value}
          min={min}
          max={max}
          {...rest}
          innerRef={ref}
        />
        {typeof max !== 'undefined' && (
          <Styles.MinMax max>{formatCallback(max)}</Styles.MinMax>
        )}
      </InputGroup>
    </Styles.BubbleWrapper>
  )
);

Slider.displayName = 'Slider';

export default Slider;
