import React from 'react';
import { EnumProductType, Product } from '@swibeco/types';
import {
  isProductPurchaseable,
  useColorVariant,
  useNumberFormatter,
} from '@swibeco/shared';
import { Text } from '@swibeco/ui';
import { Flex, FlexProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CashbackIcon } from '../../assets/images/cashback_icon.svg';
import * as Styles from './ProductItem.styles';

const ProductPrice = ({
  product,
  ...rest
}: { product: Product } & FlexProps) => {
  const { t } = useTranslation();
  const formatter = useNumberFormatter();
  const mainColorVariant = useColorVariant('primary', 'main');

  const purchasableProduct = isProductPurchaseable(product);
  const hasDiscount = product.variantsMaximumDiscount > 0;
  const hasCashback = product.defaultVariantData.cashbackAmount > 0;
  const isFreeVoucher =
    product.type === EnumProductType.Voucher &&
    product.variantsMinimumPrice === 0;

  const needsDataTestIdOnParent =
    !isFreeVoucher && hasDiscount && product.type !== EnumProductType.Voucher;

  return purchasableProduct ? (
    <Flex
      gap="8px"
      alignItems="center"
      flexWrap="wrap"
      {...rest}
      data-testid={
        needsDataTestIdOnParent ? 'product-price-and-discount' : null
      }
    >
      {(() => {
        if (isFreeVoucher) {
          return (
            <Styles.ProductPrice
              className="pr-1"
              data-testid="product-price"
              color={mainColorVariant}
            >
              {t('core.ecommerce.basketpage.free')}
            </Styles.ProductPrice>
          );
        }
        if (hasDiscount) {
          if (product.type === EnumProductType.Voucher) {
            return (
              <Text color="primary.main" bold data-testid="product-discount">
                {`${product.variantsMaximumDiscount.toFixed(0)}%`}{' '}
                {t('core.product_item.discount')}
              </Text>
            );
          }
          return (
            <>
              <Text color="primary.main" bold>
                {product.isConfigurable && t('core.product_item.from')}{' '}
                {formatter(product.variantsMinimumPrice / 100)}
              </Text>
              <Text
                border="1px solid"
                borderColor="primary.main"
                borderRadius="15px"
                padding="0 8px"
                data-testid="product-discount"
                color="primary.main"
                textAlign="center"
              >
                {product.isConfigurable && t('core.product_item.up_to')}{' '}
                {t('core.hyphen')}
                {`${product?.variantsMaximumDiscount.toFixed(0)}%`}
              </Text>
            </>
          );
        }
        if (hasCashback) {
          if (product.type === EnumProductType.Voucher) {
            return (
              <Flex gap="8px" alignItems="center">
                <CashbackIcon width="20px" height="20px" />
                <Text
                  color="primary.main"
                  bold
                  data-testid="product-cashback-percentage"
                >
                  {`${(
                    (product.defaultVariantData.cashbackAmount /
                      product.defaultVariantData.price) *
                    100
                  ).toFixed(0)}%`}{' '}
                  {t('core.ecommerce.cashback')}
                </Text>
              </Flex>
            );
          }
          // not treated yet, does not exist in the backoffice @Hala said so
          return <div />;
        }
        return (
          <Text
            data-testid="product-discount"
            color="primary.main"
            // w="min-content"
            bold
          >
            {product.isConfigurable && t('From')}{' '}
            {`${formatter(product.variantsMinimumPrice / 100)}`}
          </Text>
        );
      })()}
    </Flex>
  ) : (
    <div
      className="d-flex flex-wrap text-wrap align-items-center"
      data-testid="deal-final-price"
    >
      <Styles.ProductPrice
        className="pr-1"
        data-testid="product-price"
        color={mainColorVariant}
        noOfLines={1}
        textOverflow="ellipsis"
      >
        {product?.defaultVariantData?.freeTextDiscount}
      </Styles.ProductPrice>
    </div>
  );
};

export default ProductPrice;
