import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './ChatButton.styles';

const ChatButton = () => {
  const { t } = useTranslation();
  const isChatVisible = useMemo(() => !!window.$zoho, []);

  const openChat = () => {
    window.$zoho.salesiq.floatwindow.visible('show');
  };

  return isChatVisible ? (
    <Styles.ChatButton onClick={openChat}>
      <Styles.ChatButtonTitle>
        <>{t('core.footer.chatwidget.status')}</>
      </Styles.ChatButtonTitle>
      <Styles.ChatButtonText>
        <>{t('core.footer.chatwidget.call_to_action')}</>
      </Styles.ChatButtonText>
    </Styles.ChatButton>
  ) : null;
};

export default ChatButton;
