import React from 'react';
import { breakpoints, styled } from '@swibeco/shared';
import { ColorVariants } from '@swibeco/types';

export type InfoIconProps = {
  sm?: boolean;
  variant: ColorVariants;
  size?: string;
};

const Icon = styled.span<InfoIconProps>`
  width: ${({ size }) => size || '20px'};
  height: ${({ size }) => size || '20px'};
  font-size: 14px;
  color: ${({ theme, variant }) => {
    const variantMapping: { [key in ColorVariants]: string } = {
      [ColorVariants.Info]: theme.colors.default.white,
      [ColorVariants.Danger]: theme.colors.default.white,
      [ColorVariants.Success]: theme.colors.default.white,
      [ColorVariants.Warning]: theme.colors.default.black,
    };
    return variantMapping[variant];
  }};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  background-color: ${({ theme, variant }) => {
    const variantMapping: { [key in ColorVariants]: string } = {
      [ColorVariants.Info]: theme.colors.primary.main,
      [ColorVariants.Danger]: theme.colors.secondary.main,
      [ColorVariants.Success]: theme.colors.complementary.light,
      [ColorVariants.Warning]: theme.colors.secondary.decorationYellow,
    };

    return variantMapping[variant];
  }};

  ${breakpoints.sm} {
    width: ${({ sm, size }) => (size || sm ? '25px' : '32px')};
    height: ${({ sm, size }) => (size || sm ? '25px' : '32px')};
    font-size: ${({ sm }) => (sm ? '14px' : '17px')};
  }
`;

const InfoIcon = ({ sm, variant, size }: InfoIconProps) => (
  <Icon sm={sm} variant={variant} size={size} data-testid="info-icon">
    i
  </Icon>
);

export default InfoIcon;
