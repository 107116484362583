import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { Text } from '@swibeco/ui';
import { ReactComponent as CashbackIcon } from '../../assets/images/cashback_icon.svg';

type CashbackChipProps = {
  value: number;
  childrenOnRight?: boolean;
  children?: React.ReactNode;
} & FlexProps;

export default function CashbackChip({
  value,
  children,
  childrenOnRight = true,
  ...rest
}: CashbackChipProps) {
  if (!value) return null;
  return (
    <Flex
      alignItems="center"
      gap="4px"
      borderWidth="1px"
      borderColor="primary.main"
      borderRadius="15px"
      padding="2px 6px"
      {...rest}
    >
      <CashbackIcon width="20px" height="20px" />

      {childrenOnRight ? (
        <Text color="primary.main">
          {value}% {children}
        </Text>
      ) : (
        <Text color="primary.main">
          {children} {value}%
        </Text>
      )}
    </Flex>
  );
}
