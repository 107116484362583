import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export enum FAQDictionaryRecords {
  Swibeco = 'swibeco',
  DiscountOffer = 'discount_offer',
  PaymentMethods = 'payment_methods',
  Swipoints = 'swipoints',
  SwipointsBenefits = 'swipoints_benefits',
  SwipointsAcquire = 'swipoints_acquire',
}
export const useFaqDictionary = () => {
  const { t } = useTranslation();

  const faqDictionary = useMemo(
    () => ({
      [FAQDictionaryRecords.Swibeco]: {
        question: t(
          'core.ecommerce.universe.useful_information.swibeco.question'
        ),
        answer: t('core.ecommerce.universe.useful_information.swibeco.answer'),
        value: FAQDictionaryRecords.Swibeco,
      },
      [FAQDictionaryRecords.DiscountOffer]: {
        question: t(
          'core.ecommerce.universe.useful_information.discount_offer.question'
        ),
        answer: t(
          'core.ecommerce.universe.useful_information.discount_offer.answer'
        ),
        value: FAQDictionaryRecords.DiscountOffer,
      },
      [FAQDictionaryRecords.PaymentMethods]: {
        question: t(
          'core.ecommerce.universe.useful_information.payment_methods.question'
        ),
        answer: t(
          'core.ecommerce.universe.useful_information.payment_methods.answer'
        ),
        value: FAQDictionaryRecords.PaymentMethods,
      },
      [FAQDictionaryRecords.Swipoints]: {
        question: t(
          'core.ecommerce.universe.useful_information.swipoints.question'
        ),
        answer: t(
          'core.ecommerce.universe.useful_information.swipoints.answer'
        ),
        value: FAQDictionaryRecords.Swipoints,
      },
      [FAQDictionaryRecords.SwipointsBenefits]: {
        question: t(
          'core.ecommerce.universe.useful_information.swipoints_benefits.question'
        ),
        answer: t(
          'core.ecommerce.universe.useful_information.swipoints_benefits.answer'
        ),
        value: FAQDictionaryRecords.SwipointsBenefits,
      },
      [FAQDictionaryRecords.SwipointsAcquire]: {
        question: t(
          'core.ecommerce.universe.useful_information.swipoints_acquire.question'
        ),
        answer: t(
          'core.ecommerce.universe.useful_information.swipoints_acquire.answer'
        ),
        value: FAQDictionaryRecords.SwipointsAcquire,
      },
      pick(subset: FAQDictionaryRecords[]) {
        return subset.map((key) => this[key]);
      },
    }),
    [t]
  );

  return { faqDictionary };
};
