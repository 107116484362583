import { Divider, Flex } from '@chakra-ui/react';
import {
  selectors,
  useIsMobile,
  useLanguageSwitcher,
  usePlatform,
} from '@swibeco/core';
import { GlobalSelectors } from '@swibeco/shared';
import { AnalyticsEvents } from '@swibeco/types';
import { Legals, Text } from '@swibeco/ui';
import React from 'react';
import TagManager from 'react-gtm-module';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useCurrentCompany } from '@swibeco/security';
import facebookIcon from '../../assets/images/facebook.svg';
import instagramIcon from '../../assets/images/instagram.svg';
import linkedinIcon from '../../assets/images/linkedin.svg';
import twitterIcon from '../../assets/images/twitter.svg';
import { ReactComponent as PoweredByAxa } from '../../assets/logos/powered_by_axa.svg';
import ChatButton from '../ChatButton/ChatButton';
import * as Styles from './Footer.styles';

const showDealerDetails = (
  isDealerAxa: boolean,
  dealerLogo: string | null | undefined
): boolean => isDealerAxa || (dealerLogo !== null && dealerLogo !== '');

interface FooterProps {
  showLogoTitle?: boolean;
  whiteCopyright?: boolean;
}
const Footer: React.FC<FooterProps> = ({
  showLogoTitle = true,
  whiteCopyright,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isVisible = useSelector(selectors.isFooterVisible);
  const isDealerAxa = useSelector(GlobalSelectors.company.isDealerAxa);
  const { locales, setLocale, currentLocale } = useLanguageSwitcher();
  const environment = usePlatform(window);
  const changeLanguage = (locale: string) => {
    setLocale(locale);
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.LANGUAGE_CHANGED,
        environment,
        language: locale.toUpperCase(),
      },
    });
  };
  const { company } = useCurrentCompany();
  const dealerLogo = company?.theme.dealer_logo;
  const shouldShowDealerDetails = showDealerDetails(isDealerAxa, dealerLogo);

  return isVisible ? (
    <Styles.Wrapper className="pt-5" data-testid="footer">
      <Row className="offset-1 col-10 px-0">
        <Col xs={12} lg={{ size: 2 }} className="pl-0">
          <div className="d-flex flex-column align-items-center align-items-lg-start">
            {showLogoTitle && !shouldShowDealerDetails && (
              <Legals color="default.white">
                {t('core.footer.logo.title')}
              </Legals>
            )}
            <Styles.Logo
              src={`${process.env.PUBLIC_URL}/static/img/placeholders/logo-swibeco-light.svg`}
              alt="swibeco-logo"
              className="my-1"
            />
            {isDealerAxa && <PoweredByAxa className="mb-1" />}
            {dealerLogo && (
              <Styles.DealerLogo src={dealerLogo} alt="dealer-logo" />
            )}
          </div>
        </Col>
        {isMobile && (
          <Row className="col-12 px-0">
            <ChatButton />
          </Row>
        )}
        <Col xs={{ size: 5, offset: 1 }} lg={{ size: 2, offset: 1 }}>
          <Styles.SectionText
            hoverable
            component="a"
            href={t('core.footer.informations.about.link')}
            target="_blank"
            className="mb-3"
          >
            {t('core.footer.informations.about')}
          </Styles.SectionText>
          <br />
          <Styles.SectionText
            hoverable
            component="a"
            href={t('core.footer.informations.help.link')}
            target="_blank"
            className="mb-3"
          >
            {t('core.footer.informations.help')}
          </Styles.SectionText>
          <br />
          <Styles.SectionText
            hoverable
            component="a"
            href={t('core.footer.informations.privacy.link')}
            target="_blank"
            className="mb-3"
          >
            {t('core.footer.informations.privacy')}
          </Styles.SectionText>
          <br />
          <Styles.SectionText
            hoverable
            component="a"
            href={t('core.footer.informations.terms.link')}
            target="_blank"
          >
            {t('core.footer.informations.terms')}
          </Styles.SectionText>
        </Col>

        <Col xs={6} lg={2}>
          <Styles.SectionText>Swibeco SA</Styles.SectionText>
          <br />
          <Styles.SectionText href="mailto:info@swibeco.ch" as="a">
            info@swibeco.ch
          </Styles.SectionText>
          <br />
          <Styles.SectionText href="tel:+41413604343" as="a">
            +41 41 360 43 43
          </Styles.SectionText>
          <br />
          <Styles.SectionText
            href="http://www.swibeco.ch"
            target="_blank"
            as="a"
          >
            www.swibeco.ch
          </Styles.SectionText>
          <br />
        </Col>
        <Col
          xs={{ size: 11, offset: 1 }}
          lg={{ size: 3, offset: 0 }}
          className="mt-5 mt-lg-0"
        >
          <div className="d-flex flex-lg-column">
            <Styles.Address bordered>
              <Styles.SectionText>
                <Trans i18nKey="core.footer.address.lausanne" />
              </Styles.SectionText>
            </Styles.Address>
            <Styles.Address>
              <Styles.SectionText>
                <Trans i18nKey="core.footer.address.zurich" />
              </Styles.SectionText>
            </Styles.Address>
          </div>
        </Col>
        <Col
          xs={12}
          lg={2}
          className="mt-5 mt-lg-0 d-flex flex-column align-items-left"
        >
          <Styles.SocialContainer className="d-flex justify-content-between justify-content-lg-left mt-2">
            <a
              href="https://www.facebook.com/swibeco/"
              target="_blank"
              rel="noreferrer"
            >
              <Styles.Social src={facebookIcon} alt="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/swibeco-ag/"
              target="_blank"
              rel="noreferrer"
            >
              <Styles.Social src={linkedinIcon} alt="linkedin" />
            </a>
            <a
              href="https://www.instagram.com/swibeco/"
              target="_blank"
              rel="noreferrer"
            >
              <Styles.Social src={instagramIcon} alt="instagram" />
            </a>
            <a
              href="https://twitter.com/swibeco"
              target="_blank"
              rel="noreferrer"
            >
              <Styles.Social src={twitterIcon} alt="twitter" />
            </a>
          </Styles.SocialContainer>
          <Divider border="1px solid" borderColor="#495FBE" w={5} />
          <Flex gap={4}>
            {locales.map((mappedLocale) => (
              <Text
                key={mappedLocale}
                color="default.light"
                _hover={{ textDecor: 'underline' }}
                opacity={mappedLocale === currentLocale ? 1 : 0.6}
                onClick={() => changeLanguage(mappedLocale)}
                cursor="pointer"
              >
                <span>{mappedLocale.toUpperCase()}</span>
              </Text>
            ))}
          </Flex>
          {!isMobile && <ChatButton />}
        </Col>
      </Row>
      <Styles.Copyrights
        white={whiteCopyright}
        className="mt-5 py-3 d-flex justify-content-center"
      >
        <Styles.SectionText black={whiteCopyright}>
          {t('core.footer.copyright')}
        </Styles.SectionText>
      </Styles.Copyrights>
    </Styles.Wrapper>
  ) : null;
};

export default Footer;
