import { AxiosError } from 'axios';

export const handleServerError = (error: AxiosError) => {
  if (error.response) {
    const { status, data } = error.response;
    const { violations } = data as { violations: { message: string }[] };
    const { message } =
      violations.length > 0 ? violations[0] : { message: undefined };
    if (status === 401) {
      return {
        status,
        message: 'Unauthorized',
      };
    }
    if (status === 403) {
      return {
        status,
        message: 'Forbidden',
      };
    }
    if (status === 404) {
      return {
        status,
        message: 'Not found',
      };
    }
    if (status === 500) {
      return {
        status,
        message: 'Something went wrong',
      };
    }
    if (status === 400) {
      return {
        status,
        message,
      };
    }
    if (status === 422) {
      return {
        status,
        message,
      };
    }
    return {
      status,
      message: 'Something went wrong',
    };
  }
  return {
    status: 500,
    message: 'Something went wrong',
  };
};
