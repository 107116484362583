import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { SecurityRouter } from '@swibeco/security-web';
import { CoreRouter, ErrorPage, PageViewDataLayerHOC } from '@swibeco/core-web';
import { Loader } from '@swibeco/ui';
import { useRedirectAfterLogin } from '@swibeco/security';
import { ERROR_PAGE, LOADING_PAGE_AFTER_PAYMENT } from '@swibeco/shared';
import { DownloadApp } from '@swibeco/shared-web';

const Router = () => {
  useRedirectAfterLogin();
  return (
    <PageViewDataLayerHOC>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* <Route
            path="/"
            element={
              <span>
                {(() => {
                  window.location.href = '/';

                  return null;
                })()}
              </span>
            }
          /> */}
          {/* If the path changes, go change the path in the sidebar menu as well */}
          <Route path="/core/*" element={<CoreRouter />} />
          <Route path="/security/*" element={<SecurityRouter />} />
          <Route path="/download-app" element={<DownloadApp />} />
          <Route
            path={`${LOADING_PAGE_AFTER_PAYMENT}/:checkoutResult?`}
            element={<Loader />}
          />
          <Route path={'*'} element={<Navigate to={ERROR_PAGE} />} />
          <Route path={'/error'} element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </PageViewDataLayerHOC>
  );
};

export default Router;
