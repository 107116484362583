/* istanbul ignore file */

export const breakpointsSizes = {
  sm: '576px',
  md: '768px',
  lg: '992px', // breakpoints above this are considered desktop
  xl: '1200px',
  xxl: '1400px',
};

const sm = `@media (min-width: ${breakpointsSizes.sm})`;
const md = `@media (min-width: ${breakpointsSizes.md})`;
const lg = `@media (min-width: ${breakpointsSizes.lg})`;
const xl = `@media (min-width: ${breakpointsSizes.xl})`;
const xxl = `@media (min-width: ${breakpointsSizes.xxl})`;

const sm_max = `@media (max-width: ${breakpointsSizes.sm})`;
const md_max = `@media (max-width: ${breakpointsSizes.md})`;
const lg_max = `@media (max-width: ${breakpointsSizes.lg})`;
const xl_max = `@media (max-width: ${breakpointsSizes.xl})`;
const xxl_max = `@media (max-width: ${breakpointsSizes.xxl})`;

const breakpoints = {
  sm,
  md,
  lg,
  xl,
  xxl,
  sm_max,
  md_max,
  lg_max,
  xl_max,
  xxl_max,
};

export { breakpoints };
