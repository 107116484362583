import { BaseDeal, AnalyticsContext, ProductPageDeal } from '@swibeco/types';

export const mapDealToAnalytics =
  (context: AnalyticsContext) =>
  (deal: BaseDeal | ProductPageDeal, index: number) => ({
    item_name: deal.shortTitle,
    item_id: deal.id,
    price: deal.finalPrice,
    item_brand: deal.branding?.name,
    item_advantage: deal.discount,
    index,
    quantity: context.quantity,
    item_list_name: context.listName,
    item_list_id: context.listId,
    item_category: context.category,
  });
