import React from 'react';
import { useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ECOMMERCE_HOME, GlobalSelectors } from '@swibeco/shared';
import { Flex, Image } from '@chakra-ui/react';
import { Trans } from 'react-i18next';
import { Text } from '@swibeco/ui';
import axaLogoImg from '../../../assets/images/axa-logo.png';

const AxaHeaderBanner = () => {
  const isHomePage = useMatch(ECOMMERCE_HOME);
  const isDealerAxa = useSelector(GlobalSelectors.company.isDealerAxa);

  const shouldDisplay = isDealerAxa && isHomePage;

  if (!shouldDisplay) return null;

  return (
    <Flex
      data-testid="header-brought-by-axa"
      align="center"
      justify="center"
      bgColor="#EBEDFF"
      height="40px"
    >
      <Text variant="legal" color="default.black">
        <Trans
          i18nKey="core.header.axa.brought_by_axa"
          components={{
            AxaLogo: (
              <Image
                marginX="6px"
                boxSize="33px"
                display="inline"
                src={axaLogoImg}
                alt="Axa Logo"
              />
            ),
          }}
        />
      </Text>
    </Flex>
  );
};

export default AxaHeaderBanner;
