import { Card, CardBody, Flex, useToken } from '@chakra-ui/react';
import { Link, Text } from '@swibeco/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconHelp } from '../../../../../assets/images/help.svg';
import { ReactComponent as IconLiveChat } from '../../../../../assets/images/livechat.svg';
import { ReactComponent as IconNewTab } from '../../../../../assets/images/new-tab.svg';

type HelpCenterProps = {
  closeBottomSheet: () => void;
};
const HelpCenter = ({ closeBottomSheet }: HelpCenterProps) => {
  const { t } = useTranslation();
  const shadowColor = useToken('colors', 'shadow.primary');
  const openChat = () => {
    closeBottomSheet();
    window?.$zoho?.salesiq.floatwindow.visible('show');
  };
  return (
    <Flex direction="column" gap="8px">
      <Text variant="default-important" bold>
        {t('core.header.userprofile.menutitle.helpcenter')}
      </Text>

      {/* FAQ Card */}
      <Link
        href="https://www.swibeco.ch/contact/#faq"
        target="_blank"
        style={{ textDecoration: 'none', display: 'block' }}
        rel="noreferrer"
      >
        <Card
          boxShadow={`0px 3px 6px 0px ${shadowColor}`}
          _hover={{
            backgroundColor: 'default.main',
            cursor: 'pointer',
          }}
        >
          <CardBody p="16px">
            <Flex
              gap="8px"
              align="center"
              color="unset"
              fontWeight="normal"
              _hover={{
                color: 'default.black',
              }}
            >
              <IconHelp />
              <Text m="0">{t('core.header.userprofile.menu.help')}</Text>
              <IconNewTab />
            </Flex>
          </CardBody>
        </Card>
      </Link>

      {/* Live Chat Card */}
      <Card
        boxShadow={`0px 3px 6px 0px ${shadowColor}`}
        _hover={{
          backgroundColor: 'default.main',
          cursor: 'pointer',
        }}
        onClick={openChat} // Move the onClick event to the Card
      >
        <CardBody p="16px">
          <Flex
            gap="8px"
            align="center"
            color="unset"
            fontWeight="normal"
            _hover={{
              color: 'default.black',
              textDecoration: 'none',
            }}
          >
            <IconLiveChat />
            <Text m="0">{t('core.userpanel.helpmenu.chat')}</Text>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};
export default HelpCenter;
