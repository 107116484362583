import React from 'react';
import { styled } from '@swibeco/shared';

const Wrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  position: absolute;

  svg {
    g {
      rect {
        fill: ${({ theme }) => theme.colors.primary.main};
      }

      path {
        &:first-of-type {
          filter: brightness(85%);
          fill: ${({ theme }) => theme.colors.primary.main};
        }

        &:last-child {
          fill: white;
          filter: opacity(40%);
        }
      }
    }
  }
`;

const SwipointsWidgetBackground = () => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 355 500">
      <defs>
        <clipPath id="mySwipointsWidgetBackground">
          <rect
            width="355"
            height="212"
            fill="#006cff"
            data-name="Rectangle 579"
            rx="3"
            transform="translate(262 1927)"
          />
        </clipPath>
      </defs>
      <g data-name="Groupe 2809" transform="translate(0 -93)">
        <rect
          width="355"
          height="500"
          fill="#006cff"
          data-name="Rectangle 490"
          rx="3"
          transform="translate(0 93)"
        />
        <g
          clipPath="url(#mySwipointsWidgetBackground)"
          data-name="Groupe de masques 1180"
          transform="translate(-262 -1834)"
        >
          <path
            fill="#0067ff"
            d="M250.91 143.795c-20.789 31.61-74.277 46.079-114.634 25.525s-15.555-41.489-80.532-67.178-69.367-77.691-32.67-95.606C45.018-4.176 88.74 1.437 134.808 1.722c30.9.19 145.833-10.8 148.253 47.941 2.023 49.137-20.585 76.545-32.151 94.132z"
            data-name="Path 256"
            transform="rotate(141 -107.334 1019.401)"
            style={{ mixBlendMode: 'soft-light', isolation: 'isolate' }}
          />
          <path
            fill="#ebf1fd"
            d="M275.128 157.674c-22.8 34.661-81.447 50.526-125.7 27.989S132.374 140.17 61.125 112-14.938 26.811 25.3 7.167c24.062-11.746 72-5.591 122.518-5.279 33.888.212 159.91-11.847 162.564 52.569 2.218 53.879-22.572 83.933-35.254 103.217z"
            data-name="Path 256"
            opacity="0.8"
            transform="rotate(165 121.497 992.797)"
            style={{ mixBlendMode: 'soft-light', isolation: 'isolate' }}
          />
        </g>
      </g>
    </svg>
  </Wrapper>
);

export default SwipointsWidgetBackground;
