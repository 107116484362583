import { breakpoints, css, hexToRgb, styled } from '@swibeco/shared';
import { HoverBehavior } from '@swibeco/ui';

export const CreditRedeem = styled.div<{
  isBasket: boolean;
}>`
  ${({ isBasket, theme }) =>
    isBasket
      ? css`
          min-height: 127px;
          background: ${theme.colors.primary.decorationLight};
          align-items: flex-start;
          padding: 22px 20px;
        `
      : css`
          padding: 22px 13px;
          position: absolute;
          left: 8.33%;
          right: 8.33%;
          bottom: -120px;
          z-index: 2;
          height: 150px;
          background: ${theme.colors.default.white};
          align-items: center;

          ${breakpoints.md} {
            bottom: -34.5px;
            height: 69px;
            flex-direction: row;
          }
        `}
  border-radius: 3px;
  margin: 0 auto;
  box-shadow: ${({ theme }) =>
    `0px 3px 6px ${hexToRgb(theme.colors.default.main, 2)}`};
  border-left: 5px solid ${({ theme }) => theme.colors.secondary.decorationBlue};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const BannerLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  ${breakpoints.md_max} {
    width: 100%;
  }
`;

export const BannerHoverBehavior = styled(HoverBehavior)`
  > a {
    :hover {
      text-decoration: none;
      opacity: 1;
      color: ${({ theme, color }) =>
        theme.colors[color?.variant || 'primary']?.[
          color?.variantColor || 'main'
        ]};
    }
  }
`;
