import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * this hook can be used to make a component sticky when it is in the viewport
 *  expose the refs of the start and end of elements in which the sticky element will be visible
 *  ReturnType:
 * ```ts
 *  type StickyHook = {
      isSticky: boolean;
      setSticky: React.Dispatch<React.SetStateAction<boolean>>;
      stickyRefStart: React.RefObject<HTMLDivElement>;
      stickyRefEnd: React.RefObject<HTMLDivElement>;
    };
 ```
 * Usage:
 *
 * ```tsx
 * import StickyStepElement , { useSticky } from './useSticky';
 * import { StickyStepElement } from './hooks/useSticky/stickyWrapper';
 *
 const Usage = () => {
    const { isSticky, setSticky, stickyRefStart, stickyRefEnd } = useSticky();
    return (
     <>
        <div ref={stickyRefStart}>
         long content
        </div>
        <div ref={stickyRefEnd}>
          sticky will stop here
        </div>
        <StickyStepElement isSticky={isSticky}>
         content of sticky element
        </StickyStepElement>
    </>
  )
 *
 * }
 *
 *
 * ```
 *
 */
const useSticky = (stickyRefEnd = false) => {
  const [isSticky, setSticky] = useState(false);
  const refStart = useRef<HTMLDivElement>(null);
  const refEnd = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (refStart.current && refEnd.current) {
      // Check if start element is in the viewport
      if (
        window.innerHeight -
          refStart.current.getBoundingClientRect().top -
          refStart.current.getBoundingClientRect().height >
        0
      ) {
        setSticky(true);
      } else {
        setSticky(false);
      }

      // Check if we reach the end element
      if (refEnd.current && !stickyRefEnd) {
        if (
          window.innerHeight - refEnd.current.getBoundingClientRect().top >
          refEnd.current.getBoundingClientRect().height
        ) {
          setSticky(false);
        }
      }
    }
  }, [stickyRefEnd, setSticky]);

  useEffect(() => {
    // run it once in case it's already visible
    handleScroll();
    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {
    isSticky,
    setSticky,
    stickyRefStart: refStart,
    stickyRefEnd: refEnd,
  };
};

export default useSticky;
