import { BasketType } from '@swibeco/types';

export const user = {
  addresses: {
    billingAddress: null,
    deliveryAddress: null,
  },
  allocatedSwipoints: 0,
  useDeliveryAddressForBilling: false,
  payementMethod: null,
  card: null,
};

export const basket: BasketType = {
  products: [],
  totalProducts: 0,
  totalDelivery: 0,
  totalAmount: 0,
  finalAmountToPay: 0,
  hasPhysical: false,
  hasDigital: false,
  hasSubscription: false,
  totalSavings: 0,
  totalCashback: 0,
};

export const swibeco = {
  telephone: '+41121231212',
};

export const errors = {
  outOfStock: false,
  saferpayError: false,
};
