import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CanParameters, Generics } from '@casl/ability';
import { selectors } from '../securitySlice';
import { AppFeatureAbility } from '../features';
import { useCurrentCompany } from '../contexts';

const useFeatures = () => {
  const isAuthenticated = useSelector(selectors.isAuthenticated);
  const { company } = useCurrentCompany();

  return useCallback(
    (...args: CanParameters<Generics<AppFeatureAbility>['abilities']>) => {
      /**
       * Allow access since we don't know yet if the user is logged in or not.
       * We also wanna make sure we have the data fetched prior to check permissions.
       */
      if (isAuthenticated === null || !company?.ability) {
        return true;
      }

      return company.ability.can(...args);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, company?.ability]
  );
};

export default useFeatures;
