import React from 'react';
import SwiperCore from 'swiper';
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import {
  Swiper as BaseSwiper,
  SwiperSlide as BaseSwiperSlide,
  SwiperProps,
  SwiperSlideProps,
} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ChakraProps, chakra } from '@chakra-ui/react';
import * as Styles from './styles/Swiper.styles';

const ChakraBaseSwiperSlide = chakra(BaseSwiperSlide);

const Swiper = ({
  autoplay,
  spaceBetween = 0,
  slidesPerView = 'auto',
  children,
  allowSlideNext,
  allowSlidePrev,
  onSwiper,
  pagination,
  onSlideChange,
  speed,
  freeMode = false,
  ...rest
}: SwiperProps & {
  children: React.ReactNode;
}) => {
  const modules: any[] = [];
  if (autoplay) modules.push(Autoplay);
  if (freeMode) modules.push(FreeMode);
  if (pagination) modules.push(Pagination);
  SwiperCore.use(modules);
  return (
    <Styles.Wrapper>
      <BaseSwiper
        pagination={pagination}
        modules={modules}
        autoplay={autoplay}
        onSlideChange={onSlideChange}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        allowSlideNext={allowSlideNext}
        allowSlidePrev={allowSlidePrev}
        onSwiper={onSwiper}
        freeMode={freeMode}
        speed={speed}
        {...rest}
      >
        {children}
        {/* {React.Children.map(children, (child, index) => {
          const key = `slide_${index}`;
          if (!child) return null;
          // if (
          //   typeof child !== 'string' &&
          //   typeof child !== 'number' &&
          //   typeof child !== 'boolean'
          // ) {
          //   key += `_${child.key}`;
          // }
          return (
            <SwiperSlide key={key} style={{ width: 'auto' }} className="h-auto">
              {child}
            </SwiperSlide>
          );
        })} */}
      </BaseSwiper>
    </Styles.Wrapper>
  );
};

export default Swiper;

export const SwiperSlide = ({
  children,
  ...props
}: SwiperSlideProps & ChakraProps) => (
  <ChakraBaseSwiperSlide
    className="custom-swiper-slide"
    width={{ base: '90%', sm: 'auto' }}
    {...props}
  >
    {children}
  </ChakraBaseSwiperSlide>
);
SwiperSlide.displayName = 'SwiperSlide';
