import React from 'react';
import { AnalyticsEvents, SyliusBrand } from '@swibeco/types';
import { Box, BoxProps, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ECOMMERCE_BRAND_ROOT } from '@swibeco/shared';
import TagManager from 'react-gtm-module';
import { usePlatform } from '@swibeco/core';

export type HighlightedBrandItemProps = {
  brand: SyliusBrand;
} & BoxProps;

export default function HighlightedBrandItem({
  brand,
  ...props
}: HighlightedBrandItemProps) {
  const navigate = useNavigate();
  const environment = usePlatform(window);
  const location = useLocation();

  const handleClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.SELECT_ITEM,
        environment,
        ecommerce: {
          items: {
            item_brand: brand.name,
            item_list_name: location.pathname,
          },
        },
      },
    });
    navigate(`${ECOMMERCE_BRAND_ROOT}/${brand.slug}`);
  };

  return (
    <Box
      boxShadow="0px 3px 6px 0px #3E619133"
      p="16px"
      bgColor="white"
      w="181px"
      h="80px"
      {...props}
      cursor="pointer"
      onClick={handleClick}
    >
      <Image w="100%" h="100%" objectFit="contain" src={brand.logoName} />
    </Box>
  );
}
