import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  actions,
  useIsMobile,
  useLanguageSwitcher,
  useOnClickOutside,
  usePlatform,
} from '@swibeco/core';
import { syliusApi, UniverseParams } from '@swibeco/ecommerce';
import { HydraResponse, Product } from '@swibeco/types';
import TagManager from 'react-gtm-module';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@swibeco/ui';
import { useDispatch } from 'react-redux';
import {
  generatePageName,
  SEARCH_RESULTS_ROOT,
  useTheme,
} from '@swibeco/shared';
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Portal,
} from '@chakra-ui/react';
import * as Styles from './styles/SearchInput.styles';
import SearchItem from './SearchItem';
import { UnderlayMask } from '../../UnderlayMask';
import { ReactComponent as SmallClose } from '../../../assets/images/small_close.svg';

const RESULTS_DISPLAYED = 5;
const MIN_SEARCH_CHARS = 2;

type SearchInputProps = {
  setIsOpen?: (isOpen: boolean) => void;
  isOpen?: boolean;
};

const SearchInput = ({ setIsOpen, isOpen }: SearchInputProps) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [minCharactersNotice, setMinCharactersNotice] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isDelayActive, setIsDelayActive] = useState(false);
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const { currentLocale } = useLanguageSwitcher();
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = generatePageName(location.pathname);
  const theme = useTheme();
  const environment = usePlatform(window);
  const isMobile = useIsMobile();

  const { mutate, data, isPending, isError } = useMutation({
    mutationFn: (searchData: { query: string; locale: string }) =>
      syliusApi.getHydraProductsListing(
        {
          context: 'search',
          search: searchData.query,
        } as UniverseParams,
        1,
        {},
        searchData.locale,
        {
          itemsPerPage: 15,
        }
      ),
    onSuccess: (data: HydraResponse<Product[]>) => {
      setSearchResults(data.data.slice(0, RESULTS_DISPLAYED));
      TagManager.dataLayer({
        dataLayer: {
          event: 'view_search_result',
          environment,
          search_term: searchQuery,
          nb_items_results: data.pageInfo.total,
          localisation: pageName,
        },
      });
    },
  });

  const totalResults = useMemo(
    () => (data?.pageInfo.total === 15 ? '15+' : data?.pageInfo.total),
    [data]
  );

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      if (searchQuery.length >= MIN_SEARCH_CHARS) {
        const delayDebounceFn = setTimeout(() => {
          mutate({ query: searchQuery, locale: currentLocale });
          setIsDelayActive(false);
        }, 250);

        return () => clearTimeout(delayDebounceFn);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentLocale]
  );

  useOnClickOutside(wrapperRef, () => {
    setShowSearchResults(false);
    setMinCharactersNotice(false);
    setSearchQuery('');
    setSearchResults([]);
  });

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    dispatch(actions.hideMenu());

    if (e.target.value.length < MIN_SEARCH_CHARS) {
      setSearchResults([]);
      setMinCharactersNotice(true);
      return;
    }

    setMinCharactersNotice(false);
  };

  const onInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();

    setSearchQuery(e.target.value);

    if (e.target.value.length >= MIN_SEARCH_CHARS) {
      setMinCharactersNotice(false);
      setShowSearchResults(true);
      setIsDelayActive(true);
      return;
    }

    setShowSearchResults(false);
    setMinCharactersNotice(true);
    setSearchResults([]);
  };

  const allResultsPageUrl = `${SEARCH_RESULTS_ROOT}/${searchQuery}`;

  const hideSearchDropDown = () => {
    setShowSearchResults(false);
    // lose focus from search input
    inputRef.current?.blur();
    setIsOpen?.(false);
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchQuery.length >= MIN_SEARCH_CHARS) {
      hideSearchDropDown();
      navigate(allResultsPageUrl);
      setIsOpen?.(false);
    }
  };

  const onClear = () => {
    setSearchQuery('');
    setSearchResults([]);
    setShowSearchResults(false);
    setMinCharactersNotice(false);
    if (inputRef?.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  };

  const hasChars = searchQuery.length > 0;

  return (
    <Styles.SearchWrapper ref={wrapperRef}>
      <Flex>
        <InputGroup>
          <Input
            boxShadow="none !important"
            data-testid="header-search-input"
            borderRadius="3px"
            onChange={onInputChange}
            onFocus={onInputFocus}
            value={searchQuery}
            onKeyUp={onPressEnter}
            ref={inputRef}
            autoFocus={isMobile}
            variant="outline"
            _focus={{
              borderColor: 'primary.main',
            }}
            placeholder={t('core.ecommerce.header.search.input_placeholder')}
            bg="white"
          />
          <InputRightElement right="32px">
            <Flex gap="12px" borderRadius="3px">
              <Box minW="44px">
                {hasChars && (
                  <Button onClick={onClear} variant="ghost" _hover={{}}>
                    <SmallClose />
                  </Button>
                )}
              </Box>
              <Button
                borderTopRightRadius="3px"
                borderBottomRightRadius="3px"
                bgColor="primary.main"
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}
                _hover={{
                  bgColor: 'primary.main',
                }}
                onClick={() => {
                  setIsOpen?.(false);
                  navigate(allResultsPageUrl);
                }}
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  size="sm"
                  color={theme.colors.default.white}
                />
              </Button>
            </Flex>
          </InputRightElement>
        </InputGroup>
      </Flex>
      {minCharactersNotice && (
        <Styles.SearchResultsPanel
          data-testid="header-search-input-tooltip"
          className="p-2"
        >
          {t('core.header.search.tooltip.min_characters', {
            min: MIN_SEARCH_CHARS,
          })}
        </Styles.SearchResultsPanel>
      )}
      {showSearchResults && (
        <Styles.SearchResultsPanel data-testid="header-search-input-products-list">
          {(isPending || isDelayActive) && (
            <Text className="p-2">{t('core.header.search.searching')}</Text>
          )}
          {searchResults && searchResults.length > 0 && (
            <>
              {searchResults.map((product: Product, index) => (
                <Box key={product.id}>
                  <SearchItem
                    product={product}
                    toggleSearchResults={() => {
                      setShowSearchResults(false);
                      setIsOpen?.(false);
                    }}
                  />
                  {index !== searchResults.length - 1 && (
                    <Divider bgColor="default.main" h="1px" my="8px" />
                  )}
                </Box>
              ))}

              <Styles.AllResultsLink
                data-testid="header-search-input-all-results-link"
                to={allResultsPageUrl}
                onClick={hideSearchDropDown}
              >
                {t('core.header.search.see_all', {
                  results: totalResults,
                })}
              </Styles.AllResultsLink>
            </>
          )}
          {!isPending && searchResults.length === 0 && !isDelayActive && (
            <Text className="p-2">{t('core.header.search.no_results')}</Text>
          )}
          {isError && (
            <Text className="p-2">{t('core.header.search.error')}</Text>
          )}
        </Styles.SearchResultsPanel>
      )}
      {(minCharactersNotice || showSearchResults || isOpen) && (
        <Portal>
          <UnderlayMask onClick={() => setIsOpen?.(false)} />
        </Portal>
      )}
    </Styles.SearchWrapper>
  );
};

export default SearchInput;
