import {
  EmployeeContributionType,
  MealTaxDeductionType,
  SalaryMethod,
} from '@swibeco/types';
import {
  SimulatorEmployeeState,
  SimulatorEmployerConfigurationState,
  SimulatorEmployerState,
  SimulatorState,
} from 'reducer';
/* istanbul ignore file */
// We ignore this file because typescript type checking is enough

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  SET_NUMBER_OF_EMPLOYEES = 'SET_NUMBER_OF_EMPLOYEES',
  SET_EMPLOYER_CONTRIBUTION = 'SET_EMPLOYER_CONTRIBUTION',
  SET_AVERAGE_GROSS_SALARY = 'SET_AVERAGE_GROSS_SALARY',
  SET_EMPLOYER_SOCIAL_CONTRIBUTION_PERCENTAGE = 'SET_EMPLOYER_SOCIAL_CONTRIBUTION_PERCENTAGE',
  SET_EMPLOYER_SOCIAL_CONTRIBUTION_REDISTRIBUTED_PERCENTAGE = 'SET_EMPLOYER_SOCIAL_CONTRIBUTION_REDISTRIBUTED_PERCENTAGE',
  SET_EMPLOYEE_CONTRIBUTION_TYPE = 'SET_EMPLOYEE_CONTRIBUTION_TYPE',
  SET_SALARY_CONVERSION = 'SET_SALARY_CONVERSION',
  SET_AVERAGE_EMPLOYEES_SOCIAL_CONTRIBUTION_PERCENTAGE = 'SET_AVERAGE_EMPLOYEES_SOCIAL_CONTRIBUTION_PERCENTAGE',
  SET_AVERAGE_MARGINAL_TAX_RATE = 'SET_AVERAGE_MARGINAL_TAX_RATE',
  SET_MEAL_DEDUCTION_TYPE = 'SET_MEAL_DEDUCTION_TYPE',
  TOGGLE_MONTHS = 'TOGGLE_MONTHS',
  INITIALIZE = 'INITIALIZE',
  INITIALIZE_EMPLOYER_CONFIGURATION = 'INITIALIZE_EMPLOYER_CONFIGURATION',
  INITIALIZE_EMPLOYER = 'INITIALIZE_EMPLOYER',
  INITIALIZE_EMPLOYEE = 'INITIALIZE_EMPLOYEE',
  SET_PERSONAL_INFOS = 'SET_PERSONAL_INFOS',
  SET_DELIVERY_INFOS = 'SET_DELIVERY_INFOS',
  SET_SALARY_METHOD = 'SET_SALARY_METHOD',
  SET_CONTRACT_ACCEPTED = 'SET_CONTRACT_ACCEPTED',
  SET_NET_SALARY_CONVERTED = 'SET_NET_SALARY_CONVERTED',
  SET_GROSS_SALARY_CONVERTED = 'SET_GROSS_SALARY_CONVERTED',
  SET_AMOUNT_LOADED_ON_THE_CARD = 'SET_AMOUNT_LOADED_ON_THE_CARD',
}

type Payload = {
  [Types.SET_NUMBER_OF_EMPLOYEES]: {
    numberOfEmployees: number;
  };
  [Types.SET_EMPLOYER_CONTRIBUTION]: {
    contribution: number;
  };
  [Types.SET_AVERAGE_GROSS_SALARY]: {
    salary: number;
  };
  [Types.SET_EMPLOYER_SOCIAL_CONTRIBUTION_PERCENTAGE]: {
    percentage: number;
  };
  [Types.SET_EMPLOYEE_CONTRIBUTION_TYPE]: {
    contributionType: EmployeeContributionType;
  };
  [Types.SET_SALARY_CONVERSION]: {
    netValue: boolean;
    grossValue: boolean;
  };
  [Types.SET_EMPLOYER_SOCIAL_CONTRIBUTION_REDISTRIBUTED_PERCENTAGE]: {
    percentage: number;
  };
  [Types.SET_AVERAGE_EMPLOYEES_SOCIAL_CONTRIBUTION_PERCENTAGE]: {
    contribution: number;
  };
  [Types.SET_AVERAGE_MARGINAL_TAX_RATE]: {
    rate: number;
  };
  [Types.SET_MEAL_DEDUCTION_TYPE]: {
    deductionType: MealTaxDeductionType;
  };
  [Types.TOGGLE_MONTHS]: null;
  [Types.INITIALIZE]: SimulatorState;
  [Types.INITIALIZE_EMPLOYER_CONFIGURATION]: SimulatorEmployerConfigurationState;
  [Types.INITIALIZE_EMPLOYER]: SimulatorEmployerState;
  [Types.INITIALIZE_EMPLOYEE]: SimulatorEmployeeState;
  [Types.SET_PERSONAL_INFOS]: {
    gender: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    nationality: string;
    country: string;
    language: string;
    email: string;
    streetAddress: string;
    complement: string;
    postalCode: string;
    city: string;
  };
  [Types.SET_DELIVERY_INFOS]: {
    addressName: string;
    streetAddress: string;
    complement: string;
    postalCode: string;
    city: string;
  };
  [Types.SET_SALARY_METHOD]: {
    salaryMethod: SalaryMethod;
  };
  [Types.SET_CONTRACT_ACCEPTED]: {
    contractAccepted: boolean;
  };
  [Types.SET_NET_SALARY_CONVERTED]: {
    netSalaryConverted: number;
  };
  [Types.SET_GROSS_SALARY_CONVERTED]: {
    grossSalaryConverted: number;
  };
  [Types.SET_AMOUNT_LOADED_ON_THE_CARD]: {
    amountLoadedOnTheCard: number;
  };
};

export type SimulatorActions = ActionMap<Payload>[keyof ActionMap<Payload>];

const setNumberOfEmployees = (numberOfEmployees: number): SimulatorActions => ({
  type: Types.SET_NUMBER_OF_EMPLOYEES,
  payload: {
    numberOfEmployees,
  },
});

const setEmployerContribution = (contribution: number): SimulatorActions => ({
  type: Types.SET_EMPLOYER_CONTRIBUTION,
  payload: {
    contribution,
  },
});

const setAverageGrossSalary = (salary: number): SimulatorActions => ({
  type: Types.SET_AVERAGE_GROSS_SALARY,
  payload: {
    salary,
  },
});

const setEmployerSocialContributionPercentage = (
  percentage: number
): SimulatorActions => ({
  type: Types.SET_EMPLOYER_SOCIAL_CONTRIBUTION_PERCENTAGE,
  payload: {
    percentage,
  },
});

const setEmployeeContributionType = (
  contributionType: EmployeeContributionType
): SimulatorActions => ({
  type: Types.SET_EMPLOYEE_CONTRIBUTION_TYPE,
  payload: {
    contributionType,
  },
});

const setSalaryConversion = (
  netValue: boolean,
  grossValue: boolean
): SimulatorActions => ({
  type: Types.SET_SALARY_CONVERSION,
  payload: {
    netValue,
    grossValue,
  },
});

const setEmployerSocialContributionRedistributedPercentage = (
  percentage: number
): SimulatorActions => ({
  type: Types.SET_EMPLOYER_SOCIAL_CONTRIBUTION_REDISTRIBUTED_PERCENTAGE,
  payload: {
    percentage,
  },
});

const setAverageEmployeesSocialContributionPercentage = (
  contribution: number
): SimulatorActions => ({
  type: Types.SET_AVERAGE_EMPLOYEES_SOCIAL_CONTRIBUTION_PERCENTAGE,
  payload: {
    contribution,
  },
});

const setAverageMarginalTaxRate = (rate: number): SimulatorActions => ({
  type: Types.SET_AVERAGE_MARGINAL_TAX_RATE,
  payload: {
    rate,
  },
});

const setMealTaxDeductionType = (
  deductionType: MealTaxDeductionType
): SimulatorActions => ({
  type: Types.SET_MEAL_DEDUCTION_TYPE,
  payload: {
    deductionType,
  },
});

const toggleMonths = (): SimulatorActions => ({
  type: Types.TOGGLE_MONTHS,
  payload: null,
});

const initialize = (state: SimulatorState): SimulatorActions => ({
  type: Types.INITIALIZE,
  payload: state,
});

const initializeEmployerConfiguration = (state: {
  employerConfiguration: SimulatorEmployerConfigurationState;
}): SimulatorActions => ({
  type: Types.INITIALIZE_EMPLOYER_CONFIGURATION,
  payload: state.employerConfiguration,
});

const initializeEmployer = (state: {
  employer: SimulatorEmployerState;
}): SimulatorActions => ({
  type: Types.INITIALIZE_EMPLOYER,
  payload: state.employer,
});

const initializeEmployee = (state: {
  employee: SimulatorEmployeeState;
}): SimulatorActions => ({
  type: Types.INITIALIZE_EMPLOYEE,
  payload: state.employee,
});

const setPersonalInfos = (
  gender: string,
  firstName: string,
  lastName: string,
  birthDate: string,
  nationality: string,
  country: string,
  language: string,
  email: string,
  streetAddress: string,
  complement: string,
  postalCode: string,
  city: string
): SimulatorActions => ({
  type: Types.SET_PERSONAL_INFOS,
  payload: {
    gender,
    firstName,
    lastName,
    birthDate,
    nationality,
    country,
    language,
    email,
    streetAddress,
    complement,
    postalCode,
    city,
  },
});

const setDeliveryInfos = (
  addressName: string,
  streetAddress: string,
  complement: string,
  postalCode: string,
  city: string
): SimulatorActions => ({
  type: Types.SET_DELIVERY_INFOS,
  payload: {
    addressName,
    streetAddress,
    complement,
    postalCode,
    city,
  },
});

const setSalaryMethod = (salaryMethod: SalaryMethod): SimulatorActions => ({
  type: Types.SET_SALARY_METHOD,
  payload: {
    salaryMethod,
  },
});

const setContractAccepted = (contractAccepted: boolean): SimulatorActions => ({
  type: Types.SET_CONTRACT_ACCEPTED,
  payload: {
    contractAccepted,
  },
});

const setNetSalaryConverted = (
  netSalaryConverted: number
): SimulatorActions => ({
  type: Types.SET_NET_SALARY_CONVERTED,
  payload: {
    netSalaryConverted,
  },
});

const setGrossSalaryConverted = (
  grossSalaryConverted: number
): SimulatorActions => ({
  type: Types.SET_GROSS_SALARY_CONVERTED,
  payload: {
    grossSalaryConverted,
  },
});

const setAmountLoadedOnTheCard = (
  amountLoadedOnTheCard: number
): SimulatorActions => ({
  type: Types.SET_AMOUNT_LOADED_ON_THE_CARD,
  payload: {
    amountLoadedOnTheCard,
  },
});

export const simulatorActions = {
  setNumberOfEmployees,
  setEmployerContribution,
  setAverageGrossSalary,
  setEmployerSocialContributionPercentage,
  setEmployeeContributionType,
  setSalaryConversion,
  setEmployerSocialContributionRedistributedPercentage,
  setAverageEmployeesSocialContributionPercentage,
  setAverageMarginalTaxRate,
  setMealTaxDeductionType,
  toggleMonths,
  initialize,
  initializeEmployerConfiguration,
  initializeEmployer,
  initializeEmployee,
  setPersonalInfos,
  setDeliveryInfos,
  setSalaryMethod,
  setContractAccepted,
  setNetSalaryConverted,
  setGrossSalaryConverted,
  setAmountLoadedOnTheCard,
};
