import { Avatar, Box, Flex, Text, useToken } from '@chakra-ui/react';
import { UserType } from '@swibeco/types';
import { isPlusUser, isProUser } from '@swibeco/shared';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCross } from '../../../../../assets/images/cross.svg';
import { ReactComponent as IconPlus } from '../../../../../assets/images/plus.svg';
import avatarDefault from '../../images/avatar_default.svg';

type HeaderPanelProps = {
  userData: UserType;
  closeBottomSheet: () => void;
};
const HeaderPanel = ({ userData, closeBottomSheet }: HeaderPanelProps) => {
  const { t } = useTranslation();
  const primaryPlusBlue = useToken('colors', 'primary.plusBlue');
  const isPlus = isPlusUser(userData.type) && !isProUser(userData.type);
  return (
    <Flex align="center" justifyContent="space-between">
      <Flex align="center" gap="6px" cursor="auto">
        <Avatar
          w="51px"
          h="51px"
          src={userData!.profilePicture || avatarDefault}
          border={isPlus ? `2px solid ${primaryPlusBlue}` : 'none'}
        />
        <Flex direction="column" gap="2px">
          <Text variant="default-important" paddingTop={isPlus ? '8px' : '0'}>
            {`${t('core.header.userprofile.menu.header.greetings')}, `}
            {userData!.firstName} {userData!.lastName}
          </Text>
          {isPlus && (
            <Flex align="center" gap="4px">
              <IconPlus />
              <Text m="0" color="primary.plusBlue">
                {t('core.header.userprofile.menu.header.plus.member')}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Box
        onClick={closeBottomSheet}
        w="13.4px"
        h="13.4px"
        cursor="pointer"
        data-testid="header-userprofile-close"
      >
        <IconCross />
      </Box>
    </Flex>
  );
};
export default HeaderPanel;
