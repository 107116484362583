import { styled } from '@swibeco/shared';

export const Container = styled.div`
  .swiper {
    overflow: visible;
  }

  .question-mark {
    g {
      stroke: ${({ theme }) => theme.colors.default.white};
    }

    &:hover g {
      stroke: ${({ theme }) => theme.colors.default.dark};
    }
  }
`;

export const Callout = styled.div<{
  background?: string;
}>`
  align-items: center;
  background: ${({ background, theme }) =>
    background ?? theme.colors.primary.plusBlue};
  border-radius: 3px;
  display: flex;
  gap: 10px;
  height: 72px;
  padding: 5px;
  width: 298px;
`;

export const Image = styled.img`
  border-radius: 3px;
  height: 62px;
  object-fit: cover;
  width: 62px;
`;

export const PlusIconWrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.default.white};
  border-radius: 3px;
  display: flex;
  height: 62px;
  justify-content: center;
  width: 62px;
`;
