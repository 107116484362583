// improved version of example provided in: https://github.com/TanStack/query/discussions/1205
import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

const useLazyQuery = <TData, TError>(
  options: UseQueryOptions<TData, TError, TData, QueryKey>
) => {
  const query = useQuery<TData, TError, TData, QueryKey>({
    ...(options || {}),
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
  });

  return [query.refetch, query] as const;
};

export default useLazyQuery;
