import React, { useEffect, useRef, useState } from 'react';
import {
  AnalyticsContext,
  AnalyticsEvents,
  EnumLinkProps,
  SyliusBrand,
} from '@swibeco/types';
import { Swiper, SwiperCore, SwiperSlide } from '@swibeco/shared-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useIsMobile, usePlatform } from '@swibeco/core';

import { Box, Flex, Image } from '@chakra-ui/react';
import { HoverBehavior, Link } from '@swibeco/ui';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { BrandItem } from '../../../components/Universe/BrandsList';
import * as Styles from './BrandsSwiper.styles';

type ProductsSwiperProps = {
  title: string;
  icon?: string;
  brands: SyliusBrand[];
  spaceBetween?: number;
  config?: {
    numberOfDeals?: {
      mobile?: number;
      tablet?: number;
      desktop?: number;
    };
    numberOfDealsPerPage?: {
      mobile?: number;
      tablet?: number;
      desktop?: number;
    };
  };
  breakpoints?: {
    [width: number]: {
      slidesPerView: number;
      slidesPerGroup: number;
      spaceBetween?: number;
    };
    [ratio: string]: {
      slidesPerView: number;
      slidesPerGroup: number;
      spaceBetween?: number;
    };
  };
  paddingTop?: number;
  marginBottom?: number;
  analytics: AnalyticsContext;
  mainContent?: boolean;
  component: React.ElementType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentProps?: Record<string, any>;
  link: string;
  position: number;
  forceShowAll?: boolean;
};

const BrandsSwiper = ({
  icon,
  title,
  brands,
  spaceBetween,
  paddingTop,
  marginBottom,
  mainContent,
  component: Component = BrandItem,
  componentProps,
  link,
  analytics,
  position,
  forceShowAll = false,
}: ProductsSwiperProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const environment = usePlatform(window);
  const sentAnalytics = useRef(false);

  const [swiperController, setSwiperController] = useState<SwiperCore | null>(
    null
  );

  useEffect(() => {
    const analyticsContext = analytics || {};
    if (brands && brands.length && !sentAnalytics.current) {
      analyticsContext.listId = 'homepage_brands';
      analyticsContext.listName = `Homepage - ${title}`;
      analyticsContext.category = title;
      const mappedBrands = brands.map((brand) => ({
        item_name: brand.name,
        item_id: brand.id,
        item_brand: brand.name,
        item_list_name: analyticsContext.listName,
        item_list_id: analyticsContext.listId,
        item_category: analyticsContext.category,
        quantity: 1,
      }));
      sentAnalytics.current = true;
      TagManager.dataLayer({
        dataLayer: {
          event: AnalyticsEvents.VIEW_ITEM_LIST,
          environment,
          position,
          list_name: title,
          ecommerce: {
            items: mappedBrands,
          },
        },
      });
    }
  }, [analytics, brands, title, environment, position]);

  return (
    <div className="swiper-container">
      <Styles.DecorationShape height="149px" />
      <Flex
        paddingTop={paddingTop ?? 2}
        marginBottom={marginBottom ?? 2}
        sx={
          mainContent
            ? {
                width: '90%',
                maxWidth: '1440px',
                margin: 'auto',
                paddingTop: 4,
                marginBottom: 4,
              }
            : {}
        }
        className="swiper-header"
        justifyContent="space-between"
      >
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          width={{ base: '100%', md: 'fit-content' }}
          gap="8px"
        >
          <Flex alignItems="center">
            {icon && (
              <Image
                h="40px"
                w="40px"
                src={icon}
                alt="voucher"
                className="mr-2"
              />
            )}
            <Styles.DealsTitle component="h3" important>
              {title}
            </Styles.DealsTitle>
          </Flex>
          {((link && forceShowAll) || (link && brands.length >= 15)) && (
            <Box zIndex={2} flexShrink={0}>
              <HoverBehavior>
                <Link type={EnumLinkProps.NewPage} to={link}>
                  {t('core.ecommerce.homepage.sliders.see_all')}
                </Link>
              </HoverBehavior>
            </Box>
          )}
        </Flex>

        {!isMobile && (
          <Flex
            position="relative"
            display={{
              md: brands.length < 3 ? 'none' : 'flex',
              lg: brands.length < 5 ? 'none' : 'flex',
            }}
            alignItems="center"
            gap="24px"
          >
            <Flex>
              <Styles.CircularIconButtons
                type="button"
                className="btn shadow p-auto"
                onClick={() => swiperController?.slidePrev()}
                data-testid="slider-prev-button"
              >
                <FontAwesomeIcon icon={faChevronLeft} size="1x" />
              </Styles.CircularIconButtons>
              <Styles.CircularIconButtons
                type="button"
                className="btn shadow p-auto"
                onClick={() => swiperController?.slideNext()}
                data-testid="slider-next-button"
              >
                <FontAwesomeIcon icon={faChevronRight} size="1x" />
              </Styles.CircularIconButtons>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Styles.SwiperWrapper className="custom-swiper-wrapper">
        <Swiper
          className="pb-4"
          slidesPerView="auto"
          spaceBetween={spaceBetween ?? 18}
          onSwiper={setSwiperController}
          freeMode
          speed={500}
          allowSlideNext
          allowSlidePrev
        >
          {brands.map((brand) => (
            <SwiperSlide
              className="brands-swiper-slide"
              width="auto"
              key={brand.id}
            >
              <Component {...componentProps} brand={brand} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Styles.SwiperWrapper>
    </div>
  );
};

export default BrandsSwiper;
