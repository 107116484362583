import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { styled } from '@swibeco/shared';
import { StyledColorProps } from '@swibeco/types';

export enum ArrowDirection {
  Left = 'rotate(0)',
  Right = 'translate(18 18) rotate(180)',
}

export type ArrowProps = {
  direction: ArrowDirection;
  color?: StyledColorProps;
  width?: number;
  height?: number;
  thickness?: number;
  className?: string;
};

const ArrowWrapper = styled('span', {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && !['height', 'width'].includes(prop),
})<StyledColorProps & Pick<ArrowProps, 'width' | 'height'>>`
  display: flex;
  width: ${({ width }) => `${width ?? 18}px`};
  height: ${({ height }) => `${height ?? 18}px`};

  svg {
    path {
      stroke: ${({ theme, variant, variantColor }) =>
        theme.colors[variant][variantColor]};
    }
  }
`;

const Arrow = ({
  direction,
  color,
  width = 18,
  height = 18,
  thickness = 2,
  className,
}: ArrowProps) => (
  <ArrowWrapper
    variant={color?.variant || 'primary'}
    variantColor={color?.variantColor || 'main'}
    className={className}
    width={width}
    height={height}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <g
        data-name="Groupe 589"
        fill="none"
        stroke="#006cff"
        strokeLinecap="round"
        strokeWidth={thickness}
        transform={direction}
      >
        <path
          data-name="Trac\xE9 108"
          d="M6.345 4.514L2.001 8.86l4.344 4.344"
        />
        <path data-name="Ligne 15" d="M2 8.86h13.72" />
      </g>
    </svg>
  </ArrowWrapper>
);

export default Arrow;
