/* eslint-disable import/no-extraneous-dependencies */
/* istanbul ignore file */
import { Tuple, configureStore as createStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { API, LegacyAPI, JWT_STORAGE } from '@swibeco/shared';
import { authenticationApi, actions } from '@swibeco/security';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type * as rtk from '@reduxjs/toolkit';
import createRootReducer from './rootReducer';

export default function configureStore() {
  const store = createStore({
    reducer: createRootReducer,
    middleware: () => new Tuple(thunk),
  });

  const refreshAuthLogic = async (failedRequest: any) => {
    if (failedRequest.response?.data?.prevent_refresh_token) {
      return failedRequest.response.data;
    }
    try {
      const response = await authenticationApi.refreshToken();
      JWT_STORAGE.token = response;
      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.Authorization = `Bearer ${response}`;
    } catch (error) {
      JWT_STORAGE.token = null;
      store.dispatch(actions.userLoggedOut());
      throw error;
    }
  };

  createAuthRefreshInterceptor(API, refreshAuthLogic);

  createAuthRefreshInterceptor(LegacyAPI, refreshAuthLogic);

  return store;
}

export type AppDispatch = ReturnType<typeof configureStore>['dispatch'];
