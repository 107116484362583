import { styled } from '@swibeco/shared';

export const ErrorMessage = styled.span`
  width: 0;
  min-width: 0;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.secondary.main};
  font-size: 16px;
  font-weight: 300;
  flex-grow: 1;
`;
