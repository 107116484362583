import { Col } from 'reactstrap';
import { breakpoints, styled } from '@swibeco/shared';
import { Link as LinkBase } from '@swibeco/ui';

export const WidgetContainer = styled(Col)`
  position: initial;
  flex: auto;
  min-width: 300px;
  min-height: 180px;
`;

export const WidgetWrapper = styled.div`
  width: 100%;
  height: 98%;
  padding: 24px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;

  ${breakpoints.md} {
    padding: 10px;
  }

  ${breakpoints.lg} {
    padding: 24px;
  }
`;

export const ContentWrapper = styled.div`
  gap: 30px;
  ${breakpoints.xl} {
    gap: 15px;
  }
`;

export const Link = styled(LinkBase)`
  &:hover {
    color: ${({ theme }) => theme.colors.default.white};
    text-decoration: none;
  }
`;
