import { useMemo } from 'react';
import { useFeatures } from '@swibeco/security';

const useCanAccessPlusLanding = (): { plusLandingAccess: boolean } => {
  const features = useFeatures();
  const plusLandingAccess = useMemo(() => {
    const canView = features('view_plus_landing');
    return canView;
  }, [features]);
  return { plusLandingAccess };
};

export default useCanAccessPlusLanding;
