import React from 'react';
import { styled, css } from '@swibeco/shared';
import { StyledColorProps } from '@swibeco/types';

export enum ChevronThickDirection {
  Down = 'translate(9 1) rotate(90)',
  Up = 'translate(-9 0) rotate(-90)',
  Left = 'translate(7 10) rotate(180)',
  Right = 'translate(0, 0)',
}

export type ChevronThickProps = {
  direction?: ChevronThickDirection;
  color?: StyledColorProps | string;
  className?: string;
};

const ChevronThickWrapper = styled.span<Partial<StyledColorProps>>`
  width: 17px;
  height: 13px;
  display: flex;

  ${({ theme, variant, variantColor }) =>
    variant && variantColor
      ? css`
          svg {
            path {
              stroke: ${theme.colors[variant][variantColor]};
            }
          }
        `
      : ''}
`;

const ChevronThick = ({
  direction = ChevronThickDirection.Right,
  color = '#001E45',
  className,
}: ChevronThickProps) => (
  <ChevronThickWrapper
    className={className}
    variant={
      typeof color === 'string' ? undefined : color?.variant || 'primary'
    }
    variantColor={
      typeof color === 'string' ? undefined : color?.variantColor || 'main'
    }
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7 10"
      width="17"
      height="13"
      fill="none"
    >
      <path
        transform={direction}
        stroke={typeof color === 'string' ? color : undefined}
        strokeLinecap="round"
        strokeWidth="2"
        d="m1.982 8.74 3.4-3.4-3.4-3.4"
      />
    </svg>
  </ChevronThickWrapper>
);

export default ChevronThick;
