import { SyliusBrand, MappedSyliusBrand } from '@swibeco/types';
import { getTranslationsForLocale } from './getTranslationsForLocale';

export const getMappedBrand = (
  brand: SyliusBrand | undefined,
  locale: string
): MappedSyliusBrand | undefined =>
  brand
    ? {
        ...brand,
        translationsMap: getTranslationsForLocale(locale, brand.translations),
      }
    : undefined;
