import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectors as securitySelectors,
  useCurrentCompany,
} from '@swibeco/security';
import { Link } from '@swibeco/ui';
import { ECOMMERCE_HOME } from '@swibeco/shared';
import * as Styles from './Logo.Styles';

type LogoProps = {
  className?: string;
};

const Logo = ({ className }: LogoProps) => {
  const { company } = useCurrentCompany();
  const display_login_logo = !!company?.display_login_logo;
  const logo = company?.theme.logo || '';

  const isAuthenticated = useSelector(securitySelectors.isAuthenticated);
  const logoSrc = display_login_logo
    ? logo
    : '/static/img/placeholders/swibeco_logo.png';
  const alt = display_login_logo ? 'Company logo' : 'Swibeco logo';

  /**
   * GUILLAUME
   * Modification of the Link className
   * before: className={`${!isMobile ? 'w-100 text-center text-md-left' : ''}`}
   * Link is based on the logo width and not the entire width of the parent div (6 col)
   */
  // const isMobile = useIsMobile();

  return isAuthenticated ? (
    <Link data-testid="logo-link" to={ECOMMERCE_HOME}>
      <Styles.Logo
        alt={alt}
        src={logoSrc}
        data-testid="logo-img"
        className={className || ''}
      />
    </Link>
  ) : (
    <Styles.Logo
      alt={alt}
      src={logoSrc}
      data-testid="logo-img"
      className={className || ''}
    />
  );
};

export default Logo;
