import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectors as coreSelectors, useIsMobile } from '@swibeco/core';
import {
  shuffleArray,
  arrayFrom,
  GlobalSelectors,
  isFreeUser,
  useColorVariant,
  PlusDealSlugs,
  PLUS_LANDING_PAGE,
} from '@swibeco/shared';
import { ecommerceApi } from '@swibeco/ecommerce';
import {
  Swiper,
  SwiperSlide,
  useCanAccessPlusLanding,
} from '@swibeco/shared-web';
import {
  Arrow,
  ArrowDirection,
  Button,
  Caption,
  Link,
  Text,
} from '@swibeco/ui';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwiperClass } from 'swiper/react';
import { useSelector } from 'react-redux';
import { ReactComponent as Arrows } from '../../assets/svgs/arrows.svg';
import { ReactComponent as PlusLock } from '../../assets/svgs/plus_lock.svg';
import { ReactComponent as SmallPlusLogo } from '../../assets/svgs/small_plus_logo.svg';
import { ReactComponent as SwibecoPlusLogo } from '../../assets/svgs/swibeco_plus.svg';
import * as Styles from './PlusCarousel.styles';

export const DiscountItem = ({
  deal,
}: {
  deal: {
    id: number;
    branding: { name: string; logo: string };
    discount_low: number;
    discount_high: number;
  };
}) => {
  const blackColor = useColorVariant('default', 'black');
  return (
    <Styles.DiscountItemContainer>
      <Styles.DiscountItemWrapper>
        <PlusLock
          style={{
            position: 'absolute',
            top: '7px',
            right: '9px',
          }}
        />
        <Styles.DiscountItemLogoWrapper>
          <img src={deal.branding.logo} alt={deal.branding.name} />
        </Styles.DiscountItemLogoWrapper>
      </Styles.DiscountItemWrapper>
      <Styles.DiscountItemDetails>
        <Styles.DiscountItemDiscount>
          <Text color="primary.main" important>{`-${deal.discount_low}%`}</Text>
        </Styles.DiscountItemDiscount>
        <Arrow color={blackColor} direction={ArrowDirection.Right} />
        <div style={{ display: 'flex' }}>
          <Styles.DiscountItemDiscount>
            <Text color="primary.main" important>
              {`-${deal.discount_high}%`}
            </Text>
          </Styles.DiscountItemDiscount>
          <Arrows />
        </div>
      </Styles.DiscountItemDetails>
    </Styles.DiscountItemContainer>
  );
};

export const TestimonialItem = ({ testimonial }: { testimonial: string }) => {
  const { t } = useTranslation();
  return (
    <Styles.TestimonialItemContainer>
      <Styles.TestimonialItemTextWrapper>
        <Styles.TestimonialQuotes />
        <Text color="default.black">{testimonial}</Text>
        <Text overflow="auto" maxHeight="100%" color="default.black">
          {testimonial}
        </Text>
      </Styles.TestimonialItemTextWrapper>
      <Styles.TestimonialMemberWrapper>
        <SmallPlusLogo />
        <Caption color="default.white">
          {t('core.ecommerce.ads.testimonial.member')}
        </Caption>
      </Styles.TestimonialMemberWrapper>
    </Styles.TestimonialItemContainer>
  );
};

export const PlusCarouselBanner = () => {
  const { t } = useTranslation();
  const locale = useSelector(coreSelectors.getLocale);
  const canAccessPlusLanding = useCanAccessPlusLanding();

  return (
    <Styles.PlusBanner>
      <SwibecoPlusLogo style={{ marginBottom: '8px' }} />
      <Text color="default.white" bold style={{ marginBottom: '23px' }}>
        {t('core.ecommerce.ads.plus_banner.title')}
      </Text>
      <Link
        to={
          canAccessPlusLanding
            ? PLUS_LANDING_PAGE
            : `/core/product/${PlusDealSlugs[locale]}`
        }
      >
        <Button lightColor color="secondary">
          {t('core.ecommerce.ads.plus_banner.button')}
        </Button>
      </Link>
    </Styles.PlusBanner>
  );
};

interface PlusCarouselProps {
  hasBanner?: boolean;
  fullWidth?: boolean;
  hasBeforeElement?: boolean;
}

export default function PlusCarousel({
  hasBanner,
  fullWidth,
  hasBeforeElement,
}: PlusCarouselProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const userType = useSelector(GlobalSelectors.user.getUserType);
  const freeUser = isFreeUser(userType);
  const { data: ads, isLoading } = useQuery({
    enabled: freeUser,
    queryKey: ['advertisements'],
    queryFn: () => ecommerceApi.getAds(),
  });

  const [swiperController, setSwiperController] = useState<SwiperClass | null>(
    null
  );

  const randomizedTestimonials = useMemo(
    () => shuffleArray([0, 1, 2, 3, 4, 5, 6, 7, 8]),
    []
  );

  if (isLoading || !freeUser || !ads?.data) {
    return null;
  }

  return (
    <Styles.PlusCarouselWrapper
      fullWidth={fullWidth}
      hasBeforeElement={hasBeforeElement}
    >
      <Styles.PlusCarouselGrid
        hasBanner={hasBanner}
        className="swiper-container"
      >
        {hasBanner && <PlusCarouselBanner />}
        {/* https://github.com/nolimits4web/swiper/issues/2914#issuecomment-493384617 */}
        <div>
          <Styles.SwiperHeader className="swiper-header">
            <div className="d-flex align-items-center">
              <Text component="h3" important>
                {t('core.ecommerce.ads.title')}
              </Text>
            </div>
            {!isMobile && (
              <Styles.Controls size={ads?.data?.length}>
                <Styles.CircularIconButtons
                  type="button"
                  className="btn shadow p-auto"
                  onClick={() => swiperController?.slidePrev()}
                  data-testid="slider-prev-button"
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                </Styles.CircularIconButtons>
                <Styles.CircularIconButtons
                  type="button"
                  className="btn shadow p-auto"
                  onClick={() => swiperController?.slideNext()}
                  data-testid="slider-next-button"
                >
                  <FontAwesomeIcon icon={faChevronRight} size="1x" />
                </Styles.CircularIconButtons>
              </Styles.Controls>
            )}
          </Styles.SwiperHeader>
          <Styles.SwiperWrapper className="custom-swiper-wrapper">
            <Swiper
              className="pb-4"
              slidesPerView="auto"
              spaceBetween={18}
              onSwiper={setSwiperController}
              freeMode
              speed={500}
              allowSlideNext
              allowSlidePrev
              data-testid="plus-swiper"
            >
              {arrayFrom(ads.data.length * 2).map((index) => {
                if (index % 2 === 0) {
                  return (
                    <SwiperSlide
                      data-testid="plus-swiper-slide"
                      key={ads.data[index / 2].id}
                    >
                      <DiscountItem deal={ads.data[index / 2]} />
                    </SwiperSlide>
                  );
                }
                return (
                  <SwiperSlide
                    data-testid="plus-swiper-slide"
                    key={`testimonial-${
                      ads.data[Math.floor(index / 2)].id * 2
                    }`}
                  >
                    <TestimonialItem
                      testimonial={t(
                        `core.ecommerce.ads.testimonials.${
                          randomizedTestimonials[Math.floor(index / 2) % 9]
                        }`
                      )}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Styles.SwiperWrapper>
        </div>
      </Styles.PlusCarouselGrid>
    </Styles.PlusCarouselWrapper>
  );
}
