import { styled, breakpoints } from '@swibeco/shared';
import { MainContentWrapper } from '@swibeco/shared-web';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${breakpoints.md_max} {
    flex-wrap: wrap;
  }
  gap: 1rem;
`;

export const BannerWrapper = styled.div<{
  isSwipointBannerShown?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.primary.decorationLight};
  position: relative;
  ${breakpoints.md_max} {
    margin-bottom: ${({ isSwipointBannerShown }) =>
      isSwipointBannerShown && '120px'};
  }

  #left-hill {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  #right-hill {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;

export const BannerContent = styled(MainContentWrapper)`
  padding: 50px 0;
  ${breakpoints.sm_max} {
    max-width: 1440px;
    width: 90%;
  }
`;

export const MoreInformation = styled.div``;
