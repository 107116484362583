import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  AxiosError,
  DOWNLOAD_APP,
  FORGOT_PASSWORD,
  JWT_STORAGE,
  LOADING_PAGE_AFTER_PAYMENT,
  LOGIN,
  REQUEST_INVITATION,
  SECURITY,
  RESET_PASSWORD,
} from '@swibeco/shared';
import authenticationApi from '../authenticationApi';
import { actions } from '../securitySlice';

const shouldRedirect = (error: AxiosError) =>
  error.response?.status === 401 &&
  !window.location.pathname.includes(`/v2${SECURITY}${LOGIN}`) &&
  !window.location.pathname.includes(`/v2${SECURITY}${REQUEST_INVITATION}`) &&
  !window.location.pathname.includes(`/v2${SECURITY}${FORGOT_PASSWORD}`) &&
  !window.location.pathname.includes(`/v2${SECURITY}${RESET_PASSWORD}`) &&
  !window.location.pathname.includes(`/v2${DOWNLOAD_APP}`) &&
  !window.location.pathname.includes(LOADING_PAGE_AFTER_PAYMENT);

const useRefreshToken = (
  options: Omit<UseQueryOptions<string>, 'queryKey'> = {}
) => {
  const dispatch = useDispatch();
  const query = useQuery<string>({
    queryKey: ['refresh-token'],
    queryFn: authenticationApi.refreshToken,
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });
  useEffect(() => {
    if (query?.isSuccess) {
      JWT_STORAGE.token = query.data;
      dispatch(actions.userLoggedIn());
    }
  }, [query?.isSuccess, dispatch, query.data]);
  useEffect(() => {
    if (query?.isError) {
      if (shouldRedirect(query.error as unknown as AxiosError)) {
        dispatch(actions.userLoggedOut());
        window.location.assign(`/v2${SECURITY}${LOGIN}`);
      }
    }
  }, [query, dispatch, query?.error]);
  return query;
};

export default useRefreshToken;
