import { DraftModeActions, draftModeTypes } from './types';

const clearDraftParams = (): DraftModeActions => ({
  type: draftModeTypes.CLEAR_DRAFT_PARAMS,
});

const disableDraftMode = (): DraftModeActions => ({
  type: draftModeTypes.DISABLE_DRAFTMODE,
});

const saveGeneratedParams = (generatedParams: string): DraftModeActions => ({
  type: draftModeTypes.SAVE_GENERATED_PARAMS,
  payload: generatedParams,
});

export const draftModeActions = {
  disableDraftMode,
  clearDraftParams,
  saveGeneratedParams,
};
