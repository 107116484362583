import { breakpoints, styled } from '@swibeco/shared';
import { Caption } from '@swibeco/ui';

export const ReinsuranceElementsContainer = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  flex-wrap: wrap;
  gap: 21px;
  ${breakpoints.md_max} {
    gap: 21px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ReinsuranceElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  img {
    width: 31px;
    height: 24px;
  }
`;
export const ReinsuranceElementWrapper = styled.div`
  max-width: 260px;
  ${breakpoints.md_max} {
    max-width: 150px;
  }
`;

export const StyledCaption = styled(Caption)`
  text-align: center;
`;

export const EmojiContainer = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
`;
