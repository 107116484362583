import React from 'react';
import { styled, breakpoints } from '@swibeco/shared';
import {
  Input as RSInput,
  Label as RSLabel,
  InputProps as RSInputProps,
  FormFeedback,
} from 'reactstrap';
import { ErrorMessage } from './ErrorMessage';

const Container = styled.div`
  margin: 5px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;

  div {
    margin-top: 2px;
    ${breakpoints.md_max} {
      margin-top: 3px;
    }
    ${breakpoints.sm_max} {
      margin-top: 5px;
    }
  }
`;

const Label = styled(RSLabel)`
  font-size: 17px;
  font-weight: 500;
  margin-left: 11px;
  margin-bottom: 2px;
  line-height: 17px;
  transition: color 0.2s ease-in;
  color: ${({ labelColor, theme }) => labelColor || theme.colors.default.black};

  ${breakpoints.lg} {
    font-size: 18px;
  }

  ${breakpoints.lg_max} {
    margin-bottom: 0;
  }

  a {
    position: relative;
    z-index: 5;
  }
`;

type FillProps = {
  background: string | undefined;
  fillColor?: string[];
};

const Fill = styled.div<FillProps>`
  top: -4px;
  z-index: 1;
  width: 20px;
  height: 20px;
  position: relative;
  pointer-events: none;
  background: ${({ background }) => background ?? 'transparent'};
  transition: background 0.2s ease-in;

  &::before {
    top: 50%;
    left: 50%;
    opacity: 1;
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
    content: '\\2713';
    line-height: 18px;
    text-align: center;
    position: absolute;
    color: transparent;
    transition: color 0.2s ease-in;
    transform: translate(-50%, -50%);
    border: 2px solid
      ${({ theme, fillColor }) => {
        const [t, v] = fillColor || ['default', 'black'];
        return theme.colors[t][v];
      }};
  }
`;

const InputDesign = styled(RSInput, { shouldForwardProp: () => true })`
  top: 0;
  margin: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ * .radio-filler {
      &::before {
        transition: color 0.2s ease-out;
        color: ${({ theme }) => theme.colors.primary.main};
        border: 2px solid ${({ theme }) => theme.colors.primary.main} 
    }

    &:disabled {
      & ~ * .radio-filler::before {
        color: ${({ theme }) => theme.colors.default.strong};
        border: 2px solid ${({ theme }) => theme.colors.default.strong};
      }
    }
  }

  &:disabled {
    & ~ .radio-label {
      color: ${({ theme }) => theme.colors.default.strong};
    }

    & ~ * .radio-filler::before {
      border: 2px solid ${({ theme }) => theme.colors.default.strong};
    }
  }
`;

export interface CheckboxProps extends Omit<RSInputProps, 'type'> {
  name: string;
  label?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
  background?: string;
  startAsColor?: string[];
}

const Checkbox = React.forwardRef<any, CheckboxProps>(
  ({ name, label, errors, background, fillColor, ...rest }, ref) => {
    return (
      <>
        <Container className="radio-container">
          <InputDesign
            type="checkbox"
            name={name}
            {...rest}
            innerRef={ref}
            data-testid={rest['data-testid']}
            role="checkbox"
          />
          <Label className="radio-label">{label}</Label>
          <div>
            <Fill
              className="radio-filler"
              background={background}
              fillColor={fillColor}
            />
          </div>
        </Container>

        {errors && (
          <FormFeedback className="d-flex mb-2">
            {errors && <ErrorMessage>{errors[name]?.message}</ErrorMessage>}
          </FormFeedback>
        )}
      </>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
