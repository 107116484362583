import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@swibeco/ui';
import {
  GlobalSelectors,
  PlusDealSlugs,
  trackBannerPlusEvent,
  createBannerPlusEvent,
  PLUS_LANDING_PAGE,
  useExecuteOnView,
} from '@swibeco/shared';
import {
  MainContentWrapper,
  useCanAccessPlusLanding,
} from '@swibeco/shared-web';
import { UserTypeEnum, AnalyticsEvents } from '@swibeco/types';
import { selectors as coreSelectors, usePlatform } from '@swibeco/core';
import * as Styles from './PlusBanner.styles';

const PlusBanner = () => {
  const { t } = useTranslation();
  const locale = useSelector(coreSelectors.getLocale);
  const userType = useSelector(GlobalSelectors.user.getUserType);
  const targetRef = useRef<HTMLDivElement>(null);
  const plusLandingAccess = useCanAccessPlusLanding();
  const environment = usePlatform(window);

  const bannerTitleTranslation = t('core.ecommerce.homepage.plus_banner.title');

  const selectPromotionEventData = createBannerPlusEvent(
    AnalyticsEvents.SELECT_PROMOTION,
    environment,
    bannerTitleTranslation
  );

  const viewPromotionEventData = createBannerPlusEvent(
    AnalyticsEvents.VIEW_PROMOTION,
    environment,
    bannerTitleTranslation
  );

  useExecuteOnView(() => {
    trackBannerPlusEvent(viewPromotionEventData);
  }, targetRef);

  const plusTrialLink = useMemo(
    () =>
      plusLandingAccess
        ? PLUS_LANDING_PAGE
        : `product/${PlusDealSlugs[locale]}`,
    [locale, plusLandingAccess]
  );

  if (userType !== UserTypeEnum.FREE) {
    return null;
  }

  return (
    <Styles.Wrapper data-testid="plus-banner" ref={targetRef}>
      <Link
        className="d-block"
        to={plusTrialLink}
        data-testid="plus-banner-link"
        onClick={() => trackBannerPlusEvent(selectPromotionEventData)}
      >
        <MainContentWrapper className="position-relative h-0">
          <Styles.Headline>
            <Styles.ChronometerIcon />
            <Text color="default.white">
              {t('core.ecommerce.homepage.plus_banner.headline_label')}
            </Text>
          </Styles.Headline>
          <Styles.UpgradeArrows />
        </MainContentWrapper>
        <Styles.Banner>
          <Styles.BannerContent>
            <Styles.PlusExpandedIcon />
            <div>
              <Text color="default.white" as="h3" important>
                {bannerTitleTranslation}
              </Text>
              <Styles.Checklist>
                <Styles.ChecklistItem>
                  <Styles.CheckmarkIcon />
                  <Text color="default.white">
                    {t('core.ecommerce.homepage.plus_banner.benefits.item1')}
                  </Text>
                </Styles.ChecklistItem>
                <Styles.ChecklistItem>
                  <Styles.CheckmarkIcon />
                  <Text color="default.white">
                    {t('core.ecommerce.homepage.plus_banner.benefits.item2')}
                  </Text>
                </Styles.ChecklistItem>
                <Styles.ChecklistItem>
                  <Styles.CheckmarkIcon />
                  <Text color="default.white">
                    {t('core.ecommerce.homepage.plus_banner.benefits.item3')}
                  </Text>
                </Styles.ChecklistItem>
              </Styles.Checklist>
            </div>
            <Styles.MoreInformationButton thin lightColor color="primary">
              {t('core.ecommerce.homepage.banner.more_information')}
            </Styles.MoreInformationButton>
          </Styles.BannerContent>
        </Styles.Banner>
      </Link>
    </Styles.Wrapper>
  );
};

export default PlusBanner;
