import { produce } from 'immer';
import { DraftModeStore, draftModeTypes, DraftModeActions } from './types';

export const filterDraftModeReducer = produce(
  (state: DraftModeStore, action: DraftModeActions) => {
    switch (action.type) {
      case draftModeTypes.ENABLE_DRAFTMODE: {
        state.generatedParams = '';
        state.isDraftMode = true;
        break;
      }
      case draftModeTypes.DISABLE_DRAFTMODE: {
        state.generatedParams = '';
        state.isDraftMode = false;
        break;
      }
      case draftModeTypes.CLEAR_DRAFT_PARAMS: {
        state.generatedParams = '';
        break;
      }
      case draftModeTypes.SAVE_GENERATED_PARAMS: {
        state.generatedParams = action.payload;
        state.isDraftMode = true;
        break;
      }
      default:
        break;
    }
  }
);
