import { breakpoints, styled } from '@swibeco/shared';
import { Button, SubTitle } from '@swibeco/ui';
import { Col } from 'reactstrap';

export const BrandsWrapper = styled.section<{ limitHeight?: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.default.black};

  padding: 20px 0 33px 0;

  ${({ limitHeight }) =>
    limitHeight
      ? `max-height: 745px;
    overflow: hidden;`
      : ''}

  ${breakpoints.md} {
    padding: 44px 0 62px 0;
    ${({ limitHeight }) =>
      limitHeight
        ? `max-height: 570px;
    overflow: hidden;`
        : ''}
  }
`;

export const Title = styled(SubTitle)`
  color: ${({ theme }) => theme.colors.default.white};
  font-size: 1.529rem !important;
  line-height: 33px !important;
  font-weight: 600;

  margin-bottom: 13px;

  ${breakpoints.md} {
    margin-bottom: 16px;
  }
`;

export const BrandItem = styled.div`
  margin-bottom: 16px;

  ${breakpoints.md} {
    margin-bottom: 8px;
  }
`;
export const StyledCol = styled(Col)`
  margin-bottom: 24px;

  ${breakpoints.md} {
    margin-bottom: 32px;
  }
`;

export const StyledButton = styled(Button)`
  width: 95%;
  height: 50px;
  max-width: 310px;

  ${breakpoints.md} {
    height: 45px;
    width: 127px;
  }
`;

export const ButtonWrapper = styled.div<{ hasGradient?: boolean }>`
  height: 316px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: 0;
  padding-bottom: 26px;
  bottom: 0px;
  ${({ theme }) =>
    `background: linear-gradient(360deg, ${theme.colors.default.black} 34.25%, rgba(30, 32, 35, 0) 90.54%);`}
`;

export const LoadAllWrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: 0;
  padding-bottom: 26px;
  bottom: 0px;
`;
