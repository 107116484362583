import React from 'react';
import { Text } from '@swibeco/ui';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Welcome = () => {
  const { t } = useTranslation();
  return (
    <Flex
      align={{ base: 'center', lg: 'flex-start' }}
      background="rgba(255, 210, 62, 0.2)"
      borderRadius="5px"
      padding="8.5px 16px"
      maxW="478px"
      flexDirection="column"
    >
      <Flex flexDirection="row" align="center">
        <Text textAlign="center">
          {t('core.ecommerce.homepage.tutorial.step.welcome.content')}
        </Text>
      </Flex>
    </Flex>
  );
};
export default Welcome;
