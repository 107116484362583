import { NavLink } from 'react-router-dom';
import { breakpoints, styled } from '@swibeco/shared';
import { Text } from '@swibeco/ui';

export const MenuItem = styled(NavLink)`
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  height: 50px;

  ${breakpoints.md} {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.default.black};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.primary.main}1A;
    border-left: 4px solid ${({ theme }) => theme.colors.primary.main};

    svg {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.default.main};
  }
`;

export const IconContainer = styled.div`
  width: 60px;
`;

export const IconText = styled(Text)`
  font-size: 12px !important;
  padding-top: 0.5rem;
  line-height: 17px !important;

  // In bootstrap v.4 we do not have any breakpoint for 1400px (maximum is xl = 1200px).
  @media (min-width: 1400px) {
    font-size: 14px !important;
  }
`;
