import React from 'react';
import { Card as RSCard, CardBody, Collapse } from 'reactstrap';
import { styled } from '@swibeco/shared';
import { CloseIcon, OpenIcon } from './iconography';
import Text from './typography/Text';

const Header = styled.div`
  box-shadow: 0 3px 6px ${({ theme }) => theme.colors.shadow.primary};
  background-color: ${({ theme }) => theme.colors.default.white};
  position: relative;
  z-index: 5;
  cursor: pointer;
  border-radius: 4px;
`;

const Card = styled(RSCard)`
  border-top-color: transparent;
`;

export type AccordionProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  header: React.ReactNode;
  isOpen: boolean;
  textColor?: {
    variant?: string;
    variantColor?: string;
  };
  toggle: () => void;
};

const Accordion = ({
  header,
  isOpen,
  toggle,
  textColor,
  children,
  ...rest
}: AccordionProps) => {
  return (
    <>
      <Header
        className="d-flex align-items-center justify-content-between p-3 mt-2"
        onClick={toggle}
        {...rest}
      >
        <Text
          component="div"
          color={
            {
              variant: textColor?.variant || 'default',
              variantColor: textColor?.variantColor || 'black',
            } as any
          }
        >
          <strong>{header}</strong>
        </Text>
        {isOpen ? <CloseIcon /> : <OpenIcon />}
      </Header>
      <Collapse isOpen={isOpen} className="mb-3">
        <Card>
          <CardBody>
            <Text
              component="span"
              color={
                {
                  variant: textColor?.variant || 'default',
                  variantColor: textColor?.variantColor || 'black',
                } as any
              }
            >
              {children}
            </Text>
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};

export default Accordion;
