import { breakpoints, styled } from '@swibeco/shared';
import { Button } from '@swibeco/ui';

export const AxaBannerWrapper = styled.section`
  width: 100%;
  min-height: 20vh;
  display: flex;
  flex-wrap: wrap;
  background: #03098e;
  padding: 2% 5%;

  ${breakpoints.md_max} {
    padding: 5%;
  }

  ${breakpoints.xxl} {
    padding: 2% 10%;
  }
`;

export const AxaFirstColumn = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10%;

  ${breakpoints.md_max} {
    width: 100%;
  }
`;

export const AxaTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const AxaIconBubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.default.white};
  border-radius: 100%;
`;

export const AxaIcon = styled.img<{ isAxaSunbed?: boolean }>`
  width: ${({ isAxaSunbed }) => (isAxaSunbed ? '85%' : '60%')};
`;

export const AxaButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.default.white};
  margin-top: 20px;

  ${breakpoints.md_max} {
    margin-bottom: 10px;
  }
`;

export const AxaSecondColumn = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${breakpoints.md_max} {
    width: 100%;
  }
`;

export const AxaServices = styled.div`
  display: flex;
  width: 50%;
  align-items: center;

  ${breakpoints.md_max} {
    width: 100%;
    margin-top: 20px;
  }
`;
