export type DraftModeStore = {
  isDraftMode: boolean;
  generatedParams: string;
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum draftModeTypes {
  ENABLE_DRAFTMODE = 'ENABLE_DRAFTMODE',
  DISABLE_DRAFTMODE = 'DISABLE_DRAFTMODE',
  CLEAR_DRAFT_PARAMS = 'CLEAR_DRAFT_PARAMS',
  SAVE_GENERATED_PARAMS = 'SAVE_GENERATED_PARAMS',
}
type Payload = {
  [draftModeTypes.ENABLE_DRAFTMODE]: undefined;
  [draftModeTypes.DISABLE_DRAFTMODE]: undefined;
  [draftModeTypes.CLEAR_DRAFT_PARAMS]: undefined;
  [draftModeTypes.SAVE_GENERATED_PARAMS]: string;
};

export type DraftModeActions = ActionMap<Payload>[keyof ActionMap<Payload>];
