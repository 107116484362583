/* eslint-disable indent */
import { breakpoints, styled } from '@swibeco/shared';

export const Wrapper = styled.span`
  width: 25px;
  display: flex;
  margin: auto;

  ${breakpoints.md} {
    width: 30px;
  }
`;
