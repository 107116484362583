import React from 'react';
import * as Styles from './styles/HeaderItem.styles';

type HeaderItemProps = {
  noBorder?: boolean;
  className?: string;
  children: React.ReactNode;
};

const HeaderItem = ({ children, noBorder, className }: HeaderItemProps) => (
  <Styles.HeaderItem noBorder={noBorder} className={className}>
    {children}
  </Styles.HeaderItem>
);

export default HeaderItem;
