import { LegacyAPI } from '@swibeco/shared';
import {
  LegacyCurrentUserType,
  LegacySubmitActivationDataType,
  UpdateCurrentUserResponseType,
  UpdateCurrentUserType,
} from '@swibeco/types';
import camelcaseKeys from 'camelcase-keys';

export default {
  getLegacyUserProfile: async (): Promise<LegacyCurrentUserType> => {
    const response = await LegacyAPI.get('/user');

    return camelcaseKeys(response.data.data, { deep: true });
  },
  updateUserProfile: async (data: {
    id: number;
    params: Partial<UpdateCurrentUserType>;
  }): Promise<UpdateCurrentUserResponseType> => {
    const response = await LegacyAPI.patch(
      `/users/${data.id}/update`,
      data.params
    );
    return camelcaseKeys(response.data, { deep: true });
  },
  updateLegacyUserProfilePhoto: async (data: { id: number; fd: FormData }) => {
    const response = await LegacyAPI.post(
      `/users/${data.id}/update/photo`,
      data.fd
    );

    return camelcaseKeys(response.data, { deep: true });
  },
  submitLegacyActivationData: async (data: LegacySubmitActivationDataType) => {
    const response = await LegacyAPI.post(
      '/registration/submit-activation-data',
      data
    );
    return camelcaseKeys(response.data, { deep: true });
  },
};
