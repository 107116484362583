import { Box, Flex, Image } from '@chakra-ui/react';
import {
  createBannerPlusEvent,
  ECOMMERCE_BRAND_ROOT,
  PLUS_LANDING_PAGE,
  trackBannerPlusEvent,
  useTheme,
} from '@swibeco/shared';
import { AnalyticsEvents, SyliusBrand, UserTypeEnum } from '@swibeco/types';
import { Button, Text } from '@swibeco/ui';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useGetCurrentUser } from '@swibeco/security';
import { usePlatform } from '@swibeco/core';
import { ReactComponent as LockIcon } from '../../../assets/svgs/lock.svg';
import { ReactComponent as LockOpenIcon } from '../../../assets/svgs/lock_open.svg';

export type BrandItemProps = {
  brand: SyliusBrand;
};

export default function BrandItem({ brand }: BrandItemProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: user } = useGetCurrentUser();
  const environment = usePlatform(window);
  const navigate = useNavigate();

  const isUnlocked = useMemo(() => {
    if (!user) return false;
    if (user.type === UserTypeEnum.PRO || user.type === UserTypeEnum.PLUS) {
      return true;
    }
    if (brand.channelAvailability.includes('FREE')) {
      return true;
    }
    return false;
  }, [user, brand.channelAvailability]);

  const ctaText = useMemo(() => {
    if (isUnlocked) {
      return t('core.ecommerce.brand_item.cta');
    }
    if (user?.trialEligibility) {
      return t('core.ecommerce.brand_item.user_trial_eligible');
    }
    return t('core.ecommerce.brand_item.user_trial_not_eligible');
  }, [isUnlocked, t, user?.trialEligibility]);

  const handleNavigate = () => {
    const selectPromotionEventData = createBannerPlusEvent(
      AnalyticsEvents.SELECT_PROMOTION,
      environment,
      'exclusive-brands',
      undefined,
      brand.id.toString()
    );
    trackBannerPlusEvent(selectPromotionEventData);
    navigate(
      isUnlocked ? `${ECOMMERCE_BRAND_ROOT}/${brand.slug}` : PLUS_LANDING_PAGE
    );
  };

  return (
    <Box
      data-testid={`brand-promotion-card-${brand.id}`}
      minWidth={{ base: '100%', sm: '330px' }}
      width="100%"
      maxWidth={{ base: '100%', md: '330px' }}
      pos="relative"
    >
      <Flex
        data-testid="background-image"
        borderRadius="4px"
        bgSize="cover"
        bgRepeat="no-repeat"
        bgImage={`linear-gradient(360deg, rgba(27, 42, 106, 0.9) 0%, rgba(27, 42, 106, 0.5) 100%), url(${brand.backgroundImageName})`}
        minH="170px"
        minW="200px"
        justifyContent="center"
        w="100%"
      >
        <Flex flexDir="column" mt="22px" alignItems="center" gap="8px">
          <Box bgColor="white" width="fit-content" p="4px" borderRadius="4px">
            <Image
              data-testid="logo-image"
              w="96px"
              h="43px"
              objectFit="contain"
              src={brand.logoName}
              alt={brand.translationMap?.name}
            />
          </Box>
          <Flex alignItems="center" gap="8px" color="white">
            {isUnlocked ? <LockOpenIcon /> : <LockIcon />}
            <Text color="default.white">
              {isUnlocked
                ? t('core.ecommerce.brand_item.unlock_description')
                : t('core.ecommerce.brand_item.lock_description')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        pos="absolute"
        borderRadius="4px"
        minH="167px"
        mt="-50px"
        w="91%"
        // w={`calc(${boxRef.current?.offsetWidth}px - 30px)`}
        marginX="auto"
        left="15px"
        bgColor="white"
        p="16px"
        justifyContent={isUnlocked ? 'space-between' : 'center'}
        alignItems="center"
        gap="8px"
        border="1px solid"
        borderColor="default.middle"
      >
        <Box
          data-testid="discount-text"
          px="8px"
          width="fit-content"
          border="1px solid"
          borderColor="primary.main"
          borderRadius="32px"
          color="primary.main"
        >
          <Trans
            i18nKey="core.ecommerce.brand_item.discount"
            values={{
              discountHigh: brand.discountHigh,
            }}
          />
        </Box>
        <Text textAlign="center">
          {isUnlocked
            ? t('core.ecommerce.brand_item.plus_unlocked')
            : t('core.ecommerce.brand_item.plus_unlock')}
        </Text>

        <Button
          data-testid="plus-purchase-button"
          onClick={handleNavigate}
          color="custom"
          colorScheme={{
            backgroundColor: theme.colors.primary.plusBlue,
            color: theme.colors.default.white,
            borderColor: theme.colors.primary.plusBlue,
            hoverBackgroundColor: theme.colors.primary.plusBlue,
            hoverBorderColor: theme.colors.primary.plusBlue,
            activeBackgroundColor: `${theme.colors.primary.plusBlue}66`,
          }}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {ctaText}
        </Button>
      </Flex>
    </Box>
  );
}
