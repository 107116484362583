import React from 'react';
import { styled } from '@swibeco/shared';
import Caption from './typography/Caption';
import Text from './typography/Text';
import dottedCircle from './images/dotted_circle.svg';

const Block = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
`;

const Circle = styled.div`
  font-size: 23px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${dottedCircle});
  background-repeat: no-repeat;
  background-position: center;
`;

export type OrderedElementProps = {
  step: React.ReactNode;
  title?: string;
  description: React.ReactNode;
  additionalInfos?: React.ReactElement;
};

export const OrderedElementStrong = styled.strong`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary.main};
`;

const OrderedElement = ({
  step,
  title,
  description,
  additionalInfos,
}: OrderedElementProps) => {
  return (
    <Block className="column-block">
      <Circle className="circle" data-testid="column-step">
        {step}
      </Circle>
      <div className="d-flex flex-column justify-content-center">
        {title && (
          <Text className="column-title mb-3">
            <strong>{title}</strong>
          </Text>
        )}
        <div>
          <Text>{description}</Text>
          {additionalInfos && (
            <Caption color="default.strong" mt="6">
              {additionalInfos}
            </Caption>
          )}
        </div>
      </div>
    </Block>
  );
};

export default OrderedElement;
