/* istanbul ignore file */
import { InputGroupText as RSInputGroupText } from 'reactstrap';
import { styled } from '@swibeco/shared';

const InputGroupText = styled(RSInputGroupText)`
  background-color: transparent;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.default.black};
`;

export default InputGroupText;
