import React, { useContext } from 'react';
import { Accordion } from '@swibeco/ui';
import { InformationsContext } from './Informations';

export type InformationProps = {
  question: string | React.ReactNode;
  answer: React.ReactNode;
  number: number;
  color?: {
    variant?: string;
    variantColor?: string;
  };
};

const Information = ({ question, answer, number, color }: InformationProps) => {
  const { currentInformation, toggleInformation } =
    useContext(InformationsContext);

  return (
    <Accordion
      textColor={color}
      header={question}
      isOpen={currentInformation === number}
      toggle={() => toggleInformation(number, question)}
      data-testid={`information-${number}`}
    >
      {answer}
    </Accordion>
  );
};

export default Information;
