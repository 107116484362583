import { Flex, useToken } from '@chakra-ui/react';
import {
  ECOMMERCE_CATEGORY_ROOT,
  hexToRgb,
  moneyWithDecimalConvertor,
  useNumberFormatter,
} from '@swibeco/shared';
import { Link, Popover, Text } from '@swibeco/ui';
import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { syliusApi } from '@swibeco/ecommerce';
import { useTranslation } from 'react-i18next';
import { EnumLinkProps } from '@swibeco/types';
import { ReactComponent as IconSavings } from '../../../../../assets/images/savings.svg';
// import { ReactComponent as IconPowerUps } from '../../../../../assets/images/powerups.svg';

// const ArrowRight = chakra(Arrow);
const Savings = () => {
  const { t } = useTranslation();
  const lightGreen = useToken('colors', 'complementary.light');
  const { data } = useQuery({
    queryKey: ['savings'],
    queryFn: () => syliusApi.getSavings(),
  });
  const formatter = useNumberFormatter();

  const monthlyCHFSaved = useMemo(
    () => moneyWithDecimalConvertor(data?.savingsAmount),
    [data]
  );

  return (
    <Flex justify="center" gap="8px">
      {/* <Flex w="44%" gap="8px">
        <Flex
          backgroundColor="secondary.decoration.red.light"
          w="42px"
          h="32px"
          justify="center"
          align="center"
          borderRadius="small"
        >
          <IconPowerUps width="19px" height="16px" p="8px 7px" />
        </Flex>
        <Flex direction="column" gap="8px">
          <Text variant="caption">Your savings objectifs/month</Text>
          <Text variant="default-important">
            CHF{' '}
            <Text
              variant="default-important"
              sx={{ filter: 'blur(4.5px)' }}
              as="span"
            >
              175.-
            </Text>{' '}
          </Text>

          <HoverBehavior className="mr-1" opacity={0.2}>
            <Flex w="100%" align="center" gap="8px">
              <Text variant="caption" color="primary.main">
                Calculate
              </Text>
              <ArrowRight
                direction={ArrowDirection.Right}
                sx={{
                  display: 'inline-flex',
                }}
              />
            </Flex>
          </HoverBehavior>
        </Flex>
      </Flex>
      <Divider
        orientation="vertical"
        w="1px"
        h="64px"
        backgroundColor="default.middle"
      /> */}
      <Popover
        placement="bottom"
        popoverTrigger={
          <Flex
            flexDir="column"
            alignItems="center"
            gap="8px"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <Flex
              backgroundColor={
                hexToRgb(lightGreen, 0.1) || 'complementary.light'
              }
              w="32px"
              h="32px"
              justify="center"
              align="center"
              borderRadius="small"
            >
              <IconSavings width="19px" height="16px" p="8px 7px" />
            </Flex>
            <Flex alignItems="center" direction="column" gap="8px">
              <Text textAlign="center" variant="caption" color="default.dark">
                {t('core.ecommerce.user_panel.monthly_savings')}
              </Text>
              <Text textAlign="center" bold>
                {formatter(monthlyCHFSaved)}
              </Text>
            </Flex>
            {data?.savingsAmount === 0 && (
              <Link
                type={EnumLinkProps.NewPage}
                to={`${ECOMMERCE_CATEGORY_ROOT}/all-results`}
              >
                {t('core.ecommerce.user_panel.no_savings')}
              </Link>
            )}
          </Flex>
        }
      >
        <Text>{t('core.ecommerce.user_panel.savings_info')}</Text>
      </Popover>
    </Flex>
  );
};
export default Savings;
