import { breakpoints, styled } from '@swibeco/shared';
import { Box } from '@chakra-ui/react';
import { Chevron as OriginalChevron } from '@swibeco/ui';

export const DropdownToggle = styled(Box)`
  position: relative;
  ${breakpoints.lg} {
    &:hover {
      .background {
        display: inline-block;
        position: absolute;
        width: 42px;
        height: 42px;
        background-color: ${({ theme }) => theme.colors.default.main};
        border-radius: 50px;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export const Chevron = styled(OriginalChevron)`
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 0.3rem;
`;
