import { styled, breakpoints } from '@swibeco/shared';
import { Button, Icons } from '@swibeco/ui';
import { MainContentWrapper } from '@swibeco/shared-web';
import { ReactComponent as UpgradeArrowsBase } from '../../assets/images/upgrade-arrows.svg';
import bgMobile from '../../assets/images/plus_banner_bg_sm.png';
import bgDesktop from '../../assets/images/plus_banner_bg_lg.png';

export const Wrapper = styled.div`
  padding-top: 45px;
  position: relative;
`;

export const Headline = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.complementary.red};
  display: flex;
  gap: 8px;
  height: 37px;
  left: 0;
  padding: 7px;
  position: absolute;
  top: -18px;
  z-index: 1;
`;

export const ChronometerIcon = styled(Icons.IconChronometer)`
  fill: ${({ theme }) => theme.colors.default.white};
  height: 12px;
  stroke: ${({ theme }) => theme.colors.default.white};
  width: 12px;
`;

export const Banner = styled.div`
  background: url(${bgMobile}) no-repeat left bottom;
  background-size: cover;
  padding: 33px 0;

  ${breakpoints.sm} {
    background-image: url(${bgDesktop});
    padding: 47px 0;
  }
`;

export const BannerContent = styled(MainContentWrapper)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 18px;
  position: relative;
  z-index: 1;

  ${breakpoints.sm} {
    align-items: center;
    flex-direction: row;
  }
`;

export const CheckmarkIcon = styled(Icons.IconCheckSuccess)`
  height: 25px;
  stroke: ${({ theme }) => theme.colors.default.white};
  width: 25px;
`;

export const PlusExpandedIcon = styled(Icons.IconPlusExpandedInverted)`
  display: block;
  height: 50px;
  width: 118px;

  ${breakpoints.sm} {
    height: 60px;
    width: 141px;
  }
`;

export const Checklist = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style-type: none;
  margin-block: 0;
  margin-top: 16px;
  padding: 0;

  ${breakpoints.sm} {
    flex-direction: row;
    margin-top: 8px;
  }
`;

export const ChecklistItem = styled.li`
  display: flex;
  gap: 8px;
`;

export const MoreInformationButton = styled(Button)`
  align-self: center;
  flex: 0 1 45px;
  margin-top: 16px;

  ${breakpoints.sm} {
    flex: unset;
    margin: 0 auto;
  }

  ${breakpoints.xl} {
    margin: 0 0 0 auto;
  }
`;

export const UpgradeArrows = styled(UpgradeArrowsBase)`
  position: absolute;
  right: 0;
  top: -45px;
  width: 100px;

  ${breakpoints.sm} {
    right: -50px;
  }
`;
