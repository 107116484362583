import React from 'react';
import { Text } from '@swibeco/ui';
import { useNumberFormatter } from '@swibeco/shared';
import { EnumDealOfferType } from '@swibeco/types';
import * as Styles from './DealItem.styles';
import dealVoucherIcon from '../../assets/images/deal-voucher.svg';
import { isProductPageDeal } from './DealItem';

interface DealImageProps {
  deal: any;
  displayBrand?: boolean;
  imageHasMargin?: boolean;
  isBasket?: boolean;
  dealImageContainerClass?: string;
}
export default function DealImage({
  deal,
  displayBrand = true,
  imageHasMargin = true,
  isBasket = false,
  dealImageContainerClass,
}: DealImageProps) {
  const formatter = useNumberFormatter();
  const isVoucherDeal = deal.offerType === EnumDealOfferType.Voucher;
  const displayLogo = deal?.branding?.logoThumbnail || deal?.branding?.logo;
  const showInhouseIcon = deal.isInternal;
  const dealTitle =
    isProductPageDeal(deal) && showInhouseIcon ? deal.title : deal.shortTitle;
  const isFreeVoucher = deal.offerType === 'voucher' && deal.finalPrice === 0;

  return (
    <Styles.DealCardImageContainer
      imageHasMargin={imageHasMargin}
      isBasket={isBasket}
      className={dealImageContainerClass}
    >
      <Styles.DealCardImage
        isVoucherDeal={isVoucherDeal}
        className="deal-card-image"
        src={deal.thumbnailUrl}
        alt={dealTitle}
        isBasket={isBasket}
      />
      {isVoucherDeal && (
        <>
          <Styles.DealCardImageOverlay className="deal-card-image-overlay" />
          {!isBasket && (
            <Styles.DealCardImageShortener className="position-absolute w-100" />
          )}
          <Styles.DealVoucherTicket
            className="deal-voucher-ticket"
            isBasket={isBasket}
          >
            <img
              className="w-100"
              src={dealVoucherIcon}
              alt="Deal Voucher Icon"
            />
            <div className="position-absolute d-flex align-items-center justify-content-around h-100 w-100">
              <Styles.DealVoucherLogo
                src={displayLogo}
                alt={deal?.branding?.name}
              />
              <Styles.DealVoucherText>
                <Text
                  important
                  {...(isBasket && {
                    color: 'default.black',
                    _hover: { color: 'default.black' },
                    fontSize: { base: '11px', lg: '15px' },
                    lineHeight: { base: '17px', lg: '18px' },
                  })}
                >
                  {isFreeVoucher ? dealTitle : formatter(deal.listPrice)}
                </Text>
              </Styles.DealVoucherText>
            </div>
          </Styles.DealVoucherTicket>
        </>
      )}
      {displayBrand && (
        <Styles.DealCardImageBrand className="position-absolute d-flex align-items-center justify-content-center">
          <img src={displayLogo} alt={deal?.branding?.name} />
        </Styles.DealCardImageBrand>
      )}
    </Styles.DealCardImageContainer>
  );
}
