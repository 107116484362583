import { UserTypeEnum } from '@swibeco/types';

export const isPremiumUser = (userType?: UserTypeEnum) =>
  userType === UserTypeEnum.PLUS || userType === UserTypeEnum.PRO;

export const isFreeUser = (userType?: UserTypeEnum) =>
  userType === UserTypeEnum.FREE;

export const isProUser = (userType?: UserTypeEnum) =>
  userType === UserTypeEnum.PRO;

export const isPlusUser = (userType?: UserTypeEnum) =>
  userType === UserTypeEnum.PLUS;
