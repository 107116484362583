import { API, LegacyAPI } from '@swibeco/shared';
import { Config, CountryType, NationalityType } from '@swibeco/types';

export default {
  setLocale: async (locale: string) => {
    await LegacyAPI.post('/set_language', { language: locale });
  },
  /**
   * Call Api to get list of translated countries.
   *
   * @returns {CountryType[]} The list of countries with following structure:
   */
  getCountries: async (locale?: string) => {
    const response = await API.get<CountryType[]>('/intl/countries', {
      params: {
        locale,
      },
    });

    return response.data;
  },
  /**
   * Call Api to get list of translated nationalities.
   *
   * @returns {NationalityType[]} The list of nationalities with following structure:
   */
  getNationalities: async (locale?: string) => {
    const response = await API.get<NationalityType[]>('/intl/nationalities', {
      params: {
        locale,
      },
    });

    return response.data;
  },
  /**
   * Call Api to get GTM & Sentry ids.
   *
   * @returns {Config} The config object with GTM & Sentry
   */
  getConfig: async (): Promise<Config> => {
    const response = await API.get<Config>('/config');
    return response.data;
  },
};
