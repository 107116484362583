import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ecommerceApi } from '@swibeco/ecommerce';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserAuthenticated } from '@swibeco/types';
import { FAVOURITES_PAGE, useTheme } from '@swibeco/shared';
import { Link } from '@swibeco/ui';
import HeaderItem from './HeaderItem';
import { Badge } from './Badge';

const Wishlist = ({ isAuthenticated }: UserAuthenticated) => {
  const wishlistCountQuery = useQuery<number>({
    enabled: !!isAuthenticated,
    refetchOnWindowFocus: true,
    staleTime: 0,
    queryKey: ['wishlistCount'],
    queryFn: ecommerceApi.getWishlistCount,
  });
  const theme = useTheme();

  return (
    <HeaderItem noBorder>
      <Link
        to={FAVOURITES_PAGE}
        data-testid="wishlist-btn"
        aria-label="User Wishlist"
      >
        <Badge
          count={wishlistCountQuery.isSuccess ? wishlistCountQuery.data : 0}
        >
          <FontAwesomeIcon
            icon={faHeart}
            size="lg"
            color={theme.colors.default.black}
          />
          <span className="background" />
        </Badge>
      </Link>
    </HeaderItem>
  );
};

export default Wishlist;
