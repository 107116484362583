import React from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';

type CustomIconButtonProps = Pick<
  IconButtonProps,
  'icon' | 'aria-label' | 'color' | 'onClick'
>;

const CustomIconButton = ({
  icon,
  'aria-label': ariaLabel,
  color,
  onClick,
}: CustomIconButtonProps) => (
  <IconButton
    variant="ghost"
    minW={0}
    h="40px"
    w="40px"
    borderRadius="100%"
    _hover={{
      bg: 'default.main',
    }}
    color={color}
    sx={{
      svg: {
        width: '24px',
        height: '24px',
      },
    }}
    icon={icon}
    aria-label={ariaLabel}
    onClick={onClick}
  />
);

export default CustomIconButton;
