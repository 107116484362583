import React, { HTMLAttributes } from 'react';
import { styled } from '@swibeco/shared';

type QuestionMarkProps = {
  id?: string;
  width?: number;
  height?: number;
  className?: string;
};

const QuestionMarkWrapper = styled.span<{ width?: number }>`
  width: ${({ width }) => `${width || 30}px`};
  cursor: pointer;

  &:hover {
    g {
      fill: ${({ theme }) => theme.colors.default.dark};
    }

    text {
      fill: ${({ theme }) => theme.colors.default.white};
    }
  }
`;

const QuestionMark = ({
  id = 'questionMark',
  width,
  height,
  className,
  ...rest
}: QuestionMarkProps & HTMLAttributes<HTMLSpanElement>) => (
  <QuestionMarkWrapper
    className={className ? `question-mark ${className}` : 'question-mark'}
    width={width}
    id={id}
    {...rest}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 30}
      height={height || 30}
      data-name="Tooltip/dark"
      viewBox="0 0 30 30"
    >
      <g fill="#fff" stroke="#707070" strokeWidth="2" data-name="Ellipse 33">
        <circle cx="15" cy="15" r="15" stroke="none" />
        <circle cx="15" cy="15" r="14" fill="none" />
      </g>
      <text
        fill="#707070"
        data-name="?"
        fontFamily="museo-sans"
        fontSize="19"
        fontWeight="700"
        transform="translate(15.273 22.091)"
      >
        <tspan x="-4.589" y="0">
          ?
        </tspan>
      </text>
    </svg>
  </QuestionMarkWrapper>
);

export default QuestionMark;
