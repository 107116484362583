/* eslint-disable camelcase */
import nativeStyled from '@emotion/styled';
import {
  Global as nativeGlobal,
  keyframes as nativeKeyframes,
  useTheme as useNativeTheme,
  ThemeProvider as NativeThemeProvider,
  css as nativeCss,
} from '@emotion/react';
import { ThemeType } from '@swibeco/types';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends ThemeType {}
}

export const css = nativeCss;
export const styled = nativeStyled;
export const keyframes = nativeKeyframes;
export const useTheme = () => useNativeTheme();
export const ThemeProvider = NativeThemeProvider;
export const Global = nativeGlobal;
