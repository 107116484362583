import { selectors as coreSelectors } from '@swibeco/core';
import { syliusApi, useUniverseParams } from '@swibeco/ecommerce';
import { getTranslationsForLocale } from '@swibeco/shared';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Flex, FlexProps } from '@chakra-ui/react';
import { SyliusBrand } from '@swibeco/types';
import BrandItem from './BrandItem';
import useRouterParams from '../../../hooks/useRouterParams';

type BrandsList = FlexProps;

export default function BrandsList({ ...props }) {
  const locale = useSelector(coreSelectors.getLocale);

  const universeParams = useUniverseParams();
  const { params } = useRouterParams();
  const { data } = useQuery<SyliusBrand[]>({
    queryKey: ['brands-sylius', universeParams, locale, params],
    queryFn: () =>
      syliusApi.getBrands(undefined, locale, {
        ...params,
        itemsPerPage: params.itemsPerPage || 200,
      }),
  });

  const translatedBrands = useMemo(
    () =>
      data
        ?.map((brand) => ({
          ...brand,
          translationsMap: getTranslationsForLocale(locale, brand.translations),
        }))
        .sort((a, b) =>
          a.translationsMap.name.localeCompare(b.translationsMap.name)
        ),
    [data, locale]
  );

  return (
    <Flex {...props}>
      {translatedBrands?.map((brand) => (
        <BrandItem key={brand.id} brand={brand} />
      ))}
    </Flex>
  );
}
