import React from 'react';
import { Heading, type HeadingProps } from '@chakra-ui/react';

export type PageTitleProps = HeadingProps & {
  children: React.ReactNode;
};

const PageTitle = ({ as = 'h1', color, children, ...rest }: PageTitleProps) => (
  <Heading as={as} variant="page-title" color={color} {...rest}>
    {children}
  </Heading>
);

export default PageTitle;
