import { useCurrentUser } from '../contexts';

const useCurrentUserTrialEligibility = () => {
  const { user } = useCurrentUser();

  if (!user) {
    return false;
  }

  return user.trialEligibility;
};

export default useCurrentUserTrialEligibility;
