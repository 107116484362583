import { Col, Modal as ModalBase } from 'reactstrap';
import { styled } from '@swibeco/shared';

export const CloseButtonWrapper = styled(Col)`
  min-height: 62px;
`;

export const CloseButton = styled.button`
  top: 16px;
  opacity: 1;
  right: 28px;
  color: ${({ theme }) => theme.colors.default.white};
  font-size: 34px;
  line-height: 34px;
  position: absolute;
`;

export const Modal = styled(ModalBase)`
  height: calc(100% - 82px);

  div.modal-content {
    height: 100%;
  }
`;
