import React from 'react';
import { breakpoints, styled } from '@swibeco/shared';
import { useTheme } from '@emotion/react';

type PaymentIconProps = {
  id?: string;
  width?: number;
  height?: number;
  color?: string;
};

const PaymentIconWrapper = styled.span<PaymentIconProps>`
  display: inline-block;
  width: 32.48px;
  height: 22px;
  ${breakpoints.sm_max} {
    width: 30px;
    height: 30px;
  }
`;

const PaymentIcon = ({
  id = 'delivery-address-mark',
  width,
  height,
  color,
}: PaymentIconProps) => {
  const theme = useTheme();
  return (
    <PaymentIconWrapper id={id} color={color} width={width} height={height}>
      <svg
        width="inherit"
        height="inherit"
        viewBox="0 0 38 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 6.78906C1 4.02764 3.23858 1.78906 6 1.78906H26.2143C28.9757 1.78906 31.2143 4.02764 31.2143 6.78906V8.17204H1V6.78906Z"
          fillOpacity={0.1}
          fill={color || theme.colors.primary.main}
        />
        <rect
          x="31.2139"
          y="1.78906"
          width="20.4255"
          height="30.2143"
          rx="3"
          transform="rotate(90 31.2139 1.78906)"
          stroke={color || theme.colors.primary.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.64258 8.81055H31.214"
          stroke={color || theme.colors.primary.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.6433 31.7892C31.6448 30.1215 37.0004 25.8396 37.0004 21.8641V15.2473L28.6433 12.002L20.2861 15.2473V21.8641C20.2861 25.8396 25.6417 30.1215 28.6433 31.7892Z"
          fill="white"
          stroke={color || theme.colors.primary.main}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.1748 28.3283C31.0696 27.2241 34.4506 24.389 34.4506 21.757V17.376"
          stroke={color || theme.colors.primary.main}
          strokeOpacity={0.1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="26.0732"
          y="20.0967"
          width="5.14013"
          height="4.23895"
          fill={color || theme.colors.primary.main}
          fillOpacity={0.1}
          stroke={color || theme.colors.primary.main}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.0088 18.3442C27.0088 17.4416 27.7405 16.71 28.643 16.71V16.71C29.5456 16.71 30.2773 17.4416 30.2773 18.3442V19.8152C30.2773 19.9697 30.1521 20.0949 29.9976 20.0949H27.2885C27.134 20.0949 27.0088 19.9697 27.0088 19.8152V18.3442Z"
          stroke={color || theme.colors.primary.main}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </PaymentIconWrapper>
  );
};

export default PaymentIcon;
