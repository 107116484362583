import React, { useReducer } from 'react';
import {
  EmployeeContributionType,
  MealTaxDeductionType,
  SalaryMethod,
} from '@swibeco/types';
import simulatorReducer, { SimulatorState } from '../reducer/index';
import { SimulatorActions } from '../reducer/actions';

export const SimulatorStateContext = React.createContext<SimulatorState>(
  {} as SimulatorState
);

export const SimulatorDispatchContext = React.createContext<
  React.Dispatch<SimulatorActions>
>(/* istanbul ignore next */ () => {});
type SimulatorProps = {
  children: React.ReactNode;
};
const Simulator = ({ children }: SimulatorProps) => {
  const [state, dispatch] = useReducer(simulatorReducer, {
    settings: {
      months: 12,
    },
    employerConfiguration: {
      numberOfEmployees: 0,
      employerContribution: 0,
      employerSocialContributionPercentage: 20,
      employerSocialContributionRedistributedPercentage: 100,
      employeeContributionType: EmployeeContributionType.Complete,
      averageGrossSalary: 90000,
      grossSalaryConversion: true,
      netSalaryConversion: false,
      averageEmployeesSocialContributionPercentage: 15,
      averageMarginalTaxRate: 30,
      mealTaxDeductionType: MealTaxDeductionType.None,
      netSalaryConverted: 0,
      grossSalaryConverted: 0,
      amountLoadedOnTheCard: 0,
      netContract: '',
      grossContract: '',
    },
    employer: {
      firstName: '',
      lastName: '',
      function: '',
      profilePicture: '',
      testimony: '',
      testimonyYoutubeId: '',
      companyName: '',
      logo: '',
      address: {
        street: '',
        complement: '',
        postalCode: '',
        city: '',
        country: '',
      },
    },
    employee: {
      firstName: '',
      lastName: '',
      email: '',
      gender: '',
      birthDate: '',
      nationality: '',
      language: '',
      address: {
        street: '',
        complement: '',
        postalCode: '',
        city: '',
        country: '',
      },
      deliveryAddress: {
        name: '',
        street: '',
        complement: '',
        postalCode: '',
        city: '',
        country: '',
      },
      salaryMethod: SalaryMethod.Gross,
      contractAccepted: false,
    },
  });

  return (
    <SimulatorStateContext.Provider value={state}>
      <SimulatorDispatchContext.Provider value={dispatch}>
        {children}
      </SimulatorDispatchContext.Provider>
    </SimulatorStateContext.Provider>
  );
};

export const DataConstraints = {
  numberOfEmployeesMin: 1,
  employerContributionMin: 0,
  employerContributionMax: 180,
  employerContributionGrossSalaryMax: 90,
  employerSocialContributionPercentageMin: 8,
  employerSocialContributionPercentageMax: 25,
  averageGrossSalaryMin: 0,
  averageGrossSalaryMax: 250000,
  averageEmployeesSocialContributionPercentageMin: 0,
  averageEmployeesSocialContributionPercentageMax: 20,
  averageMarginalTaxRateMin: 0,
  averageMarginalTaxRateMax: 50,
};

export default Simulator;
