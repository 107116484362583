import { breakpoints, styled } from '@swibeco/shared';
import messageIcon from '../../assets/images/message-icon.svg';

export const ChatButton = styled.a`
  position: relative;
  font-size: 12px;
  min-width: 150px;
  height: 52px;
  padding: 10px 14px 10px 52px;
  background-color: ${({ theme }) => theme.colors.default.white};
  border-radius: 30px;
  color: ${({ theme }) => theme.colors.primary.dark};
  box-shadow: 0 0 4px 0 ${({ theme }) => theme.colors.legacy?.default.grey};
  margin: 16px auto;
  display: block;
  ${breakpoints.md_max} {
    margin: 32px auto;
  }
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary.dark};
    cursor: pointer;
  }
  &::before {
    position: absolute;
    left: 4px;
    height: 42px;
    width: 42px;
    line-height: 42px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: ${({ theme }) => theme.colors.default.white};
    border-radius: 50px;
    background-image: url('${messageIcon}');
    background-position: center;
    background-size: 42px;
    content: '';
  }
`;

export const ChatButtonTitle = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  line-height: normal;
`;

export const ChatButtonText = styled.span`
  font-size: 17px;
`;
