import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectors as coreSelectors } from '@swibeco/core';
import { useParams } from 'react-router-dom';
import { ProductPageDeal } from '@swibeco/types';
import ecommerceApi from '../api/ecommerceApi';

export type UseDealParams = {
  deal: ProductPageDeal;
  isLoading: boolean;
};

type IUseDealParams = {
  product: string;
};

export const useDeal = ({
  slug,
  options,
}: {
  slug?: string;
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>;
} = {}): UseDealParams => {
  const locale = useSelector(coreSelectors.getLocale);
  const { product } = useParams<IUseDealParams>();
  const productSlug = slug || product;
  const { data, isLoading } = useQuery({
    queryKey: ['sylius-product', productSlug, locale],
    queryFn: () => ecommerceApi.getDeal(productSlug!, locale as string),
    enabled: !!productSlug,
    retry: (failureCount, error: any) => {
      if (error?.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    ...options,
  });

  if (!productSlug) {
    return {} as UseDealParams;
  }

  return { deal: data, isLoading } as UseDealParams;
};
