import { useEffect } from 'react';
import { BackendEventTypeEnum } from '@swibeco/types';
import syliusApi from '../api/syliusApi';

export const useBackendPageViewEvent = (subjectId?: number | string) => {
  useEffect(() => {
    if (subjectId) {
      syliusApi.createBackendEvent({
        type: BackendEventTypeEnum.USER_VIEW_PRODUCT,
        subjectId,
      });
    }
  }, [subjectId]);
};
