/* eslint-disable indent */
import { useMemo } from 'react';
import {
  ThemeColor,
  ThemeColorSection,
  ColorVariantDescription,
} from '@swibeco/types';

const useColorVariant = <T extends ThemeColorSection>(
  variant: T,
  color: ThemeColor<T>
) =>
  useMemo(
    () =>
      ({
        variant,
        variantColor: color,
      } as unknown as ColorVariantDescription<ThemeColorSection> & string),
    [variant, color]
  );

export default useColorVariant;
