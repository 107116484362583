import { breakpoints, styled } from '@swibeco/shared';

export const MaskWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;
export const Mask = styled.div<{
  zIndex?: number;
  fullscreen?: boolean;
}>`
  transition: transform, opacity 0.3s;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  display: block;
  position: ${({ fullscreen }) => (fullscreen ? 'fixed' : 'absolute')};
  background-color: ${({ theme }) => theme.colors.default.black};
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: ${({ zIndex, fullscreen }) => (zIndex ?? fullscreen ? 1100 : 21)};

  ${breakpoints.lg} {
    z-index: ${({ zIndex, fullscreen }) => (zIndex ?? fullscreen ? 1100 : 9)};
  }
`;
