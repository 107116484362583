import { useQuery } from '@tanstack/react-query';
import ecommerceApi from '../api/ecommerceApi';

export const useUserSubscription = () => {
  const { data: subscription, ...rest } = useQuery({
    queryFn: ecommerceApi.getUserSubscription,
    queryKey: ['subscription'],
  });

  return { subscription, ...rest };
};
