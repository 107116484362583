import { breakpoints, styled } from '@swibeco/shared';

export const PlusAdCard = styled.div`
  container-type: inline-size;
  position: relative;
  height: 100%;
  min-height: 305px;
  width: auto;

  ${breakpoints.sm} {
    width: 310px !important;
    max-height: 312px !important;
    max-width: 350px !important;
  }

  ${breakpoints.sm_max} {
    width: 100% !important;
    height: 100% !important;
  }

  // fix for browser viewport wider than the phone screen in older iOS versions (<15.0)
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 100% !important;
    height: 100% !important;
    max-width: 350px !important;
  }
`;
