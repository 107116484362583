import { ThemeType } from '@swibeco/types';

export const SwibecoColors: Pick<ThemeType, 'colors'> = {
  colors: {
    primary: {
      light: '#62A5FF',
      main: '#006CFF',
      mainLight: '#E5F0FF',
      decorationLight: '#EBF1FD', // @TODO: change to mainLight to match design system
      strong: '#004CB3',
      dark: '#1E2023',
      footerBlue: '#1F3077',
      plusBlue: '#1B2A6A',
      axaBlue: '#18028E',
    },
    secondary: {
      decorationGreen: '#72D3BC',
      decoration: {
        red: {
          light: '#FFE6E6',
          palePink: '#FCDBE0', // not in design system
        },
      },
      main: '#FF006C', // not in design system
      mainLight: '#FAE7EF', // not in design system
      decorationYellow: '#FFD34D',
      lightYellow: '#FFF7DC', // not in design system
      decorationBlue: '#0B9FFA',
      ebonyClay: '#2A3643', // not in design system
      decorationRed: '#FF8182',
      decorationPurple: '#8C83FF',
      decorationOrange: '#FF9F3E',
    },
    complementary: {
      light: '#007644',
      red: '#ED0C2F',
      lightGreen: '#D9EAE3',
    },
    default: {
      white: '#FFFFFF',
      black: '#1E2023',
      light: '#F9F9F9',
      main: '#EEEEEE',
      middle: '#DEDEDE',
      strong: '#ADADAD',
      dark: '#707070',
      backgroundColor: '#F9F9FB',
    },
    shadow: {
      primary: '#3e619133',
      secondary: '#006CFF3D',
    },
    initial: {
      main: '#006CFF',
      light: '#62A5FF',
      strong: '#004CB3',
      decorationBlue: '#0B9FFA',
    },
    // colors inherited from angular and should be removed at some point
    legacy: {
      default: {
        grey: '#414A56',
        light: '#cacdd1',
        black: '#000000',
      },
    },
  },
};
