import TagManager from 'react-gtm-module';
import { AnalyticsEvents } from '@swibeco/types';

interface BannerPlusEventInterface {
  event: AnalyticsEvents;
  environment: string;
  creativeName: string;
  productId?: string | number;
  brandId?: string | number;
}

export const createBannerPlusEvent = (
  event: AnalyticsEvents,
  environment: string,
  creativeName: string,
  productId?: string | number,
  brandId?: string | number
): BannerPlusEventInterface => ({
  event,
  environment,
  creativeName,
  productId,
  brandId,
});

export const trackBannerPlusEvent = ({
  event,
  environment,
  creativeName,
  productId,
  brandId,
}: BannerPlusEventInterface) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      environment,
      ecommerce: {
        items: [
          {
            promotion_id: 'Plus banner',
            promotion_name: 'Freemium',
            creative_name: creativeName,
            creative_slot: '1',
            product_id: productId,
            brand_id: brandId,
          },
        ],
      },
    },
  });
};
