import { Button as ChakraButton, CloseButton, Flex } from '@chakra-ui/react';
import { styled, useColorVariant } from '@swibeco/shared';
import { EnumLinkProps } from '@swibeco/types';
import { AnimatedBox, Caption, Link, Text } from '@swibeco/ui';
import { useCycle } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const SwibecoLink = styled(Link)`
  &:hover {
    color: ${({ theme, color }) =>
      theme.colors[color?.variant || 'primary'][color?.variantColor || 'main']};
    text-decoration: none;
  }
`;

export default function Announcement() {
  const { t } = useTranslation();
  const whiteColor = useColorVariant('default', 'white');
  const icon = t('core.ecommerce.announcement.icon');
  const hasSeenAnnouncement =
    localStorage.getItem('hasSeenAnnouncement') === 'true';
  // const [x, cycleX] = useCycle(-332, 21); // hidden by default
  const [x, cycleX] = useCycle(
    hasSeenAnnouncement ? -332 : 21,
    hasSeenAnnouncement ? 21 : -332
  ); // shown by default

  const dismissAnnouncement = () => {
    localStorage.setItem('hasSeenAnnouncement', 'true');
  };

  if (icon === '') {
    return null;
  }
  return (
    <AnimatedBox
      pos="fixed"
      animate={{ right: x }}
      right="-332px"
      bottom="78px"
      zIndex={9999}
      display="flex"
    >
      <AnimatedBox>
        <ChakraButton
          p="12px"
          bg="#1E2023E5"
          borderRadius="5px 0px 0px 5px"
          onClick={() => {
            cycleX();
            dismissAnnouncement();
          }}
          backdropFilter="blur(6px)"
          _hover={{}}
          _active={{}}
        >
          {icon}
        </ChakraButton>
      </AnimatedBox>
      <AnimatedBox
        borderRadius="0px 5px 5px 5px"
        p="18px 44px 11px 11px"
        bg="#1E2023E5"
        backdropFilter="blur(6px)"
        pos="relative"
        width="332px"
      >
        <CloseButton
          color="default.white"
          top="12px"
          right="10px"
          position="absolute"
          onClick={() => {
            cycleX();
            dismissAnnouncement();
          }}
        />
        <Flex gap="12px" flexDir="column">
          <Flex flexDir="column">
            <Caption
              sx={{
                fontWeight: '600',
              }}
              color="complementary.red"
            >
              {t('core.ecommerce.announcement.title')}
            </Caption>
            <Text fontWeight={400} color="default.white">
              {t('core.ecommerce.announcement.description')}
            </Text>
          </Flex>
          <Flex justifyContent="flex-end">
            <SwibecoLink
              color={whiteColor}
              href={t('core.ecommerce.announcement.link')}
              // href={"/v2/core/home"}
              type={EnumLinkProps.NewPage}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('core.ecommerce.announcement.button')}
            </SwibecoLink>
          </Flex>
        </Flex>
      </AnimatedBox>
      <CloseButton
        color="default.white"
        top="12px"
        right="10px"
        position="absolute"
        onClick={() => {
          cycleX();
          dismissAnnouncement();
        }}
      />
      {/* <Flex gap="12px" flexDir="column">
        <Flex flexDir="column">
          <Caption
            sx={{
              fontWeight: '600',
            }}
            color="complementary.red"
          >
            {t('core.ecommerce.announcement.title')}
          </Caption>
          <Text fontWeight={400} color="default.white">
            {t('core.ecommerce.announcement.description')}
          </Text>
        </Flex>
        <Flex justifyContent="flex-end">
          <SwibecoLink
            color={whiteColor}
            href={t('core.ecommerce.announcement.link')}
            onClick={() => {
              cycleX();
              dismissAnnouncement();
            }}
            type={EnumLinkProps.NewPage}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('core.ecommerce.announcement.button')}
          </SwibecoLink>
        </Flex>
      </Flex> */}
    </AnimatedBox>
  );
}
