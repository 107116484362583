import { combineReducers } from '@reduxjs/toolkit';
import { reducer as core } from '@swibeco/core';
import { reducer as security } from '@swibeco/security';
import { reducer as flex } from '@swibeco/flex';
import { GlobalReducers as global } from '@swibeco/shared';
import type * as rtk from '@reduxjs/toolkit';

const createRootReducer = combineReducers({
  core,
  flex,
  security,
  global,
});

export type RootState = ReturnType<typeof createRootReducer>;
export default createRootReducer;
