import React, { useMemo } from 'react';
import { hexToHsb, hsbToHex, styled, useTheme } from '@swibeco/shared';
import { Button as RSButton, ButtonProps as RSButtonProps } from 'reactstrap';

export type ButtonColorSchemeType = {
  color: string;
  backgroundColor: string;
  borderColor: string;
  hoverColor: string;
  hoverBackgroundColor: string;
  hoverBorderColor: string;
  activeBackgroundColor: string;
  activeColor: string;
  disabledColor: string;
  disabledBackgroundColor: string;
  disabledBorderColor: string;
};

type StyledButtonProps = {
  lightColor?: boolean;
  thin?: boolean;
} & ButtonColorSchemeType;

const StyledButton = styled(RSButton, {
  shouldForwardProp: (prop) =>
    ![
      'color',
      'backgroundColor',
      'borderColor',
      'hoverColor',
      'hoverBackgroundColor',
      'hoverBorderColor',
      'activeBackgroundColor',
      'activeColor',
      'disabledColor',
      'disabledBackgroundColor',
      'disabledBorderColor',
      'thin',
      'outOfStock',
      'colorScheme',
      'as',
      'disabledColor',
      'disabledBorderColors',
      'toggled',
    ].includes(prop as string),
})<StyledButtonProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-width: 2px;
  border-color: ${({ borderColor }) => borderColor};
  font-weight: 700;
  color: ${({ color }) => color};
  border-radius: 0.3125rem;
  font-size: 1rem;

  ${({ thin }) =>
    !thin &&
    `
    height: 52px;
    padding-left: 2rem;
    padding-right: 2rem;
    `};

  &:focus {
    background-color: ${({ backgroundColor }) => backgroundColor} !important;
    border-color: ${({ borderColor }) => borderColor} !important;
    color: ${({ color }) => color} !important;
    box-shadow: none !important;
  }

  :hover {
    background-color: ${({ hoverBackgroundColor }) =>
      hoverBackgroundColor} !important;
    border-color: ${({ hoverBorderColor }) => hoverBorderColor} !important;
    color: ${({ color }) => color} !important;
    box-shadow: none !important;
  }

  &:active {
    background-color: ${({ activeBackgroundColor }) =>
      activeBackgroundColor} !important;
    border-color: ${({ activeBackgroundColor }) =>
      activeBackgroundColor} !important;
    color: ${({ activeColor }) => activeColor} !important;
    box-shadow: none !important;
  }

  &:disabled,
  &:hover:disabled {
    opacity: 1;
    color: ${({ disabledColor }) => disabledColor} !important;
    background-color: ${({ disabledBackgroundColor }) =>
      disabledBackgroundColor} !important;
    border-color: ${({ disabledBorderColor }) =>
      disabledBorderColor} !important;
  }
`;

const IconBefore = styled.span`
  margin-right: 10px;
`;

const Button = ({
  color,
  lightColor,
  icon,
  children,
  ...rest
}: RSButtonProps & {
  thin?: boolean;
}) => {
  const theme = useTheme();
  const colorScheme: ButtonColorSchemeType = useMemo(
    /* istanbul ignore next */ () => ({
      color:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? theme.colors.primary.main
            : theme.colors.default.white
          : lightColor
          ? theme.colors.default.white
          : theme.colors.primary.main,
      backgroundColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? theme.colors.default.white
            : theme.colors.primary.main
          : 'transparent',
      borderColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? theme.colors.default.white
            : theme.colors.primary.main
          : lightColor
          ? theme.colors.default.white
          : theme.colors.primary.main,
      hoverBorderColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? theme.colors.default.main
            : theme.colors.primary.strong
          : lightColor
          ? theme.colors.default.white
          : theme.colors.primary.main,
      hoverColor: lightColor
        ? theme.colors.default.white
        : theme.colors.primary.main,
      hoverBackgroundColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? theme.colors.default.main
            : theme.colors.primary.strong
          : lightColor
          ? `${theme.colors.default.white}33`
          : theme.colors.primary.decorationLight,
      activeBackgroundColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? theme.colors.primary.decorationLight
            : theme.colors.primary.strong
          : lightColor
          ? `${theme.colors.default.white}66`
          : theme.colors.primary.main,
      activeColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? theme.colors.primary.main
            : theme.colors.default.white
          : lightColor
          ? theme.colors.default.white
          : theme.colors.default.white,
      disabledColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? theme.colors.default.white
            : theme.colors.primary.light
          : lightColor
          ? `${theme.colors.default.white}66`
          : theme.colors.default.strong,
      disabledBackgroundColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? `${theme.colors.default.white}33`
            : theme.colors.primary.strong
          : 'transparent',
      disabledBorderColor:
        // eslint-disable-next-line no-nested-ternary
        color === 'primary'
          ? lightColor
            ? `${theme.colors.default.white}33`
            : theme.colors.primary.strong
          : lightColor
          ? `${theme.colors.default.white}66`
          : theme.colors.default.strong,
    }),
    [theme.colors, color, lightColor]
  );
  const colors = useMemo(() => {
    const { h, s, b } = hexToHsb(colorScheme.backgroundColor);
    if (rest.disabled) {
      if (color === 'primary' && !lightColor) {
        return {
          ...colorScheme,
          color: `${theme.colors.default.white}99`,
          hoverColor: colorScheme.disabledColor,
          activeColor: colorScheme.disabledColor,
          hoverBackgroundColor: colorScheme.disabledBackgroundColor,
          activeBackgroundColor: colorScheme.disabledBackgroundColor,
          backgroundColor: theme.colors.default.strong,
          disabledBackgroundColor: theme.colors.default.strong,
          disabledBorderColor: theme.colors.default.strong,
          disabledColor: `${theme.colors.default.white}99`,
        };
      }
      if (color === 'primary' && lightColor) {
        return {
          ...colorScheme,
          color: `${theme.colors.default.white}99`,
          hoverColor: colorScheme.disabledColor,
          activeColor: colorScheme.disabledColor,
          hoverBackgroundColor: colorScheme.disabledBackgroundColor,
          activeBackgroundColor: colorScheme.disabledBackgroundColor,
          backgroundColor: theme.colors.default.strong,
          disabledBackgroundColor: theme.colors.default.strong,
          disabledBorderColor: theme.colors.default.strong,
          disabledColor: `${theme.colors.default.white}99`,
        };
      }
      if (color !== 'primary' && lightColor) {
        return {
          ...colorScheme,
          disabledColor: theme.colors.default.strong,
          disabledBorderColor: theme.colors.default.strong,
        };
      }
      return {
        ...colorScheme,
        color: colorScheme.disabledColor,
        hoverColor: colorScheme.disabledColor,
        activeColor: colorScheme.disabledColor,
        hoverBackgroundColor: colorScheme.disabledBackgroundColor,
        activeBackgroundColor: colorScheme.disabledBackgroundColor,
        disabledBackgroundColor: colorScheme.disabledBackgroundColor,
      };
    }
    if (color === 'primary' && !lightColor) {
      if ((b >= 80 && s <= 40) || (b >= 80 && s >= 80 && h >= 40 && h <= 180)) {
        //if conditions match, modify text color to be company color with brightness set to 20 for normal, 30 for hover and active
        const themeTextColorInHsb = hexToHsb(theme.colors.primary.main);
        return {
          ...colorScheme,
          color: hsbToHex(themeTextColorInHsb.h, themeTextColorInHsb.s, 20),
          hoverColor: colorScheme.hoverColor,
          activeColor: colorScheme.activeColor,
          hoverBackgroundColor: hsbToHex(h, s, b * 0.7),
          activeBackgroundColor: hsbToHex(h, s, b * 0.7),
        };
      }
      const themeBackgroundColorInHsb = hexToHsb(theme.colors.primary.main);
      return {
        ...colorScheme,
        color: colorScheme.color,
        hoverColor: colorScheme.hoverColor,
        activeColor: colorScheme.activeColor,
        hoverBackgroundColor: hsbToHex(
          themeBackgroundColorInHsb.h,
          themeBackgroundColorInHsb.s,
          themeBackgroundColorInHsb.b * 0.7
        ),
        activeBackgroundColor: hsbToHex(
          themeBackgroundColorInHsb.h,
          themeBackgroundColorInHsb.s,
          themeBackgroundColorInHsb.b * 0.7
        ),
      };
    }
    if (color === 'custom' && rest.colorScheme) {
      return rest.colorScheme;
    }
    if (color !== 'primary' && !lightColor) {
      return {
        ...colorScheme,
        color: colorScheme.color,
        hoverColor: colorScheme.hoverColor,
        activeColor: colorScheme.activeColor,
        hoverBackgroundColor: `${theme.colors.primary.main}1a`,
        activeBackgroundColor: theme.colors.primary.main,
      };
    }
    if (color !== 'primary' && lightColor) {
      return {
        ...colorScheme,
        color: colorScheme.color,
        hoverColor: colorScheme.hoverColor,
        activeColor: colorScheme.activeColor,
        backgroundColor: 'transparent',
        hoverBackgroundColor: `${theme.colors.default.white}33`,
        activeBackgroundColor: `${theme.colors.default.white}33`,
      };
    }
    return {
      ...colorScheme,
      color: colorScheme.color,
      hoverColor: colorScheme.hoverColor,
      activeColor: colorScheme.activeColor,
      hoverBackgroundColor: colorScheme.hoverBackgroundColor,
      activeBackgroundColor: colorScheme.activeBackgroundColor,
    };
  }, [
    colorScheme,
    color,
    lightColor,
    rest.disabled,
    theme.colors,
    rest.colorScheme,
  ]);
  return (
    <StyledButton
      {...rest}
      {...colorScheme}
      {...colors}
      hoverBackgroundColor={
        rest.hoverBackgroundColor ||
        colors.hoverBackgroundColor ||
        colorScheme.hoverBackgroundColor
      }
    >
      {icon && <IconBefore>{icon}</IconBefore>}
      {children}
    </StyledButton>
  );
};

export default Button;
