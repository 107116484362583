import React from 'react';
import { Label as BaseLabel, LabelProps as BaseLabelProps } from 'reactstrap';
import { styled } from '@swibeco/shared';
import { ColorProps } from '@swibeco/types';

type LabelProps = BaseLabelProps &
  ColorProps & {
    required?: boolean;
  };

const StyledLabel = styled(BaseLabel, {
  shouldForwardProp: (prop) => !['required', 'variantColor'].includes(prop),
})<LabelProps>`
  position: relative;
  color: ${({ theme, variant, variantColor }) =>
    theme.colors[variant][variantColor]};
  &:after {
    color: ${({ theme }) => theme.colors.secondary.main};
    content: '*';
    font-weight: 500;
    font-size: 18px;
    position: absolute;
    top: 0;
    left: calc(100% + 5px);
    display: ${({ required }) => (required ? 'block' : 'none')};
  }
`;

const Label = ({
  children,
  required,
  className,
  color,
  ...rest
}: LabelProps) => (
  <StyledLabel
    required={required}
    className={className || 'mb-2'}
    variant={color?.variant || 'primary'}
    variantColor={color?.variantColor || 'dark'}
    {...rest}
  >
    {children}
  </StyledLabel>
);

export default Label;
